<!-- Show summery here -->
<template>
  <div>
    <Slide @closeMenu="handleCloseMenu" :isOpen="shakingIconAction.mrttOpen" :burgerIcon="false" right width="350">
      <!-- full width space -->
      <div class="row">
        <div class="col-sm-12">
          <div class="col">
            <div class="list-group">
              <div class="list-group-item premium">
                <table class="table table-borderless tbl-font-size">
                  <tr>
                    <td>Rate</td>
                    <td>{{ calcSummary.summary_formatted.rate }}%</td>
                  </tr>
                  <tr>
                    <td>Term</td>
                    <td>{{ calcSummary.summary_formatted.term }}</td>
                  </tr>
                  <tr>
                    <td>Grace</td>
                    <td>
                      {{ calcSummary.summary_formatted.grace == 1 ? "Yes": "No" }}
                    </td>
                  </tr>
                  <tr v-if="calcSummary.summary_formatted.grace == 1">
                    <td>Grace Period</td>
                    <td>{{ calcSummary.summary_formatted.grace_period }}</td>
                  </tr>
                  <tr>
                    <td>Loan Amount</td>
                    <td>{{ calcSummary.summary_formatted.loan_amount }}</td>
                  </tr>
                  <tr>
                    <td>Discount on WF</td>
                    <td>{{ calcSummary.summary_formatted.discount_Wf }}%</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="col">
            <table class="tbl-width">
              <tbody>
                <!--<tr>
                  <td>
                    <div class="list-group">
                      <a class="list-group-item visitor">
                        <table class="tbl-width">
                          <tbody>
                            <tr>
                              <td>Wakalah Fee</td>
                              <td class="notify-td">
                                <h6 class="list-group-item-heading count h6">
                                  Rs.
                                  {{
                                    calcSummary.summary_formatted.wakalah_fee
                                  }}
                                </h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="list-group">
                      <a class="list-group-item visitor">
                        <table class="tbl-width">
                          <tbody>
                            <tr>
                              <td>Tabarru</td>
                              <td class="notify-td">
                                <h6 class="list-group-item-heading count h6">
                                  Rs.
                                  {{ calcSummary.summary_formatted.tabarru }}
                                </h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="list-group">
                      <a class="list-group-item visitor">
                        <table class="tbl-width">
                          <tbody>
                            <tr>
                              <td>Grace</td>
                              <td class="notify-td">
                                <h6 class="list-group-item-heading count h6">
                                  Rs.
                                  {{
                                    calcSummary.summary_formatted.grace_amount
                                  }}
                                </h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="list-group">
                      <a class="list-group-item visitor">
                        <table class="tbl-width">
                          <tbody>
                            <tr>
                              <td>Cess</td>
                              <td class="notify-td">
                                <h6 class="list-group-item-heading count h6">
                                  Rs.
                                  {{ calcSummary.summary_formatted.cess }}
                                </h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="list-group">
                      <a class="list-group-item visitor">
                        <table class="tbl-width">
                          <tbody>
                            <tr>
                              <td>Tabarru Add</td>
                              <td class="notify-td">
                                <h6 class="list-group-item-heading count h6">
                                  Rs.
                                  {{
                                    calcSummary.summary_formatted.tabarru_add
                                  }}
                                </h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </a>
                    </div>
                  </td>
                </tr>-->
                <tr>
                  <td>
                    <div class="list-group">
                      <a class="list-group-item premium">
                        <table class="tbl-width">
                          <tbody>
                            <tr>
                              <td>Premium</td>
                              <td class="notify-td">
                                <h6 class="list-group-item-heading count h6">
                                  Rs.
                                  {{
                                    calcSummary.summary_formatted.total_premium
                                  }}
                                </h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </a>
                    </div>
                  </td>
                </tr>
                <!--<tr  v-if="calcSummary.summary_formatted.main_type == 2">
                  <td>
                    <div class="list-group">
                      <a class="list-group-item visitor">
                        <table class="tbl-width">
                          <tbody>
                            <tr>
                              <td>1st Life Premium</td>
                              <td class="notify-td">
                                <h6 class="list-group-item-heading count h6">
                                  Rs.
                                  {{
                                    calcSummary.summary_formatted
                                      .first_life_premium
                                  }}
                                </h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr  v-if="calcSummary.summary_formatted.main_type == 2">
                  <td>
                    <div class="list-group">
                      <a class="list-group-item visitor">
                        <table class="tbl-width">
                          <tbody>
                            <tr>
                              <td>2nd Life Premium</td>
                              <td class="notify-td">
                                <h6 class="list-group-item-heading count h6">
                                  Rs.
                                  {{
                                    calcSummary.summary_formatted
                                      .second_life_premium
                                  }}
                                </h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </a>
                    </div>
                  </td>
                </tr>-->
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
    </Slide>
  </div>
</template>

<script>
import { Slide } from "vue-burger-menu";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("quote_mrtt", ["calcSummary"]),
    ...mapState("common_actions", ["shakingIconAction"])
  },
  name: "SumView",
  components: {
    Slide,
  },
  methods:{
    handleCloseMenu(){
      this.shakingIconAction.shake = false;
      this.shakingIconAction.mrttOpen = false;
    }
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  border: 1px solid red;
}

div.card .card-header {
  background: #0560dd !important; /* fallback for old browsers */
  box-shadow: 0 10px 10px -12px rgba(0, 0, 0, 0.42),
    0 4px 10px 0px rgba(0, 0, 0, 0.12), 0 5px 7px -5px rgba(0, 0, 0, 0.2);
}

.a-tag {
  color: #007bff !important;
  text-decoration: none;
  background-color: transparent;
  margin-left: 30px;
}

.bm-item-list {
  margin-left: 0 !important;
  font-size: 12px !important;
  color: #5d5d5d;
}

.bm-burger-button {
  position: fixed;
}

.tbl-width {
  width: 100%;
}

.tbl-font-size {
  font-size: 12px !important;
}

.benefit-header {
  margin-top: 15px;
  margin-bottom: 8px;
}

.tbl-header {
  text-align: center;
}

.tbl-header2 {
  text-align: right;
}

.notify-td {
  text-align: right;
}

.table-top {
  margin-top: 20px;
}

.tbl-pad {
  margin: 0 10px 0 10px;
}

.tbl-btm {
  margin-bottom: 20px;
}

.policy-box {
  background-color: #1cadb6 !important;
  color: #fff !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.paying-box {
  background-color: #7289da !important;
  color: #fff !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.s-row-top {
  margin-top: 0.7rem;
}

.h6 {
  margin-bottom: 0rem !important;
}

.p {
  font-size: 12px;
}

.bm-burger-button {
  cursor: pointer;
  height: 125px !important;
  left: 18px;
  position: fixed;
  top: 75px !important;
  width: 16px !important;
  /*left: auto;*/
  right: 18px !important;
  z-index: 100000;
}

.fa {
  font-size: 50px;
  text-align: right;
  position: absolute;
  top: 7px;
  right: 27px;
  outline: none;
}
a {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.list-group-item {
  font-size: 12px !important;
  padding: 0.5rem 1.25rem !important;
}
/* Visitor */
a.visitor i,
.visitor h6.list-group-item-heading {
  color: #faa61a;
  font-size: 12px !important;
}
a.visitor:hover {
  background-color: #faa61a;
  text-decoration: none !important;
}
a.visitor:hover * {
  color: #fff;
  text-decoration: none !important;
}

.premium h6.list-group-item-heading {
  color: rgb(7, 176, 228);
}
a.Premium:hover {
  background-color: #faa61a;
  text-decoration: none !important;
}
a.Premium:hover {
  color: #fff;
  text-decoration: none !important;
}

.p-color {
  color: #fff !important;
}
</style>
