<!-- Application Footer Component -->
<template>
  <footer class="main-footer" fixed color="primary lighten-1">
    <b-row class="fluid-container">
      <b-col col-sm="6" class="f-txt-align-l footer-text-l">
        <strong>
          Copyright &copy; 2019
          <a href="https://www.tryonics.com/" class="tryonics_link" target="”_blank”">Tryonics</a>
        </strong> All rights reserved.
      </b-col>
      <b-col col-sm="6" class="f-txt-align-r footer-text-r hidden-xs">
        Solution by
        <strong>
          <a href="https://www.tryonics.com/" class="tryonics_link" target="”_blank”">Tryonics</a>
        </strong>
      </b-col>
    </b-row>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.f-txt-align-l {
  text-align: left;
}

.f-txt-align-r {
  text-align: right;
}

footer {
   position:fixed; 
  clear: both;
  left: 0;
  bottom: 0 !important;
  width: 100%;
  background-color: #ffffff;
  color: #555;
  text-align: center;
  padding: 12px !important;
  font-size: 14px !important;
}

.tryonics_link{
  color: #35bd13;
}


@media (max-width: 767px){
  .footer-text-r {
    visibility: hidden !important;
    display: none !important;
  }
  .footer-text-l {
    text-align: center !important;
  }

}

</style>
