var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row-top-list2 table-row-top"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('font-awesome-icon',{attrs:{"icon":"align-left"}}),_vm._v("  "),_c('span',[_vm._v(_vm._s(_vm.$t("list_branch")))]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Add New Branch'),expression:"'Add New Branch'",modifiers:{"hover":true,"bottom":true}}],staticClass:"ui btn-sm basic button",staticStyle:{"margin":"5px padding: .25rem .65rem","float":"right"},attrs:{"variant":"success","title":"Add New Branch"},on:{"click":function($event){return _vm.onActionClicked('add-item')}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" Add New Branch ")],1)],1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row tbl-margin-top"},[_c('div',{staticClass:"col table-responsive"},[_c('vuetable',{ref:"branchTable",staticClass:"table table-striped table-bordered nowrap vue-tbl",attrs:{"api-url":_vm.getUrl,"fields":_vm.fields,"pagination-path":"","per-page":3,"row-class":_vm.onRowClass,"http-options":_vm.httpOptions,"append-params":_vm.moreParams},on:{"vuetable:pagination-data":_vm.onPaginationData},scopedSlots:_vm._u([{key:"actions",fn:function(props){return [_c('div',{staticClass:"custom-actions",staticStyle:{"white-space":"nowrap","text-align":"center"}},[(
                    _vm.hasPermission('branch-mgt') &&
                    _vm.checkEditable(props.rowData['leadstatus']) == true
                  )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"bottom":true}}],staticClass:"ui btn-sm basic button",staticStyle:{"margin":"5px padding: .25rem .65rem"},attrs:{"variant":"info","title":"Edit"},on:{"click":function($event){return _vm.onActionClicked(
                      'edit-item',
                      props.rowData,
                      props.rowIndex
                    )}}},[_c('font-awesome-icon',{attrs:{"icon":"pen"}})],1):_vm._e()],1)]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"pagination"},[_c('vuetable-pagination',{ref:"pagination",on:{"vuetable-pagination:change-page":_vm.onChangePage}})],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }