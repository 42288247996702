<!-- Quotation Family Information Capture Widget  -->
<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header header-font">
            <font-awesome-icon icon="users" />
            <span>&nbsp;{{ $t('familyInfoHeader') }}</span>
          </div>
          <div class="card-body box-body">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lblMaritalStatus">{{ $t('familyInfoLblMaritalStatus') }}</label>
                  <select
                    class="form-control"
                    v-model="familyInfoData.drpFamilyInfoMaritalStatus"
                    @change="sendChangedData()"
                    data-vv-as="Marital Status"
                    :disabled="mainInfoData.rdbMainLifeFrequency=='S'"
                  >
                    <option disabled value>Please select</option>
                    <option>Married</option>
                    <option>Unmarried</option>
                    <option>Divorced</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lblSpouse">{{ $t('familyInfoLblSpouse') }}</label>
                  <div class="toggleswitchspouse">
                    <input
                      id="rdb_spouse_yes"
                      name="familyInfoData.rdbSpouse"
                      type="radio"
                      value="Yes"
                      :disabled="familyInfoData.drpFamilyInfoMaritalStatus != 'Married'"
                      v-model="familyInfoData.rdbSpouse"
                      @change="sendChangedData()"
                    />
                    <label for="rdb_spouse_yes">Yes</label>
                    <input
                      id="rdb_spouse_no"
                      name="familyInfoData.rdbSpouse"
                      type="radio"
                      value="No"
                      v-model="familyInfoData.rdbSpouse"
                      @change="sendChangedData()"
                    />
                    <label for="rdb_spouse_no">No</label>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div
                  class="form-group"
                  v-if="familyInfoData.drpFamilyInfoMaritalStatus == 'Married' || familyInfoData.drpFamilyInfoMaritalStatus == 'Divorced'"
                >
                  <label for="lblChildren">{{ $t('familyInfoLblChildren') }}</label>
                  <div class="toggleswitchchildren">
                    <input
                      id="child_aa"
                      name="rdbChildren"
                      type="radio"
                      value="0"
                      @change="addTplChild('0');sendChangedData()"
                      v-model="familyInfoData.rdbChildren"
                    />
                    <label for="child_aa">0</label>
                    <input
                      id="child_a"
                      name="rdbChildren"
                      type="radio"
                      value="1"
                      @change="addTplChild('1');sendChangedData()"
                      v-model="familyInfoData.rdbChildren"
                    />
                    <label for="child_a">1</label>
                    <input
                      id="child_b"
                      name="rdbChildren"
                      type="radio"
                      value="2"
                      @change="addTplChild('2');sendChangedData()"
                      v-model="familyInfoData.rdbChildren"
                    />
                    <label for="child_b">2</label>
                    <input
                      id="child_c"
                      name="rdbChildren"
                      type="radio"
                      value="3"
                      @change="addTplChild('3');sendChangedData()"
                      v-model="familyInfoData.rdbChildren"
                    />
                    <label for="child_c">3</label>
                    <input
                      id="child_d"
                      name="rdbChildren"
                      type="radio"
                      value="4"
                      @change="addTplChild('4');sendChangedData()"
                      v-model="familyInfoData.rdbChildren"
                    />
                    <label for="child_d">4</label>

                    <input
                      id="child_e"
                      name="rdbChildren"
                      type="radio"
                      value="5"
                      @change="addTplChild('5');sendChangedData()"
                      v-model="familyInfoData.rdbChildren"
                    />
                    <label for="child_e">5</label>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SpouseInfoWidget
      v-if="familyInfoData.drpFamilyInfoMaritalStatus === 'Married' && familyInfoData.rdbSpouse === 'Yes'"
    />
    <div>
      <ChildInfoWidget v-for="(child, index) in childInfoData" :index="index" :key="index" />
    </div>
  </div>
</template>

<script>
import SpouseInfoWidget from "@/components/quote_flexi_life/SpouseInfoWidget";
import ChildInfoWidget from "@/components/quote_flexi_life/ChildInfoWidget";
import { SnotifyPosition } from "vue-snotify";
import { throttle } from "lodash";

import { mapState, mapActions } from "vuex";

export default {
  name: "frmFamilyInfo",
  components: { SpouseInfoWidget, ChildInfoWidget },
  data() {
    return {};
  },
  computed: {
    ...mapState("quote_flexi_life", [
      "familyInfoData",
      "childInfoData",
      "mainInfoData",
      "flash",
      "minflash"
    ])
  },
  mounted() {
    if (this.$route.params.ref_no) {
      //this.loadQuotation();
      //console.log(this.familyInfoData.rdbChildren);
    }
  },
  methods: {
    ...mapActions("quote_flexi_life", ["addChild", "addChangedData"]),
    addTplChild(count) {
      this.addChild(count);
    },
    sendChangedData: throttle(async function() {
      this.$snotify.clear();
      /** Loading (HTML snotify component) */
      this.$snotify.html(
        `<div class="snotifyToast__title"><b>Calculating...</b></div>
            <div class="snotifyToast__body"> Mode Premium Amount</div>
            <div class="snotify-icon snotify-icon--async"></div> `,
        {
          timeout: stop,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          position: SnotifyPosition.centerTop,
          type: "async"
        }
      );
      return this.getChangeDataResponse()
        .then(
          function(response) {
            this.$snotify.clear();
            if (response.status && response.type == undefined) {
              /** Success Message  */
              this.$snotify.success("Mode Premium Amount", response.error, {
                timeout: stop,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop
              });
            } else {
              this.$snotify.clear();
              /** Error Message  */
              this.$snotify.error(response.error, "Error", {
                timeout: stop,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop
              });
            }
          }.bind(this)
        )
        .catch(function(error) {
          this.$snotify.clear();
          /** Exception Message */
          this.$snotify.error(error, "Error", {
            timeout: stop,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            position: SnotifyPosition.centerTop
          });
        });
    }, process.env.VUE_APP_QUOT_TIMEOUT_SEND_CHANGED_DATA),
    async getChangeDataResponse() {
      await this.addChangedData();
      if (this.minflash.status) {
        return this.minflash;
      } else {
        return this.flash;
      }
    }
  },
  watch: {
    "familyInfoData.drpFamilyInfoMaritalStatus": {
      handler: function() {
        if (this.familyInfoData.drpFamilyInfoMaritalStatus == "Unmarried") {
          this.familyInfoData.rdbChildren = 0;
          this.addChild(0);
        }
      }
    }
  }
};
</script>

<style scoped>
/*--- Card css-----*/

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #556065 !important;
  border-bottom: 0px solid #06a990 !important;
  color: #fff !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #06a990 !important;
  border-radius: 0.25rem;
}

/*-- Toggle switch for spouse yes/no Family Info Widget ----*/

.toggleswitchspouse label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchspouse input:checked,
.toggleswitchspouse input:not(:checked) {
  display: none;
}

.toggleswitchspouse input:checked + label {
  background: #92D127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92D127;
}

/*-- Toggle switch for no of children Family Info Widget----*/

.toggleswitchchildren label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchchildren input:checked,
.toggleswitchchildren input:not(:checked) {
  display: none;
}

.toggleswitchchildren input:checked + label {
  background: #92D127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92D127;
}

div.card {
  border: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #06a990 !important;
  border-radius: 0.25rem;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

html {
  font-size: 10px;
}
</style>

<!-- Language Data for FamilyInfoWidget -->
<i18n>
{
  "en": {
    "familyInfoHeader": "Family Information",
    "familyInfoLblMaritalStatus": "Marital Status",
    "familyInfoLblSpouse": "Spouse",
    "familyInfoLblChildren": "Children",
    "childInfoHeaderOne": "Child",
    "childInfoHeaderTwo": "Information"
  },
  "si": {
    "familyInfoHeader": "රක්ෂිතයාගේ පවුලේ තොරතුරු විවාහක අවිවාහක බව",
    "familyInfoLblMaritalStatus": "විවාහක අවිවාහක බව",
    "familyInfoLblSpouse": "කාලත්‍රයා",
    "familyInfoLblChildren": "දරුවන්",
    "childInfoHeaderOne": "දරුවා",
    "childInfoHeaderTwo": "තොරතුරු"
  },
  "ta": {
  "familyInfoHeader": "குடும்ப விபரங்கள்",
  "familyInfoLblMaritalStatus": "விவாக நிலை",
  "familyInfoLblSpouse": "வாழ்க்கைத்துணை",
  "familyInfoLblChildren": "குழந்தைகள்",
  "childInfoHeaderOne": "Child",
  "childInfoHeaderTwo": "Information"
  }
}
</i18n>