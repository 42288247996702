<template>
  <div class="admin">
    <Header />

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/layout/AppHeader.vue";
import Footer from "@/components/common/layout/AppFooter.vue";

export default {
  name: "admin",
  components: {
    Header,
    Footer
  },
  data() {
    return { active: false };
  },
  beforeDestroy: function() {
    //console.log("DESTROYYYY!!!");
  }
};
</script>

<style>

  footer {

    clear: both;
    position: relative;
    /* margin-top: 30px !important; */
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    padding: 12px !important;
    font-size: 14px !important;
  }
</style>

