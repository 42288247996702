<!-- Quotation Life Detail Cover  -->
<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header header-font">
            <font-awesome-icon icon="users" />
            <span>&nbsp;{{ $t('widgetHeader') }}</span>
          </div>
          <div class="card-body box-body">
            <div class="row">
              <div class="col-sm-2">
                <div class="form-group">
                  <label for="lbl_main_sum_cover">
                    {{
                    $t("mainLifeLblSumCover")
                    }}
                  </label>
                  <ValidationProvider name="Sum Cover" rules="required|numeric|min_value:0">
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        name="Sum Cover"
                        ref="SumCover"
                        v-model="mainInfoData.txtMainLifeSumCover"
                        @change="
                          calcReverseSumCoverUpdate();
                          sendChangedData();
                        "
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label for="lbl_main_pol_pay_term">
                    {{
                    $t("mainLifeLblPolicyTerm")
                    }}
                  </label>
                  <ValidationProvider
                    name="Policy Term"
                    rules="required|numeric|minmax_value:10,40"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        name="PolicyPayingTerm"
                        ref="PolicyPayingTerm"
                        v-model="mainInfoData.txtMainLifePolicyTerm"
                        @change="
                          calcSumCoverUpdate();
                          sendChangedData();
                        "
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label for="lbl_main_frequency">
                    {{
                    $t("mainLifeLblFrequency")
                    }}
                  </label>
                  <div class="togglefreq">
                    <input
                      id="rdb_main_freq_m"
                      name="optionf"
                      type="radio"
                      value="M"
                      v-model="mainInfoData.rdbMainLifeFrequency"
                      @change="
                        calcSumCoverUpdate();
                        sendChangedData();
                      "
                    />
                    <label for="rdb_main_freq_m">M</label>
                    <input
                      checked="checked"
                      id="rdb_main_freq_q"
                      name="optionf"
                      type="radio"
                      value="Q"
                      v-model="mainInfoData.rdbMainLifeFrequency"
                      @change="
                        calcSumCoverUpdate();
                        sendChangedData();
                      "
                    />
                    <label for="rdb_main_freq_q">Q</label>
                    <input
                      checked="checked"
                      id="rdb_main_freq_h"
                      name="optionf"
                      type="radio"
                      value="H"
                      v-model="mainInfoData.rdbMainLifeFrequency"
                      @change="
                        calcSumCoverUpdate();
                        sendChangedData();
                      "
                    />
                    <label for="rdb_main_freq_h">H</label>
                    <input
                      checked="checked"
                      id="rdb_main_freq_a"
                      name="optionf"
                      type="radio"
                      value="A"
                      v-model="mainInfoData.rdbMainLifeFrequency"
                      @change="
                        calcSumCoverUpdate();
                        sendChangedData();
                      "
                    />
                    <label for="rdb_main_freq_a">A</label>
                  </div>
                </div>
              </div>

              <div class="col-sm-2">
                <div class="form-group">
                  <label for="lbl_main_cover_multiple">{{ $t("mainLifeLblCoverMultiple") }}</label>
                  <ValidationProvider name="Cover Multiple" rules="required">
                    <div slot-scope="{ errors }">
                      <select
                        class="form-control"
                        v-model="mainInfoData.drpMainLifeCoverMultiple"
                        name="CoverMultiple"
                        @change="
                        calcSumCoverUpdate();
                        sendChangedData();
                      "
                      >
                        <option>Please select</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-2">
                <div class="form-group">
                  <label
                    v-if="mainInfoData.rdbMainLifeFrequency === 'S'"
                    for="lbl_main_monthly_basic_prem"
                  >{{ $t("mainLifeLblSingleBasicPrem") }}</label>
                  <label v-else for="lbl_main_monthly_basic_prem">
                    {{
                    $t("mainLifeLblMonthBasicPrem")
                    }}
                  </label>
                  <ValidationProvider name="Monthly Mode Basic Premium" rules="required">
                    <div slot-scope="{ errors }">
                      <vue-numeric
                        type="text"
                        class="form-control"
                        id="txtMainLifeMonthlyBasicPrem"
                        name="MonthlyBasicPremium"
                        v-model="mainInfoData.txtMainLifeMonthlyBasicPrem"
                        @change.native="
                          calcSumCoverUpdate();
                          sendChangedData();
                        "
                        separator=","
                        v-bind:min="3000"
                        currency="Rs. "
                      ></vue-numeric>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-1">
                <div class="form-group">
                  <label for="lbl_main_pension_term">
                    {{
                    $t("mainLifeLblPensionPeriod")
                    }}
                  </label>
                  <ValidationProvider
                    name="Policy Term"
                    rules="required|numeric|minmax_value:5,45"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        name="PensionPeriod"
                        ref="PensionPeriod"
                        v-model="mainInfoData.txtMainLifePensionPeriod"
                        @change="
                          calcSumCoverUpdate();
                          sendChangedData();
                        "
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>


              <div class="col-sm-2">
                <div class="form-group">
                  <label for="lbl_main_med-rem3-floater">
                    {{
                    $t("mainLblMED-REM3Floater")
                    }}
                  </label>
                  <div class="toggleswitchg">
                    <input
                      id="rdb_medrem3_floater_yes"
                      name="mainInfoData.rdbMainMedrem3Floater"
                      type="radio"
                      v-model="mainInfoData.rdbMainMedrem3Floater"
                      @change="sendChangedData()"
                      value="Yes"
                    />
                    <label for="rdb_medrem3_floater_yes">Yes</label>
                    <input
                      id="rdb_medrem3_floater_no"
                      name="mainInfoData.rdbMainMedrem3Floater"
                      type="radio"
                      v-model="mainInfoData.rdbMainMedrem3Floater"
                      @change="sendChangedData()"
                      value="No"
                    />
                    <label for="rdb_medrem3_floater_no">No</label>
                  </div>
                </div>
              </div>


              <div class="col-sm-2">
                <div class="form-group">
                  <label for="lbl_main_sum_at_risk_prev_pol">
                    {{
                    $t("mainLblSumAtRiskPrevPol")
                    }}
                  </label>
                  <ValidationProvider name="Sum at risk" rules="required|numeric">
                    <div slot-scope="{ errors }">
                      <input
                        ref="SumAtRisk"
                        type="text"
                        class="form-control"
                        id="txtMainSumAtRisk"
                        name="SumAtRisk"
                        placeholder="Sum At Risk of Previous Policies"
                        v-model="mainInfoData.txtMainSumAtRisk"
                        @change="sendChangedData()"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { SnotifyPosition } from "vue-snotify";
import { throttle } from "lodash";

export default {
  data() {
    return {
      form: {
        sumCover: 0
      }
    };
  },
mounted() {
    this.calcSumCoverUpdate();
  },
  
  computed: {
    ...mapState("quote_deergayu", [
      "mainInfoData",
      "flash",
      "minflash",
      "quotationNo",
      "version",
      "timestamp"
    ])
  },
  methods: {
    ...mapActions("quote_deergayu", [
      "addChangedData",
      "getOneQuotation",
      "getRidersList",
      "clearStateData"
    ]),
    calcSumCoverUpdate() {
      var frequency;
      switch (this.mainInfoData.rdbMainLifeFrequency) {
        case "M":
          frequency = 12;
          break;
        case "Q":
          frequency = 4;
          break;
        case "H":
          frequency = 2;
          break;
        case "A":
          frequency = 1;
          break;
      }

      this.mainInfoData.txtMainLifeSumCover =
        this.mainInfoData.txtMainLifeMonthlyBasicPrem *
        this.mainInfoData.drpMainLifeCoverMultiple *
        frequency;

        this.calcReverseSumCoverUpdate();
    },
    calcReverseSumCoverUpdate() {
      var frequency;
      switch (this.mainInfoData.rdbMainLifeFrequency) {
        case "M":
          frequency = 12;
          break;
        case "Q":
          frequency = 4;
          break;
        case "H":
          frequency = 2;
          break;
        case "A":
          frequency = 1;
          break;
      }

      this.mainInfoData.txtMainLifeMonthlyBasicPrem =
        this.mainInfoData.txtMainLifeSumCover /
        this.mainInfoData.drpMainLifeCoverMultiple /
        frequency;
    },
    sendChangedData: throttle(async function() {
      this.$snotify.clear();
      /** Loading (HTML snotify component) */
      this.$snotify.html(
        `<div class="snotifyToast__title"><b>Calculating...</b></div>
            <div class="snotifyToast__body"> Mode Premium Amount</div>
            <div class="snotify-icon snotify-icon--async"></div> `,
        {
          timeout: stop,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          position: SnotifyPosition.centerTop,
          type: "async"
        }
      );
      return this.getChangeDataResponse()
        .then(
          function(response) {
            this.$snotify.clear();
            if (response.status && response.type == undefined) {
              /** Success Message  */
              this.$snotify.success("Mode Premium Amount", response.error, {
                timeout: stop,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop
              });
            } else {
              this.$snotify.clear();
              /** Error Message  */
              this.$snotify.error(response.error, "Error", {
                timeout: stop,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop
              });
            }
          }.bind(this)
        )
        .catch(function(error) {
          this.$snotify.clear();
          /** Exception Message */
          this.$snotify.error(error, "Error", {
            timeout: stop,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            position: SnotifyPosition.centerTop
          });
        });
    }, process.env.VUE_APP_QUOT_TIMEOUT_SEND_CHANGED_DATA),

    async getChangeDataResponse() {
      await this.addChangedData();
      if (this.minflash.status) {
        return this.minflash;
      } else {
        return this.flash;
      }
    }
  }
};
</script>
<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #556065 !important;
  border-bottom: 0px solid #06a990 !important;
  color: #fff !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #06a990 !important;
  border-radius: 0.25rem;
}

.snotify {
  display: block;
  position: fixed;
  width: 99% !important;
  z-index: 9999;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: none;
}
/*-- toggle switch for Main Life Frequency ----*/

.togglefreq label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.togglefreq input:checked,
.togglefreq input:not(:checked) {
  display: none;
}

.togglefreq input:checked + label {
  background: #92D127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92D127;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked + label {
  background: #92D127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92D127;
}

@media (max-width: 768px) {
  .top-margin {
    margin-top: 60px;
  }
}
</style>
<!-- Language Data for LifeCoveredWidget -->
<i18n>
{
  "en": {
    "widgetHeader": "Life Covered",
    "mainLifeLblMonthBasicPrem": "Basic Premium",
    "mainLifeLblSingleBasicPrem": "Single Premium",
    "mainLifeLblPolicyTerm": "Contribution Accum. Period",
    "mainLifeLblPremTerm": "Premium Paying Term",
    "mainLifeLblPolicyType": "Policy Paying Type",
    "mainLifeLblFrequency": "Frequency",
    "mainLblSumAtRiskPrevPol": "Previous Sum at Risk(if any)",
    "mainLblNumOfSumAsTimes": "No of Sum Assured Times",
    "mainLblMED-REM3Floater": "Family Floater",
    "mainLifeLblSumCover" : "Life Cover",
    "mainLifeLblPensionPeriod": "Pen. Period",
    "mainLifeLblCoverMultiple": "Cover Multiple"
  },
  "si": {
    "widgetHeader": "රක්ෂිතයාගේ පවුලේ තොරතුරු විවාහක අවිවාහක බව",
    "mainLifeLblMonthBasicPrem": "Basic Premium",
    "mainLifeLblSingleBasicPrem": "Single Premium",
    "mainLifeLblPolicyTerm": "Policy Term",
    "mainLifeLblPremTerm": "Premium Paying Term",
    "mainLifeLblPolicyType": "Policy Paying Type",
    "mainLifeLblFrequency": "Frequency",
    "mainLblSumAtRiskPrevPol": "Previous Sum at Risk(if any)",
    "mainLblNumOfSumAsTimes": "No of Sum Assured Times",
    "mainLblMED-REM3Floater": "Family Floater",
    "mainLifeLblSumCover" : "Life Cover",
    "mainLifeLblPensionPeriod": "Pen. Period",
    "mainLifeLblCoverMultiple": "Cover Multiple"
  },
  "ta": {
    "widgetHeader": "குடும்ப விபரங்கள்",
    "mainLifeLblMonthBasicPrem": "Basic Premium",
    "mainLifeLblSingleBasicPrem": "Single Premium",
    "mainLifeLblPolicyTerm": "Policy Term",
    "mainLifeLblPremTerm": "Premium Paying Term",
    "mainLifeLblPolicyType": "Policy Paying Type",
    "mainLifeLblFrequency": "Frequency",
    "mainLblSumAtRiskPrevPol": "Previous Sum at Risk(if any)",
    "mainLblNumOfSumAsTimes": "No of Sum Assured Times",
    "mainLblMED-REM3Floater": "Family Floater",
    "mainLifeLblSumCover" : "Life Cover",
     "mainLifeLblPensionPeriod": "Pen. Period",
    "mainLifeLblCoverMultiple": "Cover Multiple"
  }
}
</i18n>