<template>
  <ValidationObserver ref="health_family_info_row">
    <div class="row">
      <!-- Main Member details -->
      <div class="col-md-6 col-sm-6">
        <div class="section-header-new"><strong>{{ $t("Insured") }}</strong></div>
        <div class="row mb-2 nominee-full-row" v-for="(member_input, member_index) in FamilyMemberInfo"
          :key="member_index">
          <div class="col">
            
            <div class="card-outline">
              
              <div class="row input-row" v-show="member_index > 1 && (member_index || (!member_index && FamilyMemberInfo.length > 0))">
                <div class="col close-align">
                  <button type="button" id="close" class="btn btn-sm btn-danger add-new-shadow remove-btn"
                    @click="removeFamilyMember(member_index , 'main')">
                    <font-awesome-icon icon="times" />
                  </button>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <b-badge pill variant="info" class="nominee-badge">{{ $t("Member") }} {{ member_index + 1
                      }}</b-badge>
                  </div>
                </div>
                <div class="row nominee-row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label for="relationship">
                        {{$t("Relationship")}} 
                        <span class="required-mark">*</span>
                      </label>
                      <ValidationProvider :name="'Member  ' + (member_index+1) + ' Relationship'" rules="required" :id="'vp_relationship_in'+member_index">
                        <div slot-scope="{ errors }">
                          <select :disabled="member_input.relationship == 'MOTHER' || member_input.relationship == 'FATHER' " id="member_relationship" class="form-control" v-model="member_input.relationship"
                            @change="sendChangedData()">
                            <option v-show="member_index < 2" value="FATHER">FATHER</option>
                            <option v-show="member_index < 2" value="MOTHER">MOTHER</option>
                            <option value="SISTER">SISTER</option>
                            <option value="BROTHER">BROTHER</option>
                          </select>
                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label for="living_status">
                        {{$t("Status")}} 
                        <span class="required-mark">*</span>
                      </label>
                      <ValidationProvider :name="'Member  ' + (member_index+1) + ' Living Status'" rules="required" :id="'vp_living_status_sp' +member_index">
                        <div slot-scope="{ errors }">
                          <select id="member_living_status" class="form-control" v-model="member_input.living_status"
                            @change="sendChangedData()">
                            <option value="Alive">Alive</option>
                            <option value="Dead">Dead</option>
                          </select>
                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <label for="mother_age_in" v-if="member_input.living_status == 'Alive'">
                      {{ $t("Age")}} 
                      <span class="required-mark">*</span>
                    </label>
                    <label for="age" v-else>{{
                      $t("AgeAtDeath")
                    }}</label>
                    <ValidationProvider imediate :name="'Insured Member  ' + (member_index+1) + ' Age'"
                      :rules="`${
                            member_input.living_status == 'Alive'
                              ? 'required|numeric|minmax_value:0,120'
                              : 'numeric|minmax_value:0,120'
                          }`"
                      >
                      <div slot-scope="{ errors }">
                        <input id="member_age" type="text" class="form-control" v-model="member_input.age"
                          placeholder="Years" @change="sendChangedData()" />
                        <p class="p-error">{{ errors[0] }}</p>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-12">
                      <label
                        v-if="member_input.living_status == 'Alive'"
                        >{{ $t("StateOfHealth")
                        }} <span class="required-mark">*</span></label
                      >
                      <label for="health_status" v-else>{{
                        $t("CuaseOfDeath")
                      }}</label>
                      <ValidationProvider
                        immediate
                        :name="'Insured Member  ' + (member_index+1) + ' State of Health'"
                        :rules="`${
                            member_input.living_status == 'Alive'
                              ? 'required|minmax:3,20|alpha_spaces'
                              : 'minmax:3,20|alpha_spaces'
                          }`"
                      >
                        <div slot-scope="{ errors }">
                          <textarea
                            id="member_health_status"
                            class="text-uppercase form-control"
                            type="text"
                            placeholder="Enter the reason..."
                            v-uppercase
                            v-model="member_input.cause_of_death"
                            rows="2"
                            @change="sendChangedData()"
                          ></textarea>
                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" id="add_new" class="btn btn-sm btn-success add-new-shadow" @click="addFamilyMember('main')">
              <font-awesome-icon icon="plus" />Add New
            </button>
          </div>
        </div>
      </div>
      <!-- Spouse details -->
      <div class="col-md-6 col-sm-6" v-if="InfoInsured.has_spouse == 'Yes'">
        <div class="section-header-new"><strong>{{ $t("Spouse") }}</strong></div>
        <div class="row mb-2 nominee-full-row" v-for="(member_input, member_index) in FamilyMemberInfoSp"
          :key="member_index">
          <div class="col">
            <div class="card-outline">
              <div class="row input-row" v-show="member_index > 1 && (member_index || (!member_index && FamilyMemberInfoSp.length > 0))">
                <div class="col close-align">
                  <button type="button" id="close" class="btn btn-sm btn-danger add-new-shadow remove-btn"
                    @click="removeFamilyMember(member_index , 'spouse')">
                    <font-awesome-icon icon="times" />
                  </button>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <b-badge pill variant="info" class="nominee-badge">{{ $t("Member") }} {{ member_index + 1
                      }}</b-badge>
                  </div>
                </div>
                <div class="row nominee-row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label for="relationship">
                        {{$t("Relationship")}} 
                        <span class="required-mark">*</span>
                      </label>
                      <ValidationProvider :name="'Spouse Member  ' + (member_index+1) + ' Relationship'" rules="required" :id="'vp_relationship_sp'+member_index">
                        <div slot-scope="{ errors }">
                          <select :disabled="member_input.relationship == 'MOTHER' || member_input.relationship == 'FATHER' " id="member_relationship" class="form-control" v-model="member_input.relationship"
                            @change="sendChangedData()">
                            <option v-show="member_index < 2" value="FATHER">FATHER</option>
                            <option v-show="member_index < 2" value="MOTHER">MOTHER</option>
                            <option value="SISTER">SISTER</option>
                            <option value="BROTHER">BROTHER</option>
                          </select>
                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label for="living_status">
                        {{$t("Status")}} 
                        <span class="required-mark">*</span>
                      </label>
                      <ValidationProvider :name="'Spouse Member  ' + (member_index+1) + ' Living Status'" rules="required" :id="'vp_living_status' +member_index">
                        <div slot-scope="{ errors }">
                          <select id="member_living_status" class="form-control" v-model="member_input.living_status"
                            @change="sendChangedData()">
                            <option value="Alive">Alive</option>
                            <option value="Dead">Dead</option>
                          </select>
                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <label for="mother_age_in" v-if="member_input.living_status == 'Alive'">
                      {{ $t("Age")}} 
                      <span class="required-mark">*</span>
                    </label>
                    <label for="age" v-else>{{
                      $t("AgeAtDeath")
                    }}</label>
                    <ValidationProvider imediate :name="'Spouse Member  ' + (member_index+1) + ' Age'"
                      :rules="`${
                            member_input.living_status == 'Alive'
                              ? 'required|numeric|minmax_value:0,120'
                              : 'numeric|minmax_value:0,120'
                          }`"
                      >
                      <div slot-scope="{ errors }">
                        <input id="member_age" type="text" class="form-control" v-model="member_input.age"
                          placeholder="Years" @change="sendChangedData()" />
                        <p class="p-error">{{ errors[0] }}</p>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-12">
                      <label
                        for="mother_health_status_reason_in"
                        v-if="member_input.living_status == 'Alive'"
                        >{{ $t("StateOfHealth")
                        }} <span class="required-mark">*</span></label
                      >
                      <label for="health_status" v-else>{{
                        $t("CuaseOfDeath")
                      }}</label>
                      <ValidationProvider
                        immediate
                        :name="'Spouse Member  ' + (member_index+1) + ' State of Health'"
                        :rules="`${
                            member_input.living_status == 'Alive'
                              ? 'required|minmax:3,20|alpha_spaces'
                              : 'minmax:3,20|alpha_spaces'
                          }`"
                      >
                        <div slot-scope="{ errors }">
                          <textarea
                            id="member_health_status"
                            class="text-uppercase form-control"
                            type="text"
                            placeholder="Enter the reason..."
                            v-uppercase
                            v-model="member_input.cause_of_death"
                            rows="2"
                            @change="sendChangedData()"
                          ></textarea>
                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" id="add_new" class="btn btn-sm btn-success add-new-shadow" @click="addFamilyMember('spouse')">
              <font-awesome-icon icon="plus" />Add New
            </button>
          </div>
        </div>
      </div>
    </div>
    
  </ValidationObserver>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { throttle } from "lodash";
export default {
  name: "info-family-info-question-widget",
  components: {},
  data() {
    return {
    };
  },
  computed: {
    ...mapState("proposal", ["InfoInsured", "FamilyInfoInsSp", "FamilyMemberInfo" , "FamilyMemberInfoSp"]),
  },
  methods: {
    ...mapActions("proposal", ["draftProposalData", "addFamilyMemberInfo", "removeFamilyMemberInfo"]),
    sendChangedData: throttle(async function () {
      await this.draftProposalData();
    }, process.env.VUE_APP_TIMEOUT_SEND_CHANGED_DATA),
    removeFamilyMember(index , type) {
      if (index > 0) {
        let obj = {
          index:index,
          type:type
        };
        this.removeFamilyMemberInfo(obj);
      }
    },
    addFamilyMember(type) {
      let obj = {
        type:type
      };
      this.addFamilyMemberInfo(obj);
    }
  },
};
</script>

<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

.card-outline {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-clip: border-box !important;
  border: 1px solid #92d127 !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}

.hr-top {
  margin-top: 0.12rem !important;
}

.quest {
  font-size: 12px;
  font-weight: 700;
}

.toggle-lable {
  margin-bottom: 0rem !important;
}

.text-area-pad {
  padding: 10px;
}

.card-style {
  display: block;
  height: auto;
  margin-top: 15px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-radius: calc(0.25rem - 1px);
}

.card-img {
  height: 50px;
  width: 50px;
}

.media-heading {
  margin-top: -19px;
  color: #444;
}

.media-heading:hover,
a:link {
  color: #00c853;
  text-decoration: none;
}

.members {
  margin-top: 0px;
  color: #f39721;
  float: left;
  margin-left: 20px;
}

.section-header-new{
  margin-top: 0px;
  color: #f39721;
  margin-left: 20px;
}

.btn-part {
  display: inline-block;
  margin-right: 8px;
  float: right;
}

.text-input {
  padding: 0px 25px 0px 25px;
}

.textarea-input-top {
  margin-top: 10px;
}

@media (max-width: 767px) {
  .text-input-top {
    margin-top: 10px;
  }
}

/* --- toggle switch ---- */

.section {
  text-align: center;
  height: 100%;
  margin: 0 auto;
  padding: 6em 0;
}

.section__title {
  font-size: 1.1em;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #fff;
  margin-bottom: 3em;
}

.section--aava {
  background: #2994b2;
}

.section--tuuli {
  background: #474744;
}

.section--vesi {
  background: #54567a;
}

.section--sade {
  background: #2d4659;
}

.section--ilma {
  background: #095062;
}

.section--tuli {
  background: #068b78;
}

.toggle-button {
  margin: 5px 0px;
}

/*
 * Toggle button styles
 */
.toggle-button {
  position: relative;
  display: inline-block;
  color: #fff;
}

.toggle-button label {
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  text-align: left;
}

.toggle-button input {
  display: none;
}

.toggle-button__icon {
  cursor: pointer;
  pointer-events: none;
}

.toggle-button__icon:before,
.toggle-button__icon:after {
  content: "";
  position: absolute;
  top: 45%;
  left: 35%;
  transition: 0.2s ease-out;
}

.toggle-button--aava label {
  height: 34px;
  line-height: 36px;
  transition: all 0.2s;
  border-radius: 0.2rem;
}

.toggle-button--aava label:before,
.toggle-button--aava label:after {
  position: absolute;
  right: 1.5rem;
  transition: all 0.2s 0.1s ease-out;
}

.toggle-button--aava label:before {
  content: attr(data-on-text);
}

.toggle-button--aava label:after {
  content: attr(data-off-text);
}

.toggle-button--aava input[type="checkbox"]+label {
  width: 100px;
  background: #ff5335;
}

@media (max-width: 768px) {
  .toggle-button--aava input[type="checkbox"]+label {
    width: 88px;
  }
}

.toggle-button--aava input[type="checkbox"]+label:before {
  opacity: 0;
  transform: translate(0, 20px);
}

.toggle-button--aava input[type="checkbox"]+label:after {
  opacity: 1;
  transform: translate(0, 0);
}

.toggle-button--aava input[type="checkbox"]:checked~label {
  width: 100px;
  background: #61b136;
}

@media (max-width: 768px) {
  .toggle-button--aava input[type="checkbox"]:checked~label {
    width: 88px;
  }
}

.toggle-button--aava input[type="checkbox"]:checked~label:before {
  opacity: 1;
  transform: translate(0, 0);
}

.toggle-button--aava input[type="checkbox"]:checked~label:after {
  opacity: 0;
  transform: translate(0, -20px);
}

.toggle-button--aava input[type="checkbox"]:checked~.toggle-button__icon:before {
  transform: translate(-10%, 100%) rotate(45deg);
  width: 13.66667px;
}

.toggle-button--aava input[type="checkbox"]:checked~.toggle-button__icon:after {
  transform: translate(30%) rotate(-45deg);
}

.toggle-button--aava .toggle-button__icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 36px;
  width: 38px;
}

.toggle-button--aava .toggle-button__icon:before,
.toggle-button--aava .toggle-button__icon:after {
  height: 3px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.toggle-button--aava .toggle-button__icon:before {
  width: 20px;
  transform: rotate(45deg);
}

.toggle-button--aava .toggle-button__icon:after {
  width: 20px;
  transform: rotate(-45deg);
}

/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

.card-outline {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-clip: border-box !important;
  border: 1px solid #92D127 !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}

.add-new {
  float: right;
  margin-top: 10px;
}

.close-align {
  text-align: right;
}

.remove-btn {
  margin-top: -15px;
  margin-right: -10px;
}

.nominee-row {
  margin-top: 5px;
}

.nominee-full-row {
  margin-top: 10px;
  margin-bottom: 25px;
}

.input-row {
  margin-bottom: -30px;
}

.add-new-shadow {
  box-shadow: none !important;
}

.card-style {
  display: block;
  height: auto;
  margin-top: 15px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-radius: calc(0.25rem - 1px);
}

.card-area {
  padding: 15px;
}

.nominee-badge {
  padding-right: 1em !important;
  padding-left: 1em !important;
  padding: 0.55em 1em !important;
}
</style>

<!-- Language Data for Info - Family Information of the Life to be Assured and Spouse Question Widget (08) -->
<i18n>
{
  "en": {
    "IfLiving": "If Living – indicate the present State of Health including and illness(es) suffering from",
    "IfDeceased": "If Deceased – indicate Age at the time of Death and Cause of Death (give details as whether death occurred due to an accident, sickness or natural cause)",
    "Mother": "Mother",
    "Father": "Father",
    "Other": "Other",
    "BrotherOrSister": "Brother or Sister",
    "Status": "Alive / dead",
    "Age": "Age",
    "AgeAtDeath": "Age at Death",
    "StateOfHealth": "State of Health",
    "CuaseOfDeath": "Cause of Death",
    "Insured": "Insured",
    "Spouse": "Spouse",
    "Member": "Member",
    "Relationship": "Relationship"
  },
  "si": {
    "IfLiving": "ජීවත්ව සිටින්නේ නම් රෝගාබාධ ඇතුළුව වර්තමාන සෞඛ්‍ය තත්වය සදහන් කරන්න.",
    "IfDeceased": "මියගොස් නම් මිය යන විට වයස, මරණයට හේතුව (මරණය හදිසි අනතුරකින්, අසනීපයකින් හෝ ස්වභාවික හේතු නිසා සිදු වුයේද යන්න විස්තර කරන්න)",
    "Mother": "මව",
    "Father": "පියා",
    "Other": "වෙනත්",
    "BrotherOrSister": "Brother or Sister",
    "Status": "ජීවත්ව සිටීද / මියගොස් සිටීද",
    "Age": "වයස",
    "AgeAtDeath": "මිය යන විට වයස",
    "StateOfHealth": "සෞඛ්‍ය තත්වය",
    "CuaseOfDeath": "මරණයට හේතුව",
    "Insured": "රක්ෂිතයා",
    "Spouse": "කලත්‍රයා",
    "Member": "සාමාජික",
    "Relationship": "සම්බන්ධතාවය"
  },
  "ta": {
   "IfLiving": "உயிரோடிருப்பின் பீடிக்கப்பட்டுள்ள எதேனும் நோய்கள் உட்பட தப்போதைய உடல் நிலைமையை சுட்டிக் காட்டுக/ இறந்திருப்பின்",
    "IfDeceased": "இறந்த நேரத்திலான வயதையும் இறப்புக்கான காரணத்தையும் சுட்டிக் காட்டுக (இறப்பானது விபத்தின் அல்லது சுகயீனத்தின் காரணமாக அல்லது இயற்கை காரணங்களின் விளைவாக ஏற்பட்டதா எனக் குறிப்பிடுக)",
    "Mother": "தாய்",
    "Father": "தந்தை",
    "Other": "ஏதாவது",
    "BrotherOrSister": "Brother or Sister",
    "Status": "உயிருடன் / இறந்த",
    "Age": "வயது",
    "AgeAtDeath": "இறக்கும் போது வயது",
    "StateOfHealth": "சுகாதார நிலை",
    "CuaseOfDeath": "மரணத்திற்கான காரணம்",
    "Insured": "வாழ்க்கை",
    "Spouse": "வாழ்க்கைத்துணை",
    "Member": "உறுப்பினர்",
    "Relationship": "உறவு"
  }
}
</i18n>
