<template>
  <div id="list_quote">
    <Header />
    <LeftNav />
    <main id="page-wrap">
      <b-container fluid>
          <vue-snotify></vue-snotify>
        <BranchFilterBar />
        <BranchListTable ref="brListTableRef" />
      </b-container>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/layout/AppHeader.vue";
import Footer from "@/components/common/layout/AppFooter.vue";
import BranchListTable from "@/components/common/layout/BranchListTable";
import LeftNav from "@/components/common/layout/AppLeftNav";
import BranchFilterBar from "@/components/vuetable/BranchFilterBar.vue";
// import { SnotifyPosition } from "vue-snotify";

export default {
  name: "list_lead",
  components: {
    Header,
    Footer,
    LeftNav,
    BranchListTable,
    BranchFilterBar
  }
};
</script>

<style scoped>
.row-top-list {
  margin-top: 40px;
}
</style>

<i18n>
{
  "en": {
    "search_panel": "Search Panel"
    
  },
  "si": {
    "search_panel": "සෙවුම් පැනලය"
  
  }
}
</i18n>
