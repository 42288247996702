<template>
  <div class="container-fluid">
    <div class="row row-top">
      <div class="card-2">
        <div class="card-header-2">
          <font-awesome-icon icon="search" />&nbsp;
          <span>{{ $t("search_panel") }}</span>
        </div>
        <div class="card-body">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Event</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Event"
                    v-model="event_name"
                  />
                </div>
              </div>

              <div class="col-md-3 text-right d-flex align-items-end">
                <div class="form-group">
                  <button
                    type="button"
                    id="search"
                    class="btn btn-sm btn-success btn-right"
                    @click="doFilter"
                  >
                    <font-awesome-icon icon="search" />
                    {{ $t("search") }}
                  </button>
                  <button
                    class="btn btn-sm btn-danger"
                    type="reset"
                    id="search_reset"
                    @click="resetFilter"
                  >
                    <font-awesome-icon icon="ban" />
                    {{ $t("reset") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="row"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      event_name: "",
    };
  },
  methods: {
    doFilter() {
      let filters = {
        event_name: this.event_name,
      };
      this.$events.fire("filter-set", filters);
    },
    resetFilter() {
      this.event_name = "";
      this.$parent.$refs.auditTableRef.onFilterReset();
    },
  },
  mounted() {
    this.role = localStorage.getItem("user_role")
      ? localStorage.getItem("user_role")
      : null;
  },
};
</script>


<style>
.btn-right {
  margin-right: 5px;
}

@media (max-width: 768px) {
  div.card {
    margin-top: 100px;
  }
}
</style>

<i18n>
{
  "en": {
    "quotation_number": "Quotation Number",
    "name": "Name",
    "mobile_number": "Mobile Number",
    "search": "Search",
    "status": "Status",
    "nic": "NIC",
    "reset" : "Reset",
    "search_panel": "Search Panel"
  },
  "si": {
  "quotation_number": "මිල කැඳවීම් අංකය",
  "name": "නම",
  "mobile_number": "දුරකථන අංකය",
  "nic": "ජා.හැ.අංකය",
  "status": "Status",
  "search": "සොයන්න",
  "reset" : "නැවත සකසන්න",
  "search_panel": "සෙවීමේ පුවරුව"
  },
  "ta": {
    "quotation_number": "விலைக்கோராலின் இலக்கம்",
    "name": "பெயர்",
    "mobile_number": "தொலைபேசி இலக்கம்",
    "nic": "தே. அ. அ. இலக்கம்",
    "status": "Status",
    "search": "தேடல்",
  "reset" : "மீட்டமைத்தல்",
    "search_panel": "தேடல் குழு"
  }
}
</i18n>