<template>
    <div class="row bg justify-content-around align-items-center">
      <div class="col-md-11">
        <div class="row">
          <div class="col-md-6 ">
            <div class="row">
              <div class="col">
                <img src="@/assets/img/platinum_product_img.jpg" class="showcase_img box-shadow" />
              </div>
              
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="row description_space">
              <div class="col-md-12">
                 
                  <h4 class="pr_heading">Amana - Retirement Plan</h4>
                  <p>In retirement, how you choose to spend your time is now up to you. The decisions you make about your investment today 
                    should be flexible enough to accommodate your life after retirement. Taking charge of your retirement begins with ATL’s Retirement Plan; assuring a dignified retirement.</p>
              </div>
            </div>
            <div class="row align-items-end justify-content-around"> 
              <div class="col-sm-6"> 
                <button class=" button_width btn  btn-primary  btn-block" @click="openPDF" >Get Brochure</button>
              </div>
              <div class="col-sm-6"> 
                <button class=" button_width btn  btn-primary  btn-block" @click="openURL('https://www.youtube.com/watch?v=7sRA7o2JEZg')" >Play Video</button>
              </div>
            </div>

            <div class="row description_space">
              <div class="col-md-12 mb-2">
                  <h5 class="pr_sub_heading"><strong>Product Features</strong> </h5>
                  <ul class="meateaters">
                    <li>Entry age : 18 - 55 years</li>
                    <li>Retirement age : 55 - 65 years</li>
                    <li>Flexible terms for contribution, accumulation, and pension receiving period</li>
                    <li>Personal Accident Cover up to 5 times the basic cover (optional)</li>
                  </ul>
                  <ul v-if="showMore" class="meateaters mt-zero">
                    <li>Critical Illness covering 30 ailments for you from 100k up to 5mn (optional)</li>
                    <li>Critical Illness covering 30 ailments for your spouse from 100k up to 5mn (optional)</li>
                    <li>Additional unlimited Life Cover up to 5 times your basic cover (Optional)</li>
                    <li>Hospitalization per day covers for yourself and spouse from 2000/- up to 10,000/- per day (Optional)</li>
                  </ul>
                  <a @click="showMore=!showMore" class="showMore-btn">
                {{ showMore ? "Show less" : "Show more" }}
              </a>
              </div>
            </div>
            
            <div class="row align-items-end justify-content-around"> 
              <div class="col-md-12"> 
                <button class=" button_width btn  btn-primary mb-5 btn-block" @click="goToEvents('new_quote_deergayu')" >Get Quotation</button>
              </div>
            </div>

          </div>
          
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "Welcome",
  methods: {
    goToEvents: function (package_name) {
        location.href='#/'+process.env.VUE_APP_VERSION+'/'+ package_name
    },
    openURL: function(link){
      let lang = localStorage.getItem("lang");
      let url = link;
      let base = process.env.VUE_APP_BACKEND;
      if(lang){
          url = base + "/storage/product_videos/retirement/"+lang+".mp4";
      }
      window.open(url, "_blank");
    },
    openPDF(){
      let lang = localStorage.getItem("lang");
      let url = '';
      let base = process.env.VUE_APP_BACKEND;
      if(lang){
          url = base + "/storage/product_brochure/retirement/"+lang+".pdf";
      }
      window.open(url, "_blank");
    }

},
data() {
    return {
      showMore : false
    };
  },

};
</script>

<style scoped>
.bg {
  /*https://www.pexels.com/*/
  background: white;
  min-height: 110vh;
  overflow: hidden;
  /* Center and scale the image nicely */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.showcase_img{
  width: 100%;
}
.box-shadow {
    border-radius: 8px;
    box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.15);
}

img {
    vertical-align: middle;
    border-style: none;
}
video{
  width: 60%;
}
.button_width{
  margin-top: 5px;
  margin-bottom: 5px;;
  max-width: 100%;
}
.pr_heading{
  color: #067169;
}
.pr_sub_heading{
  font-size: 0.95rem;
  color: #067169;
}

@media (max-width: 768px) {
.showcase_img {
    padding-top: 124px;
}
}

</style>