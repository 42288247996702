const namespaced = true;

const getDefaultState = () => {
    return {
        shakingIconAction: {
            shake: false,
            adyapanaPlusOpen:false,
            platinumOpen:false,
            platinumPlusOpen:false,
            deergayuOpen:false,
            flexiLifeOpen:false,
            healthPlusOpen:false,
            mrttOpen:false,
            surakshithaOpen:false,
            termPlusOpen:false,
            product:""
        },
        
    };
};

const state = getDefaultState();

const getters = {

};

const mutations = {
    setShaking(state) {
        state.shakingIconAction.shake = true;
    },
};

const actions = {
    
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
