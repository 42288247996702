<!--User Create Widget  -->
<template>
  <div class="row row-top" style="scroll: none; margin-bottom: -50px">
    <vue-snotify></vue-snotify>
    <div class="col-sm-12 top-margin">
      <div class="card">
        <div class="card-header header-font">
          <font-awesome-icon icon="users" />
          <span>&nbsp; {{ action_title }} </span>
        </div>
        <ValidationObserver ref="userform">
          <div class="card-body">
            <b-row>
              <b-col>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="txtFirstName">
                        First Name
                        <span style="color: red">*</span>
                      </label>
                      <!-- rules="required|minmax:3,60|alpha_spaces" -->
                      <ValidationProvider name="first_name">
                        <div slot-scope="{ errors }">
                          <input
                            type="text"
                            ref="firstName"
                            class="form-control"
                            id="txtFirstName"
                            name="firstName"
                            placeholder="First Name"
                            v-model="userData.first_name"
                            v-uppercase
                            :disabled="isDisabled"
                          />
                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <!-- FirstName -->
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="lbl_create_role">
                        Last Name
                        <span style="color: red">*</span>
                      </label>
                      <!-- rules="required|minmax:3,60|alpha_spaces" -->
                      <ValidationProvider name="last_name">
                        <div slot-scope="{ errors }">
                          <input
                            type="text"
                            ref="roleName"
                            class="form-control"
                            id="txtLastName"
                            name="lastName"
                            placeholder="Last Name"
                            v-model="userData.last_name"
                            v-uppercase
                            :disabled="isDisabled"
                          />
                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <!-- LastName -->
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="lbl_create_role">
                        Email
                        <span style="color: red">*</span>
                      </label>
                      <ValidationProvider name="email" rules="">
                        <div slot-scope="{ errors }">
                          <input
                            type="text"
                            ref="userEmail"
                            class="form-control"
                            id="txtEmail"
                            name="email"
                            placeholder="Email"
                            v-model="userData.email"
                            :disabled="isDisabled"
                          />
                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <!-- Email -->
                </div>

                <!-- <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="lbl_create_role">
                        User ID
                        <span style="color: red">*</span>
                      </label>
                      <ValidationProvider name="User ID" rules="required|min:6|max:6">
                        <div slot-scope="{ errors }">
                          <input
                            type="text"
                            ref="userID"
                            class="form-control"
                            placeholder="User ID"
                            v-model="userData.user_id"
                            v-uppercase
                            :disabled="isDisabled"
                          />
                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div> -->
                <!-- User ID -->

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="lbl_create_role">
                        Select Role
                        <span style="color: red">*</span>
                      </label>
                      <!-- rules="required" -->
                      <ValidationProvider name="roles">
                        <div slot-scope="{ errors }">
                          <select
                            class="form-control"
                            name="Role"
                            v-model="userData.role"
                          >
                            <option value="">--Select Role--</option>
                            <option
                              v-for="role in roleList"
                              v-bind:value="role.id"
                              :key="role.id"
                            >
                              {{ role.name }}
                            </option>
                          </select>
                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <!-- User Role -->
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>
                        Main Branch
                        <span style="color: red">*</span>
                      </label>
                      <!-- rules="required" -->
                      <ValidationProvider name="branchcode">
                        <div slot-scope="{ errors }">

                          <select
                            class="form-control"
                            name="Branch"
                            v-model="userData.branch_code"
                          >
                            <option value="">--Select Branch--</option>
                            <option
                              v-for="branch in branchList"
                              v-bind:value="branch.id"
                              :key="branch.id"
                            >
                              {{ branch.branch_name }}
                            </option>
                          </select>

                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <!-- Branch -->
                </div>

                <!-- Working branches -->
                <div class="row" v-if="(userData.role == 12 || userData.role == 15)">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>
                        Other Branches
                      </label>
                      <!-- rules="required" -->
                      <ValidationProvider name="otherbranchcode">
                        
                        <div slot-scope="{ errors }">
                          <v-select
                            v-model="userData.working_branches"
                            class="select2"
                            style="width: 100%"
                            v-validate="'required'"
                            name="Working Branch"
                            :options="branchList"
                            :reduce="(data) => data.branch_code"
                            @input="changeOtherBranches()"
                            label="branch_name"
                            multiple
                          ></v-select>
                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <!-- Branch -->
                </div>
                
              </b-col>
              <b-col>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="lbl_create_role">
                        Agent Code
                        <span style="color: red">*</span>
                      </label>
                      <!-- rules="required|min:6|max:6" -->
                      <ValidationProvider name="agent_code">
                        <div slot-scope="{ errors }">
                          <input
                            type="text"
                            class="form-control"
                            id="txtEmail"
                            name="agentcode"
                            placeholder="Agent Code"
                            v-model="userData.agent_code"
                            v-uppercase
                            :disabled="isDisabled || action == 'Edit'"
                          />
                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <!-- Agent Code -->
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>
                        Active
                        <span style="color: red">*</span>
                      </label>
                      <!-- rules="required" -->
                      <ValidationProvider name="active">
                        <div slot-scope="{ errors }">
                          <select
                            class="form-control"
                            name="Active"
                            v-model="userData.active"
                          >
                            <option value="">--Select Status--</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>

                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <!-- Active Status -->
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="lbl_create_role">
                        Password
                        <span style="color: red">*</span>
                      </label>
                      <!-- rules="min:6|password:@password" -->
                      <ValidationProvider
                        mode="passive"
                        vid="password"
                        name="Password"
                      >
                        <div slot-scope="{ errors }">
                          <input
                            type="password"
                            ref="password"
                            class="form-control"
                            id="password"
                            name="password"
                            placeholder="Password"
                            v-model="userData.password"
                          />
                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <!-- LastName -->
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="lbl_create_role">
                        Confirm Password
                        <span style="color: red">*</span>
                      </label>
                      <!-- rules="required:false" -->
                      <!-- rules="confirmed:password" -->
                      <ValidationProvider
                        mode="passive"
                        vid="password_confirmation"
                        name="Password Confirmation"
                      >
                        <div slot-scope="{ errors }">
                          <input
                            type="password"
                            ref="password_confirm"
                            class="form-control"
                            id="password_confirmation"
                            name="password_confirmation"
                            placeholder="Password Confirmation"
                            v-model="userData.password_confirmation"
                          />
                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <!-- LastName -->
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <button
                      type="submit"
                      ref="createBtn"
                      class="btn btn btn-success btn-sm btn-block btn_height"
                      @click="onSubmit"
                    >
                      &nbsp;{{ action }} User
                    </button>

                    <button
                      type="submit"
                      ref="updateBtn"
                      class="btn btn btn-secondary btn-md btn-block btn_height"
                      @click="onCancel"
                    >
                      &nbsp;Cancel
                    </button>
                  </div>
                  <div class="col-sm-6"></div>
                </div>
              </b-col>
            </b-row>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>


<script>
// import Vue from "vue";
import { mapState, mapActions } from "vuex";
// import { SnotifyPosition } from "vue-snotify";
import { extend } from "vee-validate";
import router from "@/router";

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match",
});

export default {
  name: "role-create-widget",

  data: function () {
    return {
      action: "Create",
      action_title: "Create User",
      isSave: true,
      isDisabled: false,
      user_id: "",
      user_name: "",
      active: false,
      permissions: [],
      selectedData: [],
    };
  },
  computed: {
    ...mapState("user", ["userData", "branchList", "roleList"]),
  },

  mounted() {
    // this.getAllPermissions();
    this.loadBranches();
    this.loadUserRoles();

    if (this.$route.query.action == "view") {
      this.action = "View";
      this.action_title = "View User";
      this.isDisabled = "disabled";
      this.user_id = this.$route.query.user_id;
      this.getUserData(this.user_id);
    } else if (this.$route.query.action == "edit") {
      this.isDisabled = null;
      this.action = "Edit";
      this.action_title = "Edit User";
      this.user_id = this.$route.query.user_id;
      //    alert(  this.user_id);
      this.getUserData(this.user_id);
    } else if (this.$route.query.action == "create") {
      this.isDisabled = null;
      this.action = "Create";
      this.action_title = "Create User";
    }
  },

  methods: {
    ...mapActions("user", [
      "createUser",
      "updateUser",
      "getUserData",
      "getUserRoleList",
      "getUserBranchList",
    ]),
    changeOtherBranches(){
      var index = this.userData.working_branches.indexOf(this.userData.branch_code)
      if (index != -1){
        this.userData.working_branches.splice(index , 1);
      }
    },
    inactive: function (action) {
      this.active = action;
    },

    //Load user data
    async loadUserData(user_id) {
      await this.getUserData(user_id);
    },

    //load role list
    loadUserRoles() {
      this.getUserRoleList();
    },
    //load branch list
    loadBranches() {
      this.getUserBranchList();
    },

    //Create User
    async userCreate() {
      let role_array = [];
      if (this.userData.role) {
        role_array.push(this.userData.role);
      }

      const userInfo = {
        first_name: this.userData.first_name.toUpperCase(),
        last_name: this.userData.last_name.toUpperCase(),
        email: this.userData.email,
        password: this.userData.password,
        password_confirmation: this.userData.password_confirmation,
        active: this.userData.active,
        confirmed: this.userData.confirmed,
        confirmation_email: this.userData.confirmation_email,
        branchcode: this.userData.branch_code,
        working_branches: this.userData.working_branches,
        agent_code: this.userData.agent_code.toUpperCase(),
        roles: role_array,
        user_id: this.userData.user_id,
      };

      this.createUser(userInfo).then((response) => {
        if (response.data.meta.status) {
          this.$snotify
            .success(response.data.meta.status_message, "", {
              position: "centerTop",
            })
            .on("destroyed", () => {
              //router.push("/user_management");
              router.push({
                name: "user_management",
              });
            });
        } else {
          this.$refs.userform.setErrors(response.data.meta.errors);
        }
      });

      //await this.createUser(userInfo);
    },

    //Update User
    async userUpdate() {
      let role_array = [];
      role_array.push(this.userData.role);
      const userInfo = {
        first_name: this.userData.first_name.toUpperCase(),
        last_name: this.userData.last_name.toUpperCase(),
        email: this.userData.email,
        password: this.userData.password,
        password_confirmation: this.userData.password_confirmation,
        active: this.userData.active,
        confirmed: this.userData.confirmed,
        confirmation_email: this.userData.confirmation_email,
        branchcode: this.userData.branch_code,
        working_branches: this.userData.working_branches,
        agent_code: this.userData.agent_code.toUpperCase(),
        roles: role_array,
        user_id: this.userData.user_id,
        id: this.userData.id,
      };

      this.updateUser(userInfo).then((response) => {
        if (response.data.meta.status) {
          this.$snotify
            .success(response.data.meta.status_message, "", {
              position: "centerTop",
            })
            .on("destroyed", () => {
              //router.push("/user_management");
              router.push({
                name: "user_management",
              });
            });
        } else {
          this.$refs.userform.setErrors(response.data.meta.errors);
        }
      });
    },

    //Submit User
    async onSubmit() {
      this.$refs.userform
        .validate()
        .then((result) => {
          if (!result) {
            return;
          } else {
            if (this.action == "Create") {
              this.userCreate();
            } else if (this.action == "Edit") {
              this.userUpdate();
            }
          }
        })
        .catch(() => {});
    },
    async onCancel() {
      //this.$router.push("/user_management");
      router.push({
        name: "user_management",
      });
    },
  },
};
</script>
<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

.datep > div > input {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 12px;
  color: #495057;
  padding-left: 5px;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #556065 !important;
  border-bottom: 0px solid #06a990 !important;
  color: #fff !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #06a990 !important;
  border-radius: 0.25rem;
}

.snotify {
  display: block;
  position: fixed;
  width: 99% !important;
  z-index: 9999;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: none;
}
/*-- toggle switch for Main Life Frequency ----*/

.togglefreq label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.togglefreq input:checked,
.togglefreq input:not(:checked) {
  display: none;
}

.togglefreq input:checked + label {
  background: #92d127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92d127;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked + label {
  background: #92d127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92d127;
}

.gen-btn {
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .btn-sm,
  .btn-group-sm > .btn {
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    line-height: 1.5;
  }
}

@media (max-width: 768px) {
  .top-margin {
    margin-top: 60px;
  }
}
</style>

<!-- Language Data for LeadInfoWidget-->
<i18n>
{
  "en": {
  },
  "si": {
  },
  "ta": {
  }
}
</i18n>
