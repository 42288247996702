import { http } from "@/config/http";
import { resolve } from "core-js/fn/promise";

const namespaced = true;

const getDefaultState = () => {
  return {
    mainInfoData: {
      title: "",
      fName: "",
      mName: "",
      lName: "",
      nic: "",
      mob: "",
      email: "",
      gender: "M",
      dob: "2000-01-01",
      age: "",
      maritalStatus:""
    },
    additonalInfoData: {
      mainType: 1,
      rate: 24,
      term: 10,
      grace: 1,
      gracePeriod: 1,
      loanAmount: 1000000,
      discountOnWf: 0,
      basicPremium: 0,
      mrtt_cover_option_type:2,
      address_of_fi:'',
      name_of_fi:''
    },
    spouseInfoData: {
      title: "",
      fName: "",
      mName: "",
      lName: "",
      gender: "M",
      dob: "2000-01-01",
      age: "",
    },
    calcSummary: { summary: 0 },

    flash: {
      type: "W",
      status: false,
      error: "",
    },
    timestamp: "",
    minflash: {
      status: false,
      error: "",
    },
    quotationNo: "",
    version: "",
    refNo: "",
    fInstitutionList:[]
  };
};

const state = getDefaultState();

const getters = {};

const mutations = {
  updateQuotationList(state, data) {
    state.quotationList = data.data;
  },

  setAgeObj(state, { data, type, index }) {
    if (type == "main") {
      state.mainInfoData.age = data.age;
    } else if (type == "spouse") {
      state.spouseInfoData.age = data.age;
    } else if (type == "child") {
      state.childInfoData[index].age = data.age;
    }
    if(data.timestamp){
      state.timestamp = data.timestamp.substring(0, 10);
    }
  },
  //** Create Illustration response map to state object  */
  setChangedObj(state, data) {
    state.calcSummary = data;
    state.additonalInfoData.basicPremium = data.summary_formatted.total_premium;
  },
  clearObj(state) {
    Object.assign(state, getDefaultState());
  },
  loadQuotationData(state, data) {
    /** main mamber  */ 

    state.mainInfoData.title = data.member_main.title;
    state.mainInfoData.fName = data.member_main.first_name;
    state.mainInfoData.mName = data.member_main.middle_name;
    state.mainInfoData.lName = data.member_main.last_name;
    state.mainInfoData.nic = data.member_main.nic;
    state.mainInfoData.email = data.member_main.email;
    state.mainInfoData.mob = data.member_main.mobile;
    state.mainInfoData.gender = data.member_main.gender;
    state.mainInfoData.dob = data.member_main.dob;
    state.mainInfoData.age = data.member_main.age;
    state.mainInfoData.marital_status = data.member_main.marital_status;
    /** spouse info */
    state.spouseInfoData.title = data.member_spouse.title;
    state.spouseInfoData.fName = data.member_spouse.first_name;
    state.spouseInfoData.mName = data.member_spouse.middle_name;
    state.spouseInfoData.lName = data.member_spouse.last_name;
    state.spouseInfoData.gender = data.member_spouse.gender;
    state.spouseInfoData.dob = data.member_spouse.dob;
    state.spouseInfoData.age = data.member_spouse.age;

    /** additional info */
    state.additonalInfoData.mainType = data.additional_info.main_type;
    state.additonalInfoData.rate = data.additional_info.rate;
    state.additonalInfoData.term = data.additional_info.term;
    state.additonalInfoData.grace = data.additional_info.grace;
    state.additonalInfoData.gracePeriod = data.additional_info.grace_period;
    state.additonalInfoData.loanAmount = data.additional_info.loan_amount;
    state.additonalInfoData.discountOnWf = data.additional_info.discount_Wf;
    state.additonalInfoData.basicPremium = data.additional_info.monthly_basic_premium;
    state.additonalInfoData.name_of_fi = data.additional_info.name_of_fi;
    state.additonalInfoData.address_of_fi = data.additional_info.address_of_fi;
    state.additonalInfoData.mrtt_cover_option_type = data.additional_info.mrtt_cover_option_type;

    /** Quotation no and version no for updated purposes */
    state.quotationNo = data.quote_no;
    state.version = data.version;
    state.refNo = data.ref_no;
  },
  /** Error Message setter */
  setError(state, { error, errorType }) {
    //console.log("called set error " + errorType);
    //console.log("called set error " + error);
    state.flash = {
      type: errorType,
      status: true,
      error: error,
    };
    //console.log(state.flash.status);
  },
  /** Error Message destroy */
  clearFlash(state) {
    state.flash = {
      type: "",
      status: false,
      error: "",
    };
  },

  /** Minified Summary Message setter */
  setMinMsg(state, data) {
    state.minflash = {
      status: true,
      error: data,
    };
    //console.log(state.flash.status);
  },
  /** Minified Summary Message destroy */
  clearMinMsg(state) {
    state.minflash = {
      status: false,
      error: "",
    };
  },

  setChangedObjSuccessCreate(state) {
    state.flash.status = true;
  },

  setFInstitutionsObj(state, data) {
    //** Set hospital list   */
    state.fInstitutionList = data;
    //console.log(data);
  },
};
const actions = {
  async clearStateData({ commit }) {
    commit("clearObj");
  },
  async addChangedData({ commit, state }, type = "preview", refno = null) {
    let member_main = {};
    let additional_info = {};
    let member_spouse = {};

    member_main = {
      title: state.mainInfoData.title,
      first_name: state.mainInfoData.fName,
      middle_name: state.mainInfoData.mName,
      last_name: state.mainInfoData.lName,
      gender: state.mainInfoData.gender,
      dob: state.mainInfoData.dob,
      nic: state.mainInfoData.nic,
      mobile: state.mainInfoData.mob,
      email: state.mainInfoData.email,
      age:state.mainInfoData.age,
      marital_status:state.mainInfoData.maritalStatus
    };
    additional_info = {
      main_type: state.additonalInfoData.mainType,
      rate: state.additonalInfoData.rate,
      term: parseInt(state.additonalInfoData.term),
      grace: state.additonalInfoData.grace,
      grace_period: parseInt(state.additonalInfoData.gracePeriod),
      loan_amount: state.additonalInfoData.loanAmount,
      discount_Wf: state.additonalInfoData.discountOnWf,
      monthly_basic_premium:state.additonalInfoData.basicPremium,
      name_of_fi:state.additonalInfoData.name_of_fi,
      address_of_fi:state.additonalInfoData.address_of_fi,
      mrtt_cover_option_type:state.additonalInfoData.mrtt_cover_option_type
    };

    member_spouse = {
      title: state.spouseInfoData.title,
      first_name: state.spouseInfoData.fName,
      middle_name: state.spouseInfoData.mName,
      last_name: state.spouseInfoData.lName,
      gender: state.spouseInfoData.gender,
      dob: state.spouseInfoData.dob,
      age:state.spouseInfoData.age
    };

    if (type == "update") {
      refno = state.refNo;
    }

    let data = {
      save_state: type,
      branch_code: "CLS",
      channel_code: "01",
      user_category: "MDRT",
      agent_code: "E660",
      user_role: "UWR",
      ref_no: refno,
    };

    //** Illustration request object prepare */
    data = {
      ...data,
      member_main,
      member_spouse,
      additional_info,
    };
    let errorType = "";
    let error = "";
    //** Illustration request axios call */
    commit("clearFlash"); //destroy snotify
    commit("clearMinMsg"); // destroy Min Summary Message
    await http
      .post("quote_mrtt/illustration", { data }, {})
      .then((response) => {
        if (response.data.meta.status == "true") {
          if (type == "create" || type == "update") {
            //commit("setChangedObjSuccessCreate");
            error = response.data.meta.status_message;
            errorType = "S";
            //console.log("illustration response :" + error);
            commit("setError", { error, errorType });
          } else {
            commit("setChangedObj", response.data.data);
            commit(
              "setMinMsg",
              " Rs. " + response.data.data.summary_formatted.total_premium
            );
            commit("common_actions/setShaking" , null, { root: true });
          }
        } else {
          error = response.data.meta.status_message;
          errorType = "E";
          //console.log("illustration response :" + error);
          commit("setError", { error, errorType });
        }
      })
      .catch((error) => {
        let errorType = "E";
        //commit("setError", "Error : illustration Service Response Failed");
        //alert("Error : illustration Service Response Failed");
        commit("setError", { error, errorType });
      });
  },
  async getAge({ commit }, payload) {
    let dob = payload.dob;
    let errorType = "";
    //** Age Calculation axios call  */
    commit("clearFlash"); //destroy snotify
    await http
      .post("quote_mrtt/get_age", { dob: dob }, {})
      .then((response) => {
        if (response.data.meta.status == "true") {
          let data = response.data.data;
          let type = payload.type;

          //this is to be used to identify the child
          let index = 0;
          if (payload.hasOwnProperty("index")) {
            index = payload.index;
          }
          commit("setAgeObj", { data, type, index });
        } else {
          errorType = "E";
          let error =
            "Error : Main Life Age Calculator Service Response Failed";
          commit("setError", error, errorType);
          //alert("Error : Main Life Age Calculator Service Response Failed");
        }
      })
      .catch((error) => {
        //alert(error);
        errorType = "E";
        commit("setError", { error, errorType });
      });
  },
  async getQuotationList({ commit }, filterText) {
    let errorType = "";
    commit("clearFlash"); //destroy snotify
    await http
      .post("quote/list", filterText, {})
      .then((response) => {
        if (response.data.meta.status == "true") {
          commit("updateQuotationList", response.data.data);
        } else {
          errorType = "E";
          let error = "Error : Get Quotation List Service Response Failed";
          commit("setError", { error, errorType });
          //alert("Something went wrong - getQuotationList");
        }
      })
      .catch((error) => {
        //alert(error);
        errorType = "E";
        commit("setError", { error, errorType });
      });
  },
  async getOneQuotation({ commit }, refNo) {
    let errorType = "";
    commit("clearFlash"); //destroy snotify
    await http
      .post("quote_mrtt/view", { ref_no: refNo }, {})
      .then((response) => {
        if (response.data.meta.status == "true") { 
          commit("loadQuotationData", response.data.data);
          resolve(response)
        } else {
          errorType = "E";
          let error = "Error : Get One Quotation Service Response Failed";
          commit("setError", { error, errorType });
          resolve(response)
          //alert("Something went wrong - getOneQuotation");
        }
      })
      .catch((error) => {
        //alert(error);
        errorType = "E";
        commit("setError", { error, errorType });
      });
  },
  async getFInstitutionList({ commit }) {
    let errorType = "E";
    //** Hospital list request axios call  */
    commit("clearFlash"); //destroy snotify
    await http
      .post("conf/fi_institutions", null, {})
      .then(response => {
        if (response.data.meta.status == "true") {
          //console.log(response.data.data[0]);
          commit("setFInstitutionsObj", response.data.data);
        } else {
          errorType = "E";
          let error = "Error : FInstitution Service Response Failed";
          commit("setError", error, errorType);
        }
      })
      .catch(error => {
        //alert(error);
        errorType = "E";
        commit("setError", { error, errorType });
      });
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
