<template>
  <div class="container-fluid">
    <div class="row row-top">
      <div class="card-2">
        <div class="card-header-2">
          <font-awesome-icon icon="search" />&nbsp;
          <span>Search Panel</span>
        </div>
        <div class="card-body">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Search By Name</label>

                  <v-select
                    v-model="active_user"
                    :options="activeHoUsers"
                  ></v-select>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label>Proposal Status</label>
                  <select
                    class="form-control"
                    name="PropStatus"
                    v-model="proposal_status"
                  >
                    <option value="all">All</option>
                    <option value="1">Pending Personal Info</option>
                    <option value="2">Pending Health Info</option>
                    <option value="3">Pending Documents</option>
                    <option value="4">Pending Policy Holder Sig</option>
                    <option value="5">Pending Spouse Sig</option>
                    <option value="6">Pending Agent Sig</option>
                    <option value="7">Pending Supervisor</option>
                    <option value="8">Pending Ho</option>
                    <option value="9">Returned to Agent</option>
                    <option value="10">Completed</option>
                  </select>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label>Status</label>
                  <select
                    class="form-control"
                    name="PropStatus"
                    v-model="status"
                  >
                    <option value="all">All</option>
                    <option value="1">Allocated</option>
                    <option value="2">Reverted</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group search-btn">
                  <button
                    type="button"
                    id="search"
                    class="btn btn-sm btn-success btn-right"
                    @click="doFilter"
                  >
                    <font-awesome-icon icon="search" />
                    {{ $t("search") }}
                  </button>
                  <button
                    class="btn btn-sm btn-danger"
                    type="reset"
                    id="search_reset"
                    @click="resetFilter"
                  >
                    <font-awesome-icon icon="ban" />
                    {{ $t("reset") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <HoUserAllocationListTable ref="hoUserTableRef" />
      </div>
    </div>
  </div>
</template>

<script>
import HoUserAllocationListTable from "@/components/queue_user_management/HoUserAllocationListTable.vue";
import { mapActions } from "vuex";

export default {
  name: "UserList",
  components: {
    HoUserAllocationListTable,
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      agent_code: "",
      queue_status: false,
      activeHoUsers: [],
      active_user: "",
      proposal_status: "all",
      status: "all",
    };
  },
  methods: {
    ...mapActions("user", [
      "getActiveHoUserList",
      "getAllHoUserList",
      "updateQueueStatus",
    ]),
    changeQueueStatus() {
      this.$snotify.clear();
      let payload = {
        queue_status: this.queue_status,
      };
      this.updateQueueStatus(payload).then((response) => {
        if (response.data.meta.status) {
          console.log(response);
        }
      });
    },
    addHoUser() {
      this.$snotify.clear();
      let payload = {
        user_id: this.active_user.id,
      };
      this.addActiveHoUser(payload).then((response) => {
        if (response.data.meta.status) {
          this.$refs.userTableRef.onFilterReset();
          this.active_user = "";
          this.activeHoUserList();
        }
      });
    },
    activeHoUserList() {
      this.$snotify.clear();
      let payload = {};
      this.activeHoUsers = [];
      let self = this;
      this.getAllHoUserList(payload).then((response) => {
        if (response.data.meta.status) {
          let res = response.data.data;
          res.forEach(function (user) {
            let obj = {};
            obj.label =
              user.first_name +
              " " +
              user.last_name +
              "(" +
              user.agent_code +
              ")";
            obj.id = user.id;
            self.activeHoUsers.push(obj);
          });
          this.queue_status = response.data.queuseStatus;
        }
      });
    },
    doFilter() {
      let filters = {
        active_user: this.active_user.id,
        proposal_status: this.proposal_status,
        status: this.status,
      };
      this.$events.fire("filter-set", filters);
    },
    resetFilter() {
      this.active_user = "";
      this.proposal_status = "";
      this.status = "";
      this.$refs.hoUserTableRef.onFilterReset();
    },
  },
  mounted() {
    this.activeHoUserList();
  },
};
</script>

<style scoped>
.search-btn {
  padding-top: 30px;
}
</style>

<i18n>
{
  "en": {
    "reset": "Reset",
    "search":"Search"
  },
  "si": {
    "reset": "Reset",
    "search":"Search"
  },
  "ta": {
     "reset": "Reset",
     "search":"Search"
  }
}
</i18n>
