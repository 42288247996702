<!-- Final - Particulars of Previous life Policies/Proposals Question Widget (12) -->
<template>
  <ValidationObserver ref="prevPoliciesQuesitions">
    <div>
      <!-- <div class="row" v-for="(question, index) in PrevPolicyInfo" :key="index"> -->
      <div class="col">
        <div class="card-outline">
          <div class="card-body">
            <div class="row">
              <!-- Question -->
              <div class="col quest"></div>
            </div>
            <div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="card-style">
                    <div class="media">
                      <div class="media-left">
                        <img v-if="InfoInsured.gender == 'Male'" class="media-object card-img"
                          src="@/assets/img/spouse-hubby.png" />
                        <img v-else class="media-object card-img" src="@/assets/img/spouse-wife.png" />
                      </div>

                      <div class="media-body">
                        <a href="#">
                          <h5 class="media-heading">&nbsp;</h5>
                        </a>
                        <div class="members">
                          <strong> {{ $t("Insured") }}</strong>
                        </div>
                        <div class="row add-new">
                          <div class="col text-right">
                            <button type="button" id="add_new" class="btn btn-sm btn-success add-new-shadow"
                              @click="addTplPrevPolicyRow()" v-if="PrevPolicyInfo.length <= 4">
                              <font-awesome-icon icon="plus" />Add New
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card" v-for="(question, index) in PrevPolicyInfo" :key="index">
                      <div class="card-header">
                        <span>Policy {{ index + 1 }}</span>
                        <button type="button" id="close"
                          class="btn btn-sm btn-danger add-new-shadow remove-btn float-right"
                          @click="removePrevPolicyRow(index)">
                          <font-awesome-icon icon="times" />
                        </button>
                      </div>
                      <div class="card-body">
                        <div class="row form-area-pad">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="date">{{
                                $t("NameOfInsuranceCompany")
                              }}</label>
                              <br />
                              <ValidationProvider immediate name="Insurance Company"
                                :vid="question.quest_id + 'prev_comapny_insured'" rules="required|min:3|max:120">
                                <div slot-scope="{ errors }">
                                  <input type="text" class="form-control" placeholder="Name of Insurance Company"
                                    v-uppercase v-model="question.name_of_insurance_comp_in"
                                    @change="sendChangedData()" />

                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-sm-6 reason-top reason-top-2">
                            <div class="form-group">
                              <label for="date">{{ $t("PolicyNumber") }} :</label>
                              <ValidationProvider immediate name="Policy Number" :vid="question.quest_id + 'prev_policy_number_insured'
                                " rules="required|min:3|max:30">
                                <div slot-scope="{ errors }">
                                  <input type="text" class="form-control" placeholder="Policy/Proposal Number" v-uppercase
                                    v-model="question.policy_number_in" @change="sendChangedData()" />
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-sm-6 reason-top reason-top-2">
                            <div class="form-group">
                              <label for="date">{{ $t("SumAssured") }} :</label>
                              <ValidationProvider immediate name="Sum Assured" :vid="question.quest_id + 'prev_sum_assured_insured'
                                " rules="required|numeric|max:10">
                                <div slot-scope="{ errors }">
                                  <input type="text" class="form-control" placeholder="Sum Assured"
                                    v-model="question.sum_assured_in" @change="sendChangedData()" />
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-sm-6 reason-top reason-top-2">
                            <div class="form-group">
                              <label for="date">{{
                                $t("PlanOfInsurance")
                              }}</label>
                              <ValidationProvider immediate name="Insured Sum Assured Date" :vid="question.quest_id +
                                  'in_date_sum_assured_insured'
                                  " rules="max:10">
                                <div slot-scope="{ errors }">
                                  <input type="date" class="form-control" placeholder="Date" v-uppercase
                                    v-model="question.plan_of_insurance_in" @change="sendChangedData()" />
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-sm-6 reason-top reason-top-2">
                            <div class="form-group">
                              <label for="date">{{ $t("WhetherInForce") }}</label>
                              <select class="form-control" v-model="question.is_in_force_in" @change="sendChangedData()">
                                <option>Select</option>
                                <option>Yes</option>
                                <option>No</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-sm-12 reason-top reason-top-2">
                            <div class="form-group">
                              <label for="date">{{
                                $t("IsApplicationDeclined")
                              }}</label>
                              <select id="life_application_declined_in" class="form-control col-sm-6"
                                v-model="question.life_application_declined_in" @change="sendChangedData()">
                                <option>Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </div>

                          <div class="col-sm-12 text-area-pad" v-show="question.life_application_declined_in == 'Yes'">
                            <ValidationProvider immediate :name="'Reason'" :vid="question.quest_id + 'ReasonInsured'"
                              :rules="`${question.life_application_declined_in == 'Yes'
                                  ? 'required|min:3|max:60'
                                  : ''
                                }`">
                              <div slot-scope="{ errors }">
                                <span class="required-mark">*</span>
                                <b-form-textarea :id="question.quest_id + 'ReasonInsured'"
                                  placeholder="Enter your reason..." rows="2" v-model="question.quest_yes_reason_in"
                                  @change="sendChangedData()" v-uppercase></b-form-textarea>
                                <p class="p-error">{{ errors[0] }}</p>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="alert alert-warning text-center mt-2" v-if="PrevPolicyInfo.length == 0">No insured previous
                    policy data</div>
                </div>

                <div class="col-md-6 col-sm-6" v-if="InfoInsured.has_spouse == 'Yes'">
                  <div class="card-style">
                    <div class="media">
                      <div class="media-left">
                        <img v-if="InfoInsured.sp_gender == 'Male'" class="media-object card-img"
                          src="@/assets/img/spouse-hubby.png" />
                        <img v-else class="media-object card-img" src="@/assets/img/spouse-wife.png" />
                      </div>

                      <div class="media-body">
                        <a href="#">
                          <h5 class="media-heading">&nbsp;</h5>
                        </a>
                        <div class="members">
                          <strong> {{ $t("Spouse") }}</strong>
                        </div>
                        <div class="row add-new">
                          <div class="col text-right">
                            <button type="button" id="add_new" class="btn btn-sm btn-success add-new-shadow"
                              @click="addTplPrevPolicyRowSp()" v-if="PrevPolicyInfoSp.length <= 5">
                              <font-awesome-icon icon="plus" />Add New
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card" v-for="(question, index) in PrevPolicyInfoSp" :key="index">
                      <div class="card-header">
                        <span>Policy {{ index + 1 }}</span>
                        <button type="button" id="close"
                          class="btn btn-sm btn-danger add-new-shadow remove-btn float-right"
                          @click="removePrevPolicyRowSp(index)">
                          <font-awesome-icon icon="times" />
                        </button>
                      </div>
                      <div class="card-body">
                        <div class="row form-area-pad">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="date">{{
                                $t("NameOfInsuranceCompany")
                              }}</label>

                              <ValidationProvider immediate name="Insurance Company"
                                :vid="question.quest_id + 'prev_comapny_spouse'" rules="required|min:3|max:120">
                                <div slot-scope="{ errors }">
                                  <input type="text" class="form-control" placeholder="Name of Insurance Company"
                                    v-uppercase v-model="question.name_of_insurance_comp_sp"
                                    @change="sendChangedData()" />
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-sm-6 reason-top reason-top-2">
                            <div class="form-group">
                              <label for="date">{{ $t("PolicyNumber") }}</label>
                              <ValidationProvider immediate name="Policy Number" :vid="question.quest_id + 'prev_policy_number_spouse'
                                " rules="required|min:3|max:30">
                                <div slot-scope="{ errors }">
                                  <input type="text" class="form-control" placeholder="Policy/Proposal Number" v-uppercase
                                    v-model="question.policy_number_sp" @change="sendChangedData()" />
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-sm-6 reason-top reason-top-2">
                            <div class="form-group">
                              <label for="date">{{ $t("SumAssured") }}</label>
                              <ValidationProvider immediate name="Sum Assured" :vid="question.quest_id + 'prev_sum_assured_spouse'
                                " rules="required|numeric|max:10">
                                <div slot-scope="{ errors }">
                                  <input type="text" class="form-control" placeholder="Sum Assured"
                                    v-model="question.sum_assured_sp" @change="sendChangedData()" />
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-sm-6 reason-top reason-top-2">
                            <div class="form-group">
                              <label for="date">{{
                                $t("PlanOfInsurance")
                              }}</label>
                              <ValidationProvider immediate name="Spouse Sum Assured Date" :vid="question.quest_id + 'date_sum_assured_insured'
                                  " rules="max:10">
                                <div slot-scope="{ errors }">
                                  <input type="date" class="form-control" placeholder="Date" v-uppercase
                                    v-model="question.plan_of_insurance_sp" @change="sendChangedData()" />
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-sm-6 reason-top reason-top-2">
                            <div class="form-group">
                              <label for="date">{{ $t("WhetherInForce") }}</label>
                              <select id="is_in_force_in" class="form-control" v-model="question.is_in_force_sp"
                                @change="sendChangedData()">
                                <option>Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </div>
                       
                          <div class="col-sm-12 reason-top reason-top-2">
                            <div class="form-group">
                              <label for="date">{{
                                $t("IsApplicationDeclined")
                              }}</label>
                              <select class="form-control col-sm-6" v-model="question.life_application_declined_sp"
                                @change="sendChangedData()">
                                <option>Select</option>
                                <option>Yes</option>
                                <option>No</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-sm-12 text-area-pad" v-show="question.life_application_declined_sp == 'Yes'">
                            <ValidationProvider immediate :name="'Reason '" :vid="question.quest_id + 'ReasonSpouse'"
                              :rules="`${question.life_application_declined_sp == 'Yes'
                                  ? 'required|min:3|max:60'
                                  : ''
                                }`">
                              <div slot-scope="{ errors }">
                                <span class="required-mark">*</span>
                                <b-form-textarea :id="question.quest_id + 'ReasonSpouse'"
                                  placeholder="Enter your reason..." rows="2" v-model="question.quest_yes_reason_sp"
                                  v-uppercase @change="sendChangedData()"></b-form-textarea>
                                <p class="p-error">{{ errors[0] }}</p>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="alert alert-warning text-center mt-2" v-if="PrevPolicyInfoSp.length == 0">No insured
                    previous policy data</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </ValidationObserver>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { throttle } from "lodash";
export default {
  name: "final-policies-proposal-question-widget",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState("proposal", ["PrevPolicyInfo", "PrevPolicyInfoSp", "InfoInsured"]),
  },
  methods: {
    ...mapActions("proposal", ["draftProposalData", "removeInsPrevPolicy", "removeInsPrevPolicySp"]),
    sendChangedData: throttle(async function () {
      await this.draftProposalData();
    }, process.env.VUE_APP_TIMEOUT_SEND_CHANGED_DATA),
    addTplPrevPolicyRow() {
      let obj = {};
      obj.quest_id = 'PrevPolInfoQuest';
      obj.widget_id = 'PrevPolInfoQuestList';
      obj.quest_order_id = 1;
      obj.quest_answer_in = 'N';

      this.PrevPolicyInfo.push(obj);
    },
    removePrevPolicyRow(ins_index) {
      this.removeInsPrevPolicy(ins_index);
    },
    addTplPrevPolicyRowSp() {
      let obj = {};
      obj.quest_id = 'PrevPolInfoQuest';
      obj.widget_id = 'PrevPolInfoQuestList';
      obj.quest_order_id = 1;
      obj.quest_answer_sp = 'N';

      this.PrevPolicyInfoSp.push(obj);
    },
    removePrevPolicyRowSp(ins_index) {
      this.removeInsPrevPolicySp(ins_index);
    },

  },
};
</script>

<style scoped>
.card-outline {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-clip: border-box !important;
  border: 1px solid #92d127 !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}

.reason-top {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .reason-top-2 {
    margin-top: 15px;
  }
}

.form-area-pad {
  padding: 15px;
}

.quest-row {
  margin-bottom: 15px;
}

.quest {
  font-size: 14px;
  font-weight: 600;
}

.toggle-lable {
  margin-bottom: 0rem !important;
}

.text-area-pad {
  padding: 10px;
}

.card-style {
  display: block;
  height: auto;
  margin-top: 15px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-radius: calc(0.25rem - 1px);
}

.card-img {
  height: 50px;
  width: 50px;
}

.media-heading {
  margin-top: -19px;
  color: #444;
}

.media-heading:hover,
a:link {
  color: #00c853;
  text-decoration: none;
}

.members {
  margin-top: 0px;
  color: #f39721;
  float: left;
  margin-left: 20px;
}

.btn-part {
  display: inline-block;
  margin-right: 8px;
  float: right;
}

.badge {
  display: inline-block;
  background: #00c853;
  float: right;
  padding: 7px;
  border-radius: 50px;
  margin: 10px;
}

/* --- toggle switch ---- */

.section {
  text-align: center;
  height: 100%;
  margin: 0 auto;
  padding: 6em 0;
}

.section__title {
  font-size: 1.1em;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #fff;
  margin-bottom: 3em;
}

.section--aava {
  background: #2994b2;
}

.section--tuuli {
  background: #474744;
}

.section--vesi {
  background: #54567a;
}

.section--sade {
  background: #2d4659;
}

.section--ilma {
  background: #095062;
}

.section--tuli {
  background: #068b78;
}

.toggle-button {
  margin: 5px 0px;
}

/*
 * Toggle button styles
 */
.toggle-button {
  position: relative;
  display: inline-block;
  color: #fff;
}

.toggle-button label {
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  text-align: left;
}

.toggle-button input {
  display: none;
}

.toggle-button__icon {
  cursor: pointer;
  pointer-events: none;
}

.toggle-button__icon:before,
.toggle-button__icon:after {
  content: "";
  position: absolute;
  top: 45%;
  left: 35%;
  transition: 0.2s ease-out;
}

.toggle-button--aava label {
  height: 34px;
  line-height: 36px;
  transition: all 0.2s;
  border-radius: 0.2rem;
}

.toggle-button--aava label:before,
.toggle-button--aava label:after {
  position: absolute;
  right: 1.5rem;
  transition: all 0.2s 0.1s ease-out;
}

.toggle-button--aava label:before {
  content: attr(data-on-text);
}

.toggle-button--aava label:after {
  content: attr(data-off-text);
}

.toggle-button--aava input[type="checkbox"]+label {
  width: 100px;
  background: #ff5335;
}

@media (max-width: 768px) {
  .toggle-button--aava input[type="checkbox"]+label {
    width: 88px;
  }
}

.toggle-button--aava input[type="checkbox"]+label:before {
  opacity: 0;
  transform: translate(0, 20px);
}

.toggle-button--aava input[type="checkbox"]+label:after {
  opacity: 1;
  transform: translate(0, 0);
}

.toggle-button--aava input[type="checkbox"]:checked~label {
  width: 100px;
  background: #61b136;
}

@media (max-width: 768px) {
  .toggle-button--aava input[type="checkbox"]:checked~label {
    width: 88px;
  }
}

.toggle-button--aava input[type="checkbox"]:checked~label:before {
  opacity: 1;
  transform: translate(0, 0);
}

.toggle-button--aava input[type="checkbox"]:checked~label:after {
  opacity: 0;
  transform: translate(0, -20px);
}

.toggle-button--aava input[type="checkbox"]:checked~.toggle-button__icon:before {
  transform: translate(-10%, 100%) rotate(45deg);
  width: 13.66667px;
}

.toggle-button--aava input[type="checkbox"]:checked~.toggle-button__icon:after {
  transform: translate(30%) rotate(-45deg);
}

.toggle-button--aava .toggle-button__icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 36px;
  width: 38px;
}

.toggle-button--aava .toggle-button__icon:before,
.toggle-button--aava .toggle-button__icon:after {
  height: 3px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.toggle-button--aava .toggle-button__icon:before {
  width: 20px;
  transform: rotate(45deg);
}

.toggle-button--aava .toggle-button__icon:after {
  width: 20px;
  transform: rotate(-45deg);
}
</style>

<!-- Language Data for Final - Particulars of Previous life Policies/Proposals Question Widget (12) -->
<i18n>
{
  "en": {
    "Insured": "Insured",
    "Spouse": "Spouse",
    "NameOfInsuranceCompany": "Name of Insurance Company",
    "PolicyNumber": "Policy/Proposal Number",
    "SumAssured" : "Sum Assured",
    "PlanOfInsurance":"Date of commencement",
    "WhetherInForce":"Whether in Force?",
    "IsApplicationDeclined":"Has you application for life, accidents, disability or Health Assurance ever been declined or postponed or accepted on special terms?"
  },
  "si": {
    "Insured": "ජීවිත රක්ෂිතයා",
    "Spouse": "කලත්‍රයා",
    "NameOfInsuranceCompany": "Name of Insurance Company",
    "PolicyNumber": "Policy/Proposal Number",
    "SumAssured" : "Sum Assured",
    "PlanOfInsurance":"Date of commencement",
    "WhetherInForce":"Whether in Force?",
    "IsApplicationDeclined":"Has you application for life, accidents, disability or Health Assurance ever been declined or postponed or accepted on special terms?"

  },
  "ta": {
    "Insured": "பிரதான வாழ்க்கை",
    "Spouse": "வாழ்க்கைத்துணை",
    "NameOfInsuranceCompany": "Name of Insurance Company",
    "PolicyNumber": "Policy/Proposal Number",
    "SumAssured" : "Sum Assured",
    "PlanOfInsurance":"Date of commencement",
    "WhetherInForce":"Whether in Force?",
    "IsApplicationDeclined":"Has you application for life, accidents, disability or Health Assurance ever been declined or postponed or accepted on special terms?"

  }
}
</i18n>
