import { http } from "@/config/http";

const namespaced = true;

const getDefaultState = () => {
    return {
        userData: {
            id: '',
            first_name: '',
            last_name: '',
            email: '',
            active: '',
            password: '',
            confirmed: '',
            confirmation_email: '',
            user_id: '',
            branch_code: '',
            working_branches:'',
            agent_code: '',
            roles: [],
            role: "",
        },
        branchList: [],
        roleList: [],
    };
};

const state = getDefaultState();

const getters = {};

const mutations = {
    clearObj(state) {
        Object.assign(state, getDefaultState());
    },

    /** Error Message setter */
    setError(state, { error, errorType }) {
        //console.log("called set error " + errorType);
        //console.log("called set error " + error);
        state.flash = {
            type: errorType,
            status: true,
            error: error
        };
        //console.log(state.flash.status);
    },
    /** Error Message destroy */
    clearFlash(state) {
        state.flash = {
            type: "",
            status: false,
            error: ""
        };
    },

    setUserData(state, data) {
        state.userData.id = data.user.id;
        state.userData.first_name = data.user.first_name;
        state.userData.last_name = data.user.last_name;
        state.userData.email = data.user.email;
        state.userData.company = data.user.company;
        state.userData.contact_no = data.user.contact_no;
        state.userData.user_id = data.user.user_id;

        if (data.user.active == true) {
            state.userData.active = 1;
        } else if (data.user.active == false) {
            state.userData.active = 0;
        }
        state.userData.roles = data.roles;
        state.userData.role = data.user.roles[0].id;
        state.userData.branch_code = data.user.branchcode;
        state.userData.working_branches = JSON.parse(data.user.working_branches);
        state.userData.agent_code = data.user.agent_code;
    },

    //User Role List
    setUserRoleList(state, data) {
        const roles = [];

        data.forEach(function (role) {
            let obj = {};
            obj.id = role.id;
            obj.name = role.name;
            roles.push(obj);
        });

        state.roleList = roles;
    },

    setUserBranchList(state, data) {

        const branches = [];
        data.forEach(function (branch) {
            let obj = {};
            obj.branch_code = branch.branch_code;
            obj.id = branch.branch_code;
            obj.branch_name = branch.branch_name;
            branches.push(obj);

        });
        state.branchList = branches;
    },

};

const actions = {

    //Create User
    async createUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post('users/create', data)
                // .post('/users/create', data)
                .then(response => {
                    resolve(response);
                    if (response.data.meta.status) {
                        commit('clearObj');
                    } else {

                        if (response.data.meta.status == "false_confirmation") {
                            commit(
                                "showNotification",
                                {
                                    title: "Validation Error",
                                    msg: response.data.meta.status_message,
                                    type: "error"
                                },
                                { root: true }
                            );

                        } else {
                            commit(
                                "showNotification",
                                {
                                    title: "Error",
                                    msg: "Error occured while creating user.",
                                    type: "error"
                                },
                                { root: true }
                            );

                        }


                        return response.data.meta.errors;
                    }
                })
                .catch(error => {
                    // alert(error);
                    reject(error);
                    let errorType = "E";
                    commit("setError", { error, errorType });
                });
        });
    },

    //Update User
    async updateUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("users/" + data.id, data)
                .then(response => {
                    resolve(response);
                    if (response.data.meta.status == true) {
                        commit('clearObj');
                    } else {

                        commit(
                            "showNotification",
                            {
                                title: "Error",
                                msg: response.data.meta.status_message,
                                type: "error"
                            },
                            { root: true }
                        );

                        return response.data.meta.errors;
                    }

                })
                .catch(error => {
                    reject(error);
                    //alert(error);
                    let errorType = "E";
                    commit("setError", { error, errorType });
                });
        });
    },

    //Change User password
    async changePassword({ commit }, data) {
        return new Promise((resolve, reject) => {
         http
            .post("users/password/update", data)
            .then(response => {
                resolve(response);
                if (response.data.meta.status == "true") {
                    commit('clearObj');
                } else {
                    commit(
                        "showNotification",
                        {
                            title: "Error",
                            msg: response.data.meta.status_message,
                            type: "error"
                        },
                        { root: true }
                    );
                }
            })
            .catch(error => {
                reject(error);
                //alert(error);
                let errorType = "E";
                commit("setError", { error, errorType });
            });
        });
    },

    //Clear User Session
    async clearUserSession({ commit }, data) {
        await http
            .post("users/" + data.id + "/clear_session", data)
            .then(response => {

                if (response.data.success.code == "true") {
                    commit('clearObj');
                }

                commit(
                    "showNotification",
                    {
                        title: "Success",
                        msg: "The user session was successfully cleared",
                        type: "success"
                    },
                    { root: true }
                );
            })
            .catch(error => {
                alert(error);
            });
    },

    //Deactivate User
    async deactivateUser({ commit }, data) {
        //Parameters - User ID , Status - 0
        await http
            .post("users/" + data.id + "/deactivate_user", data)
            .then(response => {
                commit(
                    "showNotification",
                    {
                        title: "Success",
                        msg: response.data.success.message,
                        type: "success"
                    },
                    { root: true }
                );
            })
            .catch(error => {
                alert(error);
            });
    },
    //Queue User Deactivate User
    async queueUserDeactivate({ commit }, data) {
        //Parameters - User ID , Status - 0
        await http
            .post("queue_users/" + data.id + "/deactivate_user", data)
            .then(response => {
                commit(
                    "showNotification",
                    {
                        title: "Success",
                        msg: response.data.success.message,
                        type: "success"
                    },
                    { root: true }
                );
            })
            .catch(error => {
                alert(error);
            });
    },

    //Active HO User List
    async getActiveHoUserList({ commit }) {
        return new Promise((resolve, reject) => {
            http
                .get("queue_users/active_ho_user_list")
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Error Occured",
                        type: "error",
                    }, { root: true }
                    );
                    reject(error);
                });
        });
    },

    //All HO User List
    async getAllHoUserList({ commit }) {
        return new Promise((resolve, reject) => {
            http
                .get("queue_users/all_ho_user_list")
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Error Occured",
                        type: "error",
                    }, { root: true }
                    );
                    reject(error);
                });
        });
    },


    //Add active ho user to queue
    async addActiveHoUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("queue_users/add_active_ho_user", data)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Error Occured",
                        type: "error",
                    }, { root: true }
                    );
                    reject(error);
                });
        });
    },
    //Update Queue status
    async updateQueueStatus({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("queue_users/update_queue_status", data)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Error Occured",
                        type: "error",
                    }, { root: true }
                    );
                    reject(error);
                });
        });
    },

    //Revert User Allocation
    async revertUserAllocation({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("queue_users/revert_user_allocation", data)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Error Occured",
                        type: "error",
                    }, { root: true }
                    );
                    reject(error);
                });
        });
    },

    //Delete User
    async deleteUser({ commit }, data) {
        await http
            .post("users/" + data.id + "/user_delete", data)
            .then(response => {
                commit(
                    "showNotification",
                    {
                        title: "Success",
                        msg: response.data.success.message,
                        type: "success"
                    },
                    { root: true }
                );
            })
            .catch(error => {
                alert(error);
            });
    },

    //Restore User
    async restoreUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
            .post("users/" + data.id + "/user_restore", data)
            .then(response => {
                resolve(response);
                commit(
                    "showNotification",
                    {
                        title: "Success",
                        msg: response.data.success.message,
                        type: "success"
                    },
                    { root: true }
                );
            })
            .catch(error => {
                reject(error);
                alert(error);
            });
        });
    },
    
    //Get User Data By ID
    async getUserData({ commit }, user_id) {
        await http
            .get("users/" + user_id, {}, {})
            .then(response => {
                if (response) {
                    commit('setUserData', response.data);
                } else {
                    commit("setUserData", null);
                }
            })
            .catch(error => {
                let errorType = "E";
                commit("setError", { error, errorType });
            });
    },

    //Get User Roles
    async getUserRoleList({ commit }) {
        await http
            .get("user_roles/dropdown_list", {}, {})
            .then(response => {
                if (response) {
                    commit('setUserRoleList', response.data);
                } else {
                    commit("setUserRoleList", null);
                }
            })
            .catch(error => {
                alert(error);
            });
    },

    //Get User Branches
    async getUserBranchList({ commit }) {
        await http
            .get("branch/dropdown_list", {}, {})
            .then(response => {
                if (response) {
                    commit('setUserBranchList', response.data);
                } else {
                    commit("setUserBranchList", null);
                }
            })
            .catch(error => {
                alert(error);
            });
    },

};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
