<template>
  <div class="row bg justify-content-around align-items-center">
    <div class="col-md-11">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col">
              <img
                src="@/assets/img/platinum_product_img.jpg"
                class="showcase_img box-shadow"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row description_space">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <h4 class="pr_heading">Amana - Platinum Plan</h4>
              <p>
                Amãna Takaful Platinum is a premier life insurance plan that
                offers extensive protection benefits for you and your loved ones
                whilst securing your family’s financial security in case of an
                unforeseen event.
              </p>
            </div>
          </div>
          <div class="row align-items-end justify-content-around">
            <div class="col-sm-6">
              <button
                class="button_width btn btn-primary btn-block"
                @click="openPDF"
              >
                Get Brochure
              </button>
            </div>
            <div class="col-sm-6">
              <button
                class="button_width btn btn-primary btn-block"
                @click="openURL('https://www.youtube.com/watch?v=7sRA7o2JEZg')"
              >
                Play Video
              </button>
            </div>
          </div>

          <div class="row description_space">
            <div class="col-md-12 mb-2">
              <h5 class="pr_sub_heading"><strong>Product Features</strong></h5>
              <ul class="meateaters">
                <li>
                  Comprehensive Hospitalization Benefits for Individual or
                  Family from 100k up to 300k (optional)
                </li>
                <li>Flexible terms (10-30 years)</li>
                <li>
                  Additional unlimited Life Coverage up to 5 times of your basic
                  cover
                </li>
                <li>
                  Critical Illness covering 30 ailments for you from 100k up to
                  5mn (optional)
                </li>
              </ul>
              <ul v-if="showMore" class="meateaters mt-zero">
                <li>
                  Critical Illness covering 30 ailments for your spouse from
                  100k up to 5mn (optional)
                </li>
                <li>Waiver of Instalment cover (optional)</li>
                <li>
                  Additional Life cover for spouse from 100k up to 1mn(optional)
                </li>
                <li>Life cover of 25,000/- for each child (optional)</li>
                <li>Fully credited Returns on Investments</li>
                <li>Emergency withdrawal upon completion of 3 years</li>
              </ul>
              <a @click="showMore=!showMore" class="showMore-btn">
                {{ showMore ? "Show less" : "Show more" }}
              </a>
            </div>
            
          </div>

          <div class="row align-items-end justify-content-around">
            <div class="col-md-12">
              <button
                class="button_width btn btn-primary mb-5 btn-block"
                @click="goToEvents('new_quote_platinum')"
              >
                Get Quotation
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Welcome",
  methods: {
    goToEvents: function (package_name) {
      location.href = "#/" + process.env.VUE_APP_VERSION + "/" + package_name;
    },
    openURL: function (link) {
      let lang = localStorage.getItem("lang");
      let url = link;
      let base = process.env.VUE_APP_BACKEND;
      if (lang) {
        url = base + "/storage/product_videos/platinum/" + lang + ".mp4";
      }
      window.open(url, "_blank");
    },

    openPDF() {
      let lang = localStorage.getItem("lang");
      let url = "";
      let base = process.env.VUE_APP_BACKEND;
      if (lang) {
        url = base + "/storage/product_brochure/platinum/" + lang + ".pdf";
      }
      window.open(url, "_blank");
    },
  },
  data() {
    return {
      showMore : false
    };
  },
};
</script>

<style scoped>
.bg {
  /*https://www.pexels.com/*/
  background: white;
  min-height: 110vh;
  overflow: hidden;
  /* Center and scale the image nicely */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.showcase_img {
  width: 100%;
}

.box-shadow {
  border-radius: 8px;
  box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.15);
}

img {
  vertical-align: middle;
  border-style: none;
}

video {
  width: 60%;
}

.button_width {
  margin-top: 5px;
  margin-bottom: 5px;
  max-width: 100%;
}

.pr_heading {
  color: #067169;
}

.pr_sub_heading {
  font-size: 0.95rem;
  color: #067169;
}

@media (max-width: 768px) {
  .showcase_img {
    padding-top: 124px;
  }
}
</style>
