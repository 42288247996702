  <template>
  <div class="row-top-list2 table-row-top">
    <div class="card">
      <div class="card-header">
        <font-awesome-icon icon="align-left" />&nbsp;
        <span>{{ $t('list_lead')}}</span>

        <!-- <b-button
          style="margin:5px padding: .25rem .65rem;float:right;"
          variant="warning"
          class="ui btn-sm basic button"
          title="Export CSV"
          v-b-tooltip.hover.bottom="'Export CSV'"
          @click="onActionClicked('export-csv')"
        >&nbsp;Export CSV</b-button>-->
    

        <b-button
          style="margin:5px padding: .25rem .65rem;float:right;"
          variant="success"
          class="ui btn-sm basic button"
          title="Add New Lead"
          v-b-tooltip.hover.bottom="'Add New Lead'"
          @click="onActionClicked('add-item')"
        >
          <font-awesome-icon icon="plus" />&nbsp;Add New Lead
        </b-button>
      </div>
      <div class="card-body">
        <div class="row tbl-margin-top">
          <div class="col table-responsive">
            <vuetable
              class="table table-striped table-bordered nowrap vue-tbl"
              ref="leadtable"
              :api-url="getUrl"
              :fields="fields"
              pagination-path
              @vuetable:pagination-data="onPaginationData"
              :per-page="3"
              :row-class="onRowClass"
              :http-options="httpOptions"
              :append-params="moreParams"
            >
              <template slot="actions" slot-scope="props">


              <!-- Lead REJECT Modal Box - START -->
                <div>
                  <b-modal
                    v-model="rejectConfirmModelShow"
                    centered
                    :title="$t('LeadCloseModalTitle')"
                    header-bg-variant="primary"
                    header-text-variant="light"
                    body-bg-variant="light"
                    body-text-variant="dark"
                    footer-bg-variant="info"
                    footer-text-variant="dark"
                    :ok-title="'Yes, Reject this lead'"
                    :id="'modal-center-rejectconfirm'"
                    @ok="rejectLeadModal();onFilterReset()"
                    ref="modal-center-rejectconfirm"
                  >                  
                     <div class="row">
                      <!-- Hospital list -->
                      <div class="col-sm-12">
                        Are you sure to reject this Lead ?
                      </div>
                     </div>             
                                    
                  </b-modal>
                </div>
                <!--  Lead Close Modal Box - END -->

                <!-- Lead Close Modal Box - START -->
                <div>
                  <b-modal
                    v-model="closeConfirmModelShow"
                    centered
                    :title="$t('LeadCloseModalTitle')"
                    header-bg-variant="primary"
                    header-text-variant="light"
                    body-bg-variant="light"
                    body-text-variant="dark"
                    footer-bg-variant="info"
                    footer-text-variant="dark"
                    :ok-title="'Yes, Close this lead'"
                    :id="'modal-center-closeconfirm'"
                    @ok="closeLeadModal"
                    ref="modal-center-closeconfirm"
                  >                  
                     <div class="row">
                      <!-- Hospital list -->
                      <div class="col-sm-12">
                        Are you sure to close this Lead ?
                      </div>
                     </div>             
                                    
                  </b-modal>
                </div>
                <!--  Lead Close Modal Box - END -->


               <!-- Quotation Create Modal Box - START -->
                <div>
                  <b-modal
                    v-model="cretateQuotationModelShow"
                    centered
                    :title="$t('QuotationCreateModalTitle')"
                    header-bg-variant="primary"
                    header-text-variant="light"
                    body-bg-variant="light"
                    body-text-variant="dark"
                    footer-bg-variant="light"
                    footer-text-variant="dark"
                    :ok-title="'Select Quotation Type'"
                    :id="'modal-create-quotation'"
                    @ok="createQuotation"
                    ref="modal-create-quotation"
                  >                  
                     <div class="row">
                      <!-- Quotation Type List -->
                      <div class="col-sm-12">
                      

                          <div class="col-sm-6">
                          <div class="form-group">
                            <label>Select Quotation Type</label>
                            <select class="form-control" id="quotation_type" v-model="quote_type" name="type" refs="quotation_type">
                              <option value="1">Platinum</option>
                              <option value="2">Surakshitha</option>
                              <option value="3">Platinum Plus</option>
                              <option value="4">Adyapana Plus</option>
                              <option value="5">Deergayu</option>
                              <option value="6">Flexi Life</option>
                            </select>
                          </div>
                        </div>

                      </div>
                     </div>             
                                    
                  </b-modal>
                </div>
                <!--  Quotation Create Modal Box - END -->

                <!-- ACTION SLOT -->
                <div class="custom-actions" style="white-space:nowrap;text-align:center;">
                  <b-button
                    style="margin:5px padding: .25rem .65rem;;"
                    variant="info"
                    class="ui btn-sm basic button"
                    title="Edit"
                    v-b-tooltip.hover.bottom="'Edit'"
                    @click="onActionClicked('edit-item', props.rowData, props.rowIndex)"
                    v-if="editPermissionCheck() == true && checkEditable(props.rowData['leadstatus']) == true"
                  >
                    <font-awesome-icon icon="pen" />
                  </b-button>

                  <b-button
                    style="margin:5px padding: .25rem .65rem;"
                    variant="success"
                    class="ui btn-sm basic button"
                    title="View"
                    v-b-tooltip.hover.bottom="'View Lead'"
                    @click="onActionClicked('view-lead', props.rowData, props.rowIndex)"
                    v-if="viewPermissionCheck() == true"
                  >
                  <font-awesome-icon icon="eye" />
                  </b-button>

                  <b-button
                    style="margin:5px padding: .25rem .65rem;"
                    variant="warning"
                    class="ui btn-sm basic button"
                    title="Appointments"
                    v-b-tooltip.hover.bottom="'View Appointments'"
                    @click="onActionClicked('view-item', props.rowData, props.rowIndex)"
                  >   <font-awesome-icon icon="file" />
                  </b-button>

                  
                  <b-button
                    style="margin:5px padding: .25rem .65rem;"
                    variant="danger"
                    class="ui btn-sm basic button"
                    title="Close"
                    v-b-tooltip.hover.bottom="'Close Lead'"
                    v-if="checkCloseStatus(props.rowData['leadstatus']) == true" 
                    @click="onActionClicked('close-lead', props.rowData, props.rowIndex)"                  
                  >
                    <font-awesome-icon icon="ban" />
                  </b-button>

                  <b-button
                    style="margin:5px padding: .25rem .65rem;"
                    variant="danger"
                    class="ui btn-sm basic button"
                    title="Reject"
                    v-b-tooltip.hover.bottom="'Reject Lead'"
                    v-if="checkRejectable(props.rowData['leadstatus']) == true" 
                    @click="onActionClicked('reject-lead', props.rowData, props.rowIndex); "                  
                  >
                    <font-awesome-icon icon="trash" />
                  </b-button>

                  <!-- SELECT QUOTATION   -->
                  <b-button
                    style="margin:5px padding: .25rem .65rem;"
                    variant="info"
                    class="ui btn-sm basic button"
                    title="Create Quotation"
                    v-b-tooltip.hover.bottom="'Create Quotation'" 
                    v-if="checkClosed(props.rowData['leadstatus']) == false"                    
                    @click="onActionClicked('create-quotation', props.rowData, props.rowIndex)"                  
                    >
                    <font-awesome-icon icon="plus" />
                  </b-button>
                </div>
                <!-- ACTION SLOT -- END  -->
              </template>
            </vuetable>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="pagination">
              <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//console.log(process.env.VUE_APP_API_ENDPOINT);
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
//import { ValidationProvider } from "vee-validate";
//import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

import VuetablePagination from "@/components/vuetable/VuetablePaginationBootstrap4";

import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    //VuetablePaginationInfo
  },
  data() {
    return {
      rejectConfirmModelShow: false,
      closeConfirmModelShow: false,
      cretateQuotationModelShow: false,
      quote_type:1,
      fields: [
        {
          name: "id",
          title: '<span class="orange glyphicon glyphicon-user"></span> #',
          sortField: "id"
        },
        {
          name: "full_name",
          title: '<span class="orange glyphicon glyphicon-user"></span> Name',
          sortField: "full_name"
        },

        {
          name: "contact",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Contact No',
          sortField: "contact"
        },

        {
          name: "appointment",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Appointment Date and Time',
          sortField: "appointment"
        },
        {
          name: "gender",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Gender',
          sortField: "gender"
        },
        {
          name: "remarks",
          title:'<span class="orange glyphicon glyphicon-user"></span> Remarks',
          sortField: "remarks",
          width:'20%',
          callback : "remarksFormatter"
        },
        {
          name: "status",
          title:
            '<span class="orange glyphicon glyphicon-user"></span>Appointment Status',
          sortField: true,
          dataClass: 'center aligned',
        },
        {
          name: "leadstatus",
          title:
            '<span class="orange glyphicon glyphicon-user"></span>Lead Status',
          sortField: true
        },
        // {
        //   name:  "__slot:actions",
        //   title: '<span class="orange glyphicon glyphicon-user"></span>Actions'
        // }
        "__slot:actions"
      ],
      httpOptions: {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        },
        data: {}
      },
      moreParams: {},
      getUrl: process.env.VUE_APP_API_ENDPOINT + "lead/list"
    };
  },
  computed: {
    ...mapState("lead", [""]),
    ...mapGetters("login", ["checkUserPermissionNew"])
  } ,
  mounted() {
    this.$events.$on("filter-set", eventData => this.onFilterSet(eventData));
    this.$events.$on("filter-reset", this.onFilterReset());
  },
  // destroyed() {
  //   this.moreParams = {};
  //   Vue.nextTick(() => this.$refs.leadtable.refresh());
  //   this.$events.off("filter-reset");
  // },

  methods: {
    ...mapActions("lead", ["getCSVExport","closeLead","rejectLead"]),

    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.leadtable.changePage(page);
    },
    onRowClass(dataItem) {
      //console.log(dataItem);

      if (dataItem.status == "Expired") {
        return dataItem.status
          ? "expired-field-background"
          : "background-field-white";
      }
    },    
    onActionClicked(action, data) {
      if (action == "edit-item") {
        let str_edit_route = "edit_lead";

        this.$router.push({
          name: str_edit_route,
          params: {
            ref_no: data.id
          }
        });
      } else if (action == "view-item") {
        let str_edit_route = "appointment_list";

        this.$router.push({
          name: str_edit_route,
          params: {
            ref_no: data.id
          }
        });
      } else if (action == "view-lead") {
        let str_edit_route = "view_lead";

        this.$router.push({
          name: str_edit_route,
          params: {
            ref_no: data.id
          }
        });
      } else if (action == "add-item") {
        let str_edit_route = "new_lead";

        this.$router.push({
          name: str_edit_route,
          params: {}
        });
      } else if (action == "export-csv") {
        this.csvExport();
      }else if (action == "close-lead") {
        this.leadNo  = data.id;
        this.$bvModal.show("modal-center-closeconfirm");
        this.$bvModal.hide("modal-create-quotation");
         this.$bvModal.hide("modal-center-rejectconfirm");
      }else if (action == "reject-lead") {
        this.leadNo  = data.id;
        this.$bvModal.show("modal-center-rejectconfirm");
        this.$bvModal.hide("modal-center-closeconfirm");
        this.$bvModal.hide("modal-create-quotation");
      }
      
      else if (action == "create-quotation") {
        //  alert(JSON.stringify(data));
       this.leadNo  = data.id;
       this.name = data.name;
       this.title = data.title;
       this.contact = data.contact;
       this.fname = data.first_name;
       this.mname = data.middle_name;
       this.lname = data.last_name;
       this.nic = data.nic;
       this.dob = data.dob;
       this.email = data.email;
       this.gender = data.gender;
          
       this.$bvModal.show("modal-create-quotation");             
       this.$bvModal.hide("modal-center-closeconfirm");

        
      }
    },
    onFilterSet(filters) {
      // console.log(filters);
      this.moreParams = {
        name: filters.name,
        contact: filters.phone_no,
        nic: filters.nic,
        status: filters.status,
        leadstatus: filters.leadstatus,
        appointmentDate: filters.appointmentDate
      };
     Vue.nextTick(() => this.$refs.leadtable.refresh());
      //Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onFilterReset() {
      //console.log("reset");
      this.moreParams = {};
     Vue.nextTick(() => this.$refs.leadtable.refresh());
      //this.$refs.vuetable.refresh();
    },
    viewPermissionCheck() {
      return this.checkUserPermissionNew("view-quote");
    },
    editPermissionCheck() {
      return this.checkUserPermissionNew("edit-quote");     
    },
    exLoadingPermissionCheck() {
      return this.checkUserPermissionNew("ex-loading");
    },
    closeLeadModal() {
     let payload = {
         lead_no: this.leadNo
        };
        this.closeLead(payload);       
        this.$bvModal.hide("modal-center-closeconfirm" + this.leadNo);
        this.$refs.vuetable.refresh();
    },
    rejectLeadModal() {
     let payload = {
         lead_no: this.leadNo
        };
        this.rejectLead(payload);  
        // this.onFilterReset();    
        this.$bvModal.hide("modal-center-rejectconfirm" + this.leadNo);
        //this.$refs.leadtable.refresh();
       
        
    },
    checkCloseStatus(status){
      if(status == 'PROPOSAL CREATED'){
        return true;
      }else{
        return false;
      }
    },
    checkEditable(status){
      if(status == 'CLOSED' && status == 'REJECTED'){
        return false;
      }else{
        return true;
      }
    },
    checkRejectable(status){
      if(status == 'NEW'){
        return true;
      }else{
        return false;
      }
    },
    checkClosed(status){
      if(status == 'CLOSED'){
        return true;
      }else{
        return false;
      }
    },
    createQuotation(){
       let lead_no = this.leadNo;
       let quote_cat = this.quote_type;
       let name = this.name;
       let title = this.title;
       let nic = this.nic;
       let fname = this.fname;
       let mname = this.mname;
       let lname = this.lname;
       let dob = this.dob;
       let email = this.email;
       let gender = this.gender;
       let contact = this.contact;

       this.$bvModal.hide("modal-create-quotation");

      let str_create_quote_from_lead_route = '';
      if(quote_cat == 1){
           str_create_quote_from_lead_route = "new_quote_platinum";
      }else if(quote_cat == 2){
          str_create_quote_from_lead_route = "new_quote_surakshitha";
      }else if(quote_cat == 3){
          str_create_quote_from_lead_route = "new_quote_platinum_plus";
      }else if(quote_cat == 4){
          str_create_quote_from_lead_route = "new_quote_adyapana_plus";
      }else if(quote_cat == 5){
         str_create_quote_from_lead_route = "new_quote_deergayu";
      }else if(quote_cat == 6){
         str_create_quote_from_lead_route = "new_quote_flexi_life";
      }
    
      this.$router.push({
            name: str_create_quote_from_lead_route,
            params: { 
              lead_no : lead_no,
              quote_category : quote_cat,
              name : name,
              nic : nic,
              fname : fname,
              mname : mname,
              lname : lname,
              dob : dob,
              gender : gender,
              email : email,
              title : title,
              contact : contact
            }
        });
    },
    remarksFormatter(value){
        if(value){
            return (value.length>=90) ? value.substring(0,90)+"...." : value;
        }else{
          return " ";
        }
    },
  }
};
</script>

<style scoped>
.modal-backdrop {
  background-color: #0000001a !important;
}

.modal-content {
  border: 0px solid !important;
}

.tbl-margin-top {
  margin-top: 15px;
}

table th,
.table td {
  padding: 0.2rem 0.75rem !important;
  vertical-align: middle !important;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}

.pagination {
  float: right;
}

.btn {
  margin: 5px;
}
.page-link {
  font-size: 12px !important;
}
.tooltip {
  top: 0 !important;
}

.background-field-white {
  background-color: white;
}
.expired-field-background {
  background-color: #fc8d89 !important;
}
</style>

<i18n>
{
  "en": {
    "list_lead": "Leads List",
    "LeadCloseModalTitle": "Close Lead",
    "QuotationCreateModalTitle": "Select Quotation Type"
  },
  "si": {
  "list_lead": "කැඳවුම් ලැයිස්තුව",
    "LeadCloseModalTitle": "Close Lead",
    "QuotationCreateModalTitle": "Select Quotation Type"
  },
  "ta": {
     "list_lead": "Leads List",
    "LeadCloseModalTitle": "Close Lead",
    "QuotationCreateModalTitle": "Select Quotation Type"
  }
}
</i18n>