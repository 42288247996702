import { render, staticRenderFns } from "./MainLifeRiderWidget.vue?vue&type=template&id=49596c07&scoped=true"
import script from "./MainLifeRiderWidget.vue?vue&type=script&lang=js"
export * from "./MainLifeRiderWidget.vue?vue&type=script&lang=js"
import style0 from "./MainLifeRiderWidget.vue?vue&type=style&index=0&id=49596c07&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49596c07",
  null
  
)

export default component.exports