<template>
    <div class="row bg justify-content-around align-items-center">
      <div class="col-md-11">
        <div class="row">
          <div class="col-md-6 ">
            <div class="row">
              <div class="col">
                <img src="@/assets/img/platinum_plus_product_img.jpg" class="showcase_img box-shadow" />
              </div>
              
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="row description_space">
              <div class="col-md-12">
                 
                  <h4 class="pr_heading">Amana - MRTT</h4>
                  <p>Secure your dreams of homeownership with Amana Life's Loan Protection Plan, a single premium policy meticulously crafted to shield borrowers from unforeseen financial challenges in the event of untimely death during the remaining loan repayment period. Secure your homeownership journey with Amana Life's Loan Protection Plan. Your dream home deserves the assurance of financial protection.</p>
              </div>
            </div>
            <div class="row align-items-end justify-content-around"> 
              <div class="col-sm-6"> 
                <button class=" button_width btn  btn-primary  btn-block" @click="openPDF" >Get Brochure</button>
              </div>
              <div class="col-sm-6"> 
                <button class=" button_width btn  btn-primary  btn-block"  @click="openURL('https://www.youtube.com/watch?v=7sRA7o2JEZg')">Play Video</button>
              </div>
            </div>

            <div class="row description_space">
              <div class="col-md-12">
                  <h5 class="pr_sub_heading"><strong>Product Features</strong> </h5>
                 <ul class="meateaters">
                        <li>In the unfortunate event of the policyholder's sudden demise or permanent disability, the Loan Protection Policy settles the remaining loan balance, ensuring your loved ones are relieved from the financial burden*</li>
                        <li>Covers Individuals and joint applicants (e.g. Husband & Wife, Business Partners)</li>
                        <li>Life Protection : Extensive cover up to LKR 150,000,000/-</li>
                        <li>Disability coverage : covers up to LKR 50,000,000/-</li>
                        <li>Flexible terms (1 year - 25 years)</li>
                 </ul>
                 *Terms & conditions apply
              </div>
            </div>
            
            <div class="row align-items-end justify-content-around"> 
              <div class="col-md-12"> 
                <button class=" button_width btn  btn-primary mb-5  btn-block" @click="goToEvents('new_quote_mrtt')" >Get Quotation</button>
              </div>
            </div>

          </div>
          
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "Welcome",
  methods: {
    goToEvents: function (package_name) {
        location.href='#/'+process.env.VUE_APP_VERSION+'/'+ package_name
    },
    openURL: function(link){
      let lang = localStorage.getItem("lang");
      let url = link;
      let base = process.env.VUE_APP_BACKEND;
      if(lang){
          url = base + "/storage/product_videos/mrtt/"+lang+".mp4";
      }
      window.open(url, "_blank");
    },
    openPDF() {
      let lang = localStorage.getItem("lang");
      let url = '';
      let base = process.env.VUE_APP_BACKEND;
      if(lang){
          url = base + "/storage/product_brochure/mrtt/"+lang+".pdf";
      }
      window.open(url, "_blank");
    }
},
};
</script>

<style scoped>
.bg {
  /*https://www.pexels.com/*/
  background: white;
  min-height: 100vh;
  overflow: hidden;
  /* Center and scale the image nicely */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.showcase_img{
  width: 100%;
}
.box-shadow {
    border-radius: 8px;
    box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.15);
}

img {
    vertical-align: middle;
    border-style: none;
}
video{
  width: 60%;
}
.button_width{
  margin-top: 5px;
  margin-bottom: 5px;;
  max-width: 100%;
}
.pr_heading{
  color: #067169;
}
.pr_sub_heading{
  font-size: 0.95rem;
  color: #067169;
}
@media (max-width: 768px) {
.showcase_img {
    padding-top: 124px;
}
}
</style>
