<template>
    <div class="row bg justify-content-around align-items-center">
      <div class="col-md-11">
        <div class="row">
          <div class="col-md-6 ">
            <div class="row">
              <div class="col">
                <img src="@/assets/img/platinum_plus_product_img.jpg" class="showcase_img box-shadow" />
              </div>
              
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="row description_space">
              <div class="col-md-12">
                 
                  <h4 class="pr_heading">Amana - Platinum Plus Plan</h4>
                  <p>Amãna Takaful Platinum Plus is the most comprehensive life and hospitalization cover in Sri Lanka. In addition to the life cover, 
                    it offers hospitalization covers up to LKR 14 million. This covers not only you, but your whole family. You and your spouse can also be covered for up to LKR 5 million each for 30 listed critical illnesses.</p>
              </div>
            </div>
            <div class="row align-items-end justify-content-around"> 
              <div class="col-sm-6"> 
                <button class=" button_width btn  btn-primary  btn-block" @click="openPDF" >Get Brochure</button>
              </div>
              <div class="col-sm-6"> 
                <button class=" button_width btn  btn-primary  btn-block"  @click="openURL('https://www.youtube.com/watch?v=7sRA7o2JEZg')">Play Video</button>
              </div>
            </div>

            <div class="row description_space">
              <div class="col-md-12">
                  <h5 class="pr_sub_heading"><strong>Product Features</strong> </h5>
                 <ul class="meateaters">
                        <li>Comprehensive Hospitalization Benefits  starting from 100k up to LKR 14 million for your family</li>
                        <li>Overseas hospitalization from 1mn cover and above for Malaysia, Singapore, and India</li>
                        <li> 30 critical illnesses covered up to LKR 10 million for you and spouse (Optional)</li>
                        <li>Cashless facilities at times of urgent medical requirements</li>
                        <li>Flexible terms (10-30 years)</li>
                        <li>Additional unlimited Life Cover up to 5 times your basic cover (Optional)</li>
                        <li>Personal Accident Cover up to 20mn (optional)</li>
                 </ul>
              </div>
            </div>
            
            <div class="row align-items-end justify-content-around"> 
              <div class="col-md-12"> 
                <button class=" button_width btn  btn-primary mb-5  btn-block" @click="goToEvents('new_quote_platinum_plus')" >Get Quotation</button>
              </div>
            </div>

          </div>
          
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "Welcome",
  methods: {
    goToEvents: function (package_name) {
        location.href='#/'+process.env.VUE_APP_VERSION+'/'+ package_name
    },
    openURL: function(link){
      let lang = localStorage.getItem("lang");
      let url = link;
      let base = process.env.VUE_APP_BACKEND;
      if(lang){
          url = base + "/storage/product_videos/platinum_plus/"+lang+".mp4";
      }
      window.open(url, "_blank");
    },
    openPDF() {
      let lang = localStorage.getItem("lang");
      let url = '';
      let base = process.env.VUE_APP_BACKEND;
      if(lang){
          url = base + "/storage/product_brochure/platinum_plus/"+lang+".pdf";
      }
      window.open(url, "_blank");
    }
},
};
</script>

<style scoped>
.bg {
  /*https://www.pexels.com/*/
  background: white;
  min-height: 100vh;
  overflow: hidden;
  /* Center and scale the image nicely */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.showcase_img{
  width: 100%;
}
.box-shadow {
    border-radius: 8px;
    box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.15);
}

img {
    vertical-align: middle;
    border-style: none;
}
video{
  width: 60%;
}
.button_width{
  margin-top: 5px;
  margin-bottom: 5px;;
  max-width: 100%;
}
.pr_heading{
  color: #067169;
}
.pr_sub_heading{
  font-size: 0.95rem;
  color: #067169;
}
@media (max-width: 768px) {
.showcase_img {
    padding-top: 124px;
}
}
</style>
