import { http } from "@/config/http";
//moment datepicker
// import moment from "moment";

const namespaced = true;

let data = {};

//date for datepicker validation
var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;

const getDefaultState = () => {
    return {
        leadInfoData: {
            leadNo: "",
            drpTitle: "",
            txtLeadFname: "",
            txtLeadMname: "",
            txtLeadLname: "",
            txtLeadNIC: "",
            txtLeadMob: "",
            txtLeadEmail: "",
            txtResidenceArea: "",
            txtLeadDOB: "",
            rdbLeadGender: "Male",
            txtLeadContactedDate: "",
            drpLeadStatus: 1
        },
        AppointmentInfo: {
            txtAppointID: "",
            txtLeadID: "",
            txtAppointDate: "",
            txtAppointTime: "",
            txtAppointRemarks: "",
            drpAppointStatus: "",
            curdate: today
        },
        AppointForm: {
            isSave: true,
            isRescheduled: false,
            loadTable: false,
            loadCalendar: false
        },
        CalendarList: [],
        LeadTable: {
            refreshTable: false
        },
        flash: {
            type: "W",
            status: false,
            error: ""
        },
        timestamp: "",
        minflash: {
            status: false,
            error: ""
        },
        exportCSV: [],
        statusOptions: {
            statusPending: true,
            statusVisited: false,
            statusRescheduled: false,
            statusCancelled: false,
            statusExpired: false
        },
        options: [
            {
                disabled: false,
                value: "Pending",
                display: "Pending"
            },
            {
                disabled: false,
                value: "Visited",
                display: "Visited"
            },
            // {
            //   disabled: false,
            //   value: "Rescheduled",
            //   display: "Rescheduled"
            // },
            {
                disabled: false,
                value: "Cancelled",
                display: "Cancelled"
            },
            // {
            //   disabled: false,
            //   value:  "Expired",
            //   display: "Expired"
            // }
        ]

    };
};

const state = getDefaultState();

const getters = {
    timestamp() {
        return state.timestamp;
    },
};

const mutations = {
    clearObj(state) {
        Object.assign(state, getDefaultState());
    },
    setDefaultLeadTime(state, data) {
        let time = data.slice(-8);
        let date = data.substring(0, 10);
       state.leadInfoData.txtLeadDOB = date;
       state.leadInfoData.txtLeadContactedDate = time.substring(0, 5); //extrace HH:mm

    },
    setDefaultAppointmentTime(state, data) {
        let time = data.slice(-8);
        let date = data.substring(0, 10);
        state.AppointmentInfo.txtAppointDate = date;
        state.AppointmentInfo.txtAppointTime = time.substring(0, 5); //extrace HH:mm
    },
    clearAppointForm(state) {
        state.AppointForm.loadTable = true;
        state.AppointmentInfo.txtAppointRemarks = "";
        state.AppointmentInfo.txtAppointRemarks = "";

    },

    /** Error Message destroy */
    clearFlash(state) {
        state.flash = {
            type: "",
            status: false,
            error: ""
        };
    },

    loadLeadData(state, data) {
        state.leadInfoData.drpTitle = data.title;
        state.leadInfoData.txtLeadFname = data.first_name;
        state.leadInfoData.txtLeadMname = data.middle_name;
        state.leadInfoData.txtLeadLname = data.last_name;
        state.leadInfoData.txtLeadNIC = data.nic;
        state.leadInfoData.txtLeadMob = data.contact;
        state.leadInfoData.txtLeadEmail = data.email;
        state.leadInfoData.txtResidenceArea = data.residence_area;
        state.leadInfoData.txtLeadDOB = data.dob;
        state.leadInfoData.txtLeadContactedDate = data.contacted_date;
        state.leadInfoData.rdbLeadGender = data.gender;
        state.leadInfoData.drpLeadStatus = data.status;
    },

    loadAppointmentData(state, data) {
        state.AppointmentInfo.txtAppointDate = data.date;
        state.AppointmentInfo.txtAppointTime = data.time;
        state.AppointmentInfo.txtAppointRemarks = data.remarks;
        state.AppointmentInfo.drpAppointStatus = data.status;
        state.AppointForm.isSave = false;
    },
    setTimestamp(state, data) {
        state.timestamp = data;

    },
    setRefresh(state) {
        if (state.AppointForm.loadTable) {
            state.AppointForm.loadTable = false;

        } else {
            state.AppointForm.loadTable = true;
        }
    },
    setReloadCal(state) {
        if (state.AppointForm.loadCalendar) {
            state.AppointForm.loadCalendar = false;

        } else {
            state.AppointForm.loadCalendar = true;
        }
    },
    setLeadRefresh(state) {
        if (state.LeadTable.refreshTable) {
            state.LeadTable.refreshTable = false;
        } else {
            state.LeadTable.refreshTable = true;
        }
    },
    setExportCSVData(state, data) {
        state.exportCSV = data;
    },
    resetExportCSVData(state) {
        state.exportCSV = [];
    },
    setCalendarList(state, data) {
        state.CalendarList = data;
        state.AppointForm.loadCalendar = true;
    },
    setCalendarAppointment(state, data) {
        state.AppointmentInfo.txtAppointDate = data.date;
        state.AppointmentInfo.txtAppointTime = data.time;
        state.AppointmentInfo.txtAppointRemarks = data.remarks;
        state.AppointmentInfo.drpAppointStatus = data.status;
    },
    setCalendarAppointmentDate(state, data) {
        state.AppointmentInfo.txtAppointDate = data.date;
        state.AppointForm.loadTable = true;
    }

};

const actions = {
    async clearStateData({ commit }) {
        commit("clearObj");
    },

    async addLead({ commit }) {
        let new_lead = {};
        let data = {};

        new_lead = {
            title: state.leadInfoData.drpTitle,
            first_name: state.leadInfoData.txtLeadFname,
            middle_name: state.leadInfoData.txtLeadMname,
            last_name: state.leadInfoData.txtLeadLname,
            nic: state.leadInfoData.txtLeadNIC,
            contact: state.leadInfoData.txtLeadMob,
            email: state.leadInfoData.txtLeadEmail,
            residence_area: state.leadInfoData.txtResidenceArea,
            dob: state.leadInfoData.txtLeadDOB,
            contacted_date: state.leadInfoData.txtLeadContactedDate,
            gender: state.leadInfoData.rdbLeadGender,
            status: 1
        }

        data = {
            new_lead
        };

        await http
            .post("lead/add", { data }, {})
            .then(response => {
                if (response.data.meta.status == "true") {

                    commit(
                        "showNotification",
                        {
                            title: "Lead",
                            msg: response.data.meta.status_message,
                            type: "success"
                        },
                        { root: true }
                    );

                } else {
                    commit(
                        "showNotification",
                        {
                            title: "Lead",
                            msg: response.data.meta.status_message,
                            type: "error"
                        },
                        { root: true }
                    );
                }
            })
            .catch();

    },

    async getTimestamp({ commit }) {
        await http
            .get("conf/date_time", { data }, {})
            .then(response => {
                if (response.data.meta.status == "true") {
                    commit("setTimestamp", response.data.data);
                }
            });

    },

    async loadDefLeadTime({ commit }) {
        await http
            .get("conf/date_time", { data }, {})
            .then(response => {
                if (response.data.meta.status == "true") {
                    commit("setDefaultLeadTime", response.data.data);
                }
            });
    },

    async loadDefAppointmentTime({ commit }) {
        await http
            .get("conf/date_time", { data }, {})
            .then(response => {
                if (response.data.meta.status == "true") {
                    commit("setDefaultAppointmentTime", response.data.data);//Extract Current time from DateTime

                }
            });
    },

    async updateLead({ commit }, leadNo) {
        let update_lead = {};
        let data = {};

        update_lead = {
            title: state.leadInfoData.drpTitle,
            first_name: state.leadInfoData.txtLeadFname,
            middle_name: state.leadInfoData.txtLeadMname,
            last_name: state.leadInfoData.txtLeadLname,
            nic: state.leadInfoData.txtLeadNIC,
            contact: state.leadInfoData.txtLeadMob,
            email: state.leadInfoData.txtLeadEmail,
            residence_area: state.leadInfoData.txtResidenceArea,
            dob: state.leadInfoData.txtLeadDOB,
            contacted_date: state.leadInfoData.txtLeadContactedDate,
            gender: state.leadInfoData.rdbLeadGender
        }

        data = {
            update_lead,
            leadNo
        };

        await http
            .post("lead/update", { data }, {})
            .then(response => {
                if (response.data.meta.status == "true") {
                    commit(
                        "showNotification",
                        {
                            title: "Lead",
                            msg: response.data.meta.status_message,
                            type: "success"
                        },
                        { root: true }
                    );
                } else {
                    commit(
                        "showNotification",
                        {
                            title: "Lead",
                            msg: response.data.meta.status_message,
                            type: "error"
                        },
                        { root: true }
                    );
                }
            })
            .catch();
    },

    async addAppointment({ commit }, lead_no) {
        let new_appointment = {};
        let data = {};
        let lead_id = lead_no;

        new_appointment = {
            lead_id: lead_id,
            date: state.AppointmentInfo.txtAppointDate,
            time: state.AppointmentInfo.txtAppointTime,
            remarks: state.AppointmentInfo.txtAppointRemarks,
            status: state.AppointmentInfo.drpAppointStatus
        }

        data = {
            new_appointment
        };

        await http
            .post("appointment/add", { data }, {})
            .then(response => {
                if (response.data.meta.status == "true") {
                    commit("setReloadCal");
                    commit("setRefresh");
                    commit(
                        "showNotification",
                        {
                            title: "Appointment",
                            msg: response.data.meta.status_message,
                            type: "success"
                        },
                        { root: true }
                    );

                } else {
                    commit(
                        "showNotification",
                        {
                            title: "Appointment",
                            msg: response.data.meta.status_message,
                            type: "error"
                        },
                        { root: true }
                    );
                }
            })
            .catch();


    },

    async updateAppointment({ commit }, appointmentNo) {
        let update_appointment = {};
        let data = {};

        update_appointment = {
            date: state.AppointmentInfo.txtAppointDate,
            time: state.AppointmentInfo.txtAppointTime,
            status: state.AppointmentInfo.drpAppointStatus,
            remarks: state.AppointmentInfo.txtAppointRemarks,
        }

        data = {
            update_appointment,
            appointmentNo
        };


        await http
            .post("appointment/update", { data }, {})
            .then(response => {
                if (response.data.meta.status == "true") {

                    //Set to Add Button
                    state.AppointForm.isSave = true;
                    //  this.loadDefAppointmentTime();
                    commit("clearAppointForm");

                    commit("setReloadCal");
                    commit("setRefresh");
                    commit(
                        "showNotification",
                        {
                            title: "Appointment",
                            msg: response.data.meta.status_message,
                            type: "success"
                        },
                        { root: true }
                    );


                } else {
                    commit(
                        "showNotification",
                        {
                            title: "Appointment",
                            msg: response.data.meta.status_message,
                            type: "error"
                        },
                        { root: true }
                    );
                }
            })
            .catch();



    },

    async addChangedData({ commit, state }, type = "preview", refno = null) {
        let member_main = {};

        if (type == "update") {
            refno = state.refNo;
        }

        let data = {
            save_state: type,
            branch_code: "CLS",
            channel_code: "01",
            user_category: "MDRT",
            agent_code: "E660",
            user_role: "UWR",
            ref_no: refno
        };
        /** Member information hold object */
        member_main = {
            title: state.mainInfoData.drpMainLifetitle,
            first_name: state.mainInfoData.txtMainLifeFname,
            middle_name: state.mainInfoData.txtMainLifeMname,
            last_name: state.mainInfoData.txtMainLifeLname,
            nic: state.mainInfoData.txtMainLifeNIC,
            email: state.mainInfoData.txtMainLifeEmail,
            gender: state.mainInfoData.rdbMainGender,
            dob: state.mainInfoData.txtMainLifeDOB,
            telephone: state.mainInfoData.txtMainLifeMob,

        };

        //** Illustration request object prepare */
        data = {
            ...data,
            member_main
        };
        let errorType = "";
        let error = "";
        //** Illustration request axios call */
        commit("clearFlash"); //destroy snotify
        commit("clearMinMsg"); // destroy Min Summary Message
        await http
            .post("quote_adyapana_plus/illustration", { data }, {})
            .then(response => {
                if (response.data.meta.status == "true") {
                    if (type == "create" || type == "update") {
                        //commit("setChangedObjSuccessCreate");
                        error = response.data.meta.status_message;
                        errorType = "S";
                        //console.log("illustration response :" + error);
                        commit("setError", { error, errorType });
                    } else {
                        commit("setChangedObj", response.data.data);
                        commit(
                            "setMinMsg",
                            " Rs. " + response.data.data.summary_formatted.total_premium
                        );
                    }
                } else {
                    error = response.data.meta.status_message;
                    errorType = "E";
                    //console.log("illustration response :" + error);
                    commit("setError", { error, errorType });
                }
            })
            .catch(error => {
                let errorType = "E";
                //commit("setError", "Error : illustration Service Response Failed");
                //alert("Error : illustration Service Response Failed");
                commit("setError", { error, errorType });
            });
    },

    async getOneLead({ commit }, refNo) {
        let errorType = "";
        commit("clearFlash"); //destroy snotify
        await http
            .post("lead/getLead", { ref_no: refNo }, {})
            .then(response => {
                if (response.data.meta.status == "true") {
                    //console.log(response.data.data);
                    commit("loadLeadData", response.data.data);
                } else {
                    errorType = "E";
                    //  let error = "Error : Get One Quotation Service Response Failed";
                    // commit("setError", { error, errorType });
                    //alert("Something went wrong - getOneQuotation");
                }
            })
            .catch(error => {
                //alert(error);
                errorType = "E";
                commit("setError", { error, errorType });
            });
    },

    async loadAppoint({ commit }, appointData) {
        commit("loadAppointmentData", appointData);
    },

    async getCalendarAppointmentList({ commit }, leadNo) {
        await http
            .post("appointment/get_calendar_list", { lead_no: leadNo }, {})
            .then(response => {
                if (response.data.meta.status == "true") {
                    commit("setCalendarList", response.data.data); //Set Appointment List for Calendar

                } else {
                    //alert('data retrieve error');
                }
            });
    },

    async getCalendarAllAppointmentList({ commit },) {
        await http
            .post("appointment/get_calendar_all_appointments", {}, {})
            .then(response => {
                if (response.data.meta.status == "true") {
                    commit("setCalendarList", response.data.data); //Set Appointment List for Calendar
                } else {
                    alert('data retrieve error');
                }
            });
    },

    async refreshLeadTable({ commit }) {
        commit("setLeadRefresh");
    },

    /** CSV EXPORT */
    async getCSVExport({ commit }, filters) {

        await http
            .post("lead/get_lead_data", filters, {})
            .then(response => {

                if (response.data.meta.status == "true") {
                    let csvdata = [];
                    csvdata = response.data.meta.data;
                    commit("clearObj");
                    commit("setExportCSVData", csvdata);
                } else {

                    commit(
                        "showNotification",
                        {
                            title: "Draft",
                            msg: 'nodata',
                            type: "error"
                        },
                        { root: true }
                    );
                }
            })
            .catch();
    },

    // Close Lead */
    async closeLead({ commit }, leadNo) {
        let data = {};

        data = {
            leadNo
        };

        await http
            .post("lead/close", { data }, {})
            .then(response => {
                if (response.data.meta.status == "true") {
                    commit(
                        "showNotification",
                        {
                            title: "Lead Close Success",
                            msg: response.data.meta.status_message,
                            type: "success"
                        },
                        { root: true }
                    );
                } else {
                    commit(
                        "showNotification",
                        {
                            title: "Lead Close Error",
                            msg: response.data.meta.status_message,
                            type: "error"
                        },
                        { root: true }
                    );
                }
            })
            .catch();
    },
    // Close Lead */
    async rejectLead({ commit }, leadNo) {
        let data = {};

        data = {
            leadNo
        };

        await http
            .post("lead/reject", { data }, {})
            .then(response => {
                if (response.data.meta.status == "true") {
                    commit(
                        "showNotification",
                        {
                            title: "Lead Rejected Successfully",
                            msg: response.data.meta.status_message,
                            type: "success"
                        },
                        { root: true }
                    );
                } else {
                    commit(
                        "showNotification",
                        {
                            title: "Lead Reject Error",
                            msg: response.data.meta.status_message,
                            type: "error"
                        },
                        { root: true }
                    );
                }
            })
            .catch();
    },
    async setCalendarAppointmentData({ commit }, appointmentNo) {

        await http
            .post("appointment/get_appointment", { appointment_no: appointmentNo }, {})
            .then(response => {

                let data = {
                    id: response.data.data.id,
                    time: response.data.data.time,
                    date: response.data.data.date,
                    remarks: response.data.data.remarks,
                    status: response.data.data.status
                }
                if (response.data.meta.status == "true") {
                    commit("setCalendarAppointment", data); //Set Appointment in Edit Modal
                } else {
                    alert('Data Retrieve Error');
                }
            });
    },
    async calendarDateSelect({ commit }, date) {

        let data = {
            date: date
        };
        commit("setCalendarAppointmentDate", data);
        commit("clearAppointForm");
        commit("setRefresh");
    }

};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
