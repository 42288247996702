import { http } from "@/config/http"

//moment datepicker
// import moment from "moment";

const namespaced = true;


const getDefaultState = () => {
    return {
        brancheInfoData: {
            branch_name: "",
            branch_code: "",
            status: 1,

        },

        CalendarList: [],
        BranchTable: {
            refreshTable: false
        },
        flash: {
            type: "W",
            status: false,
            error: ""
        },
        timestamp: "",
        minflash: {
            status: false,
            error: ""
        },
        exportCSV: [],


    };
};

const state = getDefaultState();

const getters = {
    timestamp() {
        return state.timestamp;
    },
};

const mutations = {
    clearObj(state) {
        Object.assign(state, getDefaultState());
    },



    /** Error Message destroy */
    clearFlash(state) {
        state.flash = {
            type: "",
            status: false,
            error: ""
        };
    },

    loadBranchData(state, data) {
        state.brancheInfoData.branch_name = data.branch_name;
        state.brancheInfoData.branch_code = data.branch_code;
        state.brancheInfoData.status = data.active;

    },


    setBranchRefresh(state) {
        if (state.BranchTable.refreshTable) {
            state.BranchTable.refreshTable = false;
        } else {
            state.BranchTable.refreshTable = true;
        }
    },
    setExportCSVData(state, data) {
        state.exportCSV = data;
    },
    resetExportCSVData(state) {
        state.exportCSV = [];
    },


};

const actions = {
    async clearStateData({ commit }) {
        commit("clearObj");
    },

    async addBranch({ commit }) {
        let new_branch = {};


        new_branch = {
            branch_name: state.brancheInfoData.branch_name,
            branch_code: state.brancheInfoData.branch_code,
            status: state.brancheInfoData.status
        }

        return new Promise((resolve, reject) => {

            http
                .post("branch/add", { new_branch }, {})
                .then(response => {
                    resolve(response);
                    if (response.data.meta.status == "true") {
                        console.log("logData" + response.data.meta.status);
                        commit(
                            "showNotification",
                            {
                                title: "Branch",
                                msg: response.data.meta.status_message,
                                type: "success"
                            },
                            { root: true }
                        );

                    } else {
                        commit(
                            "showNotification",
                            {
                                title: "Branch",
                                msg: response.data.meta.status_message,
                                type: "error"
                            },
                            { root: true }
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Error Occured.",
                        type: "error",
                    }, { root: true }
                    );
                });
        });

    },


    async updateBranch({ commit }, id) {
        let update_branch = {};
        let data = {};

        update_branch = {
            branch_name: state.brancheInfoData.branch_name,
            branch_code: state.brancheInfoData.branch_code,
            status: state.brancheInfoData.status
        }

        data = {
            update_branch,
            id
        };

        return new Promise((resolve, reject) => {
            http
                .post("branch/update", { data }, {})
                .then(response => {
                    resolve(response);
                    if (response.data.meta.status == "true") {
                        commit(
                            "showNotification",
                            {
                                title: "Branch",
                                msg: response.data.meta.status_message,
                                type: "success"
                            },
                            { root: true }
                        );
                    } else {
                        commit(
                            "showNotification",
                            {
                                title: "Branch",
                                msg: response.data.meta.status_message,
                                type: "error"
                            },
                            { root: true }
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Error Occured.",
                        type: "error",
                    }, { root: true }
                    );
                });
        });
    },


    async getBranch({ commit }, ref_no) {
        let errorType = "";
        commit("clearFlash");
        await http
            .post("branch/getBranch", { id: ref_no }, {})
            .then(response => {
                if (response.data.meta.status == "true") {

                    commit("loadBranchData", response.data.data);
                } else {
                    errorType = "E";

                }
            })
            .catch(error => {

                errorType = "E";
                commit("setError", { error, errorType });
            });
    },

    async refreshBranchTable({ commit }) {
        commit("setBranchRefresh");
    },



};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
