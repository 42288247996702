<!-- Health Details Root Widget -->
<template>
<div>
    <!-- Health - Good Health Question Widget (03) -->
    <div class="row">
        <vue-snotify></vue-snotify>
        <div class="card">
            <div class="card-header">
                <i class="ti ti-user"></i>
                <span>&nbsp;&nbsp;&nbsp;{{ $t("PresentStateHealthTitle") }}</span>
            </div>
            <div class="card-body box-body">
                <HealthGoodHealthQuestion />
            </div>
        </div>
    </div>
    <div class="row" v-if="typeof ChildInfoData !== 'undefined' && ChildInfoData.length > 0">
        <div class="card ">
            <div class="card-header">
                <i class="ti ti-user"></i>
                <span>&nbsp;&nbsp;&nbsp;{{ $t("ChildHealthStatusTitle") }}</span>
            </div>
            <div class="card-body box-body  ">
                <HealthChildHealthQuestion />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="card">
            <div class="card-header">
                <i class="ti ti-user"></i>
                <span>&nbsp;&nbsp;&nbsp;{{ $t("HabitsTitle") }}</span>
            </div>
            <div class="card-body box-body">
                <!-- Health - Habits Question List Widget (09) -->
                <HealthHabitsQuestionList />
            </div>
        </div>
    </div>

    <div class="row">
        <!-- Info - Family Information of the Life to be Assured and Spouse Question Widget (08) -->
        <div class="card">
            <div class="card-header">
                <i class="ti ti-user"></i>
                <span>&nbsp;&nbsp;&nbsp;{{ $t("CovidTitle") }}</span>
            </div>
            <div class="card-body box-body">
                <HealthCovid19 />
            </div>
        </div>
    </div>


    <div class="row">
        <!-- Info - Family Information of the Life to be Assured and Spouse Question Widget (08) -->
        <div class="card">
            <div class="card-header">
                <i class="ti ti-user"></i>
                <span>&nbsp;&nbsp;&nbsp;{{ $t("FamilyInfoLifeAssSpouseTitle") }}</span>
            </div>
            <div class="card-body box-body">
                <HealthFamilyInfoQuestion />
            </div>
        </div>
    </div>

    <!-- Health - For Female Applicable Only Question Widget (06) -->
    <div class="row" v-if="InfoInsured.gender == 'Female' || InfoInsured.sp_gender == 'Female'">
        <div class="card">
            <div class="card-header">
                <i class="ti ti-user"></i>
                <span>&nbsp;&nbsp;&nbsp;{{ $t("ForFemaleAppTitle") }}</span>
            </div>
            <div class="card-body box-body">
                <HealthFemaleQuestion />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
import HealthGoodHealthQuestion from "@/components/proposal/health/HealthGoodHealthQuestion";
import HealthChildHealthQuestion from "@/components/proposal/health/HealthChildHealthQuestion";
import HealthHabitsQuestionList from "@/components/proposal/health/HealthHabitsQuestionList";
/* Info - Family Information of the Life to be Assured and Spouse Question Widget (08) */
import HealthFamilyInfoQuestion from "@/components/proposal/health/HealthFamilyInfoQuestion";
import HealthFemaleQuestion from "@/components/proposal/health/HealthFemaleQuestion";
import HealthCovid19 from "@/components/proposal/health/HealthCovid19";

export default {
    name: "health-details-root-widget",
    components: {
        HealthGoodHealthQuestion,
        HealthChildHealthQuestion,
        HealthHabitsQuestionList,
        HealthFamilyInfoQuestion,
        HealthFemaleQuestion,
        HealthCovid19,
    },
    data() {
        return {};
    },
    computed: {
        ...mapState("proposal", ["ChildInfoData", "InfoInsured"]),
    },
    methods: {
        showMsgBoxError(errorMsg) {
            this.boxTwo = "";
            this.$bvModal
                .msgBoxOk(errorMsg, {
                    title: "Error",
                    size: "sm",
                    buttonSize: "lg",
                    okVariant: "danger",
                    headerBgVariant: "danger",
                    headerTextVariant: "light",
                    bodyBgVariant: "danger",
                    bodyTextVariant: "light",
                    centered: true,
                })
                .then((value) => {
                    this.boxTwo = value;
                })
                .catch((err) => {
                    // An error occurred
                    alert(err);
                });
        },
    },
};
</script>

<style scoped></style>

<!-- Language Data for Health Details Root Widget -->
<i18n>
{
  "en": {
    "PresentStateHealthTitle": "Present State of Health",
    "ChildHealthStatusTitle": "Children Health Status",
    "HabitsTitle": "Health Condition",
    "FamilyInfoLifeAssSpouseTitle": "Family Information of the Life to be Assured and Spouse",
    "DetailsHealthTitle": "Details of Health",
    "ForFemaleAppTitle": "For Female Applicable Only",
    "CovidTitle": "COVID-19 (Coronavirus) Exposure Questionnaire"
  },
  "si": {
    "PresentStateHealthTitle": "වර්තමාන සෞඛ්‍ය තත්වය",
    "ChildHealthStatusTitle": "දරුවන්ගේ සෞඛ්‍ය තත්වය",
    "HabitsTitle": "Health Condition",
    "FamilyInfoLifeAssSpouseTitle": "සහතික කළ යුතු සහ කලත්‍රයාගේ ජීවිතයේ පවුල් තොරතුරු",
    "DetailsHealthTitle": "සෞඛ්‍යය පිළිබඳ විස්තර",
    "ForFemaleAppTitle": "කාන්තා ඉල්ලුම්කරුවන් සඳහා පමණි"
  },
  "ta": {
    "PresentStateHealthTitle": "தற்போதைய சுகாதார நிலை",
    "ChildHealthStatusTitle": "குழந்தைகள் சுகாதார நிலை",
    "HabitsTitle": "Health Condition",
    "FamilyInfoLifeAssSpouseTitle": "உறுதி செய்யப்பட வேண்டிய வாழ்க்கை மற்றும் வாழ்க்கைத் குடும்பத் தகவல்",
    "DetailsHealthTitle": "சுகாதார விவரங்கள்",
    "ForFemaleAppTitle": "பெண் விண்ணப்பதாரர்களுக்கு மட்டுமே"
  }
}
</i18n>
