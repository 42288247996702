<!--Role Create Widget  -->
<template>
  <div class="row row-top" style="scroll: none; margin-bottom: -50px">
    <vue-snotify></vue-snotify>
    <div class="col-sm-12 top-margin">
      <div class="card">
        <div class="card-header header-font">
          <font-awesome-icon icon="users" />
          <span>&nbsp; {{ action_title }} </span>
        </div>

        <div class="card-body">
          <ValidationObserver ref="namerow">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="lbl_create_role">
                    Role Name
                    <span style="color: red">*</span>
                  </label>
                  <ValidationProvider
                    name="Role name"
                    rules="required|minmax:2,60|alpha_spaces"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        ref="roleName"
                        class="form-control"
                        id="txtRoleName"
                        name="roleName"
                        placeholder="Role Name"
                        v-model="roleData.role_name"
                        v-uppercase
                        :disabled="isDisabled"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- FirstName -->
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="lbl_create_role">
                    Associated Permissions
                    <span style="color: red">*</span>
                  </label>
                  <ValidationProvider
                    name="Permissions"
                    rules="required"
                    :custom-messages="{
                      required: 'A role must have at least one permission',
                    }"
                  >
                    <div slot-scope="{ errors }">
                      <b-row
                        class="row"
                        v-for="(permission, index) in roleData.permissions"
                        v-bind:key="index"
                      >
                        <div class="col-sm-2">
                          <div class="toggle-button-cover">
                            <!--<div class="button-cover">-->
                            <div class="button r" id="button-1">
                              <input
                                type="checkbox"
                                :value="permission.name"
                                :id="permission.name"
                                class="checkbox"
                                :checked="true"
                                v-model="roleData.role_permissions"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-10">
                          <div class="permission">
                            <strong>{{ permission.name }}</strong>
                          </div>
                        </div>
                      </b-row>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- FirstName -->
            </div>
          </ValidationObserver>

          <b-row>
            <div class="col-sm-10"></div>
            <div class="col-sm-2">
              <button
                type="submit"
                ref="createBtn"
                class="btn btn btn-success btn-sm btn-block btn_height"
                @click="onSubmit"
              >
                &nbsp;{{ action }} Role
              </button>

              <button
                type="submit"
                ref="updateBtn"
                class="btn btn btn-secondary btn-md btn-block btn_height"
                @click="onCancel"
              >
                &nbsp;Cancel
              </button>
            </div>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import Vue from "vue";
import { mapState, mapActions } from "vuex";
// import { SnotifyPosition } from "vue-snotify";

export default {
  name: "role-create-widget",

  data: function () {
    return {
      action: "Create",
      action_title: "Create Role",
      isSave: true,
      isDisabled: false,
      role_id: "",
      role_name: "",
      active: false,
      permissions: [],
      selectedData: [],
    };
  },
  computed: {
    ...mapState("role", ["roleData"]),
  },

  mounted() {
    this.getAllPermissions();

    if (this.$route.name == "view_role") {
      this.isDisabled = "disabled";
    } else if (this.$route.name == "edit_role") {
      this.isDisabled = null;
      this.action = "Edit";
      this.action_title = "Edit Role";
      this.role_id = this.$route.query.role_id;
      this.loadRoleData(this.role_id);
    } else if (this.$route.name == "create_role") {
      this.isDisabled = null;
      this.action = "Create";
      this.action_title = "Create Role";
    }

    // if (this.$route.params.ref_no) {
    //   await this.loadLeadInfo();
    // } else {
    //   await this.clearData();
    // }
  },

  methods: {
    ...mapActions("role", [
      "getAllPermissions",
      "updateRoleData",
      "getRoleData",
      "createRole",
    ]),
    inactive: function (action) {
      this.active = action;
    },

    async loadRoleData(role_id) {
      await this.getRoleData(role_id);
    },

    async roleCreate() {
      const roleInfo = {
        role_name: this.roleData.role_name,
        permissions: this.roleData.role_permissions,
      };
      await this.createRole(roleInfo);
      //this.$router.push("/role_management");
      this.$router.push({
        name: "role_management",
      });
    },

    async roleUpdate() {
      const roleInfo = {
        role_id: this.role_id,
        role_name: this.roleData.role_name,
        permissions: this.roleData.role_permissions,
      };
      await this.updateRoleData(roleInfo);
      //this.$router.push("/role_management");
      this.$router.push({
        name: "role_management",
      });
    },

    async onSubmit() {
      this.$refs.namerow
        .validate()
        .then((result) => {
          if (!result) {
            alert("Error");
            return;
          } else {
            if (this.action == "Create") {
              this.roleCreate();
            } else if (this.action == "Edit") {
              this.roleUpdate();
            }
          }
        })
        .catch(() => {});
    },
    async onCancel() {
      //this.$router.push("/role_management");
      this.$router.push({
        name: "role_management",
      });
    },
  },
};
</script>
<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

.datep > div > input {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 12px;
  color: #495057;
  padding-left: 5px;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #556065 !important;
  border-bottom: 0px solid #06a990 !important;
  color: #fff !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #06a990 !important;
  border-radius: 0.25rem;
}

.snotify {
  display: block;
  position: fixed;
  width: 99% !important;
  z-index: 9999;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: none;
}
/*-- toggle switch for Main Life Frequency ----*/

.togglefreq label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.togglefreq input:checked,
.togglefreq input:not(:checked) {
  display: none;
}

.togglefreq input:checked + label {
  background: #92d127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92d127;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked + label {
  background: #92d127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92d127;
}

.gen-btn {
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .btn-sm,
  .btn-group-sm > .btn {
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    line-height: 1.5;
  }
}

@media (max-width: 768px) {
  .top-margin {
    margin-top: 60px;
  }
}
</style>

<!-- Language Data for LeadInfoWidget-->
<i18n>
{
  "en": {
  },
  "si": {
  },
  "ta": {
  }
}
</i18n>
