<!-- Children Information Widget -->
<template>
  <ValidationObserver ref="child">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header header-font">
            <font-awesome-icon icon="child" />
            <span>&nbsp;{{ $t("childInfoHeader") }} ({{ index + 1 }})</span>
          </div>
          <div></div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_child_title">{{ $t("childLblTitle") }}</label>
                  <ValidationProvider
                    name="Title"
                    rules="required|genderValidation:@ChGender"
                  >
                    <div slot-scope="{ errors }">
                      <select
                        class="form-control"
                        v-model="childInfoData[index].title"
                        :name="'ChildTitle' + index"
                        @blur="sendChangedData()"
                      >
                        <option disabled>Please select</option>
                        <option value="Master">Master</option>
                        <option value="Miss.">Miss.</option>
                      </select>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_child_first_name">{{
                    $t("childLblFirstName")
                  }}</label>
                  <ValidationProvider
                    name="First Name"
                    rules="required|minmax:3,60|alpha_spaces"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        id="txtChildFName"
                        :name="'txtChildFName' + index"
                        placeholder="First Name"
                        v-model="childInfoData[index].first_name"
                        @blur="sendChangedData()"
                        v-uppercase
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_child_mid_name">{{
                    $t("childLblMidName")
                  }}</label>
                  <ValidationProvider
                    name="Middle Name"
                    rules="minmax:3,60|alpha_spaces"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        id="txtChildMidName"
                        placeholder="Middle Name"
                        v-model="childInfoData[index].middle_name"
                        v-uppercase
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_child_last_name">{{
                    $t("childLblLastName")
                  }}</label>
                  <ValidationProvider
                    name="Last Name"
                    rules="required|minmax:3,60|alpha_spaces"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        id="txtChildLName"
                        :name="'txtChildLName' + index"
                        placeholder="Last Name"
                        v-model="childInfoData[index].last_name"
                        @blur="sendChangedData()"
                        v-uppercase
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="gender">{{ $t("childLblGender") }}</label>
                  <ValidationProvider name="ChGender" rules="required">
                    <div slot-scope="{ errors }">
                      <div class="toggleswitchg">
                        <input
                          :id="'child_gender_male' + index"
                          :name="index"
                          type="radio"
                          value="Male"
                          v-model="childInfoData[index].gender"
                          data-vv-as="Male"
                        />
                        <label :for="'child_gender_male' + index">
                          <i class="fa fa-mars"></i> Male
                        </label>
                        <input
                          :id="'child_gender_female' + index"
                          checked="checked"
                          :name="index"
                          type="radio"
                          value="Female"
                          v-model="childInfoData[index].gender"
                          data-vv-as="Female"
                        />
                        <label :for="'child_gender_female' + index">
                          <i class="fa fa-venus"></i> Female
                        </label>
                      </div>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_child_dob">{{ $t("childLblDOB") }}</label>
                  <ValidationProvider name="Date of Birth" rules="required">
                    <div slot-scope="{ errors }">
                      <input
                        type="Date"
                        class="form-control"
                        id="txtChildDOB"
                        :name="'txtChildDOB' + index"
                        placeholder="Date of Birth"
                        v-model="childInfoData[index].dob"
                        @blur="
                          ageCalc(index);
                          sendChangedData();
                        "
                        :max="timestamp"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_child_age">{{ $t("childLblAge") }}</label>
                  <ValidationProvider name="Age" rules="required|max_value:65">
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        id="txtChildAge"
                        readonly
                        :name="'txtChildAge' + index"
                        v-model="childInfoData[index].age"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SnotifyPosition } from "vue-snotify";
import { throttle } from "lodash";

export default {
  props: ["index"],
  computed: {
    ...mapState("quote_platinum_plus", [
      "childInfoData",
      "flash",
      "minflash",
      "timestamp"
    ]) // assuming you are using namespaced modules
  },
  methods: {
    ...mapActions("quote_platinum_plus", ["getAge", "addChangedData"]),
    basicValidation() {
      if (this.spouseInfoData.txtSpouseAge == 0) {
        return false;
      } else {
        return true;
      }
    },
    sendChangedData: throttle(async function() {
      this.$refs.child.validate().then(valid => {
        if (valid) {
          this.$snotify.clear();
          /** Loading (HTML snotify component) */
          this.$snotify.html(
            `<div class="snotifyToast__title"><b>Calculating...</b></div>
            <div class="snotifyToast__body"> Mode Premium Amount</div>
            <div class="snotify-icon snotify-icon--async"></div> `,
            {
              timeout: stop,
              showProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              position: SnotifyPosition.centerTop,
              type: "async"
            }
          );
          return this.getChangeDataResponse()
            .then(
              function(response) {
                this.$snotify.clear();
                if (response.status && response.type == undefined) {
                  /** Success Message  */
                  this.$snotify.success("Mode Premium Amount", response.error, {
                    timeout: stop,
                    closeOnClick: true,
                    pauseOnHover: false,
                    position: SnotifyPosition.centerTop
                  });
                } else {
                  this.$snotify.clear();
                  /** Error Message  */
                  this.$snotify.error(response.error, "Error", {
                    timeout: stop,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    position: SnotifyPosition.centerTop
                  });
                }
              }.bind(this)
            )
            .catch(function(error) {
              this.$snotify.clear();
              /** Exception Message */
              this.$snotify.error(error, "Error", {
                timeout: stop,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop
              });
            });
        }
      });
    }, process.env.VUE_APP_QUOT_TIMEOUT_SEND_CHANGED_DATA),
    async ageCalc(index) {
      let payload = {
        dob: this.childInfoData[index].dob,
        type: "child",
        index: index
      };
      await this.getAge(payload);
    },
    async getChangeDataResponse() {
      await this.addChangedData();
      if (this.minflash.status) {
        return this.minflash;
      } else {
        return this.flash;
      }
    }
  }
};
</script>

<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}
.close-btn {
  float: right;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked + label {
  background: #92D127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92D127;
}
</style>

<!-- Language Data for ChildInfoWidget -->
<i18n>
{
  "en": {
    "childInfoHeader": "Child Information",
    "childLblTitle": "Title",
    "childLblFirstName": "First Name",
    "childLblMidName": "Middle Name",
    "childLblLastName": "Last Name",
    "childLblGender": "Gender",
    "childLblDOB": "Date of Birth (MM/DD/YYYY)",
    "childLblAge": "Age"
  },
  "si": {
    "childInfoHeader": "දරුවාගේ තොරතුරු",
    "childLblTitle": "ශීර්ෂය",
    "childLblFirstName": "මුල් නම",
    "childLblMidName": "මැද නම",
    "childLblLastName": "අවසන් නම",
    "childLblGender": "ස්ත්‍රී/පුරුෂ භාවය",
    "childLblDOB": "උපන්දිනය (MM/DD/YYYY)",
    "childLblAge": "වයස"
  },
  "ta": {
    "childInfoHeader": "குழந்தை தகவல்",
    "childLblTitle": "படிநிலை",
    "childLblFirstName": "முதற் பெயர்",
    "childLblMidName": "நடுத்தர பெயர்",
    "childLblLastName": "கடைசி பெயர்",
    "childLblGender": "பால்",
    "childLblDOB": "பிறந்த திகதி (MM/DD/YYYY)",
    "childLblAge": "வயது"
  }
}
</i18n>
