<template>
  <div class="container-fluid">
    <div class="row row-top">
      <div class="card-2">
        <div class="card-header-2">
          <font-awesome-icon icon="search" />&nbsp;
          <span>{{ $t('search_panel')}}</span>
        </div>
        <div class="card-body">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label>{{ $t('name')}}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Name"
                    v-model="name"
                    @keyup.enter="doFilter"
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>{{ $t('mobile_number')}}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Mobile Number"
                    v-model="phoneNo"
                    @keyup.enter="doFilter"
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>{{ $t('nic')}}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="NIC"
                    v-model="nic"
                    @keyup.enter="doFilter"
                  />
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group">
                  <label>{{ $t('leadstatus')}}</label>
                  <select class="form-control" name="LeadStatus" v-model="leadstatus">
                    <option value="all">All</option>
                    <option value="1">New</option>
                    <option value="2">Quotation Created</option>
                    <option value="3">Proposal Created</option>
                    <option value="4">Rejected</option>
                    <option value="5">Closed</option>
                  </select>
                </div>
              </div>
              
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label>{{ $t('status')}}</label>
                  <select class="form-control" name="AppointmentStatus" v-model="status">
                    <option>All</option>
                    <option>Pending</option>
                    <option>Visited</option>
                    <option>Rescheduled</option>
                    <option>Cancelled</option>
                    <option>Expired</option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('date')}}</label>
                  <Datepicker
                    class="datep"
                    id="txtAppointmentDOB"
                    name="AppointmentDate"
                    :format="customFormatter"
                    v-model="appointmentDate"
                    :highlighted="highlighted"
                  ></Datepicker>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group text-right">
                  <button
                    type="button"
                    id="search"
                    class="btn btn-sm btn-success btn-right"
                    @click="doFilter"
                  >
                    <font-awesome-icon icon="search" />
                    {{ $t('search')}}
                  </button>
                  <button
                    class="btn btn-sm btn-danger"
                    type="reset"
                    id="search_reset"
                    @click="resetFilter"
                  >
                    <font-awesome-icon icon="ban" />
                    {{ $t('reset')}}
                  </button>

                  <download-csv :data="exportCSV"  name="leads.csv" style>
                    <b-button
                      style="margin:5px padding: .25rem .65rem;float:right;margin-top:20px;"
                      variant="warning"
                      class="ui btn-sm basic button"
                      title="Export CSV"
                      v-b-tooltip.hover.bottom="'Export CSV'"
                     
                    >&nbsp;Export CSV</b-button>
                  </download-csv>
                   <!-- @click="getCSVExport" -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import moment from "moment";
Vue.use(require("vue-moment"));
import JsonCSV from "vue-json-csv";
Vue.component("downloadCsv", JsonCSV);

import Datepicker from "vue-moment-datepicker";

export default {
  data() {
    return {
      name: "",
      phoneNo: "",
      nic: "",
      status: "",
      leadstatus: "",
      appointmentDate: ""
    };
  },
  components: {
    Datepicker
  },
  async created() {
    this.exportLeadCSV();
  },
  computed: {
    ...mapState("lead", ["highlighted", "LeadTable", "exportCSV"]) // assuming you are using namespaced modules
  },
  methods: {
    ...mapActions("lead", ["refreshLeadTable", "getCSVExport"]),
    doFilter() {
      let filters = {
        name: this.name,
        phone_no: this.phoneNo,
        nic: this.nic,
        status: this.status,
        leadstatus: this.leadstatus,
        appointmentDate: this.appointmentDate
      };
      
      this.$events.fire("filter-set", filters);
    },
    resetFilter() {
      this.name = "";
      this.phoneNo = "";
      (this.status = ""), (this.nic = "");
      this.appointmentDate = "";
      this.leadstatus = "";
      this.$events.fire("filter-reset");
    },
    customFormatter(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    exportLeadCSV() {
      let filters = {
        name: this.name,
        phone_no: this.phoneNo,
        nic: this.nic,
        status: this.status,
        leadstatus: this.leadstatus,
        appointmentDate: this.appointmentDate
      };

      this.getCSVExport(filters);
    }
  }
};
</script>


<style>
.btn-right {
  margin-right: 5px;
}
.datep > div > input {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 12px;
  color: #495057;
}

@media (max-width: 768px) {
  div.card {
    margin-top: 100px;
  }
}
</style>

<i18n>
{
  "en": {
    "name": "Name",
    "mobile_number": "Contact Number",
    "nic": "NIC",
    "search": "Search",
    "reset" : "Reset",
    "search_panel": "Search Panel",
    "status":"Appointment Status",
    "leadstatus":"Lead Status",
    "date":"Appointment Date"
  },
  "si": {
    "name": "නම",
    "mobile_number": "දුරකථන අංකය",
    "nic": "ජා.හැ.අංකය",
    "search": "සොයන්න",
    "reset" : "නැවත සකසන්න",
    "search_panel": "සෙවීමේ පුවරුව",
    "status":"හමුවීම් තත්වය",
    "leadstatus":"Lead Status",
    "date":"හමුවීම් දිනය"
  },
  "ta": {
    "name": "பெயர்",
    "mobile_number": "தொலைபேசி இலக்கம்",
    "nic": "தே. அ. அ. இலக்கம்",
    "search": "தேடல்",
    "reset" : "மீட்டமைத்தல்",
    "search_panel": "தேடல் குழு",
    "status":"Appointment Status",
    "leadstatus":"Lead Status",
    "date":"Appointment Date"
  }
}
</i18n>