<!-- create/modify customer quotations -->
<template>
  <div>
    <Header />
    <LeftNav />
    <main id="page-wrap">
      <vue-snotify></vue-snotify>
      <ValidationObserver ref="form">
          <b-container fluid>
            <LeadInfoWidget />
          </b-container>
      </ValidationObserver>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/layout/AppHeader.vue";
import Footer from "@/components/common/layout/AppFooter.vue";
import LeftNav from "@/components/common/layout/AppLeftNav";
import LeadInfoWidget from "@/components/lead/LeadInfoWidget.vue";

import { mapState } from "vuex";
// import { SnotifyPosition } from "vue-snotify";

export default {
  data() {
    return {};
  },
  name: "EditLead",
  components: {
    Header,
    Footer,
    LeftNav,
    LeadInfoWidget
  },

  computed: {
    ...mapState("lead", ["LeadInfoData"])
  },
 
};
</script>

<style scoped>
div.card .card-header {
  background: #0560dd !important; /* fallback for old browsers */
  box-shadow: 0 10px 10px -12px rgba(0, 0, 0, 0.42),
    0 4px 10px 0px rgba(0, 0, 0, 0.12), 0 5px 7px -5px rgba(0, 0, 0, 0.2);
}
</style>
