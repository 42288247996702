<template>
  <div>
    <Header />
    <LeftNav />
    <main id="page-wrap">
      <!-- <vue-snotify></vue-snotify> -->
      <b-container fluid>
        <ChangePassword />
      </b-container>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/layout/AppHeader.vue";
import LeftNav from "@/components/common/layout/AppLeftNav";
import Footer from "@/components/common/layout/AppFooter.vue";
import ChangePassword from "@/components/user_management/change_password.vue";

export default {
  name: "ChangePasswordView",
  components: {
    Header,
    LeftNav,
    Footer,
    ChangePassword,
  },
};
</script>

<style>
</style>