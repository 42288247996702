<template>
  <div class="row row-top">
    <div class="col top-margin wizard-top">
      <div class>
        <div>
          <ValidationObserver ref="final">
            <form-wizard
              title
              subtitle
              shape="tab"
              color="#556065"
              error-color="#a94442"
              step-size="md"
              ref="wizard"
              @on-complete="onComplete"
            >
              <!-- Benifits tab -->
              <tab-content
                :title="$t('tabMyBenifits')"
                icon="ti ti-gift"
                :before-change="validateBenefitStep"
              >
                <!-- Benifit Details(Insured/Spouse) Widget -->
                <ValidationObserver ref="benefit">
                  <BenifitDetailsWidget />
                </ValidationObserver>
              </tab-content>
              <!-- Info tab -->
              <tab-content
                :title="$t('tabMyInfo')"
                icon="ti ti-user"
                :before-change="validateInfoStep"
              >
                <!-- Personal Details(Insured/Spouse) Widget -->
                <ValidationObserver ref="info">
                  <InfoDetailsWidget ref="infoWidget" />
                </ValidationObserver>
              </tab-content>
              <!-- Health tab -->
              <tab-content
                :title="$t('tabMyHealth')"
                icon="ti ti-heart"
                :before-change="validateHealthStep"
              >
                <!-- Health Details Widget -->
                <ValidationObserver ref="health">
                  <HealthDetailsWidget />
                </ValidationObserver>
              </tab-content>
              <!-- Complete(Final Step) tab -->
              <tab-content
                :title="$t('tabComplete')"
                icon="ti ti-check"
                :before-change="validateFundtypeStep"
              >
                <!-- Final Details Widget -->
                <ValidationObserver ref="finalize">
                  <FinalDetailsWidget />
                </ValidationObserver>
              </tab-content>
            </form-wizard>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
/** Form wizard import */
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
/** Health Details Widget Import */
import BenifitDetailsWidget from "@/components/proposal/benifit/BenifitDetailsWidget";
/** Personal Details(Insured/Spouse) Widget Import */
import InfoDetailsWidget from "@/components/proposal/info/InfoDetailsWidget";
/** Health Details Widget Import */
import HealthDetailsWidget from "@/components/proposal/health/HealthDetailsWidget";
/** Final Details Widget Import */
import FinalDetailsWidget from "@/components/proposal/final/FinalDetailsWidget";

export default {
  name: "new-proposal-wizard",

  async updated() {
    this.$refs.wizard.maxStep = 3;
  },

  components: {
    FormWizard,
    TabContent,
    BenifitDetailsWidget,
    InfoDetailsWidget,
    HealthDetailsWidget,
    FinalDetailsWidget,
  },

  computed: {
    ...mapState("proposal", [
      "ProposalStatus",
      "isBenefitComplete",
      "isInfoComplete",
      "isHealthComplete",
      "isLinkSentToCustomer",
      "isCustomerCompleted",
      "isAgentSigned",
      "isFullCompleted",
      "isValidationError",
      "ProposalID",
    ]),
  },

  data() {
    return {};
  },
  methods: {
    ...mapActions("proposal", [
      "saveProposalPDF",
      "setProposalStatus",
      "draftProposalData",
      "updateProposalStatus",
    ]),
    updatePropStatus(status, active_step) {
      let payload = {
        proposalId: this.ProposalID,
        status: status,
        active_step: active_step,
      };
      this.updateProposalStatus(payload);
    },
    onComplete: async function () {
      //await this.savePDF();
      await this.setPropStatus("final", "Y");
      await this.draftProposalData();
      setTimeout(() => {
        //this.$router.push("/proposal_list");
        this.$router.push({
          name: "proposal_list",
        });
      }, 1500);
    },
    /** Set Proposal Status */
    async setPropStatus(prop_state, prop_state_value) {
      let payload = {
        prop_status_data: prop_state_value,
        prop_status: prop_state,
      };
      await this.setProposalStatus(payload);
    },
    async validateBenefitStep() {
      return await new Promise(async (resolve) => {
        await this.$refs.benefit.validate().then(async (valid) => {
          try {
            if (valid) {
              await this.setPropStatus("benefit", "Y");
              await resolve(valid);
              window.scrollTo(0, 0);
            } else {
              this.$snotify.clear();
              window.scrollTo(0, 0);
              await resolve(valid);
            }
          } catch (error) {
            this.$snotify
              .error(
                "Please check your internet connectivity or it might be an issue with our server",
                "Error",
                {
                  position: "centerTop",
                }
              )
              .on("destroyed", () => {});
          }
        });
      });
    },
    async validateInfoStep() {
      return await new Promise(async (resolve) => {
        await this.$refs.info.validate().then(async (valid) => {
          try {
            if (valid) {
              await this.setPropStatus("info", "Y");
              await this.draftProposalData();
              this.updatePropStatus(2, 1);
              await resolve(valid);
              window.scrollTo(0, 0);
            } else {
              await resolve(valid);
              this.$snotify.clear();
              window.scrollTo(0, 0);
              this.$snotify
                .error(
                  "Please fill all the mandatory fields to continue",
                  "Error",
                  {
                    position: "centerTop",
                  }
                )
                .on("destroyed", () => {});
            }
          } catch (error) {
            await resolve(false);
            this.$snotify
              .error(
                "Please check the all the fields errors to continue",
                "Error",
                {
                  position: "centerTop",
                }
              )
              .on("destroyed", () => {});
          }
        });
      });
    },
    async validateFundtypeStep() {
      return await new Promise(async (resolve) => {
        await this.$refs.finalize.validate().then(async (valid) => {
          try {
            if (valid) {
              await this.draftProposalData();
              this.updatePropStatus(4, 2);
              await resolve(valid);
              window.scrollTo(0, 0);
            } else {
              this.$snotify.clear();
              window.scrollTo(0, 0);
              await resolve(valid);
              this.$snotify
                .error(
                  "Please fill all the mandatory fields to continue",
                  "Error",
                  {
                    position: "centerTop",
                  }
                )
                .on("destroyed", () => {});
            }
          } catch (error) {
            await resolve(false);
            this.$snotify
              .error(
                "Please check the all the fields errors to continue",
                "Error",
                {
                  position: "centerTop",
                }
              )
              .on("destroyed", () => {});
          }
        });
      });
    },
    async validateHealthStep() {
      return await new Promise(async (resolve) => {
        await this.$refs.health.validate().then(async (valid) => {
          try {
            if (valid) {
              await this.setPropStatus("health", "Y");
              await this.draftProposalData();
              this.updatePropStatus(3, 2);
              await resolve(valid);
              window.scrollTo(0, 0);
            } else {
              this.$snotify.clear();
              window.scrollTo(0, 0);
              await resolve(valid);
              this.$snotify
                .error(
                  "Please fill all the mandatory fields to continue",
                  "Error",
                  {
                    position: "centerTop",
                  }
                )
                .on("destroyed", () => {});
            }
          } catch (error) {
            await resolve(false);
            this.$snotify
              .error(
                "Please check the all the fields errors to continue",
                "Error",
                {
                  position: "centerTop",
                }
              )
              .on("destroyed", () => {});
          }
        });
      });
    },
    validateAsync: function () {
      return new Promise((resolve) => {
        resolve(true);
      });
    },

    async savePDF() {
      let payload = {
        q_ref_no: this.$route.params.ref_no,
      };
      await this.saveProposalPDF(payload);
    },

    showMsgBoxError(errorMsg) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxOk(errorMsg, {
          size: "sm",
          buttonSize: "lg",
          okVariant: "danger",
          headerBgVariant: "danger",
          headerTextVariant: "light",
          bodyBgVariant: "danger",
          bodyTextVariant: "light",
          centered: true,
        })
        .then((value) => {
          this.boxTwo = value;
        })
        .catch((err) => {
          // An error occurred
          alert(err);
        });
    },
  },
};
</script>

<style scoped>
.wizard-top {
  margin-top: 10px;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .wizard-top {
    margin-top: 70px;
  }
}
</style>

<!-- Language Data for New Proposal Widget Holder -->
<i18n>
{
  "en": {
    "propWizardTitle": "Proposal for Life Insurance",
    "tabMyBenifits": "Benifits",
    "tabMyInfo": "Info",
    "tabMyHealth": "Health",
    "tabComplete": "Finalize"
  },
  "si": {
    "propWizardTitle": "ජීවිත රක්ෂණය සඳහා වූ යෝජනාව",
    "tabMyBenifits": "ප්‍රතිලාභ",
    "tabMyInfo": "තොරතුරු",
    "tabMyHealth": "සෞඛ්‍යය",
    "tabComplete": "අවසානය"
  },
  "ta": {
    "propWizardTitle": "ஆயுள் காப்பீட்டுக்கான திட்டம்",
    "tabMyBenifits": "நன்மைகள்",
    "tabMyInfo": "தகவல்",
    "tabMyHealth": "உடல் நலம்",
    "tabComplete": "இறுதி"
  }
}
</i18n>
