<template>
  <div class="row-top-list2 table-row-top">
    <vue-snotify></vue-snotify>
    <div class="card">
      <div class="card-header">
        <font-awesome-icon icon="align-left" />&nbsp;
        <span>{{ $t("list_user") }} </span>
      </div>
      <div class="card-body">
        <div class="row tbl-margin-top">
          <div class="col table-responsive">
            <vuetable
              class="table table-striped table-bordered nowrap vue-tbl"
              ref="usertable"
              :api-url="getUrl"
              :fields="fields"
              pagination-path
              @vuetable:pagination-data="onPaginationData"
              :per-page="10"
              :row-class="onRowClass"
              :http-options="httpOptions"
              :append-params="moreParams"
            >
              <template slot="actions" slot-scope="props">
                <!-- ACTION SLOT -->
                <div
                  class="custom-actions"
                  style="white-space: nowrap; text-align: center"
                >
                  <b-dropdown
                    class="ui btn-sm basic button"
                    variant="info"
                    right
                    text="Actions"
                  >
                    <b-dropdown-item
                      @click="
                        onActionClicked(
                          'deactivate-user',
                          props.rowData,
                          props.rowIndex
                        )
                      "
                    >
                      {{
                        props.rowData.status == 1 ? "Deactivate" : "Activate"
                      }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <!-- ACTION SLOT -- END  -->
              </template>
              <div slot="branches-slot" slot-scope="props">
                <div>
                    {{ props.rowData.user.branch ? props.rowData.user.branch.branch_name : '' }}
                    <div v-for="(brnc , index) in props.rowData.user.branches" 
                      :index="index"
                      :key="index">
                      {{ brnc.branch_name }}
                    </div>
                </div>
              </div>
            </vuetable>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="pagination">
              <vuetable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              ></vuetable-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//console.log(process.env.VUE_APP_API_ENDPOINT);
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
//import { ValidationProvider } from "vee-validate";
//import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

import VuetablePagination from "@/components/vuetable/VuetablePaginationBootstrap4";
// import EditUser from "@/components/user_management/edit_user.vue"

import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    Vuetable,
    VuetablePagination,
  },
  data() {
    return {
      rejectConfirmModelShow: false,
      closeConfirmModelShow: false,
      cretateQuotationModelShow: false,
      quote_type: 1,
      user_id: "",
      fields: [
        {
          name: "id",
          title: '<span class="orange glyphicon glyphicon-user"></span> #',
          sortField: "id",
        },
        {
          name: "user.full_name",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Full Name',
          sortField: "full_name",
        },

        {
          name: "user.agent_code",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Agent Code',
          sortField: "agent_code",
        },
        {
          name: "status",
          sortField: "status",
          title: '<span class="orange glyphicon glyphicon-user"></span> Active',
          width: "10%",
          callback: "getActive",
          html: true,
        },
        {
          name: "daily_job_count",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Job Count',
          sortField: "daily_job_count",
        },

        {
          name: "__slot:branches-slot",
          title: '<span class="orange glyphicon glyphicon-user"></span> Branch',
        },
        "__slot:actions",
      ],
      httpOptions: {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: {},
      },
      moreParams: {},
      getUrl: process.env.VUE_APP_API_ENDPOINT + "queue_users",
      activeHoUsers: [],
    };
  },
  computed: {
    ...mapState("user", [""]),
    ...mapGetters("login", ["checkUserPermissionNew"]),
  },
  mounted() {
    this.$events.$on("filter-set", (eventData) => this.onFilterSet(eventData));
    this.$events.$on("filter-reset", this.onFilterReset());
  },
  methods: {
    ...mapActions("user", [
      "getUserBranchList",
      "getUserRoleList",
      "clearUserSession",
      "queueUserDeactivate",
      "changePassword",
      "deleteUser",
    ]),

    ...mapActions("login", ["loginAsUser"]),
    getRoles(roles) {
      let str = "";
      roles.forEach(function (role, index) {
        str += "<span class='badge badge-dark'>" + role.name + "</span>";
        if (index != roles.length - 1) {
          str += ",";
        }
      });
      return str;
    },
    getActive(active) {
      if (active == true) {
        return "<span class='badge badge-success'> Yes </span>";
      } else {
        return "<span class='badge badge-danger'> No </span>";
      }
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.usertable.changePage(page);
    },
    onRowClass(dataItem) {
      if (dataItem.status == "Expired") {
        return dataItem.status
          ? "expired-field-background"
          : "background-field-white";
      }
    },
    onActionClicked(action, data) {
      if (action == "deactivate-user") {
        this.userDeactivate(data.id, data.status);
      }
    },

    userDeactivate(user_id, status) {
      let data = {
        user_id: user_id,
        status: status == 1 ? 0 : 1,
      };
      this.queueUserDeactivate(data);
      Vue.nextTick(() => this.$refs.usertable.refresh());
    },

    //clear user session
    clearSession(user_id) {
      let data = {
        user_id: user_id,
      };
      this.clearUserSession(data);
      Vue.nextTick(() => this.$refs.usertable.refresh());
    },

    //Login as this user
    loginAs(userid) {
      let data = {
        user_id: userid,
      };
      this.loginAsUser(data);
    },

    //Delete user from list
    userDelete(userid) {
      let data = {
        user_id: userid,
      };

      this.deleteUser(data);

      Vue.nextTick(() => this.$refs.usertable.refresh());
    },
    onFilterSet(filters) {
      this.moreParams = {
        user_name: filters.user_name,
        agent_code: filters.agent_code,
      };
      Vue.nextTick(() => this.$refs.usertable.refresh());
    },
    onFilterReset() {
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.usertable.refresh());
    },
    viewPermissionCheck() {
      return this.checkUserPermissionNew("view-quote");
    },
    editPermissionCheck() {
      return this.checkUserPermissionNew("edit-quote");
    },
    exLoadingPermissionCheck() {
      return this.checkUserPermissionNew("ex-loading");
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  background-color: #0000001a !important;
}

.modal-content {
  border: 0px solid !important;
}

.tbl-margin-top {
  margin-top: 15px;
}

table th,
.table td {
  padding: 0.2rem 0.75rem !important;
  vertical-align: middle !important;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}

.pagination {
  float: right;
}

.btn {
  margin: 5px;
}

.page-link {
  font-size: 12px !important;
}

.tooltip {
  top: 0 !important;
}

.background-field-white {
  background-color: white;
}

.expired-field-background {
  background-color: #fc8d89 !important;
}
</style>

<i18n>
{
  "en": {
    "list_user": "User List"
  },
  "si": {
  "list_user": "User List"
  },
  "ta": {
     "list_user": "User List"
  }
}
</i18n>
