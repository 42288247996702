<!-- Rider Widgets Holder Component - Main, Spouse & Children Riders  -->
<template>
  <div class="card">
    <div class="card-header">
      <font-awesome-icon icon="cart-plus" />
      <span> {{ $t('widgetTitle') }}</span>
    </div>
    <div class="card-body box-body">
      <b-tabs>
        <b-tab :title="$t('riderTabMainLife')">
          <div class="tab-pane active" id="tab_1">
            <!-- Tab 1(Main Life) Content -->
            <MainLifeRiderWidget />
          </div>
        </b-tab>
        <b-tab :title="$t('riderTabSpouse')" :disabled="familyInfoData.drpFamilyInfoMaritalStatus == 'Un-married' || familyInfoData.drpFamilyInfoMaritalStatus == 'Divorced' || familyInfoData.drpFamilyInfoMaritalStatus == null || familyInfoData.rdbSpouse == 'No' || familyInfoData.rdbSpouse == ''">
          <div class="tab-pane active" id="tab_2">
            <!-- Tab 2(Spouse) Content -->
            <SpouseRiderWidget />
          </div>
        </b-tab>
        <b-tab :title="$t('riderTabChildren')" :disabled="familyInfoData.drpFamilyInfoMaritalStatus == 'Un-married' || familyInfoData.drpFamilyInfoMaritalStatus == null || familyInfoData.rdbChildren == 0">
          <div class="tab-pane active" id="tab_3">
            <!-- Tab 3(Children) Content -->
            <ChildrenRiderWidget />
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import MainLifeRiderWidget from "@/components/quote_platinum_plus/MainLifeRiderWidget";
import SpouseRiderWidget from "@/components/quote_platinum_plus/SpouseRiderWidget";
import ChildrenRiderWidget from "@/components/quote_platinum_plus/ChildrenRiderWidget";

export default {
  components: { MainLifeRiderWidget, SpouseRiderWidget, ChildrenRiderWidget },
  computed: {
    ...mapState("quote_platinum_plus", ["familyInfoData"])
  }
};
</script>

<style>
</style>
<!-- Language Data for RidersWidgetHolder -->
<i18n>
{
  "en": {
    "widgetTitle": "Additional Benefits for Life Assured",
    "riderTabMainLife": "Insured",
    "riderTabSpouse": "Spouse",
    "riderTabChildren": "Children"
  },
  "si": {
    "widgetTitle": "සහතික කරන ලද ජීවිත සඳහා අමතර ප්‍රතිලාභ",
    "riderTabMainLife": "ජීවිත රක්ෂිතයා",
    "riderTabSpouse": "කලත්‍රයා",
    "riderTabChildren": "දරුවන්"
  },
  "ta": {
    "widgetTitle": "பிரதான காப்புறுதிதாரரின் மேலதிக நன்மைகள்",
    "riderTabMainLife": "பிரதான வாழ்க்கை",
    "riderTabSpouse": "வாழ்க்கைத்துணை",
    "riderTabChildren": "குழந்தைகள்"
  }
}
</i18n>