<template>
  <div class="container-fluid">
    <div class="row row-top">
      <div class="card-2">
        <div class="card-header-2">
          <font-awesome-icon icon="search" />&nbsp;
          <span>Search Panel</span>
        </div>
        <div class="card-body">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="first_name"
                    placeholder="Name"
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Last Name"
                    v-model="last_name"
                  />
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group">
                  <label>Agent Code</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Agent Code"
                    v-model="agent_code"
                  />
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group">
                  <label>User Role</label>
                  <select class="form-control" name="Role" v-model="user_role">
                    <option value="">--Select Role--</option>
                    <option
                      v-for="role in roleList"
                      v-bind:value="role.id"
                      :key="role.id"
                    >
                      {{ role.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group">
                  <label>User Created Date</label>
                  <date-picker v-model="date_range" range valueType="format"></date-picker>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group search-btn">
                  <button
                    type="button"
                    id="search"
                    class="btn btn-sm btn-success btn-right"
                    @click="doFilter"
                  >
                    <font-awesome-icon icon="search" />
                    {{ $t("search") }}
                  </button>
                  <button
                    class="btn btn-sm btn-danger"
                    type="reset"
                    id="search_reset"
                    @click="resetFilter"
                  >
                    <font-awesome-icon icon="ban" />
                    {{ $t("reset") }}
                  </button>

                  <button type="button" id="export" class="btn btn-sm btn-success btn-right ml-3" @click="exportData">
                    <font-awesome-icon icon="file" />
                    {{ $t("export") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <UserListTable ref="userlistTableRef" />
      </div>
    </div>
  </div>
</template>

<script>
import UserListTable from "@/components/common/layout/UserListTable.vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapState, mapActions } from "vuex";
export default {
  name: "UserList",
  components: {
    UserListTable,
    DatePicker 
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      agent_code: "",
      user_role: "",
      date_range:null,
      date_format:'YYYY-MM-DD'
    };
  },
  computed: {
    ...mapState("user", ["branchList", "roleList"]),
  },
  methods: {
    ...mapActions("user", ["getUserRoleList", "getUserBranchList"]),
    ...mapActions("export_report", ["exportUsers"]),
    doFilter() {
      let filters = {
        user_name: this.first_name + " " + this.last_name,
        agent_code: this.agent_code,
        user_role: this.user_role,
        date_range :this.date_range
      };
      this.$events.fire("filter-set", filters);
    },
    resetFilter() {
      this.first_name = "";
      this.last_name = "";
      this.agent_code = "";
      this.user_role = "";
      // this.$events.fire("filter-reset");
      this.$refs.userlistTableRef.onFilterReset();
    },
    //load role list
    loadUserRoles() {
      this.getUserRoleList();
    },
    //load branch list
    loadBranches() {
      this.getUserBranchList();
    },
    exportData() {
      let param = {
        date_range :this.date_range
      };
      this.exportUsers(param).then((response) => {
        if (response.data.meta.status) {
          window.open(response.data.meta.url,'_blank')
        }
      });
    }
  },
  mounted() {
    this.loadUserRoles();
  },
};
</script>

<style scoped>
.search-btn {
  padding-top: 30px;
}
</style>
<!-- Language Data for LeadInfoWidget-->
<i18n>
{
  "en": {
    "reset" : "Reset",
    "search" : "Search"
  },
  "si": {
    "reset" : "Reset",
    "search" : "Search"
  },
  "ta": {
    "reset" : "Reset",
    "search" : "Search"
  }
}
</i18n>