<!-- Left main navigation bar here -->
<template>
  <div>
    <Push :left="true" width="230">
      <b-nav vertical class="w-25">
        <b-nav-item class="nav-list-margin a navlist" :to="{ name: 'welcome' }">
          <font-awesome-icon icon="th" />
          &nbsp;{{ $t("welcome") }}
        </b-nav-item>

        <b-nav-item
          :to="{ name: 'lead_list' }"
          class="navlist a iactive-nav"
          v-show="checkPermission('my-leads')"
        >
          <font-awesome-icon icon="folder-open" />
          &nbsp;{{ $t("new_lead") }}
        </b-nav-item>

        <b-nav-item
          :to="{ name: 'appointment_calendar' }"
          class="navlist a iactive-nav"
          v-show="checkPermission('appointment-calendar')"
        >
          <font-awesome-icon icon="folder-open" />
          &nbsp;{{ $t("appointment_calendar") }}
        </b-nav-item>

        <b-nav-item
          :to="{ name: 'quote_list' }"
          class="navlist a iactive-nav"
          v-show="checkPermission('view-quote')"
        >
          <font-awesome-icon icon="folder-open" />
          &nbsp;{{ $t("quotations") }}
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'proposal_list' }"
          class="navlist a iactive-nav"
          v-show="checkPermission('view-proposal')"
        >
          <font-awesome-icon icon="file" />
          &nbsp;{{ $t("proposals") }}
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'pension_calculator' }"
          class="navlist a iactive-nav"
          v-show="checkPermission('pension-calculator')"
        >
          <font-awesome-icon icon="th" />
          &nbsp;{{ $t("pension_calc") }}
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'branches_list' }"
          class="navlist a iactive-nav"
          v-show="checkPermission('branch-mgt')"
        >
          <font-awesome-icon icon="folder-open" />
          &nbsp;{{ $t("branches") }}
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'user_management' }"
          class="navlist a iactive-nav"
          v-show="checkPermission('user-mgt')"
        >
          <font-awesome-icon icon="file" />
          &nbsp;{{ $t("user_management") }}
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'role_management' }"
          class="navlist a iactive-nav"
          v-show="checkPermission('role-mgt')"
        >
          <font-awesome-icon icon="file" />
          &nbsp;{{ $t("role_management") }}
        </b-nav-item>

        <b-nav-item
          :to="{ name: 'queue_user_management' }"
          class="navlist a iactive-nav"
          v-show="checkPermission('queue-user-mgt')"
        >
          <font-awesome-icon icon="file" />
          &nbsp;{{ $t("queue_user_management") }}
        </b-nav-item>

        <b-nav-item
          :to="{ name: 'ho_user_allocation' }"
          class="navlist a iactive-nav"
          v-show="checkPermission('ho-user-allocation-mgt')"
        >
          <font-awesome-icon icon="file" />
          &nbsp;{{ $t("ho_user_allocation") }}
        </b-nav-item>

        <b-nav-item
          :to="{ name: 'audit_trail' }"
          class="navlist a iactive-nav"
          v-show="checkPermission('audit-trail')"
        >
          <font-awesome-icon icon="file" />
          &nbsp;{{ $t("audit_trail") }}
        </b-nav-item>
      </b-nav>
    </Push>
  </div>
</template>

<script>
import { Push } from "vue-burger-menu";
import commonMixin from "@/mixins/common";
import { mapGetters } from "vuex";

export default {
  name: "LeftNav",
  data: function () {
    return {
      user_role: localStorage.getItem("user_role"),
    };
  },
  mixins: [commonMixin],
  computed: {
    ...mapGetters("login", ["checkUserPermissionNew"]),
    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
  },
  methods: {
    createPermissionCheck() {
      return this.checkUserPermissionNew("create-quote");
    },
    searchPermissionCheck() {
      return this.checkUserPermissionNew("search-quote");
    },
    viewBackendPermissionCheck() {
      return this.checkUserPermissionNew("view-backend");
    },
    checkPermission(permission) {
      return this.hasPermission(permission);
    },
  },
  components: {
    Push,
  },
};
</script>

<style scoped>
.header {
  color: #4b646f;
  background: #1a2226;
  padding: 8px;
  margin-top: -42px;
  width: 230px;
  font-size: 12px !important;
}

.create-new {
  text-align: center;
  margin-top: 12px;
}

.navlist {
  color: #fff !important;
  padding: 8px;
  width: 230px;
  font-size: 14px !important;
  font-weight: 400;
}

.nav-list-margin {
  margin-top: -20px;
}

.btn-new {
  padding: 0.775rem 3rem !important;
  font-size: 11px !important;
}

.active-nav {
  background-color: #343a40;
  border-left-style: solid;
  border-left-color: #065fdd;
}
a {
  color: #ffffff;
}

a:hover {
  color: #08b49a;
}

.a {
  color: #fff !important;
}

.inactive-nav {
  margin-top: 1px;
}

.bm-burger-button {
  position: fixed !important;
}

@media (min-width: 1024px) {
  .bm-burger-button {
    top: 20px !important;
    margin-left: 260px !important;
  }
}
</style>

<i18n>
{
  "en": {
    "admin": "Admin",
    "new_platinum" : "Platinum",
    "new_platinum_plus" : "Platinum+",
    "new_adyapana" : "Adyapana+",
    "new_suraksha" : "Surakshitha",
    "quotations" : "Quotations",
    "proposals" : "Proposals",
    "pension_calc" : "Pension Calculator",
    "com_forecast" : "Commision Forecaster",
    "signout" : "Sign Out",
    "welcome" : "Home",
    "new_lead" : "My Leads",
    "appointment_calendar" : "Appointment Calendar",
     "branches" : "Branch Management",
    "user_management" : " User Management",
    "role_management" : " Role Management",
    "queue_user_management" : " Queue User Management",
    "ho_user_allocation" :  " HO User Allocation",
    "audit_trail" : "Audit Trail"
  },
  "si": {
    "admin": "Admin",
    "new_platinum" : "ප්ලැටිනම්",
    "new_platinum_plus" : "ප්ලැටිනම්+",
    "new_adyapana" : "අද්‍යාපන+",
    "new_suraksha" : "සුරක්ශිත",
    "quotations" : "කැදවුම් ලැයිස්තුව",
    "proposals" : "යෝජනාවලි",
    "pension_calc" : "Pension Calculator",
    "com_forecast" : "Commision Forecaster",
    "signout" : "ඉවත් වන්න",
     "welcome" : "Home",
     "new_lead" : "My Leads",
    "appointment_calendar" : "Appointment Calendar",
        "branches" : "Branch Management",
    "user_management" : " User Management",
      "role_management" : " Role Management",
      "queue_user_management" : " Queue User Management",
      "ho_user_allocation" :  " HO User Allocation",
      "audit_trail" : "Audit Trail"
  },
  "ta": {
    "admin": "Admin",
    "new_platinum" : "பிளாடினம்",
    "new_platinum_plus" : "பிளாடினம்+",
    "new_adyapana" : "அத்யாபன+",
    "new_suraksha" : "சுரக்சா",
    "quotations" : "மேற்கோள்கள்",
    "proposals" : "திட்ட",
    "pension_calc" : "Pension Calculator",
    "com_forecast" : "Commision Forecaster",
    "signout" : "வெளியேறு",
     "welcome" : "Home",
     "new_lead" : "My Leads",
    "appointment_calendar" : "Appointment Calendar",
        "branches" : "Branch Management",
    "user_management" : " User Management",
      "role_management" : " Role Management",
      "queue_user_management" : " Queue User Management",
      "ho_user_allocation" :  " HO User Allocation",
      "audit_trail" : "Audit Trail"
  }
}
</i18n>
