import { http } from "@/config/http";

const namespaced = true;

const getDefaultState = () => {
  return {
    familyInfoData: {
      drpFamilyInfoMaritalStatus: "Unmarried",
      rdbSpouse: "No",
      rdbChildren: 0,
      rdbChildrenPrev: "New"
    },
    mainInfoData: {
      drpMainLifetitle: "Please select",
      txtMainLifeFname: "",
      txtMainLifeMname: "",
      txtMainLifeLname: "",
      txtMainLifeNIC: "",
      txtMainLifeMob: "",
      txtMainLifeEmail: "",
      txtMainLifeAge: "",
      rdbMainGender: "Male",
      txtMainLifeDOB: "2000-01-01",
      drpMainLifePrimaryNeed: "Savings",
      txtMainLifeMonthlyBasicPrem: 5000,
      txtMainLifePolicyTerm: 15,
      drpMainLifeContributionTerm: 3,
      txtMainLifePremiumTerm: 5,
      rdbMainLifeFrequency: "M",
      // drpMainLifeOccupation: {
      //   code: "OEX0157",
      //   label: "Teachers"
      // },
      drpMainLifeOccupation:"",
      txtMainSumAtRisk: 0,
      txtNumOfSumAsTimes: 10,
      mainAge: 0,
      rdbMainMedrem3Floater: "No",
      txtMainLifeSumCover: 0,
      drpMainLifeCoverMultiple: 0.5,

    },
    spouseInfoData: {
      drpSpouseTitle: "Please select",
      txtSpouseFname: "",
      txtSpouseMname: "",
      txtSpouseLname: "",
      rdbSpouseGender: "Female",
      txtSpouseDOB: "2000-01-01",
      // drpSpouseOccupation: {
      //   code: "OEX0157",
      //   label: "Teachers"
      // },
      drpSpouseOccupation:"",
      txtSpouseSumAtRisk: 0,
      txtSpouseAge: 0
    },
    calcSummary: { summary: 0 },
    childInfoData: [],
    riderDataMain: [],
    riderDataSpouse: [],
    riderDataChildren: [],
    quotationList: [],
    occupationList: [],
    hospitalList: [],
    occupationLoadingList: [],
    drpQuoteListHospital: {
      code: "UHC001",
      label: "AROGYA HOSPITALS LTD (PVT) LTD - GAMPAHA"
    },
    flash: {
      type: "W",
      status: false,
      error: ""
    },
    timestamp: "",
    minflash: {
      status: false,
      error: ""
    },
    quotationNo: "",
    version: "",
    refNo: ""
  };
};

const state = getDefaultState();

const getters = {};

const mutations = {
  addChildObj(state, count) {
    //Set default child DOB
    let d = new Date();
    d.setFullYear(d.getFullYear() - 1);

    //if the children have not been selected before
    if (state.familyInfoData.rdbChildrenPrev == "New") {
      state.childInfoData = [];

      //add to child information
      let length = 1;
      while (length <= count) {
        state.childInfoData.push({
          title: "",
          first_name: "",
          middle_name: "",
          last_name: "",
          gender: "Male",
          dob: d.toJSON().slice(0, 10),
          age: 1
        });
        length++;
      }

      //setting the current selected value to the previous value
      state.familyInfoData.rdbChildrenPrev = Number(
        state.familyInfoData.rdbChildren
      );
    }
    //when we need to add more items from the existing array
    else if (count > state.familyInfoData.rdbChildrenPrev) {
      let diff = count - state.familyInfoData.rdbChildrenPrev;

      let length = 1;
      while (length <= diff) {
        state.childInfoData.push({
          title: "",
          first_name: "",
          middle_name: "",
          last_name: "",
          gender: "Male",
          dob: d.toJSON().slice(0, 10),
          age: 1
        });
        length++;
      }

      //setting the current selected value to the previous value
      state.familyInfoData.rdbChildrenPrev = Number(
        state.familyInfoData.rdbChildren
      );
    }
    //when we need to remove items from the existing array
    else if (state.familyInfoData.rdbChildrenPrev > count) {
      let diff = state.familyInfoData.rdbChildrenPrev - count;

      //removing items from the last position
      for (let i = 0; i < diff; i++) {
        state.childInfoData.pop();
      }


      //setting the current selected value to the previous value
      state.familyInfoData.rdbChildrenPrev = Number(
        state.familyInfoData.rdbChildren
      );
    }
  },
  updateQuotationList(state, data) {
    state.quotationList = data.data;
  },
  //** Rider response map to state objet  */
  setRidersObj(state, data) {
    //** Main life riders  */
    state.riderDataMain = data.main;
    //** Spouse riders  */
    state.riderDataSpouse = data.spouse;
    //** Children riders  */
    state.riderDataChildren = data.children;
    //** Timestamp for DOB calander current date  */
    state.timestamp = data.timestamp.substring(0, 10);
  },
  setAgeObj(state, { data, type, index }) {
    if (type == "main") {
      state.mainInfoData.txtMainLifeAge = data.age;
    } else if (type == "spouse") {
      state.spouseInfoData.txtSpouseAge = data.age;
    } else if (type == "child") {
      state.childInfoData[index].age = data.age;
    }
    if(data.timestamp){
      state.timestamp = data.timestamp.substring(0, 10);
    }
  },
  setOccupationObj(state, data) {
    //** Set Occupation list   */
    state.occupationList = data.occupations;
  },
  setHospitalObj(state, data) {
    //** Set hospital list   */
    state.hospitalList = data;
    //console.log(data);
  },
  setOccupationLoadingObj(state, data) {
    //** Set Occupation list   */
    state.occupationLoadingList = data;
    //console.log(data);
  },
  //** Create Illustration response map to state object  */
  setChangedObj(state, data) {
    state.calcSummary = data;
    //console.log(data);
  },
  clearObj(state) {
    Object.assign(state, getDefaultState());
  },
  loadQuotationData(state, data) {
    /** main mamber  */
    state.mainInfoData.txtMainLifeFname = data.member_main.first_name;
    state.mainInfoData.drpMainLifetitle = data.member_main.title;
    state.mainInfoData.txtMainLifeFname = data.member_main.first_name;
    state.mainInfoData.txtMainLifeMname = data.member_main.middle_name;
    state.mainInfoData.txtMainLifeLname = data.member_main.last_name;
    state.mainInfoData.txtMainLifeNIC = data.member_main.nic;
    state.mainInfoData.txtMainLifeEmail = data.member_main.email;
    state.mainInfoData.txtMainLifeMob = data.member_main.telephone;
    state.mainInfoData.rdbMainGender = data.member_main.gender;
    state.mainInfoData.txtMainLifeDOB = data.member_main.dob;
    state.mainInfoData.drpMainLifeOccupation = data.member_main.occupation;
    state.mainInfoData.drpMainLifePrimaryNeed =
      data.additional_info.primary_need;
    state.mainInfoData.rdbMainLifeFrequency =
      data.additional_info.frequency == "Y"
        ? "A"
        : data.additional_info.frequency;
    state.mainInfoData.txtMainLifeMonthlyBasicPrem =
      data.additional_info.monthly_basic_premium;
    state.mainInfoData.txtMainLifePremiumTerm =
      data.additional_info.premium_term;
    state.mainInfoData.txtMainLifePolicyTerm = data.additional_info.policy_term;
    state.mainInfoData.drpMainLifeContributionTerm = data.additional_info.contribution_term;
    state.mainInfoData.drpMainLifeCoverMultiple = data.additional_info.covermultiple;
    state.mainInfoData.txtMainSumAtRisk =
      data.member_main.sum_at_risk_prev_policy;
    (state.mainInfoData.txtNumOfSumAsTimes =
      data.member_main.no_of_sum_ass_times),
        state.mainInfoData.rdbMainMedrem3Floater = data.additional_info.med_rem3_floater;
        
      /** main mamber riders */
      (state.riderDataMain = data.member_main.riders);
    /** family info panel */
    state.familyInfoData.drpFamilyInfoMaritalStatus =
      data.member_main.marital_status;
    state.familyInfoData.rdbSpouse = data.member_main.has_spouse;
    state.familyInfoData.rdbChildren = Number(data.member_main.children);
    /** spouse info */
    state.spouseInfoData.drpSpouseTitle = data.member_spouse.title;
    state.spouseInfoData.txtSpouseFname = data.member_spouse.first_name;
    state.spouseInfoData.txtSpouseMname = data.member_spouse.middle_name;
    state.spouseInfoData.txtSpouseLname = data.member_spouse.last_name;
    state.spouseInfoData.rdbSpouseGender = data.member_spouse.gender;
    state.spouseInfoData.txtSpouseDOB = data.member_spouse.dob;
    state.spouseInfoData.drpSpouseOccupation = (data.member_spouse.occupation != undefined ? data.member_spouse.occupation : state.spouseInfoData.drpSpouseOccupation);
    state.spouseInfoData.txtSpouseSumAtRisk =
      data.member_spouse.sum_at_risk_prev_policy;
    /** spouse member riders */
    state.riderDataSpouse = data.member_spouse.riders;
    /** child mamber info */
    state.childInfoData = data.member_child;

    state.riderDataChildren = data.child_riders;

    //will be used in addChildObj mutation
    state.familyInfoData.rdbChildrenPrev = state.familyInfoData.rdbChildren;

    /** child mamber riders */

    /** Quotation no and version no for updated purposes */
    state.quotationNo = data.quote_no;
    state.version = data.version;
    state.refNo = data.ref_no;
  },
  /** Error Message setter */
  setError(state, { error, errorType }) {
    //console.log("called set error " + errorType);
    //console.log("called set error " + error);
    state.flash = {
      type: errorType,
      status: true,
      error: error
    };
    //console.log(state.flash.status);
  },
  /** Error Message destroy */
  clearFlash(state) {
    state.flash = {
      type: "",
      status: false,
      error: ""
    };
  },

  /** Minified Summary Message setter */
  setMinMsg(state, data) {
    state.minflash = {
      status: true,
      error: data
    };
    //console.log(state.flash.status);
  },
  /** Minified Summary Message destroy */
  clearMinMsg(state) {
    state.minflash = {
      status: false,
      error: ""
    };
  },

  setChangedObjSuccessCreate(state) {
    state.flash.status = true;
  }
};
const actions = {
  async clearStateData({ commit }) {
    commit("clearObj");
  },
  async addChild({ commit }, count) {
    commit("addChildObj", count);
  },
  async addChangedData({ commit, state }, type = "preview", refno = null) {
    console.log(type);
    let member_main = {};
    let member_spouse = {};
    let member_child = {};
    let child_riders = {};
    let additional_info = {};

    if (type == "update") {
      refno = state.refNo;
    }

    let data = {
      save_state: type,
      branch_code: "CLS",
      channel_code: "01",
      user_category: "MDRT",
      agent_code: "E660",
      user_role: "UWR",
      ref_no: refno
    };
    if (state.mainInfoData.rdbMainLifeFrequency == "S") {
      state.mainInfoData.txtNumOfSumAsTimes = 0;
    }
    /** Member information hold object */
    member_main = {
      title: state.mainInfoData.drpMainLifetitle,
      first_name: state.mainInfoData.txtMainLifeFname,
      middle_name: state.mainInfoData.txtMainLifeMname,
      last_name: state.mainInfoData.txtMainLifeLname,
      nic: state.mainInfoData.txtMainLifeNIC,
      email: state.mainInfoData.txtMainLifeEmail,
      gender: state.mainInfoData.rdbMainGender,
      dob: state.mainInfoData.txtMainLifeDOB,
      telephone: state.mainInfoData.txtMainLifeMob,
      occupation: state.mainInfoData.drpMainLifeOccupation,
      marital_status: state.familyInfoData.drpFamilyInfoMaritalStatus,
      has_spouse: state.familyInfoData.rdbSpouse,
      children: Number(state.familyInfoData.rdbChildren),
      sum_at_risk_prev_policy: state.mainInfoData.txtMainSumAtRisk,
      no_of_sum_ass_times: state.mainInfoData.txtNumOfSumAsTimes,
      riders: state.riderDataMain
    };

    (additional_info = {
      primary_need: state.mainInfoData.drpMainLifePrimaryNeed,
      monthly_basic_premium: state.mainInfoData.txtMainLifeMonthlyBasicPrem,
      premium_term: state.mainInfoData.txtMainLifePremiumTerm,
      policy_term: state.mainInfoData.txtMainLifePolicyTerm,
      contribution_term: state.mainInfoData.drpMainLifeContributionTerm,
      frequency:
        state.mainInfoData.rdbMainLifeFrequency == "A"
          ? "Y"
          : state.mainInfoData.rdbMainLifeFrequency,
        covermultiple: state.mainInfoData.drpMainLifeCoverMultiple,
        med_rem3_floater: state.mainInfoData.rdbMainMedrem3Floater,
    }),
      /** Spouse information hold object */
      (member_spouse = {
        title: state.spouseInfoData.drpSpouseTitle,
        first_name: state.spouseInfoData.txtSpouseFname,
        middle_name: state.spouseInfoData.txtSpouseMname,
        last_name: state.spouseInfoData.txtSpouseLname,
        gender: state.spouseInfoData.rdbSpouseGender,
        dob: state.spouseInfoData.txtSpouseDOB,
        occupation: state.spouseInfoData.drpSpouseOccupation,
        sum_at_risk_prev_policy: state.spouseInfoData.txtSpouseSumAtRisk,
        riders: state.riderDataSpouse
      });

    member_child = state.childInfoData;
    child_riders = state.riderDataChildren;

    //** Illustration request object prepare */
    data = {
      ...data,
      member_main,
      member_spouse,
      additional_info,
      member_child,
      child_riders
    };
    let errorType = "";
    let error = "";
    //** Illustration request axios call */
    commit("clearFlash"); //destroy snotify
    commit("clearMinMsg"); // destroy Min Summary Message
    await http
      .post("quote_flexi_life/illustration", { data }, {})
      .then(response => {
        if (response.data.meta.status == "true") {
          if (type == "create" || type == "update") {
            //commit("setChangedObjSuccessCreate");
            error = response.data.meta.status_message;
            errorType = "S";
            //console.log("illustration response :" + error);
            commit("setError", { error, errorType });
          } else {
            commit("setChangedObj", response.data.data);
            commit(
              "setMinMsg",
              " Rs. " + response.data.data.summary_formatted.total_premium
            );
            commit("common_actions/setShaking" , null, { root: true });
          }
        } else {
          error = response.data.meta.status_message;
          errorType = "E";
          //console.log("illustration response :" + error);
          commit("setError", { error, errorType });
        }
      })
      .catch(error => {
        let errorType = "E";
        //commit("setError", "Error : illustration Service Response Failed");
        //alert("Error : illustration Service Response Failed");
        commit("setError", { error, errorType });
      });
  },
  async getRidersList({ commit }) {
    let errorType = "";
    //** Riders list(Main Life, Spouse, Children) request axios call  */
    commit("clearFlash"); //destroy snotify
    await http
      .post("quote_flexi_life/riders", { product: "product_one" }, {})
      .then(response => {
        if (response.data.meta.status == "true") {
          commit("setRidersObj", response.data.data);
        } else {
          errorType = "E";
          let error = "Error : Rider Service Response Failed";
          commit("setError", { error, errorType });
          //alert("Error : Rider Service Response Failed");
        }
      })
      .catch(error => {
        //alert(error);
        errorType = "E";
        commit("setError", { error, errorType });
      });
  },
  async getOccupationList({ commit }) {
    //** Occupation list request axios call  */
    commit("clearFlash"); //destroy snotify
    await http
      .post("quote/occupation_list", null, {})
      .then(response => {
        if (response.data.meta.status == "true") {
          commit("setOccupationObj", response.data.data);
        } else {
          let errorType = "E";
          let error = "Error : Occupation Service Response Failed";
          commit("setError", { error, errorType });
          //alert("Error : Occupation Service Response Failed");
        }
      })
      .catch(error => {
        //alert(error);
        let errorType = "E";
        commit("setError", { error, errorType });
      });
  },
  async getHospitalList({ commit }) {
    let errorType = "E";
    //** Hospital list request axios call  */
    commit("clearFlash"); //destroy snotify
    await http
      .post("conf/hospitals", null, {})
      .then(response => {
        if (response.data.meta.status == "true") {
          //console.log(response.data.data[0]);
          commit("setHospitalObj", response.data.data);
        } else {
          errorType = "E";
          let error = "Error : Hospital Service Response Failed";
          commit("setError", error, errorType);
        }
      })
      .catch(error => {
        //alert(error);
        errorType = "E";
        commit("setError", { error, errorType });
      });
  },
  async getAge({ commit }, payload) {
    let dob = payload.dob;
    let errorType = "";
    //** Age Calculation axios call  */
    commit("clearFlash"); //destroy snotify
    await http
      .post("quote_flexi_life/get_age", { dob: dob }, {})
      .then(response => {
        if (response.data.meta.status == "true") {
          let data = response.data.data;
          let type = payload.type;

          //this is to be used to identify the child
          let index = 0;
          if (payload.hasOwnProperty("index")) {
            index = payload.index;
          }
          commit("setAgeObj", { data, type, index });
        } else {
          errorType = "E";
          let error =
            "Error : Main Life Age Calculator Service Response Failed";
          commit("setError", error, errorType);
          //alert("Error : Main Life Age Calculator Service Response Failed");
        }
      })
      .catch(error => {
        //alert(error);
        errorType = "E";
        commit("setError", { error, errorType });
      });
  },
  async getQuotationList({ commit }, filterText) {
    let errorType = "";
    commit("clearFlash"); //destroy snotify
    await http
      .post("quote/list", filterText, {})
      .then(response => {
        if (response.data.meta.status == "true") {
          commit("updateQuotationList", response.data.data);
        } else {
          errorType = "E";
          let error = "Error : Get Quotation List Service Response Failed";
          commit("setError", { error, errorType });
          //alert("Something went wrong - getQuotationList");
        }
      })
      .catch(error => {
        //alert(error);
        errorType = "E";
        commit("setError", { error, errorType });
      });
  },
  async getOneQuotation({ commit }, refNo) {
    let errorType = "";
    commit("clearFlash"); //destroy snotify
    await http
      .post("quote_flexi_life/view", { ref_no: refNo }, {})
      .then(response => {
        if (response.data.meta.status == "true") {
          //console.log(response.data.data);
          commit("loadQuotationData", response.data.data);
        } else {
          errorType = "E";
          let error = "Error : Get One Quotation Service Response Failed";
          commit("setError", { error, errorType });
          //alert("Something went wrong - getOneQuotation");
        }
      })
      .catch(error => {
        //alert(error);
        errorType = "E";
        commit("setError", { error, errorType });
      });
  },
  async getRequestLetter({ commit }, payload) {
    let errorType = "E";
    let hospital = payload.hospital.code;
    let parties = payload.parties;
      let quoteNo = payload.quote_no;
    await http
        .post("quote/medical_letter",
            {
              hospital: hospital,
                parties: parties,
                quote_no: quoteNo
            }, {})
        .then(response => {
          if (response.data.meta.status == "true") {
              //console.log(response.data.data);
              if (response.data.data) {
                return response.data.data;
                  /*window.open(
                      response.data.data,
                      '_blank' // <- This is what makes it open in a new window.
                  );
                  */
              }
          } else {
            errorType = "E";
            let error = "Error : Get One Request Letter Response Failed";
            commit("setError", {error, errorType});
          }
        })
        .catch(error => {
          errorType = "E";
          commit("setError", {error, errorType});
        });
  },
  async getOccupationLoading({commit}, payload) {
    let errorType = "E";
    let quoteNo = payload.quote_no;
    await http
        .post("quote/occ_loading",
            {
              quote_no: quoteNo
            }, {})
        .then(response => {
          if (response.data.meta.status == "true") {
            //console.log(response.data.data);
            commit("setOccupationLoadingObj", response.data.data);
          } else {
            errorType = "E";
            let error = "Error : Get Occupation Loading Request Letter Response Failed";
            commit("setError", {error, errorType});
          }
        })
        .catch(error => {
          errorType = "E";
          commit("setError", {error, errorType});
        });
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
