<template>
  <div class="row-top-list2 table-row-top">
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      color="#00F"
    ></loading>
    <vue-snotify></vue-snotify>
    <div class="card">
      <div class="card-header">
        <font-awesome-icon icon="align-left" />&nbsp;
        <span>{{ $t("list_proposal") }}</span>
      </div>
      <div class="card-body">
        <div class="row tbl-margin-top">
          <div class="col table-responsive">
            <vuetable
              class="table table-striped table-bordered nowrap vue-tbl"
              ref="vuetable"
              :api-url="getUrl"
              :fields="fields"
              pagination-path
              @vuetable:pagination-data="onPaginationData"
              :per-page="3"
              :http-options="httpOptions"
              :row-class="onRowClass"
              :append-params="moreParams"
            >
              <template slot="actions" scope="props">
                <!-- Occupation Combo - START -->
                <div>
                  <b-modal
                    :id="'modal-center' + props.rowData.id"
                    centered
                    scrollable
                    title="Occupation Loading"
                  >
                    <div class="row">
                      <!-- Occupation list -->
                      <div class="col-sm-12">
                        <b-table :items="occupationLoadingList" responsive>
                          <!-- We are using utility class `text-nowrap` to help illustrate horizontal scrolling -->
                          <div
                            class="text-nowrap"
                            slot="HEAD[]"
                            slot-scope="scope"
                          >
                            {{ scope.label }}
                          </div>
                          <div class="text-nowrap" slot="[]" slot-scope="scope">
                            {{ scope.value }}
                          </div>
                        </b-table>
                      </div>
                    </div>
                  </b-modal>
                </div>
                <!-- Occupation Combo - END -->

                <!-- Proposal No add Model Box - START -->
                <ValidationObserver ref="proNumValidate">
                  <div>
                    <b-modal
                      v-model="prop_no_modal"
                      centered
                      :title="$t('prop_no_add_modal_title')"
                      header-bg-variant="primary"
                      header-text-variant="light"
                      body-bg-variant="light"
                      body-text-variant="dark"
                      footer-bg-variant=""
                      footer-text-variant="dark"
                      :id="'modal-prop-number-add' + props.rowData.id"
                      ref="modal"
                      @ok="addPropNo"
                    >
                      <div class="row">
                        <div class="col-sm-12">
                          <form @submit.stop.prevent="handleSubmit" ref="form">
                            <div class="form-group">
                              <label for="lbl_hospital_list"
                                >Proposal Number</label
                              >
                              <ValidationProvider
                                immediate
                                name="Proposal Number"
                                rules="required|min:3|max:20"
                              >
                                <div slot-scope="{ errors }">
                                  <b-form-input
                                    type="text"
                                    class="form-control"
                                    id="proposalNo"
                                    name="proposalNo"
                                    v-model.trim="proposal_no"
                                    minlength="3"
                                    maxlength="20"
                                    required
                                  >
                                  </b-form-input>
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </div>
                          </form>
                        </div>
                      </div>
                    </b-modal>
                  </div>
                </ValidationObserver>
                <!-- PROPOSAL NO ADD  Model Box - END -->

                <!-- View Uploaded Documents Model Box - START -->
                <ValidationObserver>
                  <div>
                    <b-modal
                      v-model="show"
                      centered
                      :title="$t('view_upload_doc_modal_title')"
                      header-bg-variant="primary"
                      header-text-variant="light"
                      body-bg-variant="light"
                      body-text-variant="dark"
                      footer-bg-variant=""
                      footer-text-variant="dark"
                      size="xl"
                      :id="'modal-uploaded-docs-view' + props.rowData.id"
                      ref="modal"
                    >
                      <div class="row">
                        <!-- Uploaded Document List  -->

                        <div class="col">
                          <div class="container-fluid">
                            <div
                              class
                              v-for="(upload_type, index) in UploadTypes"
                              :key="index"
                            >
                              <div class="p-1 mb-1 bg-secondary text-white">
                                {{ upload_type.title }}
                              </div>
                              <div class="border border-primary rounded mb-2">
                                <div class="upload">
                                  <div class="example-btn">
                                    <div class="row">
                                      <div
                                        v-for="item in UploadedDocs"
                                        :key="item.id"
                                      >
                                        <div
                                          v-if="
                                            item.proposal_upload_type_id ==
                                            upload_type.id
                                          "
                                        >
                                          <div class="col-md-2">
                                            <a
                                              v-bind:href="item.url"
                                              target="_blank"
                                            >
                                              <img
                                                v-bind:src="item.url"
                                                target="_blank"
                                                class="rounded mt-1 mb-1 ml-3"
                                                alt="No Preview"
                                                width="150"
                                                height="150"
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- <button type="button" class="btn btn-success ml-3 mt-2 mb-2 float-right">Submit</button> -->
                          </div>
                        </div>

                        <!-- END UPLOADED Document List  -->
                      </div>
                    </b-modal>
                  </div>
                </ValidationObserver>
                <!-- View Uploaded Documents Model Box - END -->

                <div
                  class="custom-actions"
                  style="white-space: nowrap; text-align: center"
                >
                  <div>
                    <b-dropdown
                      id="dropdown-1"
                      text="Actions"
                      class="m-md-2"
                      size="sm"
                    >
                      <b-dropdown-item
                        style="margin:5px padding: .25rem .65rem;;"
                        variant="info"
                        class="ui btn-sm basic button"
                        :title="transferString(props.rowData)"
                        @click="
                          onActionClicked(
                            'transfer-to-customer',
                            props.rowData,
                            props.rowIndex
                          )
                        "
                        v-show="
                          [1, 2, 3].includes(props.rowData.proposal_status)
                        "
                      >
                        <font-awesome-icon icon="share" />
                        {{ transferString(props.rowData) }}
                      </b-dropdown-item>

                      <b-dropdown-item
                        style="margin:5px padding: .25rem .65rem;;"
                        variant="info"
                        class="ui btn-sm basic button"
                        :title="transferSigString(props.rowData)"
                        v-show="[4, 5].includes(props.rowData.proposal_status)"
                        @click="
                          onActionClicked(
                            'send-customer-signature-request-link',
                            props.rowData,
                            props.rowIndex
                          )
                        "
                      >
                        <font-awesome-icon icon="pen" />
                        {{ transferSigString(props.rowData) }}
                      </b-dropdown-item>

                      <b-dropdown-item
                        style="margin:5px padding: .25rem .65rem;;"
                        variant="info"
                        class="ui btn-sm basic button"
                        title="Edit Item"
                        @click="
                          onActionClicked(
                            'edit-item',
                            props.rowData,
                            props.rowIndex
                          )
                        "
                        v-if="editPermissionCheck() == true"
                        v-show="
                          [1, 2, 3, 4].includes(
                            props.rowData.proposal_status
                          )
                        "
                      >
                        <font-awesome-icon icon="pen" />
                        Edit Item
                      </b-dropdown-item>

                      <b-dropdown-item
                        style="margin:5px padding: .25rem .65rem;;"
                        variant="info"
                        class="ui btn-sm basic button"
                        :title="docUploadString()"
                        v-show="
                          ([9].includes(props.rowData.proposal_status) &&
                            (role != 'SUP' && role != 'HO')   ) ||
                          ([7].includes(props.rowData.proposal_status) &&
                            role == 'SUP') ||
                          ([8].includes(props.rowData.proposal_status) &&
                            role == 'HO' &&
                            props.rowData.ho_document_edit_permission)
                        "
                        @click="
                          onActionClicked(
                            'upload-documents',
                            props.rowData,
                            props.rowIndex
                          )
                        "
                      >
                        <font-awesome-icon icon="upload" />
                        {{ docUploadString() }}
                      </b-dropdown-item>


                      <b-dropdown-item
                        style="margin:5px padding: .25rem .65rem;;"
                        variant="info"
                        class="ui btn-sm basic button"
                        :title="docUploadString()"
                        v-show="role == 'HO'"
                        @click="
                          onActionClicked(
                            'view-documents',
                            props.rowData,
                            props.rowIndex
                          )
                        "
                      >
                        <font-awesome-icon icon="file" />
                          View Documents
                      </b-dropdown-item>

                      <b-dropdown-item
                        style="margin:5px padding: .25rem .65rem;"
                        variant="info"
                        class="ui btn-sm basic button"
                        title="Agent Signature"
                        v-show="[6].includes(props.rowData.proposal_status)"
                        @click="
                          onActionClicked(
                            'agent-signature',
                            props.rowData,
                            props.rowIndex
                          )
                        "
                      >
                        <font-awesome-icon icon="pen" />
                        Agent Signature
                      </b-dropdown-item>

                      <b-dropdown-item
                        style=""
                        variant="info"
                        class="ui btn-sm basic button"
                        title="Download Quotation PDF"
                        @click="
                          onActionClicked(
                            'download-item',
                            props.rowData,
                            props.rowIndex,
                            'Q'
                          )
                        "
                        v-if="viewPermissionCheck() == true"
                      >
                        <font-awesome-icon icon="file-pdf" />
                        Download Quotation PDF
                      </b-dropdown-item>

                      <!-- Download Proposal PDF -->
                      <b-dropdown-item
                        style=""
                        variant="info"
                        class="ui btn-sm basic button"
                        title="Download Proposal PDF"
                        @click="
                          onActionClicked(
                            'download-item',
                            props.rowData,
                            props.rowIndex,
                            'P'
                          )
                        "
                        v-if="viewPermissionCheck() == true"
                        v-show="
                          (props.rowData.deprecated == 'true' ? false : true) &&
                          [7, 8, 9, 10].includes(props.rowData.proposal_status)
                        "
                      >
                        <font-awesome-icon icon="file-pdf" />
                        Download Proposal PDF
                      </b-dropdown-item>

                      <b-dropdown-item
                        style="margin:5px padding: .25rem .65rem; margin-right:10px;"
                        variant="info"
                        class="ui btn-sm basic button"
                        title="Proposal No"
                        @click="
                          onActionClicked(
                            'add-prop-no',
                            props.rowData,
                            props.rowIndex
                          )
                        "
                        v-show="
                          ([7].includes(props.rowData.proposal_status)
                            ? true
                            : false) &&
                          role == 'SUP' &&
                          props.rowData.document_approved_by_sup == 1
                        "
                      >
                        <font-awesome-icon icon="plus" />
                        {{ proposalNoStr(props.rowData) }}
                      </b-dropdown-item>

                      <b-dropdown-item
                        style="margin:5px padding: .25rem .65rem;;"
                        variant="info"
                        class="ui btn-sm basic button"
                        title="View Uploaded Documents"
                        @click="
                          onActionClicked(
                            'view-uploaded-docs',
                            props.rowData,
                            props.rowIndex
                          )
                        "
                        v-show="
                          ([7].includes(props.rowData.proposal_status)
                            ? true
                            : false) &&
                          role == 'SUP' &&
                          false
                        "
                      >
                        <font-awesome-icon icon="eye" />
                        View Uploaded Documents
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </template>
              <div slot="custom_status" slot-scope="props">
                <div class="status-wrapper">
                  <div class="badge badge-info status-sub-wrapper1">
                    {{ props.rowData.prop_status_new }}
                  </div>
                  <br />
                  <div class="badge badge-warning status-sub-wrapper2" v-if="props.rowData.proposal_status < 7">
                    {{
                      props.rowData.transfer_status == 2 ? "Customer" : "Agent"
                    }}
                  </div>
                </div>
              </div>
              <div slot="custom_prop_no" slot-scope="props">
                <div class="status-wrapper">
                  {{ props.rowData.prop_no }}
                  <br />
                  <div class="badge badge-warning status-sub-wrapper2" v-if="role == 'HO'">
                    SB-DATE - {{ props.rowData.supervisor_submit_date }}
                  </div>
                </div>
              </div>
            </vuetable>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="pagination">
              <vuetable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              ></vuetable-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile number request modal -->
    <b-modal
      centered
      size="md"
      title="Transfer Proposal to Customer"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="light"
      body-text-variant="dark"
      footer-bg-variant=""
      footer-text-variant="dark"
      :id="'customer-mobile-request-modal'"
      ref="customer-mobile-request-modal"
      :ok-disabled="true"
    >
    <ValidationObserver ref="customer_mobile_request_form">
      <div class="row">
        
          <div class="col-sm-12 col-md-12">
            <b-form-group label="Prefered communication type" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-transfer-type"
                v-model="transfer_type"
                :options="transfer_options"
                :aria-describedby="ariaDescribedby"
                name="radio-options"
              ></b-form-radio-group>
            </b-form-group>
            <div class="col-sm-6 col-md-6" v-if="transfer_type == 1 || transfer_type == 3">
              <label>Customer Mobile Number</label>
              <ValidationProvider rules="" v-slot="{ errors }" name="mobile">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="customer_mobile"
                />
                <div v-if="errors[0]" class="error-text">
                  <div
                    class="p-error"
                    v-for="(error, index) in errors"
                    v-bind:key="index"
                    >{{ error }}</div
                  >
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6 col-md-6" v-if="transfer_type == 2 || transfer_type == 3">
              <label>Customer Email</label>
              <ValidationProvider rules="" v-slot="{ errors }" name="email">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="customer_email"
                />
                <div v-if="errors[0]" class="error-text">
                  <div
                    class="p-error"
                    v-for="(error, index) in errors"
                    v-bind:key="index"
                    >{{ error }}</div
                  >
                </div>
              </ValidationProvider>
            </div>
          </div>
        
      </div>
    </ValidationObserver>
      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          :disabled="!customer_mobile"
          size="sm"
          variant="primary"
          @click="confirmTransferProposal()"
        >
          Send Link
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- Send signature link modal -->
    <b-modal
      centered
      size="lg"
      title="Request Customer Signature"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="light"
      body-text-variant="dark"
      footer-bg-variant=""
      footer-text-variant="dark"
      :id="'send-signature-link-modal'"
      ref="send-signature-link-modal"
      :ok-disabled="true"
      scrollable
    >
      <div class="row">
        <div class="col-sm-12">
          <ValidationObserver class="col-sm-12" ref="customer_signature_link_form">
            <div class="col-sm-12">
              <label class="mr-3">
                <input type="radio" value="1" v-model="sigType" />
                Online
              </label>

              <label>
                <input type="radio" value="2" v-model="sigType" /> Offline
              </label>

              <div class="float-right" v-if="sigType == 2">
                  <b-button size="sm" variant="primary" @click="previewProposal()">Preview Proposal</b-button>
              </div>
            </div>
            <div class="col-sm-12" v-if="sigType == 2">
              <FinalInsuredSignBoard />
              <FinalSpouseSignBoard
                v-if="
                  InfoInsured.sig_data_insured && InfoInsured.has_spouse == 'Yes'
                "
              />
              

              <div class="col-md-12 text-center">
                <div class="proposal-view-container" v-html="viewData">
                </div>
              </div>
            </div>

            <div class="col-md-12" v-if="sigType == 1">
              <div class="padding-without-card">
                  <div class="card-body p-3 mt-0">
                    <b-form-group label="Prefered communication type" v-slot="{ ariaDescribedby }">
                      <b-form-radio-group
                          id="radio-group-transfer-type"
                          v-model="transfer_type"
                          :options="transfer_options"
                          :aria-describedby="ariaDescribedby"
                          name="radio-options"
                        ></b-form-radio-group>
                    </b-form-group>
                    <div class="col-sm-12 col-md-12" v-if="transfer_type == 1 || transfer_type == 3">
                      <label>Customer Mobile Number</label>
                      <ValidationProvider rules="" v-slot="{ errors }" name="mobile">
                        <input
                          type="text"
                          class="form-control"
                          v-model.trim="customer_mobile"
                        />
                        <div v-if="errors[0]" class="error-text">
                          <div
                            class="p-error"
                            v-for="(error, index) in errors"
                            v-bind:key="index"
                            >{{ error }}</div
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-sm-12 col-md-12" v-if="transfer_type == 2 || transfer_type == 3">
                      <label>Customer Email</label>
                      <ValidationProvider rules="" v-slot="{ errors }" name="email">
                        <input
                          type="text"
                          class="form-control"
                          v-model.trim="customer_email"
                        />
                        <div v-if="errors[0]" class="error-text">
                          <div
                            class="p-error"
                            v-for="(error, index) in errors"
                            v-bind:key="index"
                            >{{ error }}</div
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>

              </div>
              
            </div>
          </ValidationObserver>
        </div>
      </div>
      

      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          :disabled="!customer_mobile"
          size="sm"
          variant="primary"
          @click="sendCustomerSignatureLink()"
          v-if="sigType == 1"
        >
          {{ sigType == 1 ? "Send Link" : "Submit" }}
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- Edit proposal confirmation -->
    <b-modal
      centered
      size="md"
      title="Request Proposal Edit Access Forcefully"
      header-bg-variant="warning"
      header-text-variant="light"
      body-bg-variant="light"
      body-text-variant="dark"
      footer-bg-variant=""
      footer-text-variant="dark"
      :id="'edit-proposal-modal'"
      ref="edit-proposal-modal"
      :ok-disabled="true"
    >
      <div class="row">
        <div class="col-sm-12">
          <label
            >This proposal has transferd to customer.Do you realy need to edit
            this?</label
          >
        </div>
      </div>
      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="primary" @click="editProposalRoute()">
          Confirm
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- Add Agent Signature -->
    <b-modal
      centered
      size="lg"
      title="Agent Signature"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="light"
      body-text-variant="dark"
      footer-bg-variant=""
      footer-text-variant="dark"
      :id="'agent-signature-modal'"
      ref="agent-signature-modal"
      :ok-disabled="true"
    >
      <div>
        <FinalAgentSignBoard />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
//console.log(process.env.VUE_APP_API_ENDPOINT);
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
// import VuetableFieldMixin from "vuetable-2/src/components/VuetableFieldMixin";
//import { ValidationProvider } from "vee-validate";
//import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

import VuetablePagination from "@/components/vuetable/VuetablePaginationBootstrap4";
import FinalInsuredSignBoard from "@/components/proposal/final/Signature/FinalInsuredSignBoard";
import FinalSpouseSignBoard from "@/components/proposal/final/Signature/FinalSpouseSignBoard";
import FinalAgentSignBoard from "@/components/proposal/final/Signature/FinalAgentSignBoard";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    FinalInsuredSignBoard,
    FinalSpouseSignBoard,
    FinalAgentSignBoard,
    // mixins: [VuetableFieldMixin]
    //VuetablePaginationInfo
  },
  data() {
    return {
      fullPage:true,
      isLoading:false,
      show: false,
      prop_no_modal: false,
      proposal_no: "",
      role: "",
      prop_id: "",
      prop_no: "",
      nameState: null,
      fields: [
        {
          name: "quote_ref",
          title: '<span class="orange glyphicon glyphicon-user"></span> #',
          sortField: "quote_ref",
        },
        {
          name: "quote_no",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Quotation Number',
          sortField: "quote_no",
        },
        {
          name: "__slot:custom_prop_no",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Proposal Number'
        },
        {
          name: "hash_id",
          visible: false,
        },
        
        {
          name: "version",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Version',
          sortField: "version",
        },
        {
          name: "product",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Product',
          sortField: "product",
        },

        {
          name: "name",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Customer Name',
          sortField: "name",
        },
        {
          name: "mobile_no",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Mobile Number',
          sortField: "telephone",
        },
        {
          name: "email",
          visible: false,
        },
        {
          name: "nic",
          title: '<span class="orange glyphicon glyphicon-user"></span> NIC',
          sortField: "nic",
        },
        {
          name: "agent_code",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Agent Code',
          sortField: "agent_code",
        },
        {
          name: "transfer_status",
          visible: false,
        },
        {
          name: "document_approved_by_sup",
          visible: false,
        },
        {
          name: "assigned_user",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Assigned To',
          sortField: "assigned_user",
          visible: this.visibleAssignUser(),
        },
        {
          name: "ho_document_edit_permission",
          visible: false,
        },
        {
          name: "proposal_status",
          visible: false,
        },
        {
          name: "__slot:custom_status",
          title: '<span class="orange glyphicon glyphicon-user"></span>Status',
        },
        {
          name: "id",
          visible: false,
        },

        // {
        //   name: "proc_status",
        //   title: '<span class="orange glyphicon glyphicon-user"></span>Status',
        //   sortField: "proc_status",
        // },

        "__slot:actions",
      ],
      httpOptions: {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: {},
      },
      moreParams: {},
      getUrl: process.env.VUE_APP_API_ENDPOINT + "proposal/list",
      selected: ["Main"], // Must be an array reference!
      options: [
        {
          text: "Main",
          value: "Main",
        },
        {
          text: "Spouse",
          value: "Spouse",
        },
      ],
      quote_no: null,
      customer_mobile: null,
      customer_email:null,
      active_quote_no: null,
      active_hash_id: null,
      active_proposal_id: null,
      sigType: 1,
      transfer_options: [
          { text: 'SMS', value: 1 },
          { text: 'Email', value: 2 },
          { text: 'SMS & Email', value: 3}
        ],
      transfer_type:1,
      viewData:""
    };
  },
  computed: {
    ...mapState("proposal", [
      "occupationLoadingList",
      "UploadTypes",
      "UploadedDocs",
      "QuoteRefNo",
      "pStatus",
      "InfoInsured",
      "ProposalID"
    ]),
    ...mapState("common_actions", ["shakingIconAction"]),
    ...mapGetters("login", ["checkUserPermissionNew"]),
  },
  mounted() {
    this.$snotify.clear();
    this.getUploadTypes();
    this.role = localStorage.getItem("user_role")
      ? localStorage.getItem("user_role")
      : null;
    this.$events.$on("filter-set", (eventData) => this.onFilterSet(eventData));
    this.$events.$on("filter-reset", this.onFilterReset());
    Vue.nextTick(() => this.$refs.vuetable.refresh());
    this.shakingIconAction.product = "";
  },
  methods: {
    ...mapActions("quotation", ["getRequestLetter", "getOccupationLoading"]),
    ...mapActions("proposal", [
      "setProposalNumber",
      "UploadFile",
      "loadUploadedFiles",
      "getUploadTypes",
      "transferProposal",
      "sendCustomerSignatureRequestLink",
      "getBenefitData",
      "forceProposalUpdate",
      "loadProposalDataView"
    ]),
    previewProposal() {
      // if(!this.viewData){
      //   let params = {
      //       proposalId: this.ProposalID,
      //       quoteRefNo: this.QuoteRefNo,
      //       lang: localStorage.getItem("lang"),
      //   };
      //   this.loadProposalDataView(params).then((response) => {
      //       this.viewData = response.data;
      //   });
      // }else{
      //   this.viewData = "";
      // }

      const routeData = this.$router.resolve({name: 'view_proposal', params: {
          ref_no: this.active_hash_id,
      }});
      window.open(routeData.href, '_blank');
        
    },
    visibleAssignUser() {
      if (localStorage.getItem("user_role") == "HO") {
        return true;
      } else {
        return false;
      }
    },
    proposalNoStr(data) {
      if (data && data.prop_no && data.prop_no.length) {
        return "Edit Proposal No";
      } else {
        return "Add Proposal No";
      }
    },
    docUploadString() {
      let docUploadString = "";
      if (this.role == "SUP") {
        docUploadString = "Verify Document";
      } else if (this.role == "HO") {
        docUploadString = "Verify Document";
      } else {
        docUploadString = "Upload Document";
      }
      return docUploadString;
    },
    transferSigString(rowData) {
      let transfer_string = "";
      if (rowData && rowData.transfer_status == 2) {
        transfer_string = "Re-Request Customer Sig";
      } else if (rowData && rowData.transfer_status == 1) {
        transfer_string = "Request Customer Sig";
      }
      return transfer_string;
    },
    transferString(rowData) {
      let transfer_string = "";
      if (rowData && rowData.transfer_status == 2) {
        transfer_string = "Re-Transfer to Customer";
      } else if (rowData && rowData.transfer_status == 1) {
        transfer_string = "Transfer to Customer";
      }
      return transfer_string;
    },
    closeSpecificModal(modalId) {
      this.$bvModal.hide(modalId);
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onRowClass(dataItem) {
      //console.log(dataItem);

      if (
        dataItem.prop_status == "Completed" &&
        dataItem.prop_status == "false"
      ) {
        //console.log(dataItem.prop_status);
        //     ? "disabled:true"
        //     : "disabled:false";
        // return dataItem.prop_status
        //     ? "disabled:true"
        //     : "disabled:false";
      }
    },
    editProposalRoute() {
      this.$snotify.clear();
      let payload = {
        q_ref_no: this.active_quote_no,
        proposalId: this.active_proposal_id,
      };

      this.forceProposalUpdate(payload).then((response) => {
        if (response.data.meta.status) {
          this.$router.push({
            name: "new_proposal",
            params: {
              ref_no: this.active_quote_no,
            },
          });
          Vue.nextTick(() => this.$refs.vuetable.refresh());
        }
      });
    },
    onActionClicked(action, data, rowIndex, pdf = "N") {
      let lang = localStorage.getItem("lang");
      this.active_quote_no = data.quote_ref;
      this.active_hash_id = data.hash_id;
      this.active_proposal_id = data.id;
      if (action == "edit-item") {
        if (data.transfer_status == 2) {
          this.$bvModal.show("edit-proposal-modal");
        } else {
          this.$router.push({
            name: "new_proposal",
            params: {
              ref_no: data.quote_ref,
            },
          });
        }
      } else if (pdf == "P") {
        let base = process.env.VUE_APP_BACKEND;
        let url =
          base +
          "/storage/proposal/" +
          data.quote_ref.toString() +
          "_" +
          lang +
          ".pdf";
        window.open(url, "_blank");
      } else if (pdf == "Q") {
        let base = process.env.VUE_APP_BACKEND;
        let url =
          base +
          "/storage/quotation/" +
          data.quote_ref.toString().padStart(8, "0") +
          "_" +
          lang +
          ".pdf";
        window.open(url, "_blank");
      } else if (pdf == "U") {
        this.$router.push({
          name: "push_back",
          params: {
            ref_no: data.quote_ref,
          },
        });
      } else if (action == "add-prop-no") {
        this.prop_id = data.id;
        this.showPropModal(data.id);
      } else if (action == "view-uploaded-docs") {
        let payload = {
          q_ref_no: data.quote_ref,
        };
        this.loadUploadedFiles(payload); // Get Uploaded Documents
        this.showUploadedDocsModal(data.id); // Show Uploaded Document Modal
      } else if (action == "transfer-to-customer") {
        this.$bvModal.show("customer-mobile-request-modal");
        this.customer_mobile = data.mobile_no;
        this.customer_email = data.email;
      } else if (action == "send-customer-signature-request-link") {
        this.getBenefitData(data.quote_ref);
        this.$bvModal.show("send-signature-link-modal");
        this.customer_mobile = data.mobile_no;
        this.customer_email = data.email;
      } else if (action == "agent-signature") {
        this.getBenefitData(data.quote_ref);
        this.$bvModal.show("agent-signature-modal");
      } else if (action == "upload-documents") {
        this.getBenefitData(data.quote_ref);
        let routeData = this.$router.resolve({
          name: "verify_document",
          params: {
            ref_no: data.quote_ref,
          },
        });
        window.open(routeData.href, "_blank");
      }
      else if (action == "view-documents") {
        this.getBenefitData(data.quote_ref);
        let routeData = this.$router.resolve({
          name: "view_document",
          params: {
            ref_no: data.quote_ref,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
    sendCustomerSignatureLink() {
      this.isLoading = true;
      this.$snotify.clear();
      let payload = {
        q_ref_no: this.active_quote_no,
        mobile: this.customer_mobile,
        proposal_id: this.active_proposal_id,
        transfer_type:this.transfer_type,
        email: this.customer_email,
      };

      this.sendCustomerSignatureRequestLink(payload).then((response) => {
        this.isLoading = false;
        if (response.data.meta.status) {
          this.$snotify
            .success(response.data.meta.status_message, "", {
              position: "centerTop",
            })
            .on("destroyed", () => {
              this.$bvModal.hide("send-signature-link-modal");
            });
          Vue.nextTick(() => this.$refs.vuetable.refresh());
        } else {
          this.$refs.customer_signature_link_form.setErrors(
            response.data.meta.errors
          );
        }
      });
    },
    confirmTransferProposal() {
      this.isLoading = true;
      this.$snotify.clear();
      let payload = {
        q_ref_no: this.active_quote_no,
        transfer_status: 2,
        transfer_type:this.transfer_type,
        mobile: this.customer_mobile,
        email: this.customer_email,
      };

      this.transferProposal(payload).then((response) => {
        this.isLoading = false;
        if (response.data.meta.status) {
          this.$snotify
            .success(response.data.meta.status_message, "", {
              position: "centerTop",
            })
            .on("destroyed", () => {
              this.$bvModal.hide("customer-mobile-request-modal");
            });

          Vue.nextTick(() => this.$refs.vuetable.refresh());
        } else {
          this.$refs.customer_mobile_request_form.setErrors(
            response.data.meta.errors
          );
        }
      });
    },
    onFilterSet(filters) {
      //console.log(filters);
      this.moreParams = {
        quote_no: filters.quote_no,
        proposal_no: filters.proposal_no,
        name: filters.name,
        phone_no: filters.phone_no,
        nic: filters.nic,
        status: filters.status,
        date_range :filters.date_range
      };
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onFilterReset() {
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    documentPermissionCheck() {
      return this.checkUserPermissionNew("view-documents");
    },
    viewPermissionCheck() {
      return this.checkUserPermissionNew("view-quote");
    },
    editPermissionCheck() {
      return this.checkUserPermissionNew("edit-quote");
    },
    exLoadingPermissionCheck() {
      return this.checkUserPermissionNew("ex-loading");
    },
    addPropNo(bvModalEvt) {
      this.$snotify.clear();
      let propo_id = this.prop_id;
      let propo_no = this.proposal_no;

      let payload = {
        proposal_id: propo_id,
        proposal_no: propo_no,
      };

      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        bvModalEvt.preventDefault();
        return;
      }

      this.setProposalNumber(payload).then((response) => {
        if (!response.data.meta.status) {
          this.$snotify
            .error("Something went wrong while adding proposal number.Please try again.", "", {
              position: "centerTop",
            });
        }
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      });

      //this.setProposalNumber(payload);
      this.resetModal();
    },
    showPropModal(id) {
      //Show Add new Proposal No
      this.prop_no = id;
      this.$bvModal.show("modal-prop-number-add" + id);
    },
    showUploadedDocsModal(id) {
      //Show Add new Proposal No
      this.prop_no = id;
      this.$bvModal.show("modal-uploaded-docs-view" + id);
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.proposal_no = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
    },

    async onExLoadingClick(id) {
      //console.log(id);
      this.$bvModal.show("modal-center" + id);
      let payload = {
        quote_no: id,
      };
      await this.getOccupationLoading(payload);
    },
  },
  watch: {
    role: function () {
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
  },
};
</script>

<style>
.proposal-view-container{
  width: 150%;
}
.modal-backdrop {
  background-color: #0000001a !important;
}

.modal-content {
  border: 0px solid !important;
}

.tbl-margin-top {
  margin-top: 15px;
}

table th,
.table td {
  padding: 0.2rem 0.75rem !important;
  vertical-align: middle !important;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}

.pagination {
  float: right;
}

.page-link {
  font-size: 12px !important;
}

.tooltip {
  top: 0 !important;
}

/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

/* .status-wrapper {
    width: 100%;
    float: left;
    align-items: center;
    display: flex;
    flex-direction: column;
} */
/* 
.status-sub-wrapper1 {
    margin: 2px;
}

.status-sub-wrapper2 {
    margin: 2px;
} */
</style>

<i18n>
{
  "en": {
    "list_proposal": "Proposal List",
    "prop_no_add_modal_title": "Add Proposal Number ",
    "view_upload_doc_modal_title": " Uploaded Documents"
  },
  "si": {
  "list_proposal": "යෝජනා ලැයිස්තුව",
    "prop_no_add_modal_title": "Add Proposal Number " ,
    "view_upload_doc_modal_title": " Uploaded Documents"
  },
  "ta": {
    "list_proposal": "திட்ட பட்டியல்",
    "prop_no_add_modal_title": "Add Proposal Number ",
    "view_upload_doc_modal_title": " Uploaded Documents"
  }
}
</i18n>
