<!-- Final Details Root Widget -->
<template>
  <div>
    <div class="row">
      <vue-snotify></vue-snotify>
      <!-- Final - Particulars of Previous life Policies/Proposals Question Widget (12) -->
      <div class="card">
        <div class="card-header">
          <i class="ti ti-user"></i>
          <span
            >&nbsp;&nbsp;&nbsp;{{ $t("FinalPrevPoliciesQuestionTitle") }}</span
          >
        </div>
        <div class="card-body box-body">
          <!-- Final - Particulars of Previous life Policies/Proposals Question Widget (12) -->
          <FinalPrevPoliciesQuestion />
        </div>
      </div>
    </div>

    <div class="row" v-if="InfoInsured.product != 'MRTT' && InfoInsured.product != 'Term Plus CIC' ">
      <!-- Final - Fund Type Selection -->
      <div class="card">
        <div class="card-header">
          <i class="ti ti-user"></i>
          <span>&nbsp;&nbsp;&nbsp;{{ $t("FinalFundTypeSelectionTitle") }}</span>
        </div>
        <div class="card-body box-body">
          <!-- Final - Document Upload -->
          <FinalFundTypes />
        </div>
      </div>
    </div>
    
    <div class="row">
      <!-- Final - Document Upload -->
      <!-- <div class="card">
        <div class="card-header">
          <i class="ti ti-user"></i>
          <span>&nbsp;&nbsp;&nbsp;{{ $t("FinalDocumentUploadTitle") }}</span>
        </div> -->
        <div class="card-body box-body">
          <!-- Final - Document Upload -->
          <FinalDocumentUpload />
        </div>
      <!-- </div> -->
    </div>



    <div class="row" v-if="false">
      <!-- Final - Insured Signature -->
      <div class="card">
        <div class="card-header">
          <i class="ti ti-user"></i>
          <span>&nbsp;&nbsp;&nbsp;{{ $t("FinalInsuredSignBoardTitle") }}</span>
        </div>
        <div class="card-body box-body">
          <!-- Final - Insured Signature -->
          <FinalInsuredSignBoard />
        </div>
      </div>
    </div>
    <div class="row" v-if="Number(InfoSpouse.sp_age) > 16 && false">
      <!-- Final - Spouse Signature -->
      <div class="card">
        <div class="card-header">
          <i class="ti ti-user"></i>
          <span>&nbsp;&nbsp;&nbsp;{{ $t("FinalSpouseSignBoardTitle") }}</span>
        </div>
        <div class="card-body box-body">
          <!-- Final - Spouse Signature -->
          <FinalSpouseSignBoard />
        </div>
      </div>
    </div>
    <div class="row" v-if="false">
      <!-- Final - Agent Signature -->
      <div class="card">
        <div class="card-header">
          <i class="ti ti-user"></i>
          <span>&nbsp;&nbsp;&nbsp;{{ $t("FinalAgentSignBoardTitle") }}</span>
        </div>
        <div class="card-body box-body">
          <!-- Final - Agent Signature -->
          <FinalAgentSignBoard />
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { mapState } from "vuex";

import FinalPrevPoliciesQuestion from "@/components/proposal/final/FinalPrevPoliciesQuestion";
import FinalDocumentUpload from "@/components/proposal/final/FinalDocumentUpload";
import FinalFundTypes from "@/components/proposal/final/FinalFundTypes";
import FinalInsuredSignBoard from "@/components/proposal/final/FinalInsuredSignBoard";
import FinalSpouseSignBoard from "@/components/proposal/final/FinalSpouseSignBoard";
import FinalAgentSignBoard from "@/components/proposal/final/FinalAgentSignBoard";

export default {
  name: "benifits-root-widget",
  computed: {
    ...mapState("proposal", ["InfoSpouse" , "InfoInsured"])
  },
  components: {
    FinalPrevPoliciesQuestion,
    FinalFundTypes,
    FinalDocumentUpload,
    FinalInsuredSignBoard,
    FinalSpouseSignBoard,
    FinalAgentSignBoard
  },
  data() {
    return {};
  }
};
</script>

<style scoped>
.insured-pad-left {
  padding-left: 0px !important;
}

.child-pad-right {
  padding-right: 0px !important;
}

@media (max-width: 767px) {
  .col-pad-new {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>

<!-- Language Data for Final Details Root Widget -->
<i18n>
{
  "en": {
    "FinalPrevPoliciesQuestionTitle": "Particulars of Previous Life Policies/Proposals",
    "FinalPaymentMethodQuestionTitle": "Method of Premium Payment",
    "FinalDocumentUploadTitle": "Document Upload",
    "FinalInsuredSignBoardTitle": "Signature of Life to be Assured",
    "FinalSpouseSignBoardTitle": "Signature of the Spouse",
    "FinalAgentSignBoardTitle": "Agent Signature",
    "FinalFundTypeSelectionTitle" : "Investment Fund"
  },
  "si": {
    "FinalPrevPoliciesQuestionTitle": "පෙර ජීවිත ප්‍රතිපත්ති / යෝජනා පිළිබඳ විස්තර",
    "FinalPaymentMethodQuestionTitle": "වාරික ගෙවීමේ ක්‍රමය",
    "FinalDocumentUploadTitle": "ලේඛන උඩුගත කිරීම",
    "FinalInsuredSignBoardTitle": "පාරිභෝගික අත්සන",
    "FinalSpouseSignBoardTitle": "කලත්‍රයාගේ අත්සන",
    "FinalAgentSignBoardTitle": "නියෝජිත අත්සන",
    "FinalFundTypeSelectionTitle" : "Investment Fund"
  },
  "ta": {
    "FinalPrevPoliciesQuestionTitle": "முந்தைய வாழ்க்கை கொள்கைகள் / திட்டங்களின் விவரங்கள்",
    "FinalPaymentMethodQuestionTitle": "பிரீமியம் செலுத்தும் முறை",
    "FinalDocumentUploadTitle": "ஆவண பதிவேற்றம்",
    "FinalInsuredSignBoardTitle": "காப்பீட்டாளரின் கையொப்பம்",
    "FinalSpouseSignBoardTitle": "துணைவரின் கையொப்பம்",
    "FinalAgentSignBoardTitle": "முகவர் கையொப்பம்",
    "FinalFundTypeSelectionTitle" : "Investment Fund"
  }
}
</i18n>
