<!-- Benifit Details Root Widget -->
<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      color="#00F"
    ></loading>
    <div class="row">
      <!-- Benifits & Premium Widget -->
      <div class="card">
        <div class="card-header">
          <i class="ti ti-user"></i>
          <span>&nbsp;&nbsp;&nbsp;{{ $t("BenifitsPremiumTitle") }}</span>
        </div>
        <div class="card-body box-body">
          <!-- Quotation No/Product/Purpose/Frequency/Term/Basic Sum Assured/Total Premium/Basic Anual Premium/Cover Multiple -->
          <BenifitsPremiumWidget />
        </div>
      </div>
    </div>
    <div class="row" v-if="BenefitData.product != 'MRTT'">
      <div class="col-sm-4 col-pad-new insured-pad-left">
        <!-- Additional Benifits - Insured -->
        <div class="card card-height">
          <div class="card-header">
            <i class="ti ti-user"></i>
            <span
              >&nbsp;&nbsp;&nbsp;{{ $t("AdditionalBenifitInsuredTitle") }}</span
            >
          </div>
          <div class="card-body box-body">
            <!-- Benifit/Basic Sum Assured/Premium -->
            <BenifitsAdditionalInsuredWidget />
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-pad-new">
        <!-- Additional Benifits - Spouse -->
        <div class="card card-height">
          <div class="card-header">
            <i class="ti ti-user"></i>
            <span
              >&nbsp;&nbsp;&nbsp;{{ $t("AdditionalBenifitSpouseTitle") }}</span
            >
          </div>
          <div class="card-body box-body">
            <!-- Benifit/Basic Sum Assured/Premium -->
            <BenifitsAdditionalSpouseWidget />
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-pad-new child-pad-right">
        <!-- Additional Benifits - Children -->
        <div class="card card-height">
          <div class="card-header">
            <i class="ti ti-user"></i>
            <span
              >&nbsp;&nbsp;&nbsp;{{ $t("AdditionalBenifitChildTitle") }}</span
            >
          </div>
          <div class="card-body box-body">
            <!-- Benifit/Basic Sum Assured/Premium -->
            <BenifitsAdditionalChildWidget />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import BenifitsPremiumWidget from "@/components/proposal/benifit/BenifitsPremiumWidget";
import BenifitsAdditionalInsuredWidget from "@/components/proposal/benifit/BenifitsAdditionalInsuredWidget";
import BenifitsAdditionalSpouseWidget from "@/components/proposal/benifit/BenifitsAdditionalSpouseWidget";
import BenifitsAdditionalChildWidget from "@/components/proposal/benifit/BenifitsAdditionalChildWidget";

export default {
  name: "benifits-root-widget",
  components: {
    BenifitsPremiumWidget,
    BenifitsAdditionalInsuredWidget,
    BenifitsAdditionalSpouseWidget,
    BenifitsAdditionalChildWidget,
  },
  data() {
    return {
      fullPage:true
    };
  },

  computed: {
    ...mapState("proposal", ["isLoading" , "BenefitData"]),
  },
  async created() {
    if (this.$route.params.ref_no) {
      this.preLoader(true);
      try {
        await this.loadBenefitData();
      } catch (error) {
        this.$snotify
          .error(
            "Please check your internet connectivity or it might be an issue with our server",
            "Error",
            {
              position: "centerTop",
            }
          )
          .on("destroyed", () => {});
      }
      this.preLoader(false);
    } else {
      alert("Error : Invalid quotation reference number");
    }
  },
  methods: {
    ...mapActions("proposal", ["getBenefitData", "preLoader"]),
    async loadBenefitData() {
      await this.getBenefitData(this.$route.params.ref_no);
    },

    showMsgBoxError(errorMsg) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxOk(errorMsg, {
          size: "sm",
          buttonSize: "lg",
          okVariant: "danger",
          headerBgVariant: "danger",
          headerTextVariant: "light",
          bodyBgVariant: "danger",
          bodyTextVariant: "light",
          centered: true,
        })
        .then((value) => {
          this.boxTwo = value;
        })
        .catch((err) => {
          // An error occurred
          alert(err);
        });
    },
  },
};
</script>

<style scoped>
.insured-pad-left {
  padding-left: 0px !important;
}

.child-pad-right {
  padding-right: 0px !important;
}

.card-height {
  height: 41rem;
}

@media (max-width: 767px) {
  .col-pad-new {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>

<!-- Language Data for Benifits Root Widget -->
<i18n>
{
  "en": {
    "BenifitsPremiumTitle": "Premium & Benifits Information",
    "AdditionalBenifitInsuredTitle": "Benifits - Insured",
    "AdditionalBenifitSpouseTitle": "Benifits - Spouse",
    "AdditionalBenifitChildTitle": "Benifits - Children"
  },
  "si": {
    "BenifitsPremiumTitle": "වාරික සහ ප්‍රතිලාභ තොරතුරු",
    "AdditionalBenifitInsuredTitle": "ප්‍රතිලාභ - ජීවිත රක්ෂිතයා",
    "AdditionalBenifitSpouseTitle": "ප්‍රතිලාභ - කලත්‍රයා",
    "AdditionalBenifitChildTitle": "ප්‍රතිලාභ - දරුවන්"
  },
  "ta": {
    "BenifitsPremiumTitle": "கட்டுப்பணம் மற்றும் நன்மைகள் தகவல்",
    "AdditionalBenifitInsuredTitle": "நன்மைகள் - பிரதான வாழ்க்கை",
    "AdditionalBenifitSpouseTitle": "நன்மைகள் - வாழ்க்கைத்துணை",
    "AdditionalBenifitChildTitle": "நன்மைகள் - குழந்தைகள்"
  }
}
</i18n>
