<!-- Show summery here -->
<template>
  <div>
    <Slide @closeMenu="handleCloseMenu" :isOpen="shakingIconAction.flexiLifeOpen" :burgerIcon="false" right width="350">
      <div class="row">
        <div class="col-sm-12">
          <div class="col">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div class="list-group paying-box">
                      <a class="list-group-item visitor paying-box">
                        <h4 v-if="calcSummary.summary_formatted.frequency == 'Q'"
                          class="list-group-item-heading count"
                        >Quarterly</h4>
                         <h4 v-else-if="calcSummary.summary_formatted.frequency == 'M'"
                          class="list-group-item-heading count"
                        >Monthly</h4>
                         <h4 v-if="calcSummary.summary_formatted.frequency == 'H'"
                          class="list-group-item-heading count"
                        >Half Yearly</h4>
                         <h4 v-if="calcSummary.summary_formatted.frequency == 'Y'"
                          class="list-group-item-heading count"
                        >Annualy</h4>
                        <p class="list-group-item-text p-color">Frequency</p>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="list-group">
                      <a class="list-group-item visitor policy-box">
                        <h4
                          class="list-group-item-heading count"
                        >{{calcSummary.summary_formatted.policy_term}}</h4>
                        <p class="list-group-item-text p-color">Policy Term</p>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="col">
            <table class="tbl-width">
              <tbody>
                <tr>
                  <td>
                    <div class="list-group">
                      <a class="list-group-item visitor">
                        <table class="tbl-width">
                          <tbody>
                            <tr>
                              <td>BSA</td>
                              <td class="notify-td">
                                <h6
                                  class="list-group-item-heading count h6"
                                >Rs. {{calcSummary.summary_formatted.sum_assured}}</h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="list-group">
                      <a class="list-group-item visitor">
                        <table class="tbl-width">
                          <tbody>
                            <tr>
                              <td>Riders</td>
                              <td class="notify-td">
                                <h6
                                  class="list-group-item-heading count h6"
                                >Rs. {{calcSummary.summary_formatted.prem_additional_benifits}}</h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="list-group">
                      <a class="list-group-item visitor">
                        <table class="tbl-width">
                          <tbody>
                            <tr>
                              <td>Premium</td>
                              <td class="notify-td">
                                <h6
                                  class="list-group-item-heading count h6"
                                >Rs. {{calcSummary.summary_formatted.total_premium}}</h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="col">
            <table class="tbl-width">
              <tbody>
                <tr>
                  <td>
                    <div class="list-group">
                      <a class="list-group-item premium">
                        <h6 class="list-group-item-heading count h6">Main Life</h6>
                        <p class="list-group-item-text p">Additional Premium Details</p>
                        <table class="tbl-width tbl-font-size">
                          <thead>
                            <tr>
                              <th>Benefit</th>
                              <th class="tbl-header2">S/A (Rs.)</th>
                              <th class="tbl-header2">Pre. (Rs.)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(main_life, index) in calcSummary.member_main"
                              :index="index"
                              :key="index"
                            >
                              <td>{{main_life.rider_code}}</td>
                              <td class="tbl-header2">{{main_life.sum_assured}}</td>
                              <td class="tbl-header2">{{main_life.total_premium}}</td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- Sum at Risk values for Main life --> 
                        <hr>
                        <table class="tbl-width tbl-font-size">
                          <thead>
                            <tr>
                              <th>Sum at Risk Type</th>
                              <th class="tbl-header2">Amt. (Rs.)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="(calcSummary.summary_formatted.sumatrisk_main != 0)">
                              <td>SUM AT RISK</td>
                              <td class="tbl-header2">{{calcSummary.summary_formatted.sumatrisk_main}}</td>
                            </tr>
                            <tr v-if="(calcSummary.summary_formatted.mib2_sumatrisk_main != 0)">
                              <td>MIB-2</td>
                              <td class="tbl-header2">{{calcSummary.summary_formatted.mib2_sumatrisk_main}}</td>
                            </tr>
                            <tr v-if="(calcSummary.summary_formatted.wop_b_sumatrisk_main != 0)">
                              <td>WOP-B</td>
                              <td class="tbl-header2">{{calcSummary.summary_formatted.wop_b_sumatrisk_main}}</td>
                            </tr>
                            <tr v-if="(calcSummary.summary_formatted.wop_r_sumatrisk_main != 0)">
                              <td>WOP-R</td>
                              <td class="tbl-header2">{{calcSummary.summary_formatted.wop_r_sumatrisk_main}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="calcSummary.member_spouse.length">
        <div class="col-sm-12">
          <div class="col">
            <table class="tbl-width">
              <tbody>
                <tr>
                  <td>
                    <div class="list-group">
                      <a class="list-group-item premium">
                        <h6 class="list-group-item-heading count h6">Spouse</h6>
                        <p class="list-group-item-text p">Additional Premium Details</p>
                        <table class="tbl-width tbl-font-size">
                          <thead>
                            <tr>
                              <th>Benefit</th>
                              <th class="tbl-header2">S/A (Rs.)</th>
                              <th class="tbl-header2">Pre. (Rs.)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(spouse_info, index) in calcSummary.member_spouse"
                              :index="index"
                              :key="index"
                            >
                              <td>{{spouse_info.rider_code}}</td>
                              <td class="tbl-header2">{{spouse_info.sum_assured}}</td>
                              <td class="tbl-header2">{{spouse_info.total_premium}}</td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- Sum at Risk values for Spouse --> 
                        <hr>
                        <table class="tbl-width tbl-font-size" v-if="(calcSummary.summary_formatted.sumatrisk_spouse != 0)">
                          <thead>
                            <tr>
                              <th>Sum at Risk Type</th>
                              <th class="tbl-header2">Amt. (Rs.)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>SUM AT RISK</td>
                              <td class="tbl-header2">{{calcSummary.summary_formatted.sumatrisk_spouse}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Children Information Display -->
      <div
        class="row"
        v-for="(children_info, index) in calcSummary.member_child"
        :index="index"
        :key="index"
      >
        <div class="col-sm-12">
          <div class="col">
            <table class="tbl-width">
              <tbody>
                <tr>
                  <td>
                    <div class="list-group">
                      <a class="list-group-item premium">
                        <h6 class="list-group-item-heading count h6">Child {{index+1}}</h6>
                        <p class="list-group-item-text p">Additional Premium Details</p>
                        <table class="tbl-width tbl-font-size">
                          <thead>
                            <tr>
                              <th>Benefit</th>
                              <th class="tbl-header2">S/A (Rs.)</th>
                              <th class="tbl-header2">Pre. (Rs.)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(child_info, index) in children_info"
                              :index="index"
                              :key="index"
                            >
                              <td>{{child_info.rider_code}}</td>
                              <td class="tbl-header2">{{child_info.sum_assured}}</td>
                              <td class="tbl-header2">{{child_info.total_premium}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- full width space -->
      <div class="row" style="height:130px;"></div>
    </Slide>
  </div>
</template>

<script>
import { Slide } from "vue-burger-menu";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("quote_flexi_life", ["calcSummary"]),
    ...mapState("common_actions", ["shakingIconAction"])
  },
  name: "SumView",
  components: {
    Slide
  },
  methods:{
    handleCloseMenu(){
      this.shakingIconAction.shake = false;
      this.shakingIconAction.flexiLifeOpen = false;
    }
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  border: 1px solid red;
}

div.card .card-header {
  background: #0560dd !important; /* fallback for old browsers */
  box-shadow: 0 10px 10px -12px rgba(0, 0, 0, 0.42),
    0 4px 10px 0px rgba(0, 0, 0, 0.12), 0 5px 7px -5px rgba(0, 0, 0, 0.2);
}

.a-tag {
  color: #007bff !important;
  text-decoration: none;
  background-color: transparent;
  margin-left: 30px;
}

.bm-item-list {
  margin-left: 0 !important;
  font-size: 12px !important;
  color: #5d5d5d;
}

.bm-burger-button {
  position: fixed;
}

.tbl-width {
  width: 100%;
}

.tbl-font-size {
  font-size: 12px !important;
}

.benefit-header {
  margin-top: 15px;
  margin-bottom: 8px;
}

.tbl-header {
  text-align: center;
}

.tbl-header2 {
  text-align: right;
}

.notify-td {
  text-align: right;
}

.table-top {
  margin-top: 20px;
}

.tbl-pad {
  margin: 0 10px 0 10px;
}

.tbl-btm {
  margin-bottom: 20px;
}

.policy-box {
  background-color: #1cadb6 !important;
  color: #fff !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.paying-box {
  background-color: #7289da !important;
  color: #fff !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.s-row-top {
  margin-top: 0.7rem;
}

.h6 {
  margin-bottom: 0rem !important;
}

.p {
  font-size: 12px;
}

.bm-burger-button {
  cursor: pointer;
  height: 125px !important;
  left: 18px;
  position: fixed;
  top: 75px !important;
  width: 16px !important;
  /*left: auto;*/
  right: 18px !important;
  z-index: 100000;
}

.fa {
  font-size: 50px;
  text-align: right;
  position: absolute;
  top: 7px;
  right: 27px;
  outline: none;
}
a {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
/* Visitor */
a.visitor i,
.visitor h6.list-group-item-heading {
  color: #faa61a;
}
a.visitor:hover {
  background-color: #faa61a;
}
a.visitor:hover * {
  color: #fff;
}

a.premium i,
.premium h6.list-group-item-heading {
  color: rgb(7, 176, 228);
}
a.Premium:hover {
  background-color: #faa61a;
}
a.Premium:hover * {
  color: #fff;
}

.p-color {
  color: #fff !important;
}
</style>