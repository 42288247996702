<template>
<div class="container-fluid">
    <div class="row row-top">
  
            <div class="col-sm-12">
                <RoleListTable />
            </div>
        </div>
</div>
</template>

<script>
import RoleListTable from "@/components/common/layout/RoleListTable.vue";

export default {
    name: "UserList",
    components: {
        RoleListTable
    }
}
</script>

<style scoped>
.search-btn {
    padding-top: 30px;
}
</style>
