<template>
  <b-container>
    <div class="wrapper">
      <vue-snotify></vue-snotify>
      <div>
        <b-form action="#" @submit.prevent="login" class="form-signin">
          <h2 class="form-signin-heading text-center">{{ $t("title") }}</h2>
          <input
            type="text"
            class="form-control"
            name="email"
            v-model="email"
            placeholder="User ID"
          />
          <!-- <div class="invalid-feedback">{{ errors.first("email") }}</div> -->
          <div class="invalid-feedback"></div>
          <input
            type="password"
            class="form-control"
            name="password"
            v-model="password"
            placeholder="Password"
          />
          <!-- <div class="invalid-feedback">{{ errors.first("password") }}</div> -->
          <div class="invalid-feedback"></div>
          <!-- <label class="checkbox">
            <b-form-checkbox
              id="checkbox-1"
              v-model="status"
              name="checkbox-1"
              value="accepted"
              unchecked-value="not_accepted"
            >{{ $t('remember_me')}}</b-form-checkbox>
          </label>-->
          <div class="locale-changer" @change="setLocale($event)">
            <b-select v-model="$i18n.locale">
              <option
                v-for="(lang, i) in langs"
                :key="`Lang${i}`"
                :value="lang"
              >
                {{ lang }}
              </option>
            </b-select>
          </div>
          <br />
          <b-button class="btn btn-lg btn-primary btn-block" type="submit"
            >Login</b-button
          >
        </b-form>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SnotifyPosition } from "vue-snotify";
import router from "@/router";

export default {
  name: "login",
  data() {
    return {
      msg: "Login",
      email: "",
      password: "",
      status: "",
      langs: ["en"],
    };
  },
  mounted() {
    if (this.access_token !== null) {
      //router.push("/welcome");
      router.push({
        name: "welcome",
      });
    }
  },
  computed: {
    ...mapState("login", ["access_token"]),
  },
  methods: {
    ...mapActions("login", ["getToken"]),
    async login() {
      const userdata = {
        email: this.email,
        password: this.password,
        remember_me: true,
      };

      await this.getToken(userdata);
      if (this.access_token === null) {
        this.$snotify.error("Wrong Username or Password", {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          position: SnotifyPosition.rightTop,
        });
      }
    },

    setLocale(lng) {
      this.$root.$i18n.locale = lng.target.value;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
body {
  background: #eee !important;
}

.wrapper {
  margin-top: 80px;
  margin-bottom: 80px;
}

.form-signin {
  max-width: 380px;
  padding: 15px 35px 45px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);

  .form-signin-heading,
  .checkbox {
    margin-bottom: 30px;
  }

  .checkbox {
    font-weight: normal;
  }

  .form-control {
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;

    &:focus {
      z-index: 2;
    }
  }

  input[type="text"] {
    margin-bottom: -1px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  input[type="password"] {
    margin-bottom: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>


<i18n>
{
  "en": {
    "title": "Login",
    "remember_me" : "Remember me"
  },
  "si": {
    "title": "ඇතුල් වන්න",
     "remember_me" : "මාව මතක තබා ගන්න"
  }
}
</i18n>
