<!-- Info - Details of Special Risks Question Widget (11) -->
<template>
  <ValidationObserver ref="special_risk_row">
    <div>
      <div
        class="row quest-row"
        v-for="(question, index) in DynamicQuestions.SpecialRiskQuestionList"
        :key="index"
      >
        <div class="col">
          <div class="card-outline">
            <div class="card-body">
              <div class="row">
                <!-- Question -->
                <div class="col quest">{{ $t(question.quest_id) }}</div>
              </div>
              <div>
                <div class="row">
                  <!-- Insured -->
                  <div class="col-md-6 col-sm-6">
                    <div class="card-style">
                      <div class="media">
                        <div class="media-left">
                          <img
                            v-if="InfoInsured.gender == 'Male'"
                            class="media-object card-img"
                            src="@/assets/img/spouse-hubby.png"
                          />
                          <img
                            v-else
                            class="media-object card-img"
                            src="@/assets/img/spouse-wife.png"
                          />
                        </div>

                        <div class="media-body">
                          <a href="#">
                            <h5 class="media-heading">&nbsp;</h5>
                          </a>
                          <div class="members">
                            <strong>{{ $t("Insured") }}</strong>
                          </div>
                          <div class="btn-part" style="margin-right: 1px">
                            <div class="btn-part">
                              <div class="toggle-button toggle-button--aava">
                                <input
                                  :id="question.quest_id + 'Insured'"
                                  type="checkbox"
                                  true-value="Y"
                                  false-value="N"
                                  :checked="question.quest_answer_in"
                                  v-model="question.quest_answer_in"
                                  @change="sendChangedData()"
                                />
                                <label
                                  :for="question.quest_id + 'Insured'"
                                  data-on-text="YES"
                                  data-off-text="NO"
                                  class="toggle-lable"
                                ></label>
                                <div class="toggle-button__icon"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="text-area-pad"
                        v-show="question.quest_answer_in == 'Y'"
                      >
                        <ValidationProvider
                          immediate
                          :name="'Reason '"
                          :vid="question.quest_id + 'ReasonInsured'"
                          :rules="`${
                            question.quest_answer_in == 'Y'
                              ? 'required|min:3|max:120'
                              : ''
                          }`"
                        >
                          <div slot-scope="{ errors }">
                            <span class="required-mark">*</span>
                            <b-form-textarea
                              :id="question.quest_id + 'ReasonInsured'"
                              placeholder="Enter your reason..."
                              rows="2"
                              v-model="question.quest_yes_reason_in"
                              v-uppercase
                              @change="sendChangedData()"
                            ></b-form-textarea>
                            <p class="p-error">{{ errors[0] }}</p>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <!-- Spouse -->
                  <div
                    class="col-md-6 col-sm-6"
                    v-if="InfoInsured.has_spouse == 'Yes'"
                  >
                    <div class="card-style">
                      <div class="media">
                        <div class="media-left">
                          <img
                            v-if="InfoInsured.sp_gender == 'Male'"
                            class="media-object card-img"
                            src="@/assets/img/spouse-hubby.png"
                          />
                          <img
                            v-else
                            class="media-object card-img"
                            src="@/assets/img/spouse-wife.png"
                          />
                        </div>

                        <div class="media-body">
                          <a href="#">
                            <h5 class="media-heading">&nbsp;</h5>
                          </a>
                          <div class="members">
                            <strong>{{ $t("Spouse") }}</strong>
                          </div>
                          <div class="btn-part">
                            <div class="toggle-button toggle-button--aava">
                              <input
                                :id="question.quest_id + 'Spouse'"
                                type="checkbox"
                                true-value="Y"
                                false-value="N"
                                :checked="question.quest_answer_sp"
                                v-model="question.quest_answer_sp"
                                @change="sendChangedData()"
                              />
                              <label
                                :for="question.quest_id + 'Spouse'"
                                data-on-text="YES"
                                data-off-text="NO"
                                class="toggle-lable"
                              ></label>
                              <div class="toggle-button__icon"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="text-area-pad"
                        v-show="question.quest_answer_sp == 'Y'"
                      >
                        <ValidationProvider
                          immediate
                          :name="'Reason '"
                          :vid="question.quest_id + 'ReasonSpouse'"
                          :rules="`${
                            question.quest_answer_sp == 'Y'
                              ? 'required|min:3|max:120'
                              : ''
                          }`"
                        >
                          <div slot-scope="{ errors }">
                            <span class="required-mark">*</span>
                            <b-form-textarea
                              :id="question.quest_id + 'ReasonSpouse'"
                              placeholder="Enter your reason..."
                              rows="2"
                              v-model="question.quest_yes_reason_sp"
                              v-uppercase
                              @change="sendChangedData()"
                            ></b-form-textarea>
                            <p class="p-error">{{ errors[0] }}</p>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { throttle } from "lodash";
export default {
  name: "info-special-risk-question-widget",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState("proposal", ["DynamicQuestions", "InfoInsured"]),
  },
  methods: {
    ...mapActions("proposal", ["draftProposalData"]),
    sendChangedData: throttle(async function () {
      await this.draftProposalData();
    }, process.env.VUE_APP_TIMEOUT_SEND_CHANGED_DATA),
  },
};
</script>

<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}
.card-outline {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-clip: border-box !important;
  border: 1px solid #92d127 !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}

.reason-top {
  margin-top: 10px;
}

.quest-row {
  margin-bottom: 15px;
}

.quest {
  font-size: 12px;
  font-weight: 700;
}
.toggle-lable {
  margin-bottom: 0rem !important;
}

.text-area-pad {
  padding: 10px;
}

.card-style {
  display: block;
  height: auto;
  margin-top: 15px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-radius: calc(0.25rem - 1px);
}
.card-img {
  height: 50px;
  width: 50px;
}
.media-heading {
  margin-top: -19px;
  color: #444;
}
.media-heading:hover,
a:link {
  color: #00c853;
  text-decoration: none;
}
.members {
  margin-top: 0px;
  color: #f39721;
  float: left;
  margin-left: 20px;
}
.btn-part {
  display: inline-block;
  margin-right: 8px;
  float: right;
}
.badge {
  display: inline-block;
  background: #00c853;
  float: right;
  padding: 7px;
  border-radius: 50px;
  margin: 10px;
}

/* --- toggle switch ---- */

.section {
  text-align: center;
  height: 100%;
  margin: 0 auto;
  padding: 6em 0;
}
.section__title {
  font-size: 1.1em;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #fff;
  margin-bottom: 3em;
}
.section--aava {
  background: #2994b2;
}
.section--tuuli {
  background: #474744;
}
.section--vesi {
  background: #54567a;
}
.section--sade {
  background: #2d4659;
}
.section--ilma {
  background: #095062;
}
.section--tuli {
  background: #068b78;
}

.toggle-button {
  margin: 5px 0px;
}

/*
 * Toggle button styles
 */
.toggle-button {
  position: relative;
  display: inline-block;
  color: #fff;
}
.toggle-button label {
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  text-align: left;
}

.toggle-button input {
  display: none;
}
.toggle-button__icon {
  cursor: pointer;
  pointer-events: none;
}
.toggle-button__icon:before,
.toggle-button__icon:after {
  content: "";
  position: absolute;
  top: 45%;
  left: 35%;
  transition: 0.2s ease-out;
}

.toggle-button--aava label {
  height: 34px;
  line-height: 36px;
  transition: all 0.2s;
  border-radius: 0.2rem;
}
.toggle-button--aava label:before,
.toggle-button--aava label:after {
  position: absolute;
  right: 1.5rem;
  transition: all 0.2s 0.1s ease-out;
}
.toggle-button--aava label:before {
  content: attr(data-on-text);
}
.toggle-button--aava label:after {
  content: attr(data-off-text);
}

.toggle-button--aava input[type="checkbox"] + label {
  width: 100px;
  background: #ff5335;
}

@media (max-width: 768px) {
  .toggle-button--aava input[type="checkbox"] + label {
    width: 88px;
  }
}

.toggle-button--aava input[type="checkbox"] + label:before {
  opacity: 0;
  transform: translate(0, 20px);
}
.toggle-button--aava input[type="checkbox"] + label:after {
  opacity: 1;
  transform: translate(0, 0);
}

.toggle-button--aava input[type="checkbox"]:checked ~ label {
  width: 100px;
  background: #61b136;
}

@media (max-width: 768px) {
  .toggle-button--aava input[type="checkbox"]:checked ~ label {
    width: 88px;
  }
}

.toggle-button--aava input[type="checkbox"]:checked ~ label:before {
  opacity: 1;
  transform: translate(0, 0);
}
.toggle-button--aava input[type="checkbox"]:checked ~ label:after {
  opacity: 0;
  transform: translate(0, -20px);
}

.toggle-button--aava
  input[type="checkbox"]:checked
  ~ .toggle-button__icon:before {
  transform: translate(-10%, 100%) rotate(45deg);
  width: 13.66667px;
}

.toggle-button--aava
  input[type="checkbox"]:checked
  ~ .toggle-button__icon:after {
  transform: translate(30%) rotate(-45deg);
}

.toggle-button--aava .toggle-button__icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 36px;
  width: 38px;
}
.toggle-button--aava .toggle-button__icon:before,
.toggle-button--aava .toggle-button__icon:after {
  height: 3px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.toggle-button--aava .toggle-button__icon:before {
  width: 20px;
  transform: rotate(45deg);
}
.toggle-button--aava .toggle-button__icon:after {
  width: 20px;
  transform: rotate(-45deg);
}
</style>

<!-- Language Data for Info - Details of Special Risks Question Widget (11) -->
<i18n>
{
  "en": {
    "SpcRiskCriminalProceeding": "a. Have you been convicted of any criminal proceeding instituted and/or pending against you ?",
    "SpcRiskLifeThreat": "b. Do you have or had any kind of threat on your life ?",
    "SpcRiskFamilyThreat": "c. Have your immediate family members ever had any kind of threats ? If 'YES' Please give details",
    "SpcRiskAlcohol": "d. Do you consume alcohol? If so, Please state quantity consumed for a week ?",
    "SpcRiskSmoke": "e. Do you smoke ? If so, Please state how many sticks per day ?",
  
    "Insured": "Insured",
    "Spouse": "Spouse"
  },
  "si": {
    "SpcRiskCriminalProceeding": "a. Have you been convicted of any criminal proceeding instituted and/or pending against you ?",
    "SpcRiskLifeThreat": "b. Do you have or had any kind of threat on your life ?",
    "SpcRiskFamilyThreat": "c. Have your immediate family members ever had any kind of threats ? If 'YES' Please give details",
    "SpcRiskAlcohol": "d. Do you consume alcohol? If so, Please state quantity consumed for a week ?",
    "SpcRiskSmoke": "e. Do you smoke ? If so, Please state how many sticks per day ?",

    "Insured": "රක්ෂිතයා",
    "Spouse": "කලත්‍රයා"
  },
  "ta": {
   "SpcRiskCriminalProceeding": "a. Have you been convicted of any criminal proceeding instituted and/or pending against you ?",
    "SpcRiskLifeThreat": "b. Do you have or had any kind of threat on your life ?",
    "SpcRiskFamilyThreat": "c. Have your immediate family members ever had any kind of threats ? If 'YES' Please give details",
    "SpcRiskAlcohol": "d. Do you consume alcohol? If so, Please state quantity consumed for a week ?",
    "SpcRiskSmoke": "e. Do you smoke ? If so, Please state how many sticks per day ?",
  
    "Insured": "வாழ்க்கை",
    "Spouse": "வாழ்க்கைத்துணை"
  }
}
</i18n>
