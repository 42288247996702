<template>
  <div class="row bg justify-content-around">
    <!-- Header Text -->
    <div class="col-md-11 col-sm-11 col-11">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-lg-12 white-text mb-1 mt-7 text-center">
          <h2>Exceptionally outstanding solutions for your peace of mind</h2>
        </div>
      </div>
    </div>

    <div
      class="col-md-11 col-sm-11 col-11 mb-10"
      v-show="hasPermission('search-quote')"
    >
      <div class="row">
        <div class="col-md-3 col-sm-4 col-xs-12 plr-10">
          <b-card title="" :img-src="img.platinum_logo" img-alt="Image" img-top>
            <b-card-text class="custom-text-style">
              <p class="bold-500 text-center">
                Secure family's future, accumulate value, diverse investment options.
              </p>
            </b-card-text>
            <button class="button_width btn btn-primary btn-block button-custom " @click="goToEvents('welcome_platinum')">
              Get Quote
            </button>
          </b-card>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 plr-10">
          <b-card title="" :img-src="img.familyProtection_logo" img-alt="Image" img-top>
            <b-card-text class="custom-text-style">
              <p class="bold-500 text-center">
                Comprehensive coverage with hospitalization & critical Illness cover
              </p>
            </b-card-text>
            <button class="button_width btn btn-primary btn-block button-custom " @click="goToEvents('welcome_surakshitha')">
              Get Quote
            </button>
          </b-card>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 plr-10">
          <b-card title="" :img-src="img.platinumPlus_logo" img-alt="Image" img-top>
            <b-card-text class="custom-text-style">
              <p class="bold-500 text-center">
                Ultimate protection with cashless facility &  overseas medical cover
              </p>
            </b-card-text>
            <button class="button_width btn btn-primary btn-block button-custom " @click="goToEvents('welcome_platinum_plus')">
              Get Quote
            </button>
          </b-card>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 plr-10">
          <b-card title="" :img-src="img.adyapana_logo" img-alt="Image" img-top>
            <b-card-text class="custom-text-style">
              <p class="bold-500 text-center">
                Secure funds for childs higher education with customizable plans.
              </p>
            </b-card-text>
            <button class="button_width btn btn-primary btn-block button-custom "  @click="goToEvents('welcome_adyapana_plus')">
              Get Quote
            </button>
          </b-card>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 plr-10">
          <b-card title="" :img-src="img.retirement_logo" img-alt="Image" img-top>
            <b-card-text class="custom-text-style min-h">
              <p class="bold-500 text-center">
                Secure happy, healthy, wealthy retirement. Flexible, forward-thinking
              </p>
            </b-card-text>
            <button class="button_width btn btn-primary btn-block button-custom "  @click="goToEvents('welcome_deergayu')">
              Get Quote
            </button>
          </b-card>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 plr-10">
          <b-card title="" :img-src="img.flexiLife_logo" img-alt="Image" img-top>
            <b-card-text class="custom-text-style">
              <p class="bold-500 text-center">
                Choose payment terms, protection level &  investment fund options
              </p>
            </b-card-text>
            <button class="button_width btn btn-primary btn-block button-custom "  @click="goToEvents('welcome_flexi_life')">
              Get Quote
            </button>
          </b-card>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 plr-10">
          <b-card title="" :img-src="img.premHealth_logo" img-alt="Image" img-top>
            <b-card-text class="custom-text-style">
              <p class="bold-500 text-center">
                Up to LKR 20M coverage/person, cashless settlement, global health treatment
              </p>
            </b-card-text>
            <button class="button_width btn btn-primary btn-block button-custom" @click="goToEvents('welcome_health_plus')">
              Get Quote
            </button>
          </b-card>
        </div>

        <div class="col-md-3 col-sm-4 col-xs-12 plr-10" v-if="false">
          <b-card title="" :img-src="img.loanProtect_logo" img-alt="Image" img-top>
            <b-card-text class="custom-text-style">
              <p class="bold-500 text-center">
                Safeguard loan obligations providing relief in case of borrower's death.
              </p>
            </b-card-text>
            <button class="button_width btn btn-primary btn-block button-custom"  @click="goToEvents('welcome_mrtt')">
              Get Quote
            </button>
          </b-card>
        </div>

        <div class="col-md-3 col-sm-4 col-xs-12 plr-10" v-if="true">
          <b-card title="" :img-src="img.pureLife_logo" img-alt="Image" img-top>
            <b-card-text class="custom-text-style">
              <p class="bold-500 text-center">
                Comprehensive coverage for life and critical illnesses
              </p>
            </b-card-text>
            <button class="button_width btn btn-primary btn-block button-custom"  @click="goToEvents('welcome_term_plus_cic')">
              Get Quote
            </button>
          </b-card>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-2 plr-10">
          <b-card title="" :img-src="img.pureLife_logo" img-alt="Image" img-top>
            <b-card-text class="custom-text-style">
              <p class="bold-500 text-center">
                Comprehensive coverage for life and critical illnesses
              </p>
            </b-card-text>
            <button class="button_width btn btn-primary btn-block button-custom"  @click="goToEvents('welcome_term_plus_cic')">
              Get Quote
            </button>
          </b-card>
        </div>
        <div class="col-md-2 plr-10">
          <b-card title="" :img-src="img.premHealth_logo" img-alt="Image" img-top>
            <b-card-text class="custom-text-style">
              <p class="bold-500 text-center">
                Up to LKR 20M coverage/person, cashless settlement, global health treatment.
              </p>
            </b-card-text>
            <button class="button_width btn btn-primary btn-block button-custom" @click="goToEvents('welcome_health_plus')">
              Get Quote
            </button>
          </b-card>
        </div>
        <div class="col-md-2 plr-10">
          <b-card title="" :img-src="img.loanProtect_logo" img-alt="Image" img-top>
            <b-card-text class="custom-text-style">
              <p class="bold-500 text-center">
                Safeguard loan obligations providing relief in case of borrower's death.
              </p>
            </b-card-text>
            <button class="button_width btn btn-primary btn-block button-custom"  @click="goToEvents('welcome_mrtt')">
              Get Quote
            </button>
          </b-card>
        </div>
      </div> -->
    </div>

    <!-- <div
      class="col-md-11 col-sm-11 col-11"
      v-show="hasPermission('search-quote')"
    >
     <div class="row card_arrangment">
        <div
          class="col-md-4 col-sm-12 col-lg-2 my_col"
          @click="goToEvents('welcome_platinum')"
        >
          <div class="row sm_pad">
            <div class="col logo_platinum"></div>
          </div>
        </div>
      </div> 
    </div> -->
  </div>
</template>

<script>
import commonMixin from "@/mixins/common";
export default {
  name: "Welcome",
  mixins: [commonMixin],
  methods: {
    goToEvents: function (package_name) {
      location.href = "#/" + process.env.VUE_APP_VERSION + "/" + package_name;
    },
  },
  data() {
    return {
      img: {
        platinum_logo: require("../../../assets/img/new_logos/platinum_logo.webp"),
        familyProtection_logo: require("../../../assets/img/new_logos/surakshitha_logo.webp"),
        platinumPlus_logo: require("../../../assets/img/new_logos/platinum_plus_logo.webp"),
        adyapana_logo: require("../../../assets/img/new_logos/child_education_logo.webp"),
        retirement_logo: require("../../../assets/img/new_logos/retirement_logo.webp"),
        flexiLife_logo: require("../../../assets/img/new_logos/flexi_life_logo.webp"),
        pureLife_logo: require("../../../assets/img/new_logos/term_plus_logo.webp"),
        premHealth_logo: require("../../../assets/img/new_logos/health_plus.webp"),
        loanProtect_logo: require("../../../assets/img/new_logos/mrtt.webp"),
      },
    };
  },
};
</script>

<style scoped>
.btn-outline-primary {
  color: #92d127;
  border-color: #92d127;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #92d127;
  border-color: #92d127;
}

.card_arrangment {
  padding-bottom: 100px;
}

.bg {
  /*https://www.pexels.com/*/
  background: url("~@/assets/img/amana_bg.jpg");
  min-height: 94vh;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  /* Center and scale the image nicely */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.my_col {
  padding: 25px;
}

.sm_pad {
  border: 5px solid #92d127;
  border-radius: 26px;
  background: #ffffff;
  padding: 5px;
}

.sm_pad:hover {
  border: 5px solid #5d666a;
  transform: scale(1.1);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.logo_surakshitha {
  background: url("~@/assets/img/new_logos/surakshitha_logo.jpg");
  height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 180px;
}

.logo_platinum {
  background: url("~@/assets/img/new_logos/platinum_logo.jpg");
  height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 180px;
}

.logo_platinum_plus {
  background: url("~@/assets/img/new_logos/platinum_plus_logo.jpg");
  height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 180px;
}

.logo_adhyapana_plus {
  background: url("~@/assets/img/new_logos/child_education_logo.jpg");
  height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 180px;
}

.logo_dheegayu_life {
  background: url("~@/assets/img/new_logos/retirement_logo.jpg");
  height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 180px;
}

.logo_flexi_life {
  background: url("~@/assets/img/new_logos/flexi_life_logo.jpg");
  height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 180px;
}

.logo_term_plus_cic {
  background: url("~@/assets/img/new_logos/term_plus_logo.jpg");
  height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 180px;
}

.logo_health_plus {
  background: url("~@/assets/img/new_logos/health_plus.jpg");
  height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 180px;
}

.logo_mrtt {
  background: url("~@/assets/img/new_logos/mrtt.jpg");
  height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 180px;
}

.welcome_header {
  color: #000000;
  font-size: 32px;
  /* text-shadow: 2px 2px 4px #000000; */
  margin-top: 70px;
}
div.card {
    margin-top: 0px !important;
}



@media (max-width: 767px) {
  .logo_surakshitha,
  .logo_platinum,
  .logo_platinum_plus,
  .logo_adhyapana_plus,
  .logo_dheegayu_life {
    background-size: 300px;
  }

  .welcome_header {
    display: none;
  }

  .mobile_view {
    padding-top: 60px;
  }
}
.card-img-top {
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 10px !important;
}
.plr-10{
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.button-custom{
  padding-left: 0.375rem !important;
  padding-right: 0.375rem !important;
  
}
@media (max-width: 1024px) {
  .custom-text-style{
    min-height: 125px !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
}

.mt-7{
  margin-top: 65px !important;
}
</style>
