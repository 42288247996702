<!-- create/modify customer quotations -->
<template>
  <div>
    <Header />
    <LeftNav />
    <main id="page-wrap">
      <vue-snotify></vue-snotify>
      <b-container fluid>
        <BranchInfoWidget />
      </b-container>
    </main>
    <Footer />
  </div>
</template>

<script>
import BranchInfoWidget from "@/components/branch/BranchInfoWidget.vue";
import Header from "@/components/common/layout/AppHeader.vue";
import Footer from "@/components/common/layout/AppFooter.vue";
import LeftNav from "@/components/common/layout/AppLeftNav";
// import LeadCreateButton from "@/components/lead/LeadCreateButton.vue";
//import { SnotifyPosition } from "vue-snotify";
import { mapState, mapActions } from "vuex";
export default {
  name: "NewBranch",
  components: {
    BranchInfoWidget,
    Header,
    Footer,
    LeftNav
    // LeadCreateButton
  },
 
  computed: {
    ...mapState("branch", ["brancheInfoData"])
  },

  mounted() {},

  methods: {
    ...mapActions("branch", ["addBranch"])
  }
};
</script>

<style scoped>
div.card .card-header {
  background: #ff660d !important; /* fallback for old browsers */
  box-shadow: 0 10px 10px -12px rgba(0, 0, 0, 0.42),
    0 4px 10px 0px rgba(0, 0, 0, 0.12), 0 5px 7px -5px rgba(0, 0, 0, 0.2);
}
</style>
