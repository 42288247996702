<!-- Mrtt Cover Details Widget-->
<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header header-font">
            <font-awesome-icon icon="users" />
            <span>&nbsp;{{ $t("widgetHeader") }}</span>
          </div>
          <div class="card-body box-body">
            <div class="row">
              <div class="col-sm-12 top-margin">
                <!-- Cover details row 1 Cover Amount , Period of repayment , Years including grace period , Years -->
                <ValidationObserver ref="coverDetailsSection">
                  <!-- Personal details row 1 End -->
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="lbl_name_of_fi">{{ $t("langNameOfFI") }} <span
                            class="required-mark">*</span></label>
                        <ValidationProvider immediate name="Name Of Financial Institute" rules="required">
                          <div slot-scope="{ errors }">
                            <!-- <v-select class="select2" style="width: 100%" v-validate="'required'"
                              name="Name Of Financial Institute" :options="fInstitutionList"
                              v-model.trim="additonalInfoData.name_of_fi" :reduce="(val) => val.code"
                              @input="sendChangedData()"></v-select> -->

                              <input type="text" class="form-control" id="txtNameOfFI" name="Name Of Financial Institute"
                              placeholder="Name Of Financial Institute"
                              v-model.trim="additonalInfoData.name_of_fi" v-uppercase @change="sendChangedData()" />

                            <p class="p-error">{{ errors[0] }}</p>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="lbl_address_of_fi">{{ $t("langAddressOfFI") }} <span
                            class="required-mark">*</span></label>
                        <ValidationProvider immediate name="Address Of Financial Institute" rules="required">
                          <div slot-scope="{ errors }">
                            <input type="text" class="form-control" id="txtAddressOfFI" name="AddressOfFI"
                              placeholder="Address Of Financial Institute"
                              v-model.trim="additonalInfoData.address_of_fi" v-uppercase @change="sendChangedData()" />
                            <p class="p-error">{{ errors[0] }}</p>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <label for="mrtt_cover_option">{{ $t("langCoverOption") }} <span
                          class="required-mark">*</span></label>
                      <b-form-group v-slot="{ ariaDescribedby }">
                        <ValidationProvider immediate name="Cover" rules="required">
                          <div slot-scope="{ errors }">
                            <b-form-radio-group id="mrtt_cover_option"
                              v-model="additonalInfoData.mrtt_cover_option_type" :options="cover_options"
                              :aria-describedby="ariaDescribedby" name="radio-options"></b-form-radio-group>
                            <p class="p-error">{{ errors[0] }}</p>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </div>

                  </div>
                  <!-- Personal details row 2 End -->
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState, mapActions } from "vuex";
import helpMixin from "@/mixins/filters";
import { SnotifyPosition } from "vue-snotify";
import { throttle } from "lodash";

export default {
  name: "mrtt-quotation-cover-details-widget",
  data() {
    return {
      cover_options: [
        { text: 'Death Only', value: 1 },
        { text: 'Death & PTD', value: 2 }
      ],
    };
  },
  computed: {
    ...mapState("quote_mrtt", ["fInstitutionList", "additonalInfoData", "flash",
      "minflash"])
  },
  mixins: [helpMixin],
  methods: {
    ...mapActions("quote_mrtt", ["getFInstitutionList", "addChangedData"]),
    sendChangedData: throttle(async function () {
      this.$snotify.clear();
      /** Loading (HTML snotify component) */
      this.$snotify.html(
        `<div class="snotifyToast__title"><b>Calculating...</b></div>
            <div class="snotifyToast__body"> Mode Premium Amount</div>
            <div class="snotify-icon snotify-icon--async"></div> `,
        {
          timeout: stop,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          position: SnotifyPosition.centerTop,
          type: "async",
        }
      );
      return this.getChangeDataResponse()
        .then(
          function (response) {
            this.$snotify.clear();
            if (response.status && response.type == undefined) {
              /** Success Message  */
              this.$snotify.success("Mode Premium Amount", response.error, {
                timeout: stop,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop,
              });
            } else {
              this.$snotify.clear();
              /** Error Message  */
              this.$snotify.error(response.error, "Error", {
                timeout: stop,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop,
              });
            }
          }.bind(this)
        )
        .catch(function (error) {
          this.$snotify.clear();
          /** Exception Message */
          this.$snotify.error(error, "Error", {
            timeout: stop,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            position: SnotifyPosition.centerTop,
          });
        });
    }, process.env.VUE_APP_QUOT_TIMEOUT_SEND_CHANGED_DATA),

    async getChangeDataResponse() {
      await this.addChangedData();
      if (this.minflash.status) {
        return this.minflash;
      } else {
        return this.flash;
      }
    },
  },
  mounted() {
    this.getFInstitutionList()
  }
};
</script>

<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

/* -- togle switch ---*/

.toggleswitchg input:checked+label {
  background: #e9ecef !important;
  color: #575e65 !important;
  font-weight: 400 !important;
  border: 1px solid #ced4da !important;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked+label {
  background: #039fec;
  color: #fff;
  font-weight: 600;
  border: 1px solid #039fec;
}
</style>

<!-- Language Data for Personal Details Insured Widget -->
<i18n>
{
  "en": {    
    "langCoverAmount": "Cover Amount",
    "langPeriodOfRepayment": "Period Of Repayment",
    "langGracePeriod": "Grace Period",
    "langRepaymentRate": "Repayment Rate",
    "langNameOfFI":"Name Of Financial Institute",
    "langAddressOfFI": "Address Of Financial Institute",
    "langRelationshipWithJointBorrower": "Relationship With Joint Borrower",
    "langCoverOption": "Cover",
    "widgetHeader": "Cover Details"
  },
  "si": {
    "langCoverAmount": "Cover Amount",
    "langPeriodOfRepayment": "Period Of Repayment",
    "langGracePeriod": "Grace Period",
    "langRepaymentRate": "Repayment Rate",
    "langNameOfFI":"Name Of Financial Institute",
    "langAddressOfFI": "Address Of Financial Institute",
    "langRelationshipWithJointBorrower": "Relationship With Joint Borrower",
    "langCoverOption": "Cover",
    "widgetHeader": "Cover Details"
  },
  "ta": {
    "langCoverAmount": "Cover Amount",
    "langPeriodOfRepayment": "Period Of Repayment",
    "langGracePeriod": "Grace Period",
    "langRepaymentRate": "Repayment Rate",
    "langNameOfFI":"Name Of Financial Institute",
    "langAddressOfFI": "Address Of Financial Institute",
    "langRelationshipWithJointBorrower": "Relationship With Joint Borrower",
    "langCoverOption": "Cover",
    "widgetHeader": "Cover Details"
  }
}
</i18n>
