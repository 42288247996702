/* Tryonics Authentication - Login 
        Removed Reason : ebuddy integration(http://<Application URL>/#/?agentCode=E660&logToken=ebuddy20190820093801)
        Removed by     : Menuka Walpitagamage
        Removed on     :[20190821]
*/
import router from "@/router";
import { http } from "@/config/http";
import { isEmpty, includes } from "lodash";

const namespaced = true;

const state = {
  access_token: localStorage.getItem("access_token") || null,
  full_name: null,
  user_id: null,
  user_role: null,
};

const getters = {
  loggedIn() {
    return localStorage.getItem("access_token") != null;
  },
  checkUserPermissionSearch() {
    let permValue = "search-quote";
    if (!isEmpty(state.userPermissions)) {
      return includes(state.userPermissions, permValue);
    } else if (!isEmpty(state.access_token)) {
      //let jwtSecretKey = "Dm9gw86ahGzbD9or8mYjE44iGywWw11cCerHvAgK";
      let payload = JSON.parse(atob(state.access_token.split(".")[1]));
      //console.log(payload);
      if (payload.scopes != undefined && !isEmpty(payload.scopes)) {
        state.userPermissions = payload.scopes;
        return includes(payload.scopes, permValue);
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  checkUserPermissionCreate() {
    let permValue = "create-quote";
    if (!isEmpty(state.userPermissions)) {
      return includes(state.userPermissions, permValue);
    } else if (!isEmpty(state.access_token)) {
      //let jwtSecretKey = "Dm9gw86ahGzbD9or8mYjE44iGywWw11cCerHvAgK";
      let payload = JSON.parse(atob(state.access_token.split(".")[1]));
      //console.log(payload);
      if (payload.scopes != undefined && !isEmpty(payload.scopes)) {
        state.userPermissions = payload.scopes;
        return includes(payload.scopes, permValue);
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  checkUserPermissionEdit() {
    let permValue = "edit-quote";
    if (!isEmpty(state.userPermissions)) {
      return includes(state.userPermissions, permValue);
    } else if (!isEmpty(state.access_token)) {
      //let jwtSecretKey = "Dm9gw86ahGzbD9or8mYjE44iGywWw11cCerHvAgK";
      let payload = JSON.parse(atob(state.access_token.split(".")[1]));
      //console.log(payload);
      if (payload.scopes != undefined && !isEmpty(payload.scopes)) {
        state.userPermissions = payload.scopes;
        return includes(payload.scopes, permValue);
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  checkUserPermissionViewBackend() {
    let permValue = "view-backend";
    if (!isEmpty(state.userPermissions)) {
      return includes(state.userPermissions, permValue);
    } else if (!isEmpty(state.access_token)) {
      //let jwtSecretKey = "Dm9gw86ahGzbD9or8mYjE44iGywWw11cCerHvAgK";
      let payload = JSON.parse(atob(state.access_token.split(".")[1]));
      //console.log(payload);
      if (payload.scopes != undefined && !isEmpty(payload.scopes)) {
        state.userPermissions = payload.scopes;
        return includes(payload.scopes, permValue);
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  checkUserPermissionNew: (state) => (permValue) => {
    if (!isEmpty(state.userPermissions)) {
      return includes(state.userPermissions, permValue);
    } else if (!isEmpty(state.access_token)) {
      //let jwtSecretKey = "Dm9gw86ahGzbD9or8mYjE44iGywWw11cCerHvAgK";
      let payload = JSON.parse(atob(state.access_token.split(".")[1]));
      //console.log(payload);
      if (payload.scopes != undefined && !isEmpty(payload.scopes)) {
        state.userPermissions = payload.scopes;
        return includes(payload.scopes, permValue);
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  checkUserPermission: (state) => (permission) => {
    if (permission) {
      var base64Url = state.access_token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      let payload = JSON.parse(jsonPayload);
      let permissions = payload.scopes;

      if (permissions.indexOf(permission) != -1) {
        return true;
      } else {
        return false;
      }

    } else {
      return false;
    }
  }

};

const mutations = {
  doLogin(state, response) {
    const token = response.authentication_data.access_token;
    const username = response.user_data.full_name;
    const user_role = response.user_data.user_role;
    localStorage.setItem("access_token", token);
    localStorage.setItem("username", username);
    state.access_token = token;
    state.full_name = response.user_data.full_name;
    state.user_id = response.user_data.user_id;
    state.user_role = user_role;
    localStorage.setItem("user_role", user_role);
    //router.push("/welcome");

    router.push({
      name: "welcome",
    });
    //router.go("/welcome");
  },
  doLogout(state) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("username");
    localStorage.removeItem("user_role");
    state.access_token = null;
    state.full_name = null;
    state.user_id = null;
    state.user_role = null;
    router.push("/");
  },

  clearLogin(state) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("username");
    localStorage.removeItem("user_role");
    state.access_token = null;
    state.full_name = null;
    state.user_id = null;
    state.user_role = null;
  },

  doLoginAs(state, response) {

    localStorage.removeItem("access_token");
    localStorage.removeItem("username");
    localStorage.removeItem("user_role");
    state.access_token = null;
    state.full_name = null;
    state.user_id = null;
    state.user_role = null;


    const token = response.authentication_data.access_token;
    const username = response.user_data.full_name;
    const user_role = response.user_data.user_role;
    localStorage.setItem("access_token", token);
    localStorage.setItem("username", username);
    state.access_token = token;
    state.full_name = response.user_data.full_name;
    state.user_id = response.user_data.user_id;
    state.user_role = user_role;
    localStorage.setItem("user_role", user_role);
    //router.push("/welcome");
    router.push({
      name: "welcome",
    });
  },
};

const actions = {
  async getToken({ commit }, userdata) {
    let agent_code = userdata.email;//AGENT CODE
    let password = userdata.password;
    let remember_me = userdata.remember_me;

    await http
      .post("auth/login", null, {
        params: {
          agent_code,
          password,
          remember_me
        }
      })
      .then(response => {
        if (response.data.meta.status == "true") {
          commit("doLogin", response.data);
        } else {
          //alert("wrong password");
        }
      })
      .catch(error => {
        alert(error);
      });
  },

  async destoryToken({ commit }) {
    await http
      .post("auth/logout", null, {})
      .then(response => {
        if (response.data.meta.status == "true") {
          commit("doLogout");
        } else {
          commit("doLogout");
          //alert("Error");
        }
      })
      .catch(error => {
        alert(error);
      });
  },


  //Login As User
  async loginAsUser({ commit }, data) {
    //Parameters - User ID
    await http
      .post("users/" + data.id + "/login_as", data)
      .then(response => {
        if (response.data.meta.status == "true") {
          commit("doLogin", response.data);
        }

        commit(
          "showNotification",
          {
            title: "Success",
            msg: "Successfully logged in as " + response.data.user_data.full_name,
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        alert(error);
      });
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
