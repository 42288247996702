<!-- Final - Agent Signature Widget -->
<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      color="#00F"
    ></loading>
    <div class="padding-without-card">
      <div class="card-title">
        <span class="p-error p-3">Note:answers will use in the proposal pdf.</span>
      </div>
      <div class="card-body">
        <ValidationObserver ref="finalAgentSignatureValidate">
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                immediate
                name="propAgentQues1"
              >
                <div slot-scope="{ errors }">
                  <label>
                    Is there any apparent signs, deformity or ailment affecting suitability of proposer for cover? If so, give details.
                  </label>
                  <textarea class="form-control v-uppercase" v-model="InfoInsured.proposal_agent_ques_1" v-uppercase></textarea>
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-12">
              <ValidationProvider
                immediate
                name="propAgentQues2"
              >
                <div slot-scope="{ errors }">
                  <label>
                    Any other details which you think is important to this proposal?
                  </label>
                  <textarea class="form-control v-uppercase" v-model="InfoInsured.proposal_agent_ques_2" v-uppercase></textarea>
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
    
    
    <div class="col-12 alert alert-success mt-3">Agent Signature</div>
    <div class="row">
      <div class="col-6 mt-2">
        <VueSignaturePad
          id="signature"
          width="350px"
          height="250px"
          ref="signaturePad"
          :options="{
            onBegin: () => {
              $refs.signaturePad.resizeCanvas();
            },
          }"
        />
      </div>
      <div class="col-6 mt-2">
        <img
          v-show="InfoInsured.sig_data_agent"
          :src="InfoInsured.sig_data_agent"
          width="350px"
          height="250px"
          alt="Agent Signature"
        />
      </div>
    </div>
    <div class="row">
      <div
        class="btn-group button-space"
        role="group"
        aria-label="Basic example"
      >
        <button class="btn btn-outline-primary" @click="save">Save</button>
        <button class="btn btn-outline-secondary" @click="undo">Undo</button>
        <button class="btn btn-outline-secondary" @click="clear">Clear</button>
      </div>
    </div>
    <div class="row"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "final-signature-widget",
  data() {
    return {
      fullPage:true,
      isLoading:false,
    };
  },
  computed: {
    ...mapState("proposal", ["InfoInsured", "ProposalID", "QuoteRefNo"]),
  },
  methods: {
    ...mapActions("proposal", ["updateAgentSignature"]),
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        this.isLoading = true;
        let params = {
          signature: data,
          proposalId: this.ProposalID,
          quoteRefNo: this.QuoteRefNo,
          propAgentQues1: this.InfoInsured.proposal_agent_ques_1.toUpperCase(),
          propAgentQues2: this.InfoInsured.proposal_agent_ques_2.toUpperCase()
        };
        this.updateAgentSignature(params).then((response) => {
          this.isLoading = false;
          if (response.data.meta.status) {
            this.$snotify.success(response.data.meta.status_message, "", {
              position: "centerTop",
            });
            this.InfoInsured.sig_data_agent = response.data.meta.data;
            this.$parent.$parent.$parent.onFilterReset();
            this.$parent.$parent.$parent.closeSpecificModal(
              "agent-signature-modal"
            );
          } else {
            this.$snotify.error(response.data.meta.status_message, "", {
              position: "centerTop",
            });
            this.$refs.finalAgentSignatureValidate.setErrors(response.data.meta.errors);
          }
        });
      }
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
  },
};
</script>

<style scoped>
.card-outline {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-clip: border-box !important;
  border: 1px solid #92d127 !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}
.button-space {
  margin-top: 5px;
  margin-left: 20px;
}
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>

<!-- Language Data for Final - Signature Widget -->
<i18n>
{
  "en": {
    "Insured": "Insured",
    "Spouse": "Spouse"
  },
  "si": {
    "Insured": "ජීවිත රක්ෂිතයා",
    "Spouse": "කලත්‍රයා"
  },
  "ta": {
    "Insured": "பிரதான வாழ்க்கை",
    "Spouse": "வாழ்க்கைத்துணை"
  }
}
</i18n>
