<!--Lead Information Capture Widget  -->
<template>
  <div class="row row-top" style="scroll: none; margin-bottom: -50px">
    <div class="col-sm-12 top-margin">
      <div class="card">
        <div class="card-header header-font">
          <font-awesome-icon icon="heartbeat" />
          <span>&nbsp;{{ $t("branchHeaderMsg") }}</span>
        </div>

        <div class="card-body">
          <ValidationObserver ref="branch_add_from">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="branch_name">
                    {{ $t("branchName") }}
                    <span style="color: red">*</span>
                  </label>
                  <ValidationProvider
                    name="branch_name"
                    rules="required|minmax:3,30"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        ref="BranchName"
                        class="form-control"
                        id="branchHeaderMsg"
                        name="BranchName"
                        placeholder="Branch Name"
                        v-model="brancheInfoData.branch_name"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_branch_code">
                    {{ $t("branchCode") }}
                    <span style="color: red">*</span>
                  </label>
                  <ValidationProvider name="branch_code">
                    <div slot-scope="{ errors }">
                      <input
                        ref="BranchCode"
                        type="text"
                        class="form-control"
                        id="branchCode"
                        name="branchCode"
                        placeholder="Branch Code"
                        v-model="brancheInfoData.branch_code"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_gender">
                    {{ $t("active") }}
                  </label>
                  <ValidationProvider name="status">
                    <div slot-scope="{ errors }">
                      <div class="">
                        <input
                          id="status"
                          checked="true"
                          name="status"
                          ref="status"
                          type="checkbox"
                          value="1"
                          v-model="brancheInfoData.status"
                        />
                      </div>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </ValidationObserver>

          <b-row>
            <div class="col-sm-10"></div>
            <div class="col-sm-2">
              <button
                type="submit"
                ref="createBtn"
                class="btn btn btn-success btn-sm btn-block btn_height"
                v-on:click="onSubmit"
                v-show="isSave"
                :disabled="isDisabled"
              >
                &nbsp;Create Branch
              </button>
              <button
                type="submit"
                ref="updateBtn"
                class="btn btn btn-success btn-md btn-block btn_height"
                v-on:click="onSubmit"
                v-show="!isSave && !isDisabled"
                :disabled="isDisabled"
              >
                &nbsp;Edit Branch
              </button>

              <button
                type="submit"
                ref="updateBtn"
                class="btn btn btn-secondary btn-md btn-block btn_height"
                v-on:click="onCancel"
              >
                &nbsp;Cancel
              </button>
            </div>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";
// import { SnotifyPosition } from "vue-snotify";

//moment datepicker

Vue.use(require("vue-moment"));
// import Datepicker from "vue-moment-datepicker";

export default {
  name: "branch-info-widget",

  data: function () {
    return {
      isSave: true,
      user_role: localStorage.getItem("user_role"),
    };
  },
  components: {
    // Datepicker
  },
  async created() {
    if (this.$route.name == "branches_edit") {
      this.isDisabled = null;
    }
    if (this.$route.name == "branches_add") {
      this.isDisabled = null;
    }
    if (this.$route.params.ref_no) {
      await this.loadBranchInfo();
    } else {
      await this.clearData();
    }
  },

  computed: {
    ...mapState("branch", ["brancheInfoData", "flash", "minflash"]), // assuming you are using namespaced modules
  },

  mounted() {
    if (this.$route.params.ref_no) {
      this.isSave = false;
    }
  },

  methods: {
    ...mapActions("branch", [
      "addBranch",
      "getBranch",
      "updateBranch",
      "clearStateData",
    ]),

    async onSubmit() {
      if (this.$route.params.ref_no) {
        // Update Existing Lead
        //this.updateBranch(this.$route.params.ref_no);
        this.updateBranch(this.$route.params.ref_no).then((response) => {
          if (response.data.meta.status == "true") {
            this.$router.push({
              name: "branches_list",
            });
          } else {
            this.$refs.branch_add_from.setErrors(response.data.meta.errors);
          }
        });
      } else {
        // Add New Lead
        this.addBranch().then((response) => {
          if (response.data.meta.status == "true") {
            this.$router.push({
              name: "branches_list",
            });
          } else {
            this.$refs.branch_add_from.setErrors(response.data.meta.errors);
          }
        });

        //this.$router.push("/branches_list");
      }
    },
    async onCancel() {
      //this.$router.push("/branches_list");
      this.$router.push({
        name: "branches_list",
      });
    },

    async loadBranchInfo() {
      await this.getBranch(this.$route.params.ref_no);
    },
    async clearData() {
      await this.clearStateData();
    },
  },
};
</script>
<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

.datep > div > input {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 12px;
  color: #495057;
  padding-left: 5px;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #556065 !important;
  border-bottom: 0px solid #06a990 !important;
  color: #fff !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #06a990 !important;
  border-radius: 0.25rem;
}

.snotify {
  display: block;
  position: fixed;
  width: 99% !important;
  z-index: 9999;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: none;
}
/*-- toggle switch for Main Life Frequency ----*/

.togglefreq label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.togglefreq input:checked,
.togglefreq input:not(:checked) {
  display: none;
}

.togglefreq input:checked + label {
  background: #92d127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92d127;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked + label {
  background: #92d127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92d127;
}

.gen-btn {
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .btn-sm,
  .btn-group-sm > .btn {
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    line-height: 1.5;
  }
}

@media (max-width: 768px) {
  .top-margin {
    margin-top: 60px;
  }
}
</style>

<!-- Language Data for LeadInfoWidget-->
<i18n>
{
  "en": {
    "branchHeaderMsg": "Branch Information",
    "branchName": "Branch Name",
    "branchCode": "Branch Code",
    "status": "Status",
    "active" : "Active"
  },
  "si": {
      "branchHeaderMsg": "Branch Information",
      "branchName": "Branch Name",
      "branchCode": "Branch Code",
      "status": "Status",
      "active" : "Active"
  },
  "ta": {
    "branchHeaderMsg": "Branch Information",
    "branchName": "Branch Name",
    "branchCode": "Branch Code",
    "status": "Status",
    "active" : "Active"

  }
}
</i18n>
