<!-- Final - Document Upload Widget -->
<template>
  <div class>
    <vue-snotify></vue-snotify>
    <div class="card">
      <div class="card-header">
       
      </div>
      <div class="">
        <div class="row">
          <div class="col-md-10 offset-md-1" v-html="viewData">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
//import FileUpload from "vue-upload-component";
export default {
  name: "view-proposal-view-widget",
  computed: {

  },
  components: {
  },
  mounted() {
    if (this.$route.params.ref_no) {
      let params = {
        quoteRefNo: this.$route.params.ref_no,
        lang: localStorage.getItem("lang"),
      };
      this.loadProposalDataViewByHashId(params).then((response) => {
        this.viewData = response.data;
      });
    }
  },
  methods: {
    ...mapActions("proposal", [
      "loadProposalDataViewByHashId",
    ])
  },

  data() {
    return {
      viewData: ""
    };
  },
};
</script>

<style scoped>
.fa-bold {
  font-weight: bold;
}

.card-outline {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-clip: border-box !important;
  border: 1px solid #92d127 !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}

.example-multiple label.btn {
  margin-bottom: 0 !important;
  margin-right: 1rem !important;
}

.example-multiple .upload {
  margin-bottom: 1rem !important;
}
</style>

<!-- Language Data for Final - Document Upload Widget -->
<i18n>
{
  "en": {
    "AreYouInGoodHealthQuestion": "Are you in good health ? If 'No', Give details",
    "Insured": "Insured",
    "Spouse": "Spouse",
    "FinalDocumentUploadTitle": "Document Upload",
    "prop_no_add_modal_title": "Add Proposal Number "
  },
  "si": {
    "AreYouInGoodHealthQuestion": "Are you in good health ?",
    "Insured": "ජීවිත රක්ෂිතයා",
    "Spouse": "කලත්‍රයා",
    "FinalDocumentUploadTitle": "ලේඛන උඩුගත කිරීම",
    "prop_no_add_modal_title": "Add Proposal Number "
  },
  "ta": {
    "AreYouInGoodHealthQuestion": "Are you in good health ?",
    "Insured": "பிரதான வாழ்க்கை",
    "Spouse": "வாழ்க்கைத்துணை",
    "FinalDocumentUploadTitle": "ஆவண பதிவேற்றம்",
    "prop_no_add_modal_title": "Add Proposal Number "
  }
}
</i18n>
