<template>
 <ValidationObserver ref="finalFundType">
  <div>
    <div class="row">
      <div class="col">
        <div class="card-outline">
          <div class="card-body">
            <div class="row"></div>
            <div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="card-style">
                    <div class="media">
                      <div class="media-left">
                        <img
                          v-if="InfoInsured.gender == 'Male'"
                          class="media-object card-img"
                          src="@/assets/img/spouse-hubby.png"
                        />
                        <img
                          v-else
                          class="media-object card-img"
                          src="@/assets/img/spouse-wife.png"
                        />
                      </div>

                      <div class="media-body">
                        <a href="#">
                          <h5 class="media-heading">&nbsp;</h5>
                        </a>
                        <div class="members">
                          <strong>{{ $t("Insured") }}</strong>
                        </div>
                      </div>
                    </div>

                    <div class="row text-area-pad">
                      <div class="col-sm-6 text-input">
                        <div class="form-group">
                          <label for="fund_type">{{ $t("Status") }} <span class="required-mark">*</span> </label>
                          <ValidationProvider
                            immediate
                            name="Fund Type"
                            rules="required"
                            vid="fundtype"
                          >
                            <div slot-scope="{ errors }">
                              <select
                                id="fund_type"
                                class="form-control"
                                v-model="InfoInsured.fund_type"
                                @change="sendChangedData()"
                              >
                                <option value="PROTECTED MULTIPLE FUND">Protected Multiple Fund</option>
                                <option v-show="ProductType != 'Health Plus'" value="GROWTH MULTIPLE FUND">Growth Multiple Fund</option>
                                <option v-show="ProductType != 'Health Plus' && ProductType != 'Platinum Plus'" value="STABLE MULTIPLE FUND">Stable Multiple Fund</option>
                                <option v-show="ProductType != 'Health Plus' && ProductType != 'Platinum Plus'" value="VOLATILE MULTIPLE FUND">Volatile Multiple Fund</option>
                                <option v-show="ProductType != 'Health Plus' && ProductType != 'Platinum Plus'" value="GOLD INVESTMENT FUND">Gold Investment Fund</option>
                              </select>
                              <p class="p-error">{{ errors[0] }}</p>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </ValidationObserver>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { throttle } from "lodash";
export default {
  name: "info-family-info-question-widget",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState("proposal", ["InfoInsured" , "ProductType"]),
  },
  methods: {
    ...mapActions("proposal", ["draftProposalData"]),
    sendChangedData: throttle(async function () {
      //const validationFF = await this.$refs.finalFundType.validate();
          await this.draftProposalData();
     
    }, process.env.VUE_APP_TIMEOUT_SEND_CHANGED_DATA),
  },
};
</script>

<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

.card-outline {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-clip: border-box !important;
  border: 1px solid #92D127 !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}

.hr-top {
  margin-top: 0.12rem !important;
}

.quest {
  font-size: 12px;
  font-weight: 700;
}

.toggle-lable {
  margin-bottom: 0rem !important;
}

.text-area-pad {
  padding: 10px;
}

.card-style {
  display: block;
  height: auto;
  margin-top: 15px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-radius: calc(0.25rem - 1px);
}
.card-img {
  height: 50px;
  width: 50px;
}
.media-heading {
  margin-top: -19px;
  color: #444;
}
.media-heading:hover,
a:link {
  color: #00c853;
  text-decoration: none;
}
.members {
  margin-top: 0px;
  color: #f39721;
  float: left;
  margin-left: 20px;
}
.btn-part {
  display: inline-block;
  margin-right: 8px;
  float: right;
}

.text-input {
  padding: 0px 25px 0px 25px;
}

.textarea-input-top {
  margin-top: 10px;
}

@media (max-width: 767px) {
  .text-input-top {
    margin-top: 10px;
  }
}

/* --- toggle switch ---- */

.section {
  text-align: center;
  height: 100%;
  margin: 0 auto;
  padding: 6em 0;
}
.section__title {
  font-size: 1.1em;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #fff;
  margin-bottom: 3em;
}
.section--aava {
  background: #2994b2;
}
.section--tuuli {
  background: #474744;
}
.section--vesi {
  background: #54567a;
}
.section--sade {
  background: #2d4659;
}
.section--ilma {
  background: #095062;
}
.section--tuli {
  background: #068b78;
}

.toggle-button {
  margin: 5px 0px;
}

/*
 * Toggle button styles
 */
.toggle-button {
  position: relative;
  display: inline-block;
  color: #fff;
}
.toggle-button label {
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  text-align: left;
}

.toggle-button input {
  display: none;
}
.toggle-button__icon {
  cursor: pointer;
  pointer-events: none;
}
.toggle-button__icon:before,
.toggle-button__icon:after {
  content: "";
  position: absolute;
  top: 45%;
  left: 35%;
  transition: 0.2s ease-out;
}

.toggle-button--aava label {
  height: 34px;
  line-height: 36px;
  transition: all 0.2s;
  border-radius: 0.2rem;
}
.toggle-button--aava label:before,
.toggle-button--aava label:after {
  position: absolute;
  right: 1.5rem;
  transition: all 0.2s 0.1s ease-out;
}
.toggle-button--aava label:before {
  content: attr(data-on-text);
}
.toggle-button--aava label:after {
  content: attr(data-off-text);
}

.toggle-button--aava input[type="checkbox"] + label {
  width: 100px;
  background: #ff5335;
}

@media (max-width: 768px) {
  .toggle-button--aava input[type="checkbox"] + label {
    width: 88px;
  }
}

.toggle-button--aava input[type="checkbox"] + label:before {
  opacity: 0;
  transform: translate(0, 20px);
}
.toggle-button--aava input[type="checkbox"] + label:after {
  opacity: 1;
  transform: translate(0, 0);
}

.toggle-button--aava input[type="checkbox"]:checked ~ label {
  width: 100px;
  background: #61b136;
}

@media (max-width: 768px) {
  .toggle-button--aava input[type="checkbox"]:checked ~ label {
    width: 88px;
  }
}

.toggle-button--aava input[type="checkbox"]:checked ~ label:before {
  opacity: 1;
  transform: translate(0, 0);
}
.toggle-button--aava input[type="checkbox"]:checked ~ label:after {
  opacity: 0;
  transform: translate(0, -20px);
}

.toggle-button--aava
  input[type="checkbox"]:checked
  ~ .toggle-button__icon:before {
  transform: translate(-10%, 100%) rotate(45deg);
  width: 13.66667px;
}

.toggle-button--aava
  input[type="checkbox"]:checked
  ~ .toggle-button__icon:after {
  transform: translate(30%) rotate(-45deg);
}

.toggle-button--aava .toggle-button__icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 36px;
  width: 38px;
}
.toggle-button--aava .toggle-button__icon:before,
.toggle-button--aava .toggle-button__icon:after {
  height: 3px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.toggle-button--aava .toggle-button__icon:before {
  width: 20px;
  transform: rotate(45deg);
}
.toggle-button--aava .toggle-button__icon:after {
  width: 20px;
  transform: rotate(-45deg);
}
</style>

<!-- Language Data for Info - Family Information of the Life to be Assured and Spouse Question Widget (08) -->
<i18n>
{
  "en": {
    "Status": "Fund Type",
    "Insured": "Insured",
    "Spouse": "Spouse"
  },
  "si": {
    "Status": "Fund Type",
    "Insured": "රක්ෂිතයා",
    "Spouse": "කලත්‍රයා"
  },
  "ta": {
    "Status": "Fund Type",
    "Insured": "வாழ்க்கை",
    "Spouse": "வாழ்க்கைத்துணை"
  }
}
</i18n>
