export default {
  methods: {
    thousandSeparator(value = 0) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    premPaymentMethod(value = "") {
      var mode = "";
      switch (value) {
        case "M":
          mode = "Monthly";
          break;
        case "Q":
          mode = "Quarterly";
          break;
        case "H":
          mode = "Half Yearly";
          break;
        case "Y":
          mode = "Yearly";
          break;
        default:
          mode = "";
      }
      return mode;
    }
  }
};
