import { render, staticRenderFns } from "./FinalDetailsWidget.vue?vue&type=template&id=5d0d6831&scoped=true"
import script from "./FinalDetailsWidget.vue?vue&type=script&lang=js"
export * from "./FinalDetailsWidget.vue?vue&type=script&lang=js"
import style0 from "./FinalDetailsWidget.vue?vue&type=style&index=0&id=5d0d6831&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d0d6831",
  null
  
)

/* custom blocks */
import block0 from "./FinalDetailsWidget.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports