<!-- Health - Good Health Question Widget (03) -->
<template>
<ValidationObserver ref="health_good_question_row">
    <div>
        <div class="row" v-for="(question, index) in PresentStateHealthQuestions" :key="index">
            <div class="col">
                <div class="card-outline">
                    <div class="card-body">
                        <div class="row">
                            <div class="col quest">
                                {{ $t(question.quest_id) }}
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6">
                                    <div class="card-style">
                                        <div class="media">
                                            <div class="media-left">
                                                <img v-if="InfoInsured.gender == 'Male'" class="media-object card-img" src="@/assets/img/spouse-hubby.png" />
                                                <img v-else class="media-object card-img" src="@/assets/img/spouse-wife.png" />
                                            </div>

                                            <div class="media-body">
                                                <a href="#">
                                                    <h5 class="media-heading">&nbsp;</h5>
                                                </a>
                                                <div class="members">
                                                    <strong>{{ $t("Insured") }}</strong>
                                                </div>
                                                <div class="btn-part" style="margin-right: 1px">
                                                    <div class="btn-part">
                                                        <div class="toggle-button toggle-button--aava">
                                                            <input :id="question.quest_id + 'Insured'" type="checkbox" true-value="Y" false-value="N" :checked="question.quest_answer_in" v-model="question.quest_answer_in" @change="sendChangedData()" />
                                                            <label :for="question.quest_id + 'Insured'" data-on-text="YES" data-off-text="NO" class="toggle-lable"></label>
                                                            <div class="toggle-button__icon"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="text-area-pad" v-show="question.quest_answer_in == 'Y'">
                                                <ValidationProvider :name="'Reason '" :vid="question.quest_id + 'ReasonInsured'" :rules="`${
                              question.quest_answer_in == 'Y'
                                ? 'required|min:3|max:120'
                                : ''
                            }|alpha_spaces`">
                                                    <div slot-scope="{ errors }">
                                                        <span class="required-mark">*</span>
                                                        <b-form-textarea :id="question.quest_id + 'ReasonInsured'" placeholder="Enter your reason..." rows="2" v-model="question.quest_yes_reason_in" @change="sendChangedData()" class="text-uppercase"></b-form-textarea>
                                                        <p class="p-error">{{ errors[0] }}</p>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row text-area-pad">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <!-- <label for="date">{{ $t("height") }} <span class="required-mark">*</span></label>
                                                <ValidationProvider name="Insured Height" rules="required|numeric|minmax_value:90,220">
                                                    <div slot-scope="{ errors }">
                                                        <input id="height" type="text" class="form-control" placeholder="Centimeter" v-model="question.quest_height_in" @change="sendChangedData()" />
                                                        <p class="p-error">{{ errors[0] }}</p>
                                                    </div>
                                                </ValidationProvider> -->
                                                <div class="card">
                                                    <div class="card-header">
                                                        <label for="date">{{ $t("height") }} <span class="required-mark">*</span></label>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <label for="date">{{ $t("height_feets") }} <span class="required-mark">*</span></label>
                                                                <ValidationProvider name="Insured Height(ft)" rules="required|numeric|minmax_value:2,8">
                                                                    <div slot-scope="{ errors }">
                                                                        <input id="height_feets" type="text" class="form-control" placeholder="Feets" v-model="question.quest_height_in_feets" @change="sendChangedData()" />
                                                                        <p class="p-error">{{ errors[0] }}</p>
                                                                    </div>
                                                                </ValidationProvider>   
                                                            </div>
                                                            <!-- Height feets -->

                                                            <div class="col-sm-6">
                                                                <!-- Height inches -->    
                                                                <label for="date">{{ $t("height_inches") }} <span class="required-mark">*</span></label>
                                                                <ValidationProvider name="Insured Height(in)" rules="required|numeric|minmax_value:0,11">
                                                                    <div slot-scope="{ errors }">
                                                                        <input id="height_inches" type="text" class="form-control" placeholder="Inches" v-model="question.quest_height_in_inches" @change="sendChangedData()" />
                                                                        <p class="p-error">{{ errors[0] }}</p>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <label for="date">Weight <span class="required-mark">*</span></label>
                                                    </div>
                                                    <div class="card-body">
                                                        <label for="date">(Kg) <span class="required-mark">*</span></label>
                                                        <ValidationProvider name="Insured Weight" rules="required|numeric|minmax_value:20,400">
                                                            <div slot-scope="{ errors }">
                                                                <input id="weight" type="text" class="form-control" placeholder="Kg" v-model="question.quest_weight_in" @change="sendChangedData()" />
                                                                <p class="p-error">{{ errors[0] }}</p>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-6" v-if="InfoInsured.has_spouse == 'Yes'">
                                    <div class="card-style">
                                        <div class="media">
                                            <div class="media-left">
                                                <img v-if="InfoInsured.sp_gender == 'Male'" class="media-object card-img" src="@/assets/img/spouse-hubby.png" />
                                                <img v-else class="media-object card-img" src="@/assets/img/spouse-wife.png" />
                                            </div>

                                            <div class="media-body">
                                                <a href="#">
                                                    <h5 class="media-heading">&nbsp;</h5>
                                                </a>
                                                <div class="members">
                                                    <strong>{{ $t("Spouse") }}</strong>
                                                </div>
                                                <div class="btn-part">
                                                    <div class="toggle-button toggle-button--aava">
                                                        <input :id="question.quest_id + 'Spouse'" type="checkbox" true-value="Y" false-value="N" :checked="question.quest_answer_sp" v-model="question.quest_answer_sp" @change="sendChangedData()" />
                                                        <label :for="question.quest_id + 'Spouse'" data-on-text="YES" data-off-text="NO" class="toggle-lable"></label>
                                                        <div class="toggle-button__icon"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="text-area-pad" v-show="question.quest_answer_sp == 'Y'">
                                                <ValidationProvider :name="'Reason '" :vid="question.quest_id+ 'ReasonSpouse'" :rules="`${
                              question.quest_answer_sp == 'Y'
                                ? 'required|min:3|max:120'
                                : ''
                            }`">
                                                    <div slot-scope="{ errors }">
                                                        <span class="required-mark">*</span>
                                                        <b-form-textarea :id="question.quest_id + 'ReasonSpouse'" placeholder="Enter your reason..." rows="2" v-model="question.quest_yes_reason_sp" @change="sendChangedData()" class="text-uppercase"></b-form-textarea>
                                                        <p class="p-error">{{ errors[0] }}</p>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row text-area-pad">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <!-- <label for="date">{{ $t("height") }} <span class="required-mark">*</span></label>
                                                <ValidationProvider name="Spouse Height" rules="required|numeric|minmax_value:90,220">
                                                    <div slot-scope="{ errors }">
                                                        <input id="height" type="text" class="form-control" placeholder="Centimeter" v-model="question.quest_height_sp" @change="sendChangedData()" />
                                                        <p class="p-error">{{ errors[0] }}</p>
                                                    </div>
                                                </ValidationProvider> -->
                                                <div class="card">
                                                    <div class="card-header">
                                                        <label for="date">{{ $t("height") }} <span class="required-mark">*</span></label>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <label for="date">{{ $t("height_feets") }} <span class="required-mark">*</span></label>
                                                                <ValidationProvider name="Spouse Height" rules="required|numeric|minmax_value:2,8">
                                                                    <div slot-scope="{ errors }">
                                                                        <input id="height_feets" type="text" class="form-control" placeholder="Feets" v-model="question.quest_height_sp_feets" @change="sendChangedData()" />
                                                                        <p class="p-error">{{ errors[0] }}</p>
                                                                    </div>
                                                                </ValidationProvider>   
                                                            </div>
                                                            <!-- Height feets -->

                                                            <div class="col-sm-6">
                                                                <!-- Height inches -->    
                                                                <label for="date">{{ $t("height_inches") }} <span class="required-mark">*</span></label>
                                                                <ValidationProvider name="Insured Height" rules="required|numeric|minmax_value:0,11">
                                                                    <div slot-scope="{ errors }">
                                                                        <input id="height_inches" type="text" class="form-control" placeholder="Inches" v-model="question.quest_height_sp_inches" @change="sendChangedData()" />
                                                                        <p class="p-error">{{ errors[0] }}</p>
                                                                    </div>
                                                                </ValidationProvider>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <label for="date">Weight <span class="required-mark">*</span></label>    
                                                    </div>
                                                    <div class="card-body">
                                                        <label for="date">(Kg) <span class="required-mark">*</span></label>
                                                        <ValidationProvider name="Spouse Weight" rules="required|numeric|minmax_value:20,400">
                                                            <div slot-scope="{ errors }">
                                                                <input id="weight" type="text" class="form-control" placeholder="Kg" v-model="question.quest_weight_sp" @change="sendChangedData()" />
                                                                <p class="p-error">{{ errors[0] }}</p>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>   
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ValidationObserver>
</template>

<script>
import {
    mapState,
    mapActions
} from "vuex";
import {
    throttle
} from "lodash";
export default {
    name: "health-good-health-question-widget",
    components: {},
    data() {
        return {};
    },
    computed: {
        ...mapState("proposal", ["PresentStateHealthQuestions", "InfoInsured"]),
    },
    methods: {
        ...mapActions("proposal", ["draftProposalData"]),
        sendChangedData: throttle(async function () {
            await this.draftProposalData();
        }, process.env.VUE_APP_TIMEOUT_SEND_CHANGED_DATA),
    },
};
</script>

<style scoped>
/* -- inline error alert ---*/
.p-error {
    margin: 8px 0 10px !important;
    color: #e60d0d !important;
    /*background-color:#fff3f3;
  padding: 10px;*/
    border-radius: 3px;
    font-size: small;
}

.card-outline {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    min-width: 0 !important;
    word-wrap: break-word !important;
    background-clip: border-box !important;
    border: 1px solid #92d127 !important;
    border-radius: 0.25rem !important;
    box-shadow: none !important;
}

.quest {
    font-size: 12px;
    font-weight: 700;
}

.toggle-lable {
    margin-bottom: 0rem !important;
}

.text-area-pad {
    padding: 10px;
}

.card-style {
    display: block;
    height: auto;
    margin-top: 15px;
    cursor: pointer;
    background-color: #f8f9fa;
    border-radius: calc(0.25rem - 1px);
}

.card-img {
    height: 50px;
    width: 50px;
}

.media-heading {
    margin-top: -19px;
    color: #444;
}

.media-heading:hover,
a:link {
    color: #00c853;
    text-decoration: none;
}

.members {
    margin-top: 0px;
    color: #f39721;
    float: left;
    margin-left: 20px;
}

.btn-part {
    display: inline-block;
    margin-right: 8px;
    float: right;
}

@media (max-width: 768px) {
    .weight-top {
        margin-top: 10px;
    }
}

/* --- toggle switch ---- */

.section {
    text-align: center;
    height: 100%;
    margin: 0 auto;
    padding: 6em 0;
}

.section__title {
    font-size: 1.1em;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: #fff;
    margin-bottom: 3em;
}

.section--aava {
    background: #2994b2;
}

.section--tuuli {
    background: #474744;
}

.section--vesi {
    background: #54567a;
}

.section--sade {
    background: #2d4659;
}

.section--ilma {
    background: #095062;
}

.section--tuli {
    background: #068b78;
}

.toggle-button {
    margin: 5px 0px;
}

/*
 * Toggle button styles
 */
.toggle-button {
    position: relative;
    display: inline-block;
    color: #fff;
}

.toggle-button label {
    display: inline-block;
    text-transform: uppercase;
    cursor: pointer;
    text-align: left;
}

.toggle-button input {
    display: none;
}

.toggle-button__icon {
    cursor: pointer;
    pointer-events: none;
}

.toggle-button__icon:before,
.toggle-button__icon:after {
    content: "";
    position: absolute;
    top: 45%;
    left: 35%;
    transition: 0.2s ease-out;
}

.toggle-button--aava label {
    height: 34px;
    line-height: 36px;
    transition: all 0.2s;
    border-radius: 0.2rem;
}

.toggle-button--aava label:before,
.toggle-button--aava label:after {
    position: absolute;
    right: 1.5rem;
    transition: all 0.2s 0.1s ease-out;
}

.toggle-button--aava label:before {
    content: attr(data-on-text);
}

.toggle-button--aava label:after {
    content: attr(data-off-text);
}

.toggle-button--aava input[type="checkbox"]+label {
    width: 100px;
    background: #ff5335;
}

@media (max-width: 768px) {
    .toggle-button--aava input[type="checkbox"]+label {
        width: 88px;
    }
}

.toggle-button--aava input[type="checkbox"]+label:before {
    opacity: 0;
    transform: translate(0, 20px);
}

.toggle-button--aava input[type="checkbox"]+label:after {
    opacity: 1;
    transform: translate(0, 0);
}

.toggle-button--aava input[type="checkbox"]:checked~label {
    width: 100px;
    background: #61b136;
}

@media (max-width: 768px) {
    .toggle-button--aava input[type="checkbox"]:checked~label {
        width: 88px;
    }
}

.toggle-button--aava input[type="checkbox"]:checked~label:before {
    opacity: 1;
    transform: translate(0, 0);
}

.toggle-button--aava input[type="checkbox"]:checked~label:after {
    opacity: 0;
    transform: translate(0, -20px);
}

.toggle-button--aava input[type="checkbox"]:checked~.toggle-button__icon:before {
    transform: translate(-10%, 100%) rotate(45deg);
    width: 13.66667px;
}

.toggle-button--aava input[type="checkbox"]:checked~.toggle-button__icon:after {
    transform: translate(30%) rotate(-45deg);
}

.toggle-button--aava .toggle-button__icon {
    position: absolute;
    left: 0;
    top: 0;
    height: 36px;
    width: 38px;
}

.toggle-button--aava .toggle-button__icon:before,
.toggle-button--aava .toggle-button__icon:after {
    height: 3px;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.toggle-button--aava .toggle-button__icon:before {
    width: 20px;
    transform: rotate(45deg);
}

.toggle-button--aava .toggle-button__icon:after {
    width: 20px;
    transform: rotate(-45deg);
}
</style>

<!-- Language Data for Health Good Health Question Widget (03) -->
<i18n>
{
  "en": {
    "HealthComplications": "Do you have any health complications ? If 'Yes', Give details",
    "height": "Height ",
    "height_feets": "(feets) ",
    "height_inches": "(inches) ",
    "weight": "Weight (Kg) ",
    "Insured": "Insured",
    "Spouse": "Spouse"
  },
  "si": {
    "HealthComplications": "ඔබට සෞඛ්‍ය සංකූලතා තිබේද? පිළිතුර 'ඔව්' නම් විස්තර සපයන්න.",
    "height": "උස ",
    "height_feets": "(feets) ",
    "height_inches": "(inches) ",
    "weight": "බර (Kg)",
    "Insured": "රක්ෂිතයා",
    "Spouse": "කලත්‍රයා"
  },
  "ta": {
    "HealthComplications": "ஒரு வேளை? பதில் ஆம் எனில், விவரங்களைக் கொடுங்கள்",
    "height": "உயரம் ",
    "height_feets": "(feets) ",
    "height_inches": "(inches) ",
    "weight": "எடை (Kg)",
    "Insured": "வாழ்க்கை",
    "Spouse": "வாழ்க்கைத்துணை"
  }
}
</i18n>
