<!-- Spouse Information Widget -->
<template id="tmpSpouseChildInfo">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header header-font">
          <font-awesome-icon icon="male" />/
          <font-awesome-icon icon="female" />
          <span>&nbsp;{{ $t("jointApplicantInfoHeader") }}</span>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_spouse_title">{{ $t("spouse_title") }}</label>
                <ValidationProvider
                  name="Title"
                  rules="required|genderValidation:@SpGender"
                >
                  <div slot-scope="{ errors }">
                    <select
                      class="form-control"
                      v-model="spouseInfoData.title"
                      name="STitle"
                      @blur="sendChangedData()"
                    >
                      <option value="">Please select</option>
                      <option value="Mr.">Mr.</option>
                      <option value="Mrs.">Mrs.</option>
                      <option value="Ms.">Ms.</option>
                      <option value="Dr.">Dr.</option>
                      <option value="Rev.">Rev.</option>
                    </select>
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_spouse_fname">{{ $t("spouse_fName") }}</label>
                <ValidationProvider
                  name="First Name"
                  rules="required|minmax:3,60|alpha_spaces"
                >
                  <div slot-scope="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="txtSpouseFname"
                      name="SFirstName"
                      placeholder="First Name"
                      v-model="spouseInfoData.fName"
                      @blur="sendChangedData()"
                      v-uppercase
                    />
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_spouse_mname">{{ $t("spouse_mName") }}</label>
                <ValidationProvider
                  name="Middle Name"
                  rules="minmax:3,60|alpha_spaces"
                >
                  <div slot-scope="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="txtSpouseMname"
                      name="MiddleName"
                      placeholder="Middle Name"
                      v-model="spouseInfoData.mName"
                      v-uppercase
                    />
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_spouse_lname">{{ $t("spouse_lName") }}</label>
                <ValidationProvider
                  name="Last Name"
                  rules="required|minmax:3,60|alpha_spaces"
                >
                  <div slot-scope="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="txtSpouseLname"
                      placeholder="Last Name"
                      name="SLastName"
                      v-model="spouseInfoData.lName"
                      @blur="sendChangedData()"
                      v-uppercase
                    />
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_nic">{{ $t("jointApplicantNICNo") }}</label>
                <ValidationProvider
                  name="NIC Number"
                  :rules="{
                    required: true,
                    regex: /^(\d{9}?[V|X]{1}|\d{12})$/i,
                  }"
                >
                  <div slot-scope="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="txtMainLifeNIC"
                      name="NIC"
                      placeholder="NIC Number"
                      v-model.trim="spouseInfoData.txtMainLifeNIC"
                      v-uppercase
                    />
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="gender">{{ $t("spouse_gender") }}</label>
                <ValidationProvider name="SpGender" rules="required">
                  <div slot-scope="{ errors }">
                    <div class="toggleswitchg">
                      <input
                        id="spouse_male"
                        ref="spouse_male_female"
                        name="spouse_male_female"
                        type="radio"
                        value="M"
                        v-model="spouseInfoData.gender"
                        @change="sendChangedData()"
                      />
                      <label for="spouse_male">
                        <i class="fa fa-mars"></i> Male
                      </label>
                      <input
                        checked="checked"
                        id="spouse_female"
                        ref="spouse_male_female"
                        name="spouse_male_female"
                        type="radio"
                        value="F"
                        v-model="spouseInfoData.gender"
                        @change="sendChangedData()"
                      />
                      <label for="spouse_female">
                        <i class="fa fa-venus"></i> Female
                      </label>
                    </div>
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <ValidationObserver ref="spouse">
                <div class="form-group">
                  <label for="spouse_dob">{{ $t("spouse_dob") }}</label>
                  <ValidationProvider name="Date of Birth" rules="required">
                    <div slot-scope="{ errors }">
                      <input
                        type="Date"
                        class="form-control"
                        id="txtSpouseDOB"
                        name="DateOfBirth"
                        placeholder="Date of Birth"
                        v-model="spouseInfoData.dob"
                        @blur="
                          ageCalc();
                          sendChangedData();
                        "
                        @change="
                          ageCalc();
                          sendChangedData();
                        "
                        :max="timestamp"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </ValidationObserver>
            </div>

            <div class="col-sm-3">
              <ValidationObserver ref="spouse">
                <div class="form-group">
                  <label for="spouse_age">{{ $t("spouse_age") }}</label>
                  <ValidationProvider name="Age" rules="required">
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        name="txtSpouseAge"
                        readonly
                        v-model="spouseInfoData.age"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SnotifyPosition } from "vue-snotify";
import { throttle } from "lodash";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("quote_mrtt", [
      "spouseInfoData",
      "additonalInfoData",
      "flash",
      "minflash",
      "timestamp",
    ]),
  },
  async mounted() {
    await this.ageCalc();
  },

  methods: {
    ...mapActions("quote_mrtt", ["getAge", "addChangedData"]),
    basicValidation() {
      if (this.spouseInfoData.txtSpouseAge == 0) {
        return false;
      } else {
        return true;
      }
    },
    sendChangedData: throttle(async function () {
      this.$refs.spouse.validate().then((valid) => {
        if (valid) {
          // do stuff if valid.
          this.$snotify.clear();
          /** Loading (HTML snotify component) */
          this.$snotify.html(
            `<div class="snotifyToast__title"><b>Calculating...</b></div>
            <div class="snotifyToast__body"> Mode Premium Amount</div>
            <div class="snotify-icon snotify-icon--async"></div> `,
            {
              timeout: stop,
              showProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              position: SnotifyPosition.centerTop,
              type: "async",
            }
          );
          return this.getChangeDataResponse()
            .then(
              function (response) {
                this.$snotify.clear();
                if (response.status && response.type == undefined) {
                  /** Success Message  */
                  this.$snotify.success("Mode Premium Amount", response.error, {
                    timeout: stop,
                    closeOnClick: true,
                    pauseOnHover: false,
                    position: SnotifyPosition.centerTop,
                  });
                } else {
                  this.$snotify.clear();
                  /** Error Message  */
                  this.$snotify.error(response.error, "Error", {
                    timeout: stop,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    position: SnotifyPosition.centerTop,
                  });
                }
              }.bind(this)
            )
            .catch(function (error) {
              this.$snotify.clear();
              /** Exception Message */
              this.$snotify.error(error, "Error", {
                timeout: stop,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop,
              });
            });
        }
      });
    }, process.env.VUE_APP_QUOT_TIMEOUT_SEND_CHANGED_DATA),
    async ageCalc() {
      let payload = {
        dob: this.spouseInfoData.dob,
        type: "spouse",
      };
      await this.getAge(payload);
    },
    async getChangeDataResponse() {
      await this.addChangedData();
      if (this.minflash.status) {
        return this.minflash;
      } else {
        return this.flash;
      }
    },
  },
};
</script>

<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

.close-btn {
  float: right;
}

.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 70px;
  border-bottom-left-radius: 30px;
  padding: 65px;
}
body {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

/*--- Card css-----*/

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #556065 !important;
  border-bottom: 0px solid #06a990 !important;
  color: #fff !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #06a990 !important;
  border-radius: 0.25rem;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked + label {
  background: #92d127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92d127;
}
</style>

<!-- Language Data for SpouseInfoWidget -->
<i18n>
{
  "en": {
    "spouseInfoHeader": "Spouse Information",
    "spouse_title": "Title",
    "spouse_fName": "First Name",
    "spouse_mName": "Middle Name",
    "spouse_lName": "Last Name",
    "spouse_gender": "Gender",
    "spouse_dob": "Date of Birth (MM/DD/YYYY)",
    "spouse_age": "Age",
    "jointApplicantInfoHeader": "Joint Applicant Information",
    "jointApplicantNICNo": "NIC No"
  },
  "si": {
    "spouseInfoHeader": "කලත්‍රයාගේ තොරතුරු",
    "spouse_title": "ශීර්ෂය",
    "spouse_fName": "මුල් නම",
    "spouse_mName": "මැද නම",
    "spouse_lName": "අවසන් නම",
    "spouse_gender": "ස්ත්‍රී පුරුෂ භාවය (MM/DD/YYYY)",
    "spouse_dob": "උපන්දිනය",
    "spouse_age": "වයස",
    "jointApplicantInfoHeader": "Joint Applicant Information",
    "jointApplicantNICNo": "NIC No"
  },
  "ta": {
    "spouseInfoHeader": "மனைவி தகவல்",
    "spouse_title": "படிநிலை",
    "spouse_fName": "முதற் பெயர்",
    "spouse_mName": "மத்திய பெயர்",
    "spouse_lName": "கடைசி பெயர்",
    "spouse_gender": "பால் (MM/DD/YYYY)",
    "spouse_dob": "பிறந்த திகதி",
    "spouse_age": "வயது",
    "jointApplicantInfoHeader": "Joint Applicant Information",
    "jointApplicantNICNo": "NIC No"
  }
}
</i18n>
