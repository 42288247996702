<!-- Final - Document Upload Widget -->
<template>
  <div class>
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      color="#00F"
    ></loading>
    <div class="card">
      <div class="card-header">
        <i class="ti ti-user"></i>
        <span>&nbsp;&nbsp;&nbsp;{{ $t("FinalDocumentUploadTitle") }}</span>
        <span class="text-warning"> *(only jpeg,jpg,png,PDF) *(max file size - 2mb)</span>
      </div>
      <div class="card-body box-body">
        <!-- Final - Particulars of Previous life Policies/Proposals Question Widget (12) -->
        <div class="col">
          <div class="container-fluid">
            <div class v-for="(upload_type, index) in UploadTypes" :key="index">
              <div class="p-1 mb-1 bg-secondary text-white">{{ upload_type.title }}</div>
              <div class="border border-primary rounded mb-2">
                <div class="upload">
                  <div class="example-btn">
                    <div v-if="user_role == 'ADV'">
                      <input type="file" :data-code="upload_type.id" class="btn btn-primary m-3 upload_img"
                        v-on:change="onFileChange" ref="file" accept="image/png, image/jpeg , .pdf" multiple="multiple" />
                    </div>

                    <div class="row margin-img-row">
                      <div class="col-md-2 col-sm-4 margin-imge-div"
                        v-for="item in filterUploadImageTypes(UploadedDocs, upload_type.id)" :key="item.id">
                        <a v-bind:href="item.url" target="_blank"
                          v-if="['jpeg', 'jpg', 'png', 'PNG', 'JPEG', 'jpg'].includes(item.extension)">
                          <img v-bind:src="item.url" target="_blank" class="rounded mt-1 mb-1 ml-3" alt="No Preview"
                            width="150" height="150" />
                        </a>
                        <a class="doc-preview-link" v-else v-bind:href="item.url" target="_blank">
                          {{ item.name }}
                        </a>
                        <button type="button" class="btn btn-danger btn-sm float-right action-delete-btn"
                          @click="deleteDocumentConfirmation(item.id)">Delete</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <button type="button" class="btn btn-success ml-3 mt-2 mb-2 float-right">Submit</button> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Delete agent document confirmation -->
    <b-modal centered size="md" title="Delete Documents" header-bg-variant="warning" header-text-variant="light"
      body-bg-variant="light" body-text-variant="dark" footer-bg-variant="" footer-text-variant="dark"
      :id="'delete-document-modal'" ref="delete-document-modal" :ok-disabled="true">
      <div class="row">
        <div class="col-sm-12">
          <label>Are you sure want to delete this document.?</label>
        </div>
      </div>
      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="primary" @click="deleteDocument()">
          Confirm
        </b-button>
        <b-button size="sm" variant="danger" @click="cancelDeleteDoc()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
//import FileUpload from "vue-upload-component";
export default {
  name: "final-document-upload-widget",
  computed: {
    ...mapState("proposal", ["UploadTypes", "UploadedDocs"]),
    remarkState() {
      return this.remark.length > 2 ? true : false;
    },
    selectedSearchType: {
      get() {
        //console.log(this.selectedSearchType);
        return this.value;
      },
    },
  },
  components: {
    // FileUpload
  },
  mounted() {
    this.getUploadTypes();
    if (this.$route.params.ref_no) {
      let payload = {
        q_ref_no: this.$route.params.ref_no,
      };

      this.loadUploadedFiles(payload);
    }
    if (this.$route.name == "push_back") {
      this.setBtnApproveStatus(true);
    }
    if (localStorage.user_role) {
      this.user_role = localStorage.getItem("user_role");
    }
  },
  methods: {
    ...mapActions("proposal", [
      "UploadFile",
      "loadUploadedFiles",
      "getUploadTypes",
      "deleteAgentDocument"
    ]),
    cancelDeleteDoc() {
      this.$bvModal.hide("delete-document-modal");
    },
    deleteDocument() {
      this.$snotify.clear();
      let payload = {
        id: this.active_doc_id,
      };

      this.deleteAgentDocument(payload).then((response) => {
        if (response.data.meta.status) {
          this.$snotify
            .success(response.data.meta.status_message, "", {
              position: "centerTop",
            })
            .on("destroyed", () => {
              this.$bvModal.hide("delete-document-modal");
              if (this.$route.params.ref_no) {
                let payload = {
                  q_ref_no: this.$route.params.ref_no,
                };

                this.loadUploadedFiles(payload);
              }
            });
        }
      });
    },

    deleteDocumentConfirmation(id) {
      this.active_doc_id = id;
      this.$bvModal.show("delete-document-modal");

    },
    filterUploadImageTypes(obj, id) {
      if (obj.length) {
        return obj.filter((item) => {
          return item.proposal_upload_type_id == id;
        });
      } else {
        return [];
      }
    },

    onFileChange(e) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("q_ref_no", this.$route.params.ref_no);
      formData.append("p_doc_type", e.currentTarget.dataset.code);

      for (var i = 0; i < e.currentTarget.files.length; i++) {
        let file = e.currentTarget.files[i];
        //console.log(file);
        formData.append("files[" + i + "]", file);
      }
      this.UploadFile(formData).then(() => {
        this.isLoading = false;
      });
    },
  },

  data() {
    return {
      index: 0,
      files1: [],
      file: [],
      user_role: "",
      active_doc_id: "",
      isLoading: false,
      fullPage: true
    };
  },
};
</script>

<style scoped>
.action-delete-btn {
  position: absolute;
  right: 2px;
  bottom: 2px;
}

.margin-img-row {
  margin-left: 12px;
  margin-bottom: 12px;
}

.margin-imge-div {
  border: 1px solid #92D127 !important;
  padding: 12px;
  margin: 3px;
}

.doc-preview-link {
  display: inline-block;
}

.card-outline {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-clip: border-box !important;
  border: 1px solid #92D127 !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}

.example-multiple label.btn {
  margin-bottom: 0 !important;
  margin-right: 1rem !important;
}

.example-multiple .upload {
  margin-bottom: 1rem !important;
}
</style>

<!-- Language Data for Final - Document Upload Widget -->
<i18n>
{
  "en": {
    "AreYouInGoodHealthQuestion": "Are you in good health ? If 'No', Give details",
    "Insured": "Insured",
    "Spouse": "Spouse",
    "FinalDocumentUploadTitle": "Document Upload"
  },
  "si": {
    "AreYouInGoodHealthQuestion": "Are you in good health ?",
    "Insured": "ජීවිත රක්ෂිතයා",
    "Spouse": "කලත්‍රයා",
    "FinalDocumentUploadTitle": "ලේඛන උඩුගත කිරීම"
  },
  "ta": {
    "AreYouInGoodHealthQuestion": "Are you in good health ?",
    "Insured": "பிரதான வாழ்க்கை",
    "Spouse": "வாழ்க்கைத்துணை",
    "FinalDocumentUploadTitle": "ஆவண பதிவேற்றம்"
  }
}
</i18n>
