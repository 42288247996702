<template>
  <div class="container-fluid">
    <div class="row row-top">
      <div class="card-2">
        <div class="card-header-2">
          <font-awesome-icon icon="search" />&nbsp;
          <span>Search Panel</span>
        </div>
        <div class="card-body">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Search By Name</label>

                  <v-select
                    v-model="active_user"
                    :options="activeHoUsers"
                  ></v-select>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group search-btn">
                  <button
                    type="button"
                    id="search"
                    class="btn btn-sm btn-success btn-right"
                    @click="addHoUser"
                  >
                    <font-awesome-icon icon="search" />
                    {{ $t("Add") }}
                  </button>
                  <button
                    class="btn btn-sm btn-danger"
                    type="reset"
                    id="search_reset"
                    @click="resetFilter"
                  >
                    <font-awesome-icon icon="ban" />
                    {{ $t("reset") }}
                  </button>
                </div>
              </div>
              <div class="col-sm-5 text-right">
                <b-form-checkbox
                  @change="changeQueueStatus()"
                  v-model="queue_status"
                  switch
                  size="lg"
                  >Queue - (
                  {{ queue_status ? "On" : "Off" }} )</b-form-checkbox
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <UserListTable ref="userTableRef" />
      </div>
    </div>
  </div>
</template>

<script>
import UserListTable from "@/components/queue_user_management/UserListTable.vue";
import { mapActions } from "vuex";

export default {
  name: "UserList",
  components: {
    UserListTable,
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      agent_code: "",
      queue_status: false,
      activeHoUsers: [],
      active_user: "",
    };
  },
  methods: {
    ...mapActions("user", [
      "getActiveHoUserList",
      "addActiveHoUser",
      "updateQueueStatus",
    ]),
    changeQueueStatus() {
      this.$snotify.clear();
      let payload = {
        queue_status: this.queue_status,
      };
      this.updateQueueStatus(payload).then((response) => {
        if (response.data.meta.status) {
          console.log(response);
        }
      });
    },
    addHoUser() {
      this.$snotify.clear();
      let payload = {
        user_id: this.active_user.id,
      };
      this.addActiveHoUser(payload).then((response) => {
        if (response.data.meta.status) {
          this.$refs.userTableRef.onFilterReset();
          this.active_user = "";
          this.activeHoUserList();
        }
      });
    },
    activeHoUserList() {
      this.$snotify.clear();
      let payload = {};
      this.activeHoUsers = [];
      let self = this;
      this.getActiveHoUserList(payload).then((response) => {
        if (response.data.meta.status) {
          let res = response.data.data;
          res.forEach(function (user) {
            let obj = {};
            obj.label =
              user.first_name +
              " " +
              user.last_name +
              "(" +
              user.agent_code +
              ")";
            obj.id = user.id;
            self.activeHoUsers.push(obj);
          });
          this.queue_status = response.data.queuseStatus;
        }
      });
    },
    resetFilter() {
      this.active_user = "";
      this.$events.fire("filter-reset");
    },
  },
  mounted() {
    this.activeHoUserList();
  },
};
</script>

<style scoped>
.search-btn {
  padding-top: 30px;
}
</style>
<i18n>
{
  "en": {
    "reset": "Reset"
  },
  "si": {
    "reset": "Reset"
  },
  "ta": {
     "reset": "Reset"
  }
}
</i18n>