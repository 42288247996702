<template>
  <div class="row-top-list2 table-row-top">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-6 col-md-8 col-lg-8">
            <font-awesome-icon icon="align-left" />&nbsp;
            <span>{{ $t("list_appointment") }}</span>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-4">
            <b-button
              style="margin:5px padding: .25rem .65rem; "
              variant="info"
              class="ui btn-sm basic button btn_arrange"
              title="Add New Lead"
              v-b-tooltip.hover.bottom="'go back to leads'"
              @click="onCancel"
            >
              <!-- <font-awesome-icon icon="" /> -->
              &nbsp;Back
            </b-button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row tbl-margin-top">
          <div class="col table-responsive">
            <vuetable
              class="table table-striped table-bordered nowrap vue-tbl"
              ref="vuetable"
              :api-url="getUrl"
              :fields="fields"
              pagination-path
              @vuetable:pagination-data="onPaginationData"
              :per-page="3"
              :http-options="httpOptions"
              :append-params="moreParams"
            >
              <template slot="actions" scope="props">
                <div
                  class="custom-actions"
                  style="white-space: nowrap; text-align: center"
                >
                  <b-button
                    style="margin:5px padding: .25rem .65rem;;"
                    variant="primary"
                    class="ui btn-sm basic button"
                    title="Edit"
                    v-b-tooltip.hover.bottom="'Edit'"
                    v-if="
                      props.rowData.status != 'Cancelled' &&
                      props.rowData.status != 'Visited' &&
                      props.rowData.status != 'Expired'
                    "
                    @click="
                      onActionClicked(
                        'edit-item',
                        props.rowData,
                        props.rowIndex
                      )
                    "
                  >
                    <font-awesome-icon icon="pen" />
                  </b-button>
                </div>
              </template>
            </vuetable>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="pagination">
              <vuetable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              ></vuetable-pagination>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "@/components/vuetable/VuetablePaginationBootstrap4";

import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    Vuetable,
    VuetablePagination,
  },
  data() {
    return {
      show: false,
      fields: [
        {
          name: "id",
          title: '<span class="orange glyphicon glyphicon-user"></span> #',
          sortField: "id",
        },
        {
          name: "date",
          title: '<span class="orange glyphicon glyphicon-user"></span> Date',
          sortField: "date",
        },
        {
          name: "time",
          title: '<span class="orange glyphicon glyphicon-user"></span> Time',
          sortField: "time",
        },
        {
          name: "remarks",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Remarks',
          sortField: "remarks",
        },
        {
          name: "status",
          title: '<span class="orange glyphicon glyphicon-user"></span>Status',
          sortField: "status",
        },
        {
          name: "created_at",
          title:
            '<span class="orange glyphicon glyphicon-user"></span>Created On',
          sortField: "created_at",
        },
        "__slot:actions",
      ],
      httpOptions: {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: {},
      },
      moreParams: { lead_no: this.$route.params.ref_no },
      getUrl: process.env.VUE_APP_API_ENDPOINT + "lead/appointment",
    };
  },
  props: ["selectedDate"],
  computed: {
    ...mapState("lead", ["AppointmentInfo", "AppointForm", "CalendarList"]),
    ...mapGetters("login", ["checkUserPermissionNew"]),
    loadTbl() {
      return this.AppointForm.isSave;
    },
    refreshTbl() {
      return this.AppointForm.loadTable;
    },
  },
  watch: {
    selectedDate: function () {
      this.moreParams.selected_date = this.AppointmentInfo.txtAppointDate;
    },
    loadTbl() {
      this.$refs.vuetable.refresh();
    },
    refreshTbl() {
      this.$refs.vuetable.refresh();
    },
  },
  methods: {
    ...mapActions("lead", [
      "addAppointment",
      "loadAppoint",
      "getCalendarAppointmentList",
    ]),

    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onActionClicked(action, data) {
      if (action == "edit-item") {
        this.loadAppointmentInfo(data);
        this.AppointmentInfo.txtAppointID = data.id;
        this.AppointForm.isSave = false;
        this.$bvModal.show("modal-create-quotation");
      }
    },
    async loadAppointmentInfo(data) {
      await this.loadAppoint(data);
    },

    async onCancel() {
      //this.$router.push("/lead_list");
      this.$router.push({
        name: "lead_list",
      });
    },
    viewPermissionCheck() {
      return this.checkUserPermissionNew("view-quote");
    },
  },
};
</script>

<style>
.modal-backdrop {
  background-color: #0000001a !important;
}

.modal-content {
  border: 0px solid !important;
}

.tbl-margin-top {
  margin-top: 15px;
}

table th,
.table td {
  padding: 0.2rem 0.75rem !important;
  vertical-align: middle !important;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}

.pagination {
  float: right;
}

.page-link {
  font-size: 12px !important;
}
.tooltip {
  top: 0 !important;
}
</style>

<i18n>
{
  "en": {
    "list_appointment": "Appointments",
    "AppointmentModalTitle": "Appointments"
  },
  "si": {
  "list_appointment": "හමුවීම් ලැයිස්තුව",
    "AppointmentModalTitle": "හමුවීම්"
  },
  "ta": {
    "list_appointment": "நியமனங்கள்",
    "AppointmentModalTitle": "sநியமனங்கள்"
  }
}
</i18n
>s
