<template>
  <div class="row-top-list2 table-row-top">
    <div class="card">
      <div class="card-header">
        <font-awesome-icon icon="align-left" />&nbsp;
        <span>User Role List</span>
        <b-button
          style="margin:5px padding: .25rem .65rem;float:right;"
          variant="success"
          class="ui btn-sm basic button"
          title="Add New Role"
          v-b-tooltip.hover.bottom="'Add New Role'"
          @click="onActionClicked('create-role', null, null)"
        >
          <font-awesome-icon icon="plus" />&nbsp;Add New Role
        </b-button>
      </div>
      <div class="card-body">
        <div class="row tbl-margin-top">
          <div class="col table-responsive">
            <vuetable
              class="table table-striped table-bordered nowrap vue-tbl"
              ref="roletable"
              :api-url="getUrl"
              :fields="fields"
              pagination-path
              @vuetable:pagination-data="onPaginationData"
              :per-page="15"
              :http-options="httpOptions"
              :append-params="moreParams"
            >
              <template slot="actions" slot-scope="props">
                <!-- ACTION SLOT -->
                <div
                  class="custom-actions"
                  style="white-space: nowrap; text-align: center"
                >
                  <b-button
                    style="margin:5px padding: .25rem .65rem;;"
                    variant="info"
                    class="ui btn-sm basic button"
                    title="Edit"
                    @click="
                      onActionClicked(
                        'edit-role',
                        props.rowData,
                        props.rowIndex
                      )
                    "
                    v-b-tooltip.hover.bottom="'Edit'"
                  >
                    <font-awesome-icon icon="pen" />
                  </b-button>
                </div>
                <!-- ACTION SLOT -- END  -->
              </template>
            </vuetable>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="pagination">
              <vuetable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              ></vuetable-pagination>
            </div>
          </div>
        </div>
      </div>

      <!-- Add New ROle Module -->
      <b-modal
        id="createRoleModel"
        ref="createRole"
        title="Add New Role"
        @show="resetCreateRoleModal"
        @hidden="resetCreateRoleModal"
        @ok="handleCreateRoleModelOk"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <div class="row">
            <div class="col-sm-12">
              <div>
                <label>Enter Name</label>
                <input
                  v-model="roleData.role_name"
                  type="text"
                  class="form-control"
                  placeholder="Enter Name"
                />
              </div>
            </div>

            <div class="col-sm-12">
              <div class="form-group">
                <h6 class="pt-4 pb-2"><b>Associated Permissions</b></h6>
                <div
                  class="row"
                  v-for="(permissions, id) in roleData.permissions"
                  :key="id"
                >
                  <div class="col-sm-6">
                    <label>{{ permissions.name }}</label>
                  </div>
                  <div class="col-sm-6">
                    <b-form-checkbox size="lg" v-model="permissions.enabled">
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </b-modal>

      <!-- Edit User ROle Model -->
      <b-modal id="editRoleModel" title="Edit User Role">
        <div class="row">
          <div class="col-sm-12">
            <div>
              <label>Enter Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Name"
              />
            </div>
          </div>

          <div class="col-sm-12">
            <div class="form-group">
              <h6 class="pt-4 pb-2"><b>Associated Permissions</b></h6>
              <div class="row">
                <div class="col-sm-6">
                  <label>View-backend</label>
                </div>
                <div class="col-sm-6">
                  <b-form-checkbox size="lg"> </b-form-checkbox>
                </div>
                <div class="col-sm-6">
                  <label>Search-quote</label>
                </div>
                <div class="col-sm-6">
                  <b-form-checkbox size="lg"> </b-form-checkbox>
                </div>
                <div class="col-sm-6">
                  <label>View-quote</label>
                </div>
                <div class="col-sm-6">
                  <b-form-checkbox size="lg"> </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
//import { ValidationProvider } from "vee-validate";
//import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

import VuetablePagination from "@/components/vuetable/VuetablePaginationBootstrap4";
// import EditUser from "@/components/user_management/edit_user.vue"

import { mapGetters, mapActions, mapState } from "vuex";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    // EditUser,
    //VuetablePaginationInfo
  },
  data() {
    return {
      fields: [
        {
          name: "id",
          title: '<span class="orange glyphicon glyphicon-user"></span> #',
          sortField: "id",
        },
        {
          name: "name",
          title: '<span class="orange glyphicon glyphicon-user"></span> Role',
          sortField: "name",
        },

        {
          name: "permissions",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Permissions',
          callback: "getPermissionsLabel",
        },

        {
          name: "users",
          title:
            '<span class="orange glyphicon glyphicon-user"></span>Number of Users',
          callback: "getUsersCount",
        },
        "__slot:actions",
      ],
      httpOptions: {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: {},
      },
      moreParams: {},
      getUrl: process.env.VUE_APP_API_ENDPOINT + "roles",
    };
  },
  computed: {
    ...mapGetters("login", ["checkUserPermissionNew"]),
    ...mapState("role", ["roleData"]),
  },
  mounted() {
    this.$events.$on("filter-set", (eventData) => this.onFilterSet(eventData));
    this.$events.$on("filter-reset", this.onFilterReset());
  },
  created() {
    this.getPermissisons();
  },
  // destroyed() {
  //   this.moreParams = {};
  //   Vue.nextTick(() => this.$refs.roletable.refresh());
  //   this.$events.off("filter-reset");
  // },

  methods: {
    ...mapActions("role", ["getAllPermissions"]),

    onActionClicked(action, data) {
      if (action == "create-role") {
        this.$router.push({
          name: "create_role",
          query: {
            action: "create",
          },
        });
      } else if (action == "edit-role") {
        this.$router.push({
          name: "edit_role",
          params: {
            role_id: data.id,
          },
          query: {
            action: "edit",
            role_id: data.id,
          },
        });
      }
    },

    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.roletable.changePage(page);
    },
    onFilterSet(filters) {
      // console.log(filters);
      this.moreParams = {
        name: filters.name,
        contact: filters.phone_no,
        nic: filters.nic,
        status: filters.status,
        leadstatus: filters.leadstatus,
        appointmentDate: filters.appointmentDate,
      };
      Vue.nextTick(() => this.$refs.roletable.refresh());
      //Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onFilterReset() {
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.roletable.refresh());
    },
    getPermissionsLabel(permissions) {
      let str = "";
      permissions.forEach(function (permission, index) {
        str +=
          '<span class="badge badge-primary badge-pill" style="padding: 0.65em 0.6em !important;">' +
          permission.name +
          "</span>";
        if (index != permissions.length - 1) {
          str += " ";
        }
      });
      return str;
    },
    getUsersCount(users) {
      return users.length;
    },
    async getPermissisons() {
      await this.getAllPermissions();
    },
    handleCreateRoleModelOk() {},
    resetCreateRoleModal() {},
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  background-color: #0000001a !important;
}

.modal-content {
  border: 0px solid !important;
}

.tbl-margin-top {
  margin-top: 15px;
}

table th,
.table td {
  padding: 0.2rem 0.75rem !important;
  vertical-align: middle !important;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}

.pagination {
  float: right;
}

.btn {
  margin: 5px;
}

.page-link {
  font-size: 12px !important;
}

.tooltip {
  top: 0 !important;
}

.background-field-white {
  background-color: white;
}

.expired-field-background {
  background-color: #fc8d89 !important;
}
</style>

<i18n>
{
  "en": {
   
  },
  "si": {
  
  },
  "ta": {
    
  }
}
</i18n>
