import { render, staticRenderFns } from "./InfoNominees.vue?vue&type=template&id=312d13f0&scoped=true"
import script from "./InfoNominees.vue?vue&type=script&lang=js"
export * from "./InfoNominees.vue?vue&type=script&lang=js"
import style0 from "./InfoNominees.vue?vue&type=style&index=0&id=312d13f0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "312d13f0",
  null
  
)

/* custom blocks */
import block0 from "./InfoNominees.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports