<!--Change Password Widget  -->
<template>
  <div class="row row-top" style="scroll: none; margin-bottom: -50px">
    <vue-snotify></vue-snotify>
    <div class="col-sm-12 top-margin">
      <div class="card">
        <div class="card-header header-font">
          <font-awesome-icon icon="users" />
          <span>&nbsp; {{ action_title }} </span>
        </div>
        <ValidationObserver ref="password_form">
          <div class="card-body">
            <b-row>
              <b-col>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="txtFirstName">
                        Password
                        <span style="color: red">*</span>
                      </label>
                      <ValidationProvider
                        name="Password"
                        rules="required|min:6"
                      >
                        <div slot-scope="{ errors }">
                          <input
                            type="password"
                            ref="firstName"
                            class="form-control"
                            id="txtFirstName"
                            name="firstName"
                            placeholder="New Password"
                            v-model="password"
                          />
                          <p class="p-error">{{ errors[0] }}</p>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <!-- Password -->
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <button
                      type="submit"
                      ref="createBtn"
                      class="btn btn btn-success btn-sm btn-block btn_height"
                      @click="onSubmit"
                    >
                      &nbsp;{{ action }}
                    </button>

                    <button
                      type="submit"
                      ref="updateBtn"
                      class="btn btn btn-secondary btn-md btn-block btn_height"
                      @click="onCancel"
                    >
                      &nbsp;Cancel
                    </button>
                  </div>
                  <div class="col-sm-6"></div>
                </div>
              </b-col>
            </b-row>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>


<script>
// import Vue from "vue";
import { mapActions } from "vuex";
// import { SnotifyPosition } from "vue-snotify";

export default {
  name: "password-change-widget",

  data: function () {
    return {
      action: "Change Password",
      action_title: "Change Password",
      isSave: true,
      isDisabled: false,
      user_id: "",
      password: "",
      active: false,
    };
  },

  mounted() {
    if (this.$route.query.user_id) {
      this.user_id = this.$route.query.user_id;
    }
  },

  methods: {
    ...mapActions("user", ["changePassword"]),

    //Update Password
    async passwordChange() {
      const passwordInfo = {
        user_id: this.user_id,
        password: this.password,
      };
      //await this.changePassword(passwordInfo);
      this.changePassword(passwordInfo).then((response) => {
        if (response.data.meta.status) {
          this.$snotify
            .success(response.data.meta.status_message, "", {
              position: "centerTop",
              title:"Success"
            })
            .on("destroyed", () => {
              //router.push("/user_management");
              this.$router.push({
                name: "user_management",
              });
            });
        } else {
          this.$refs.password_form.setErrors(response.data.meta.errors);
        }
      });
    },

    //Submit User
    async onSubmit() {
      this.$refs.password_form
        .validate()
        .then((result) => {
          if (!result) {
            return;
          } else {
            this.passwordChange();
          }
        })
        .catch(() => {});
    },
    async onCancel() {
      //this.$router.push("/user_management");
      this.$router.push({
        name: "user_management",
      });
    },
  },
};
</script>
<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #556065 !important;
  border-bottom: 0px solid #06a990 !important;
  color: #fff !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #06a990 !important;
  border-radius: 0.25rem;
}

@media (max-width: 768px) {
  .btn-sm,
  .btn-group-sm > .btn {
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    line-height: 1.5;
  }
}

@media (max-width: 768px) {
  .top-margin {
    margin-top: 60px;
  }
}
</style>

<!-- Language Data for LeadInfoWidget-->
<i18n>
{
  "en": {
  },
  "si": {
  },
  "ta": {
  }
}
</i18n>
