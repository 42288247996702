<!-- Additional Benifits - Children Widget -->
<template>
  <div v-if="BenefitsChild != undefined">
    <!-- CHILD 01 -->
    <div class="row" v-if="ChildInfoData[0] != undefined">
      <div class="col-sm-1 rotated">
        <span class="rotate-bcg"><b>CHILD 01</b></span>
      </div>
      <div class="col-sm-11">
        <table class="tbl-width">
          <tbody>
            <tr>
              <td>
                <div class="list-group">
                  <a class=" premium">
                    <table class="tbl-width tbl-font-size table table-striped">
                      <thead>
                        <tr>
                          <th>Benefit</th>
                          <th class="tbl-header2" style="float: right;">
                            S/A (Rs.)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(benefits, index) in BenefitsChild"
                          :index="index"
                          :key="index"
                        >
                          <td v-if="benefits.child == 1">
                            {{ benefits.rider_code }}
                          </td>
                          <td style="float: right;" v-if="benefits.child == 1">
                            {{ thousandSeparator(benefits.sum_assured) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- CHILD 02 -->
    <div class="row ch-padding" v-if="ChildInfoData[1] != undefined">
      <div class="col-sm-1 rotated">
        <span class="rotate-bcg"><b>CHILD 02</b></span>
      </div>
      <div class="col-sm-11">
        <table class="tbl-width">
          <tbody>
            <tr>
              <td>
                <div class="list-group">
                  <a class=" premium">
                    <table class="tbl-width tbl-font-size table table-striped">
                      <thead>
                        <tr>
                          <th>Benefit</th>
                          <th class="tbl-header2" style="float: right;">
                            S/A (Rs.)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(benefits, index) in BenefitsChild"
                          :index="index"
                          :key="index"
                        >
                          <td v-if="benefits.child == 2">
                            {{ benefits.rider_code }}
                          </td>
                          <td style="float: right;" v-if="benefits.child == 2">
                            {{ thousandSeparator(benefits.sum_assured) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- CHILD 03 -->
    <div class="row ch-padding" v-if="ChildInfoData[2] != undefined">
      <div class="col-sm-1 rotated">
        <span class="rotate-bcg"><b>CHILD 03</b></span>
      </div>
      <div class="col-sm-11">
        <table class="tbl-width">
          <tbody>
            <tr>
              <td>
                <div class="list-group">
                  <a class=" premium">
                    <table class="tbl-width tbl-font-size table table-striped">
                      <thead>
                        <tr>
                          <th>Benefit</th>
                          <th class="tbl-header2" style="float: right;">
                            S/A (Rs.)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(benefits, index) in BenefitsChild"
                          :index="index"
                          :key="index"
                        >
                          <td v-if="benefits.child == 3">
                            {{ benefits.rider_code }}
                          </td>
                          <td style="float: right;" v-if="benefits.child == 3">
                            {{ thousandSeparator(benefits.sum_assured) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- CHILD 04 -->
    <div class="row ch-padding" v-if="ChildInfoData[3] != undefined">
      <div class="col-sm-1 rotated">
        <span class="rotate-bcg"><b>CHILD 04</b></span>
      </div>
      <div class="col-sm-11">
        <table class="tbl-width">
          <tbody>
            <tr>
              <td>
                <div class="list-group">
                  <a class=" premium">
                    <table class="tbl-width tbl-font-size table table-striped">
                      <thead>
                        <tr>
                          <th>Benefit</th>
                          <th class="tbl-header2" style="float: right;">
                            S/A (Rs.)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(benefits, index) in BenefitsChild"
                          :index="index"
                          :key="index"
                        >
                          <td v-if="benefits.child == 4">
                            {{ benefits.rider_code }}
                          </td>
                          <td style="float: right;" v-if="benefits.child == 4">
                            {{ thousandSeparator(benefits.sum_assured) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- CHILD 5 -->
    <div class="row ch-padding" v-if="ChildInfoData[4] != undefined">
      <div class="col-sm-1 rotated">
        <span class="rotate-bcg"><b>CHILD 5</b></span>
      </div>
      <div class="col-sm-11">
        <table class="tbl-width">
          <tbody>
            <tr>
              <td>
                <div class="list-group">
                  <a class=" premium">
                    <table class="tbl-width tbl-font-size table table-striped">
                      <thead>
                        <tr>
                          <th>Benefit</th>
                          <th class="tbl-header2" style="float: right;">
                            S/A (Rs.)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(benefits, index) in BenefitsChild"
                          :index="index"
                          :key="index"
                        >
                          <td v-if="benefits.child == 5">
                            {{ benefits.rider_code }}
                          </td>
                          <td style="float: right;" v-if="benefits.child == 5">
                            {{ thousandSeparator(benefits.sum_assured) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- CHILD 6 -->
    <div class="row ch-padding" v-if="ChildInfoData[5] != undefined">
      <div class="col-sm-1 rotated">
        <span class="rotate-bcg"><b>CHILD 6</b></span>
      </div>
      <div class="col-sm-11">
        <table class="tbl-width">
          <tbody>
            <tr>
              <td>
                <div class="list-group">
                  <a class=" premium">
                    <table class="tbl-width tbl-font-size table table-striped">
                      <thead>
                        <tr>
                          <th>Benefit</th>
                          <th class="tbl-header2" style="float: right;">
                            S/A (Rs.)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(benefits, index) in BenefitsChild"
                          :index="index"
                          :key="index"
                        >
                          <td v-if="benefits.child == 6">
                            {{ benefits.rider_code }}
                          </td>
                          <td style="float: right;" v-if="benefits.child == 6">
                            {{ thousandSeparator(benefits.sum_assured) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
  <div v-else><span>Not Applicable</span></div>
</template>

<script>
import { mapState } from "vuex";
import helpMixin from "@/mixins/filters";
export default {
  name: "additional-benifits-child-widget",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState("proposal", ["BenefitsChild", "ChildInfoData"])
  },
  mixins: [helpMixin]
};
</script>

<style scoped>
.ch-padding {
  padding-top: 2px;
}
.table thead th {
  border: 0px solid !important;
}

.table th,
.table td {
  border: 0px solid !important;
}

.rotated {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  text-align: center;
}

.rotate-bcg {
  background-color: #fcc109;
  padding: 1.2rem 0.5rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 12px;
}

@media (max-width: 768px) {
  .rotated {
    writing-mode: unset;
    transform: unset;
    text-align: unset;
  }

  .rotate-bcg {
    background-color: #fcc109;
    padding: 0.4rem 1.2rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 6px;
    font-size: 12px;
  }
}
</style>
