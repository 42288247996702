<!-- Additional Benifits - Spouse Widget -->
<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <table class="tbl-width" v-if="BenefitsSpouse != undefined">
          <tbody>
            <tr>
              <td>
                <div class="list-group">
                  <a class=" premium">
                    <table class="tbl-width tbl-font-size table table-striped">
                      <thead>
                        <tr>
                          <th>Benefit</th>
                          <th class="tbl-header2" style="float: right;">
                            S/A (Rs.)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(benefit, index) in BenefitsSpouse"
                          :index="index"
                          :key="index"
                        >
                          <td>{{ benefit.rider_code }}</td>
                          <td style="float: right;">
                            {{
                              thousandSeparator(
                                benefit.sum_assured != null
                                  ? benefit.sum_assured
                                  : 0
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else><span>Not Applicable</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import helpMixin from "@/mixins/filters";

export default {
  name: "additional-benifits-spouse-widget",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState("proposal", ["BenefitsSpouse"])
  },
  mixins: [helpMixin]
};
</script>

<style scoped>
.table thead th {
  border: 0px solid !important;
}

.table th,
.table td {
  border: 0px solid !important;
}
</style>
