<!-- create/modify customer quotations -->
<template>
  <div>
    <Header />
    <LeftNav />
    <main id="page-wrap">
      <vue-snotify></vue-snotify>
      <b-container fluid class="calander_margin">
        <!-- <AppointInfoForm /> -->
          <div class="row row-top">
          <div class="col-6 col-md-6 col-sm-12 col-xs-12">
            <AppointmentCalendarLead  @setAppointDate="getAppointmentDate($event)"/>
          </div>
          <div class="col-6 col-md-6 col-sm-12 col-xs-12">
            <AppointmentListLead :selectedDate="selectedAppDate" />
          </div>
         </div>
      </b-container>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/layout/AppHeader.vue";
import Footer from "@/components/common/layout/AppFooter.vue";
import LeftNav from "@/components/common/layout/AppLeftNav";
import AppointmentListLead from "@/components/common/layout/AppointmentListTable.vue";
import AppointmentCalendarLead from "@/components/common/layout/AppointmentCalenderLead.vue";
// import AppointInfoForm from "@/components/lead/AppointmentInfoWidget.vue";
//import { SnotifyPosition } from "vue-snotify";
import { mapState } from "vuex";

export default {
  name: "NewLead",
  data:()=>{
    return{
      selectedAppDate: ""
    }
  },
  components: {
    Header,
    Footer,
    LeftNav,
    AppointmentListLead,
    // AppointInfoForm,
    AppointmentCalendarLead
  },

  computed: {
    ...mapState("lead", ["AppointmentInfo"])
  },

  mounted() {},

  methods: {
    
    getAppointmentDate(eventData){
      this.selectedAppDate = eventData;
    }
  }
};
</script>

<style scoped>
div.card .card-header {
  background: #ff660d !important; /* fallback for old browsers */
  box-shadow: 0 10px 10px -12px rgba(0, 0, 0, 0.42),
    0 4px 10px 0px rgba(0, 0, 0, 0.12), 0 5px 7px -5px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {

.col-6{
  display:contents;
}

.calander_margin{
  padding-top: 25px;
}



}


</style>
