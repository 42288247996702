<template>
  <div id="list_quote">
    <Header />
    <LeftNav />
    <main id="page-wrap">
      <b-container fluid>
        <Welcome />
      </b-container>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/layout/AppHeader.vue";
import Footer from "@/components/common/layout/AppFooter.vue";
import Welcome from "@/components/quote_health_plus/Welcome";
import LeftNav from "@/components/common/layout/AppLeftNav";

export default {
  name: "list_quote",
  components: {
    Header,
    Footer,
    Welcome,
    LeftNav,
  }
};
</script>

<style scoped>
.row-top-list {
  margin-top: 40px;
}
</style>

<i18n>
{
  "en": {
    "search_panel": "Search Panel",
    "list_quotaion": "Quotation List"
  },
  "si": {
    "search_panel": "සෙවුම් පැනලය",
    "list_quotaion": "ලැයිස්තු උපුටා දැක්වීම"
  }
}
</i18n>
