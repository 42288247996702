<!-- ebuddy login redirect -->
<template>
  <div class="home bg_image">
   
 

    <div class="row ">
      <div class="col-sm-4 col-md-4 col-lg-4 pt-5 ">
       
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4 pt-5 ">
        <img src="@/assets/img/atl_new_logo.png" class="login_logo  " />
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4 ">
       
      </div>
    </div>

    <Login />
   
  </div>

 
 
</template>

<script>
import Login from "@/components/auth/login.vue";

export default {
  name: "home",
  components: {
    Login
  }
};
</script>

<style scope>

.bg_image{
  background-color: #ffffff;
  min-height: 99vh;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  }
 
 .login_logo{

 width: 70%;
 height: auto;
 display: block;
    margin-left: auto;
    margin-right: auto;

 }

</style>
