<!-- Info Details Root Widget (01) -->
<template>
  <div>
    <vue-snotify></vue-snotify>
    <div class="row">
      <div class="card">
        <div class="card-header">
          <i class="ti ti-user"></i>
          <span
            >&nbsp;&nbsp;&nbsp;{{ $t("PersonalDetailsWidgetTitle") }} -
            {{ $t("tabInsured") }}</span
          >
        </div>
        <div class="card-body box-body">
          <!-- Insured Personal Details Widget (01/A) -->
          <InfoInsuredWigt />
        </div>
      </div>
    </div>
    <div class="row" v-if="Number(InfoSpouse.sp_age) > 16">
      <div class="card">
        <div class="card-header">
          <i class="ti ti-user"></i>
          <span
            >&nbsp;&nbsp;&nbsp;{{ $t("PersonalDetailsWidgetTitle") }} -
            {{ $t("tabSpouse") }}
          </span>
        </div>
        <div class="card-body box-body">
          <!-- Spouse Personal Details Widget (01/B) -->
          <InfoSpouse />
        </div>
      </div>
    </div>

    <div class="row" v-if="InfoInsured.product == 'MRTT'">
      <div class="card">
        <div class="card-header">
          <i class="ti ti-user"></i>
          <span
            >&nbsp;&nbsp;&nbsp;{{ $t("MrttCoverDetailsWidgetTitle") }}
          </span>
        </div>
        <div class="card-body box-body">
          <!-- Mrtt cover details -->
          <MrttCoverDetails />
        </div>
      </div>
    </div>

    <!-- TODO  -->
    <!-- v-if="typeof ChildInfoData !== 'undefined'" -->
    <div class="row"  v-if="typeof ChildInfoData !== 'undefined'">
      <!-- Child Personal Details Widget (01/B) -->
      <InfoChild
        v-for="(child, index) in ChildInfoData"
        :index="index"
        :key="index"
      />
    </div>

    <div class="row" v-if="InfoInsured.product != 'MRTT'">
      <div class="card">
        <div class="card-header">
          <i class="ti ti-user"></i>
          <span>&nbsp;&nbsp;&nbsp;{{ $t("NomineesTitle") }}</span>
        </div>
        <div class="card-body box-body">
          <!-- Info - Nominees Details Widget (02)-->
          <InfoNominees />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="card">
        <div class="card-header">
          <i class="ti ti-user"></i>
          <span
            >&nbsp;&nbsp;&nbsp;{{
              $t("HazardousOccupationPursuitTitile")
            }}</span
          >
        </div>
        <div class="card-body box-body">
          <!-- Info - Information Regarding Hazardous Occupation, Pursuit etc Question List Widget (10) -->
          <InfoHazardousQuestionList />
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Info - Details of Special Risks Question Widget (11) -->
      <div class="card">
        <div class="card-header">
          <i class="ti ti-user"></i>
          <span>&nbsp;&nbsp;&nbsp;{{ $t("DetailsSpecialRisksTitle") }}</span>
        </div>
        <div class="card-body box-body">
          <InfoDetailsSpecialRiskQuestion />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
/* Personal details insured/spouse widgets list import (01) */
import InfoInsuredWigt from "@/components/proposal/info/InfoInsured";
import InfoSpouse from "@/components/proposal/info/InfoSpouse";
import InfoChild from "@/components/proposal/info/InfoChild";
import MrttCoverDetails from "@/components/proposal/mrtt/CoverDetails";

/* Nominees widgets list import (02) */
import InfoNominees from "@/components/proposal/info/InfoNominees";
/* Hazardous question widgets list import (10) */
import InfoHazardousQuestionList from "@/components/proposal/info/InfoHazardousQuestionList";
/* Special Risks question widgets list import (11) */
import InfoDetailsSpecialRiskQuestion from "@/components/proposal/info/InfoDetailsSpecialRiskQuestion";

export default {
  name: "personal-details-widget",
  components: {
    InfoInsuredWigt,
    InfoSpouse,
    InfoChild,
    InfoNominees,
    InfoHazardousQuestionList,
    InfoDetailsSpecialRiskQuestion,
    MrttCoverDetails
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("proposal", ["InfoInsured", "InfoSpouse", "ChildInfoData"]),
  },
  methods: {
    showMsgBoxError(errorMsg) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxOk(errorMsg, {
          title: "Error",
          size: "sm",
          buttonSize: "lg",
          okVariant: "danger",
          headerBgVariant: "danger",
          headerTextVariant: "light",
          bodyBgVariant: "danger",
          bodyTextVariant: "light",
          centered: true,
        })
        .then((value) => {
          this.boxTwo = value;
        })
        .catch((err) => {
          // An error occurred
          alert(err);
        });
    },
  },
};
</script>

<style scoped></style>

<!-- Language Data for Info Details Root Widget (01) -->
<i18n>
{
  "en": {
    "PersonalDetailsWidgetTitle": "Personal Details",
    "tabInsured": "Insured",
    "tabSpouse": "Spouse",
    "OccQuestionnaireWidgetTitle": " Occupation - Questions",
    "NomineesTitle": "Nominees (Beneficiary)",
    "HazardousOccupationPursuitTitile": "Information on Hobbies",
    "DetailsSpecialRisksTitle": "Details of Special Risks",
    "MrttCoverDetailsWidgetTitle": "Cover Details"
  },
  "si": {
    "PersonalDetailsWidgetTitle": "පෞද්ගලික තොරතුරු",
    "tabInsured": "ජීවිත රක්ෂිතයා",
    "tabSpouse": "කලත්‍රයා",
    "OccQuestionnaireWidgetTitle": "රැකියාව - ප්‍රශ්නාවලිය",
    "NomineesTitle": "නාමිකයන් (ප්‍රතිලාභියා)",
    "HazardousOccupationPursuitTitile": "විනෝදාංශ සහ විනෝදාස්වාදය පිළිබඳ තොරතුරුරු",
    "DetailsSpecialRisksTitle": "විශේෂ අවදානම් පිළිබඳ විස්තර",
    "MrttCoverDetailsWidgetTitle": "Cover Details"
  },
  "ta": {
    "PersonalDetailsWidgetTitle": "தனிப்பட்ட விவரங்கள்",
    "tabInsured": "பிரதான வாழ்க்கை",
    "tabSpouse": "வாழ்க்கைத்துணை",
    "OccQuestionnaireWidgetTitle": "தொழில் - கேள்வித்தாளை",
    "NomineesTitle": "வேட்பாளர்கள் (பயனாளி)",
    "HazardousOccupationPursuitTitile": "பொழுதுபோக்குகள் மற்றும் பொழுது போக்குகள் பற்றிய தகவல்கள்",
    "DetailsSpecialRisksTitle": "சிறப்பு அபாயங்களின் விவரங்கள்",
    "MrttCoverDetailsWidgetTitle": "Cover Details"
  }
}
</i18n>
