<!-- Mrtt Cover Details Widget-->
<template>
  <div class="col-sm-12 top-margin">
    <!-- Cover details row 1 Cover Amount , Period of repayment , Years including grace period , Years -->
    <ValidationObserver ref="coverDetailsSection">
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <label for="lbl_cover_amount">{{ $t("langCoverAmount") }} <span class="required-mark">*</span></label>
            <ValidationProvider immediate name="Cover Amount" rules="required">
              <div slot-scope="{ errors }">
                <input type="text" class="form-control" id="txCoverAmount" name="CoverAmount" placeholder="Cover Amount"
                  v-model.trim="BenefitData.mrtt_cover_amount" v-uppercase @change="sendChangedData()" readonly />
                <p class="p-error">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="lbl_period_of_repayment">{{ $t("langPeriodOfRepayment") }} <span
                class="required-mark">*</span></label>
            <ValidationProvider immediate name="Period Of Repayment" rules="required">
              <div slot-scope="{ errors }">
                <input type="text" class="form-control" id="txtPeriodOfRepayment" name="periodOfRepayment"
                  placeholder="Period Of Repayment" v-model="BenefitData.mrtt_period_of_repayment"
                  @change="sendChangedData()" readonly />
                <p class="p-error">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="lbl_grae_period">{{ $t("langGracePeriod") }} <span class="required-mark">*</span></label>
            <ValidationProvider immediate name="Grace Period" rules="required">
              <div slot-scope="{ errors }">
                <input type="text" class="form-control" id="txtGracePeriod" name="GracePeriod" placeholder="Grace Period"
                  v-model="BenefitData.mrtt_grace_period" v-uppercase @change="sendChangedData()" readonly />
                <p class="p-error">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-sm-3">
          <label for="mrtt_cover_option">{{ $t("langCoverOption") }} <span class="required-mark">*</span></label>
          <b-form-group v-slot="{ ariaDescribedby }">
            <ValidationProvider immediate name="Cover" rules="required">
              <div slot-scope="{ errors }">
                <b-form-radio-group id="mrtt_cover_option" v-model="BenefitData.mrtt_cover_option_type"
                  :options="cover_options" :aria-describedby="ariaDescribedby" name="radio-options"></b-form-radio-group>
                <p class="p-error">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </b-form-group>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="lbl_repayment_rate">{{ $t("langRepaymentRate") }} <span class="required-mark">*</span></label>
            <ValidationProvider immediate name="Repayment Rate" rules="required">
              <div slot-scope="{ errors }">
                <input type="text" class="form-control" id="txRepaymentRate" name="RepaymentRate"
                  placeholder="Repayment Rate" v-model="BenefitData.mrtt_period_of_repayment" @change="sendChangedData()"
                  readonly />
                <p class="p-error">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <!-- Personal details row 1 End -->

      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <label for="lbl_name_of_fi">{{ $t("langNameOfFI") }} <span class="required-mark">*</span></label>
            <ValidationProvider immediate name="Name Of Financial Institute" rules="required">
              <div slot-scope="{ errors }">
                <input type="text" class="form-control" id="txtNameOfFI" name="NameOfFI"
                  placeholder="Name Of Financial Institute" v-model.trim="InfoInsured.name_of_fi" v-uppercase
                  @change="sendChangedData()" />
                  <!-- <v-select
                    class="select2"
                    style="width: 100%"
                    v-validate="'required'"
                    name="Name Of Financial Institute"
                    :options="fInstitutionList"
                    v-model.trim="InfoInsured.name_of_fi"
                    :reduce="(val) => val.code"
                    @input="sendChangedData()"
                  ></v-select> -->

                <p class="p-error">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="lbl_address_of_fi">{{ $t("langAddressOfFI") }} <span class="required-mark">*</span></label>
            <ValidationProvider immediate name="Address Of Financial Institute" rules="required">
              <div slot-scope="{ errors }">
                <input type="text" class="form-control" id="txtAddressOfFI" name="AddressOfFI"
                  placeholder="Address Of Financial Institute" v-model.trim="InfoInsured.address_of_fi" v-uppercase
                  @change="sendChangedData()" />
                <p class="p-error">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="lbl_relationship_with_joint_borrower">{{ $t("langRelationshipWithJointBorrower") }} <span
                class="required-mark">*</span></label>
            <ValidationProvider immediate name="Relationship With Joint Borrower" rules="required">
              <div slot-scope="{ errors }">
                <input type="text" class="form-control" id="txtRelationshipWithJointBorrower"
                  name="RelationshipWithJointBorrower" placeholder="Relationship With Joint Borrower"
                  v-model.trim="InfoInsured.relationship_joint_borrower" v-uppercase @change="sendChangedData()" />
                <p class="p-error">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
        </div>

      </div>
      <!-- Personal details row 2 End -->
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { throttle } from "lodash";
import helpMixin from "@/mixins/filters";

export default {
  name: "mrtt-cover-details-widget",
  data() {
    return {
      cover_options: [
        { text: 'Death Only', value: 1 },
        { text: 'Death & PTD', value: 2 }
      ],
    };
  },
  computed: {
    ...mapState("proposal", ["MrttCoverData", "BenefitData", "InfoInsured"]),
    ...mapState("quote_mrtt", ["fInstitutionList"])
  },
  mixins: [helpMixin],
  methods: {
    ...mapActions("proposal", ["clearStateData", "draftProposalData"]),
    ...mapActions("quote_mrtt", ["getFInstitutionList"]),
    sendChangedData: throttle(async function () {
      this.callDraftProposalData();
    }, process.env.VUE_APP_TIMEOUT_SEND_CHANGED_DATA),
    async callDraftProposalData() {
      await this.draftProposalData();
    }
  },
  mounted(){
    this.getFInstitutionList()
  }
};
</script>

<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

/* -- togle switch ---*/

.toggleswitchg input:checked+label {
  background: #e9ecef !important;
  color: #575e65 !important;
  font-weight: 400 !important;
  border: 1px solid #ced4da !important;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked+label {
  background: #039fec;
  color: #fff;
  font-weight: 600;
  border: 1px solid #039fec;
}
</style>

<!-- Language Data for Personal Details Insured Widget -->
<i18n>
{
  "en": {    
    "langCoverAmount": "Cover Amount",
    "langPeriodOfRepayment": "Period Of Repayment",
    "langGracePeriod": "Grace Period",
    "langRepaymentRate": "Repayment Rate",
    "langNameOfFI":"Name Of Financial Institute",
    "langAddressOfFI": "Address Of Financial Institute",
    "langRelationshipWithJointBorrower": "Relationship With Joint Borrower",
    "langCoverOption": "Cover"
  },
  "si": {
    "langCoverAmount": "Cover Amount",
    "langPeriodOfRepayment": "Period Of Repayment",
    "langGracePeriod": "Grace Period",
    "langRepaymentRate": "Repayment Rate",
    "langNameOfFI":"Name Of Financial Institute",
    "langAddressOfFI": "Address Of Financial Institute",
    "langRelationshipWithJointBorrower": "Relationship With Joint Borrower",
    "langCoverOption": "Cover"
  },
  "ta": {
    "langCoverAmount": "Cover Amount",
    "langPeriodOfRepayment": "Period Of Repayment",
    "langGracePeriod": "Grace Period",
    "langRepaymentRate": "Repayment Rate",
    "langNameOfFI":"Name Of Financial Institute",
    "langAddressOfFI": "Address Of Financial Institute",
    "langRelationshipWithJointBorrower": "Relationship With Joint Borrower",
    "langCoverOption": "Cover"
  }
}
</i18n>
