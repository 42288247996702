  <template>
    <div class="card">
      <div class="card-header">
        <font-awesome-icon icon="align-left" />&nbsp;
        <span>{{ $t('capitalGrowthTable')}}</span>
      </div>
      <div class="card-body">
        <div class="row tbl-margin-top">
          <div class="col table-responsive">
            <div style="max-height: 510px">
              <b-table striped hover :items="capital_growth"></b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    //VuetablePagination
  },
  data() {
    return {
      items: this.capital_growth,
      show: false,
      fields: [
        {
          name: "id",
          title: '<span class="orange glyphicon glyphicon-user"></span> #',
          sortField: "id"
        },
        {
          name: "date",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Contribtuion Year',
          sortField: "date"
        },
        {
          name: "time",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Capital Growth',
          sortField: "time"
        },

        "__slot:actions"
      ],
      httpOptions: {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        },
        data: {}
      },
      moreParams: { lead_no: this.$route.params.ref_no },
      getUrl: process.env.VUE_APP_API_ENDPOINT + "lead/appointment"
    };
  },
  computed: {
    ...mapState("lead", ["AppointmentInfo", "AppointForm"]),
    ...mapState("pension_calc", ["capital_growth"]),
    ...mapGetters("login", ["checkUserPermissionNew"]),
    loadTbl() {
      return this.AppointForm.isSave;
    },
    refreshTbl() {
      return this.AppointForm.loadTable;
    }
  },
  watch: {
    loadTbl() {
      this.$refs.vuetable.refresh();
    },
    refreshTbl() {
      this.$refs.vuetable.refresh();
    }
  },
  methods: {
    ...mapActions("lead", ["addAppointment", "loadAppoint"]),

    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onActionClicked(action, data) {
      if (action == "edit-item") {
        this.loadAppointmentInfo(data);
        this.AppointmentInfo.txtAppointID = data.id;
        this.AppointForm.isSave = false;
      }
    },
    async loadAppointmentInfo(data) {
      await this.loadAppoint(data);
    },
    viewPermissionCheck() {
      return this.checkUserPermissionNew("view-quote");
    }
  }
};
</script>

<style>
.modal-backdrop {
  background-color: #0000001a !important;
}

.modal-content {
  border: 0px solid !important;
}

.tbl-margin-top {
  margin-top: 15px;
}

table th,
.table td {
  padding: 0.2rem 0.75rem !important;
  vertical-align: middle !important;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}

.pagination {
  float: right;
}

.page-link {
  font-size: 12px !important;
}
.tooltip {
  top: 0 !important;
}
</style>

<i18n>
{
  "en": {
    "capitalGrowthTable": "Capital Growth"
  },
  "si": {
  "capitalGrowthTable": "Capital Growth"
  },
  "ta": {
    "capitalGrowthTable": "Capital Growth"
  }
}
</i18n>