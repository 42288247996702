<template>
  <div>
  <Header />
    <LeftNav />
    <main id="page-wrap">
      <!-- <vue-snotify></vue-snotify> -->
      <b-container fluid>
      <RoleList/>
      </b-container>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/layout/AppHeader.vue";
import LeftNav from "@/components/common/layout/AppLeftNav";
import Footer from "@/components/common/layout/AppFooter.vue";
import RoleList from "@/components/role_management/role_list.vue";

export default {
name: "UserRole",
  components: {
    Header,
    LeftNav,
    Footer,
    RoleList,
  }
}
</script>

<style>

</style>