import { render, staticRenderFns } from "./audit_trail.vue?vue&type=template&id=58f7565c&scoped=true"
import script from "./audit_trail.vue?vue&type=script&lang=js"
export * from "./audit_trail.vue?vue&type=script&lang=js"
import style0 from "./audit_trail.vue?vue&type=style&index=0&id=58f7565c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58f7565c",
  null
  
)

/* custom blocks */
import block0 from "./audit_trail.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports