<!-- create/modify customer quotations -->
<template>
  <div>
    <Header />
    <SumView v-if="calcSummary.summary_formatted != undefined" />
    <LeftNav />
    <main id="page-wrap">
      <vue-snotify></vue-snotify>
      <ValidationObserver ref="form">
        <form v-on:submit.prevent="onSubmit">
          <b-container fluid>
            <MainLifeInfoWidget />
            <FamilyInfoWidget />
            <LifeCoveredWidget />
            <RidersWidgetHolder
              v-if="mainInfoData.rdbMainLifeFrequency != 'S'"
            />
            <QuoteGenButton ref="GenerateButton" />
          </b-container>
        </form>
      </ValidationObserver>
    </main>
    <Footer />
  </div>
</template>

<script>
import MainLifeInfoWidget from "@/components/quote_term_plus_cic/MainLifeInfoWidget.vue";
import FamilyInfoWidget from "@/components/quote_term_plus_cic/FamilyInfoWidget.vue";
import LifeCoveredWidget from "@/components/quote_term_plus_cic/LifeCoveredWidget.vue";
import RidersWidgetHolder from "@/components/quote_term_plus_cic/RidersWidgetHolder.vue";
import Header from "@/components/common/layout/AppHeader.vue";
import Footer from "@/components/common/layout/AppFooter.vue";
import SumView from "@/components/common/layout/AppSummaryViewTermPlusCIC";
import LeftNav from "@/components/common/layout/AppLeftNav";
import QuoteGenButton from "@/components/quote_term_plus_cic/QuoteGenButton.vue";
import { mapActions, mapState } from "vuex";
import { SnotifyPosition } from "vue-snotify";

export default {
  name: "NewQuote",
  components: {
    MainLifeInfoWidget,
    FamilyInfoWidget,
    LifeCoveredWidget,
    RidersWidgetHolder,
    Header,
    Footer,
    SumView,
    LeftNav,
    QuoteGenButton,
  },
  computed: {
    ...mapState("quote_term_plus_cic", ["mainInfoData", "calcSummary", "flash"]),
    ...mapState("common_actions", ["shakingIconAction"])
  },
  methods: {
    ...mapActions("quote_term_plus_cic", ["addChangedData"]),

    async onSubmit() {
      this.$refs.GenerateButton.disableButton();
      this.$refs.form
        .validate()
        .then((result) => {
          if (!result) {
            this.$refs.GenerateButton.enableButton();
            this.$snotify.error("There are items that require your attention", {
              timeout: 3000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              position: SnotifyPosition.centerTop,
            });

            return;
          } else {
            if (this.$route.params.ref_no) {
              this.addChangedData("update", this.$route.params.ref_no);
              if (this.flash.status && this.flash.type == "S") {
                this.$snotify
                  .success("Quotation Successfully Updated", {
                    timeout: 3000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    position: SnotifyPosition.centerTop,
                  })
                  .on("destroyed", () =>
                    this.$router.push({
                      name: "quote_list",
                    })
                  );
                //this.$router.push("/quote_list");
              }
            } else {
              (async () => {
                await this.addChangedData("create");
                if (this.flash.status && this.flash.type == "S") {
                  this.$snotify
                    .success("Quotation Successfully Created", {
                      timeout: 3000,
                      showProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: false,
                      position: SnotifyPosition.centerTop,
                    })
                    .on("destroyed", () =>
                      this.$router.push({
                        name: "quote_list",
                      })
                    );
                  //this.$router.push("/quote_list");
                } else {
                  this.$snotify.error(this.flash.error, {
                    timeout: 3000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    position: SnotifyPosition.centerTop,
                  });
                  this.$refs.GenerateButton.enableButton();
                }
              })();
            }
          }
        })
        .catch(() => {});
    },
  },
  mounted(){
    this.shakingIconAction.product = 'term_plus';
  }
};
</script>

<style scoped>
div.card .card-header {
  background: #ff660d !important; /* fallback for old browsers */
  box-shadow: 0 10px 10px -12px rgba(0, 0, 0, 0.42),
    0 4px 10px 0px rgba(0, 0, 0, 0.12), 0 5px 7px -5px rgba(0, 0, 0, 0.2);
}
</style>
