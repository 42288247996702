import Vue from "vue";
import App from "@/App.vue";
import store from "@/store/store";
//import login from "@/store/modules/login";
import lead from "@/store/modules/lead";
import router from "@/router";
import {BootstrapVue,IconsPlugin } from "bootstrap-vue";
import VueI18n from "vue-i18n";
import { library } from "@fortawesome/fontawesome-svg-core";
import VueSignaturePad from "vue-signature-pad"; // Signature pad library
import NProgress from "nprogress"; // Nprogress library for router switching progress bar
/** Import Vee Validate 3.1 */
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {
  required,
  email,
  min,
  alpha_spaces,
  alpha,
  regex,
  length,
  oneOf,
  max_value,
  min_value,
  numeric,
  required_if,
  max,
} from "vee-validate/dist/rules";

// Install components globally - Vee Validate 3.1
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component('loading', Loading);
// Override the default message - Vee Validate 3.1
extend("required", {
  ...required,
  message: "The {_field_} field is required",
});
extend("required_if", {
  ...required_if,
  message: "The {_field_} field is required",
});
extend("email", {
  ...email,
  message: "This {_field_} field must contain a valid email address",
});

extend("min", {
  ...min,
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: "The {_field_} field must have at least {length} characters",
});
var minmax, minmax_value;
extend("minmax", {
  ...minmax,
  validate(value, { min, max }) {
    return value.length >= min && value.length <= max;
  },
  params: ["min", "max"],
  message:
    "The {_field_} field must have at least {min} characters and {max} characters at most",
});

extend("alpha_spaces", {
  ...alpha_spaces,
  message:
    "The {_field_} field may only contain alphabetic characters as well as spaces",
});

extend("alpha", {
  ...alpha,
  message: "The {_field_} field may only contain alphabetic characters",
});

extend("regex", {
  ...regex,
  message: "The {_field_} field format is invalid",
});

extend("length", {
  params: ["length"],
  ...length,
  message: "The {_field_} field must be {length} long",
});

extend("oneOf", {
  ...oneOf,
  message: "The {_field_} is not a valid value",
});

extend("min_value", {
  ...min_value,
  params: ["min"],
  message: "The {_field_} must be {min} or more",
});

extend("max_value", {
  ...max_value,
  params: ["max"],
  message: "The {_field_} field must be {max} or less",
});

extend("numeric", {
  ...numeric,
  message: "The {_field_} field may only contain numeric characters",
});

extend("max", {
  params: ["length"],
  ...max,
  message: "The {_field_} field max length is {length}",
});

extend("minmax_value", {
  ...minmax_value,
  validate(value, { min, max }) {
    return parseInt(value) >= parseInt(min) && parseInt(value) <= parseInt(max);
  },
  params: ["min", "max"],
  message: "The {_field_} field value must be at least {min} and {max} at most",
});

//Appointment Status Validation
extend("appointmentStatusValidation", {
  params: ["AppointmentRemarks"],
  validate(value, { AppointmenRemarks }) {
    // let today = new Date(lead.getters.timestamp()).getDate();
    // let appdate = new Date(AppointmentDate).getDate();

    let functionreturn = "";

    // alert(value);
    if (value == "Visited") {
      functionreturn = true;
    } else if (value == "Cancelled" && AppointmenRemarks == "") {
      functionreturn = false;
    } else {
      functionreturn = true;
    }

    return functionreturn;
  },
  message: "Please Select a valid {_field_} ",
});

/*Decimal Validation*/
extend("decimal", {
  validate: (value, { decimals = "2", separator = "." } = {}) => {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`;
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
    );
    return {
      valid: regex.test(value),
      data: {
        serverMessage: "Only decimal values are available",
      },
    };
  },
  message: "Only decimal values up to two digits are available",
});

//Appointment Status Validation
extend("appointmentStVal", {
  params: ["AppointmentDate"],
  validate(value, { AppointmentDate }) {
    let today = new Date(lead.getters.timestamp()).getDate();
    let appdate = new Date(AppointmentDate).getDate();

    let functionreturn = "";

    // alert(today);
    if (appdate < today) {
      if (value == "Pending") {
        functionreturn = false;
        // lead.options[0].disabled = true;
      } else if (value == "Expired") {
        functionreturn = false;
      } else if (value == "Rescheduled") {
        functionreturn = false;
      } else if (value == "Visited") {
        functionreturn = true;
      } else if (value == "Cancelled") {
        functionreturn = true;
      } else {
        functionreturn = false;
      }
    } else if (appdate.valueOf() == today.valueOf()) {
      if (value == "Pending") {
        functionreturn = true;
      } else if (value == "Expired") {
        functionreturn = false;
      } else if (value == "Rescheduled") {
        functionreturn = true;
      } else if (value == "Visited") {
        functionreturn = true;
      } else if (value == "Cancelled") {
        functionreturn = true;
      } else {
        functionreturn = false;
      }
      //console.log('same');
    } else if (appdate > today) {
      if (value == "Pending") {
        functionreturn = true;
      } else if (value == "Expired") {
        functionreturn = false;
      } else if (value == "Rescheduled") {
        functionreturn = true;
      } else if (value == "Visited") {
        functionreturn = false;
      } else if (value == "Cancelled") {
        functionreturn = true;
      } else {
        functionreturn = false;
      }
    } else {
      functionreturn = false;
    }

    return functionreturn;
  },
  message: "Please Select a valid {_field_} ",
});

// Title and gender validation
extend("genderValidation", {
  params: ["InGender"],
  validate(value, { InGender }) {
    let gender = "M";
    if (InGender !== undefined) {
      gender = InGender[0];
    }
    let functionreturn = "";

    switch (gender) {
      case "M":
        if (
          value == "Mr." ||
          value == "Dr." ||
          value == "Ven." ||
          value == "Rev." ||
          value == "Master"
        ) {
          functionreturn = true;
        } else {
          functionreturn = false;
        }
        break;
      case "F":
        if (
          value == "Mrs." ||
          value == "Miss." ||
          value == "Ms." ||
          value == "Ven." ||
          value == "Dr." ||
          value == "Rev." ||
          value == "Miss."
        ) {
          functionreturn = true;
        } else {
          functionreturn = false;
        }
        break;
      default:
        functionreturn = false;
    }
    return functionreturn;
  },
  message: "The {_field_} does not match the gender",
});

extend("mrttGracePeriodValidation", {
  params: ["Term"],
  validate(value, { Term }) {
    if (Term !== undefined) {
      if (parseInt(value) >= parseInt(Term)) {
        return false;
      }
    }
    return true;
  },
  message: "The {_field_} should less than Term",
});

extend("mrttAgeTermValidation", {
  validate(value, [Term]) { 
    if (Term !== undefined) {
      if (parseInt(value) + parseInt(Term)>= 65) {
        return false;
      }
    }
    return true;
  },
  message: "The {_field_} plus Term value should less than 65",
});

extend("mrttTermValidation", {
  params: ["GracePeriod"],
  validate(value, { GracePeriod }) {
    if (GracePeriod !== undefined) {
      if (parseInt(value) <= parseInt(GracePeriod)) {
        return false;
      }
    }
    return true;
  },
  message: "The {_field_} should greater than Grace Period",
});


import {
  faEye,
  faHeartbeat,
  faTimes,
  faUserSecret,
  faUsers,
  faMale,
  faFemale,
  faChild,
  faCartPlus,
  faFilePdf,
  faAlignRight,
  faAlignLeft,
  faPlus,
  faFolderOpen,
  faFile,
  faTh,
  faChartPie,
  faStethoscope,
  faSearch,
  faBan,
  faPen,
  faUserCircle,
  faPowerOff,
  faFolder,
  faUserTie,
  faArrowRight,
  faArrowAltCircleRight,
  faFileInvoice,
  faCalculator,
  faMinusCircle,
  faTrash,
  faSms,
  faCheck,
  faShare,
  faDownload,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import "@icon/themify-icons/themify-icons.css"; // Themify Icons for Form Wizard
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueEvents from "vue-events";

import vSelect from "vue-select";

import "@/assets/css/snotify.css";
import Snotify from "vue-snotify";
import "vue-snotify/styles/material.css";
Vue.use(Snotify);

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
require("@/assets/css/custom.css");

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-select/dist/vue-select.css";
import "../node_modules/nprogress/nprogress.css"; // Progress bar on router switching

import VueNumeric from "vue-numeric";

Vue.use(VueNumeric);

Vue.config.productionTip = false;

Vue.use(VueEvents);
Vue.use(Snotify);
Vue.component("v-select", vSelect);
Vue.use(VueSignaturePad); // Signature pad

library.add(faEye);
library.add(faHeartbeat);
library.add(faTimes);
library.add(faUserSecret);
library.add(faUsers);
library.add(faFemale);
library.add(faMale);
library.add(faChild);
library.add(faCartPlus);
library.add(faFilePdf);
library.add(faAlignRight);
library.add(faPlus);
library.add(faFolderOpen);
library.add(faFile);
library.add(faTh);
library.add(faChartPie);
library.add(faStethoscope);
library.add(faSearch);
library.add(faBan);
library.add(faAlignLeft);
library.add(faPen);
library.add(faUserCircle);
library.add(faPowerOff);
library.add(faFolder);
library.add(faUserTie);
library.add(faArrowRight);
library.add(faArrowAltCircleRight);
library.add(faFileInvoice);
library.add(faCalculator);
library.add(faMinusCircle);
library.add(faTrash);
library.add(faSms);
library.add(faCheck);
library.add(faShare);
library.add(faDownload);
library.add(faUpload);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueI18n);

/** File Upload Library */
const VueUploadComponent = require("vue-upload-component");
Vue.component("file-upload", VueUploadComponent);

let lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : null;

if (lang == null) {
  lang = "en";
  localStorage.setItem("lang", lang);
}

const i18n = new VueI18n({
  locale: lang, // set locale
  fallbackLocale: "en", // set fallback locale
});

/** Upper case input field - masking */
Vue.directive("uppercase", {
  update(el) {
    el.value = el.value.toUpperCase();
  },
});
/** Lower case input field - masking */
Vue.directive("lowercase", {
  update(el) {
    el.value = el.value.toLowerCase();
  },
});

/** Before Permission Development 
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (login.getters.loggedIn() == false) {
      next({
        path: "/"
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
*/

/** Permission Development - New Code */
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAdmin)) {
//     if (login.getters.loggedIn() == false) {
//       next({
//         path: "/"
//       });
//     } else {
//       // Check user has permissions for search, create, edit quote
//       if (
//         to.matched.some(record => record.meta.permission.searchQuote) &&
//         login.getters.checkUserPermissionSearch() == true
//       ) {
//         next();
//       } else if (
//         to.matched.some(record => record.meta.permission.createQuote) &&
//         login.getters.checkUserPermissionCreate() == true
//       ) {
//         next();
//       } else if (
//         to.matched.some(record => record.meta.permission.editQuote) &&
//         login.getters.checkUserPermissionEdit() == true
//       ) {
//         next();
//       }else if (

//         to.matched.some(record => record.meta.permission.viewBackend) &&
//         login.getters. checkUserPermissionViewBackend() == true
//       ) {
//         next();
//       } else {
//         next({
//           path: from.path
//         });
//       }
//     }
//   } else {
//     next();
//   }
// });

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("access_token");
  // If logged in, or going to the Login page.

  if (token) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      var base64Url = localStorage.getItem("access_token").split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      let payload = JSON.parse(jsonPayload);
      let permissions = payload.scopes;
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (
          to.meta.permission && !to.meta.permission.every((i) => permissions.includes(i)) &&
          to.meta.permission
        ) {
          store.commit("login/doLogout");
          next({ name: "login" });
        } else {
          next();
        }
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    if (to.meta.requiresAuth) {
      next({ name: "login" });
    } else {
      next();
    }
  }
});

/** Progress bar on router switching */
router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.afterEach((to, from) => {
  if (to.name && from.name) {
    NProgress.done();
  }
});

new Vue({
  i18n,
  store,
  router,
  BootstrapVue,
  beforeCreate() {
    Vue.$snotify = this.$snotify;
  },
  render: (h) => h(App),
}).$mount("#app");
