<!-- Quotation Generate /Update Button Component-->
<template>
  <div>
    <b-row class="pb-5">
      <div class="col-sm-10"></div>
      <div class="col-sm-2">
        <button type="submit" class="btn btn btn-success btn-md btn-block btn_height" :disabled="isDisabled">
          <font-awesome-icon icon="folder" />&nbsp;Generate
        </button>
      </div>
    </b-row>
  </div>
</template>

<script>
export default {
  data () {
      return {
        isDisabled: false
      }
    },
  methods: {
    disableButton() {
      this.isDisabled = true;
    },
    enableButton() {
      this.isDisabled = false;
    }
  }
};
</script>

<style scoped>
.gen-btn {
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .btn-sm,
  .btn-group-sm > .btn {
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    line-height: 1.5;
  }
}
</style>

    