<!--Lead Information Capture Widget  -->
<template>
  <div class="row row-top" style="scroll: none; margin-bottom: -50px">
    <div class="col-sm-12 top-margin">
      <div class="card">
        <div class="card-header header-font">
          <font-awesome-icon icon="heartbeat" />
          <span>&nbsp;{{ $t("mainLifeHeaderMsg") }}</span>
        </div>

        <div class="card-body">
          <ValidationObserver ref="namerow">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_title">
                    {{ $t("mainLifeLblTitle") }}
                    <span style="color: red">*</span>
                  </label>
                  <ValidationProvider
                    name="Title"
                    rules="required|genderValidation:@InGender"
                    ref="title"
                  >
                    <div slot-scope="{ errors }">
                      <select
                        class="form-control"
                        v-model="leadInfoData.drpTitle"
                        name="Title"
                        :disabled="isDisabled"
                      >
                        <option>Please select</option>
                        <option>Mr.</option>
                        <option>Mrs.</option>
                        <option>Miss.</option>
                        <option>Ms.</option>
                        <option>Ven.</option>
                        <option>Dr.</option>
                        <option>Rev.</option>
                      </select>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- Title -->

              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_first_name">
                    {{ $t("mainLifeLblFirstName") }}
                    <span style="color: red">*</span>
                  </label>
                  <ValidationProvider
                    name="First Name"
                    rules="required|minmax:3,60|alpha_spaces"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        ref="fname"
                        class="form-control"
                        id="txtMainLifeFname"
                        name="FirstName"
                        placeholder="First Name"
                        v-model="leadInfoData.txtLeadFname"
                        v-uppercase
                        :disabled="isDisabled"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- FirstName -->

              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_mid_name">
                    {{ $t("mainLifeLblMidName") }}
                  </label>
                  <ValidationProvider
                    name="Middle Name"
                    rules="minmax:3,60|alpha_spaces"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        id="txtMainLifeMname"
                        name="MiddleName"
                        placeholder="Middle Name"
                        v-model="leadInfoData.txtLeadMname"
                        v-uppercase
                        :disabled="isDisabled"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- Middle Name  -->

              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_last_name">
                    {{ $t("mainLifeLblLastName") }}
                    <span style="color: red">*</span>
                  </label>
                  <ValidationProvider
                    name="Last Name"
                    rules="required|minmax:3,60|alpha_spaces"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        id="txtMainLifeLname"
                        name="LastName"
                        placeholder="Last Name"
                        v-model="leadInfoData.txtLeadLname"
                        v-uppercase
                        :disabled="isDisabled"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- Lastname -->
            </div>

            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_nic">{{ $t("mainLifeLblNICNo") }}</label>
                  <ValidationProvider
                    name="NIC Number"
                    :rules="{
                      regex: /^(\d{9}?[V|X]{1}|\d{12})$/i,
                    }"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        id="txtMainLifeNIC"
                        name="NIC"
                        placeholder="NIC Number"
                        v-model="leadInfoData.txtLeadNIC"
                        v-uppercase
                        :disabled="isDisabled"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- NIC -->

              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_mobile">
                    {{ $t("mainLifeLblMobNo") }}
                    <span style="color: red">*</span>
                  </label>

                  <ValidationProvider
                    name="Contact No"
                    rules="required|numeric|length:10"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        id="txtMainLifeMob"
                        name="MobileNumber"
                        placeholder="Contact Number"
                        v-model="leadInfoData.txtLeadMob"
                        :disabled="isDisabled"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- Contact No -->

              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_email">{{
                    $t("mainLifeLblEmail")
                  }}</label>
                  <ValidationProvider name="E-mail" rules="email">
                    <div slot-scope="{ errors }">
                      <input
                        type="Email"
                        class="form-control"
                        id="txtMainLifeEmail"
                        placeholder="Email"
                        v-model="leadInfoData.txtLeadEmail"
                        name="Email"
                        v-lowercase
                        :disabled="isDisabled"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- Email -->

              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_residence_area">
                    {{ $t("mainLifeLblResidenceArea") }}
                    <span style="color: red">*</span>
                  </label>
                  <ValidationProvider
                    name="Residence Area"
                    :rules="{
                      required,
                      min: 3,
                      max: 60,
                      regex: /^[A-Z\d\s]+$/i,
                    }"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        id="txtMainLifeResidenceArea"
                        name="ResidenceArea"
                        placeholder="Residence Area"
                        v-model="leadInfoData.txtResidenceArea"
                        v-uppercase
                        :disabled="isDisabled"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- Residence Area -->
            </div>

            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_dob">
                    {{ $t("mainLifeLblDOB") }}
                  </label>
                  <ValidationProvider
                    name="Date Of Birth"
                    :rules="{
                      required,
                    }"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="date"
                        class="form-control"
                        id="txtMainLifeDOB"
                        name="DateOfBirth"
                        placeholder="Date of Birth"
                        v-model="leadInfoData.txtLeadDOB"
                        :disabled="isDisabled"
                        :max="today"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- Date of Birth -->

              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_dob">
                    {{ $t("leadContactedDate") }}
                  </label>
                  <ValidationProvider name="Contacted Date" rules="required">
                    <div slot-scope="{ errors }">
                      <input
                        type="date"
                        class="form-control"
                        id="txtLeadContactDate"
                        name="leadContactedDate"
                        v-model="leadInfoData.txtLeadContactedDate"
                        :disabled="isDisabled"
                        :max="today"
                      />

                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- Contacted Date -->

              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_gender">
                    {{ $t("mainLifeLblGender") }}
                  </label>
                  <ValidationProvider name="InGender" rules="required">
                    <div slot-scope="{ errors }">
                      <div class="toggleswitchg">
                        <input
                          id="INSMale"
                          checked="checked"
                          name="INSGender"
                          ref="rdbMainGender"
                          type="radio"
                          value="Male"
                          v-model="leadInfoData.rdbLeadGender"
                        />
                        <label for="INSMale">
                          <i class="fa fa-mars"></i> Male
                        </label>
                        <input
                          id="INSFemale"
                          name="INSGender"
                          ref="rdbMainGender"
                          type="radio"
                          value="Female"
                          v-model="leadInfoData.rdbLeadGender"
                        />
                        <label for="INSFemale">
                          <i class="fa fa-venus"></i> Female
                        </label>
                      </div>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <!-- Gender  -->
            </div>
          </ValidationObserver>

          <b-row>
            <div class="col-sm-10"></div>
            <div class="col-sm-2">
              <button
                type="submit"
                ref="createBtn"
                class="btn btn btn-success btn-sm btn-block btn_height"
                v-on:click="onSubmit"
                v-show="isSave"
                :disabled="isDisabled"
              >
                &nbsp;Create Lead
              </button>
              <button
                type="submit"
                ref="updateBtn"
                class="btn btn btn-success btn-md btn-block btn_height"
                v-on:click="onSubmit"
                v-show="!isSave && !isDisabled"
                :disabled="isDisabled"
              >
                &nbsp;Update Lead
              </button>

              <button
                type="submit"
                ref="updateBtn"
                class="btn btn btn-secondary btn-md btn-block btn_height"
                v-on:click="onCancel"
              >
                &nbsp;Cancel
              </button>
            </div>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";
// import { SnotifyPosition } from "vue-snotify";

//moment datepicker
import moment from "moment";
Vue.use(require("vue-moment"));
// import Datepicker from "vue-moment-datepicker";

export default {
  name: "lead-info-widget",

  data: function () {
    return {
      isSave: true,
      timestamp: this.getTimestamp(),
      disabledDates: {
        from: new Date(Date.now() + 8640000),
      },
      today: moment().format("YYYY-MM-DD"),
    };
  },
  components: {
    // Datepicker
  },
  async created() {
    if (this.$route.name == "view_lead") {
      this.isDisabled = "disabled";
    }
    if (this.$route.name == "edit_lead") {
      this.isDisabled = null;
    }
    if (this.$route.name == "new_lead") {
      this.isDisabled = null;
    }
    if (this.$route.params.ref_no) {
      await this.loadLeadInfo();
    } else {
      await this.clearData();
    }
  },

  computed: {
    ...mapState("lead", ["leadInfoData", "flash", "minflash"]), // assuming you are using namespaced modules
  },

  mounted() {
    if (this.$route.params.ref_no) {
      this.isSave = false;
    } else {
      this.loadDefLeadTime();
    }
  },

  methods: {
    ...mapActions("lead", [
      "addLead",
      "getOneLead",
      "updateLead",
      "clearStateData",
      "getTimestamp",
      "loadDefLeadTime",
    ]),

    customFormatter(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    async onSubmit() {
      this.$refs.namerow
        .validate()
        .then((result) => {
          if (!result) {
            // this.$snotify.error(
            //   "Please fill the required details to create the lead",
            //   {
            //     timeout: 3000,
            //     showProgressBar: true,
            //     closeOnClick: true,
            //     pauseOnHover: false,
            //     position: SnotifyPosition.centerTop
            //   }
            // );
            return;
          } else {
            if (this.$route.params.ref_no) {
              // Update Existing Lead
              this.updateLead(this.$route.params.ref_no);
              //this.$router.push("/lead_list");
              this.$router.push({
                name: "lead_list",
              });
            } else {
              // Add New Lead
              this.addLead();
              //this.$router.push("/lead_list");
              this.$router.push({
                name: "lead_list",
              });
            }
          }
        })
        .catch(() => {});
    },
    async onCancel() {
      //this.$router.push("/lead_list");
      this.$router.push({
        name: "lead_list",
      });
    },

    async loadLeadInfo() {
      await this.getOneLead(this.$route.params.ref_no);
    },
    async clearData() {
      await this.clearStateData();
    },
  },
};
</script>
<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

.datep > div > input {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 12px;
  color: #495057;
  padding-left: 5px;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #556065 !important;
  border-bottom: 0px solid #06a990 !important;
  color: #fff !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #06a990 !important;
  border-radius: 0.25rem;
}

.snotify {
  display: block;
  position: fixed;
  width: 99% !important;
  z-index: 9999;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: none;
}
/*-- toggle switch for Main Life Frequency ----*/

.togglefreq label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.togglefreq input:checked,
.togglefreq input:not(:checked) {
  display: none;
}

.togglefreq input:checked + label {
  background: #92d127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92d127;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked + label {
  background: #92d127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92d127;
}

.gen-btn {
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .btn-sm,
  .btn-group-sm > .btn {
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    line-height: 1.5;
  }
}

@media (max-width: 768px) {
  .top-margin {
    margin-top: 60px;
  }
}
</style>

<!-- Language Data for LeadInfoWidget-->
<i18n>
{
  "en": {
    "mainLifeHeaderMsg": "Lead Information",
    "mainLifeLblTitle": "Title",
    "mainLifeLblFirstName": "First Name",
    "mainLifeLblMidName": "Middle Name",
    "mainLifeLblLastName": "Last Name",
    "mainLifeLblNICNo": "NIC No",
    "mainLifeLblMobNo": "Contact No",
    "mainLifeLblTelNo": "Telephone No",
    "mainLifeLblEmail": "Email",
    "mainLifeLblOccupation": "Occupation",
    "mainLifeLblGender": "Gender",
    "mainLifeLblDOB": "Date of Birth ( MM/DD/YYYY)",
    "mainLifeLblResidenceArea": "Residence Area",
    "leadContactedDate": "Contacted Date"

  },
  "si": {
    "mainLifeHeaderMsg": "ජීවිත රක්ෂිතයාගේ පෞද්ගලික තොරතුරු",
    "mainLifeLblTitle": "ශීර්ෂය",
    "mainLifeLblFirstName": "මුල් නම",
    "mainLifeLblMidName": "මැද නම",
    "mainLifeLblLastName": "අවසන් නම",
    "mainLifeLblNICNo": "ජා.හැ.අංකය",
    "mainLifeLblMobNo": "ජංගම දුරකථන අංකය",
    "mainLifeLblTelNo": "දුරකථන අංකය",
    "mainLifeLblEmail": "විද්‍යුත් තැපෑල",
    "mainLifeLblOccupation": "රැකියාව",
    "mainLifeLblGender": "ස්ත්‍රී පුරුෂ භාවය",
    "mainLifeLblDOB": "උපන් දිනය ( MM/DD/YYYY)",
    "leadContactedDate": "Contacted Date"

  },
  "ta": {
    "mainLifeHeaderMsg": "பிரதான காப்புறுதிதாரரின் தனிப்பட்ட விபரங்கள்",
    "mainLifeLblTitle": "படிநிலை",
    "mainLifeLblFirstName": "முதற் பெயர்",
    "mainLifeLblMidName": "நடுத்தர பெயர்",
    "mainLifeLblLastName": "கடைசி பெயர்",
    "mainLifeLblNICNo": "தே. அ. அ. இலக்கம்",
    "mainLifeLblMobNo": "தொலைபேசி இலக்கம்",
    "mainLifeLblTelNo": "தொலைபேசி இலக்கம்",
    "mainLifeLblEmail": "மின்னஞ்சல்",
    "mainLifeLblOccupation": "தொழில்",
    "mainLifeLblGender": "பால்",
    "mainLifeLblDOB": "பிறந்த திகதி ( MM/DD/YYYY)",
    "leadContactedDate": "Contacted Date"

  }
}
</i18n>
