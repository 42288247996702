<!-- create/modify customer quotations -->
<template>
  <div>
    <Header />

    <LeftNav />
    <main id="page-wrap">
      <vue-snotify></vue-snotify>
      <b-container fluid class="calander_margin">
        <b-row class="justify-content-md-center row row-top">
          <b-col col lg="6" md="6" sm="12" style="margin-top:-50px;">
            <AppointmentCalendar @setAppointDate="getAppointmentDate($event)" />
          </b-col>
          <b-col col lg="6" md="6" sm="12">
            <AppointmentList :selectedDate="selectedAppDate" />
          </b-col>
        </b-row>
      </b-container>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/layout/AppHeader.vue";
import Footer from "@/components/common/layout/AppFooter.vue";
import LeftNav from "@/components/common/layout/AppLeftNav";
import AppointmentCalendar from "@/components/common/layout/AppointmentCalendarView.vue";
import AppointmentList from "@/components/common/layout/AppointmentCalendarEditList.vue";
//import { SnotifyPosition } from "vue-snotify";
import { mapState } from "vuex";

export default {
  name: "NewLead",
  data: () => {
    return {
      selectedAppDate: "",
    };
  },
  components: {
    Header,
    Footer,
    LeftNav,
    AppointmentCalendar,
    AppointmentList,
  },

  computed: {
    ...mapState("lead", ["AppointmentInfo"]),
  },

  mounted() {},

  methods: {
    getAppointmentDate(eventData) {
      this.selectedAppDate = eventData;
    },
  },
};
</script>

<style scoped>
div.card .card-header {
  background: #ff660d !important; /* fallback for old browsers */
  box-shadow: 0 10px 10px -12px rgba(0, 0, 0, 0.42),
    0 4px 10px 0px rgba(0, 0, 0, 0.12), 0 5px 7px -5px rgba(0, 0, 0, 0.2);
}

.col-6 {
  display: contents;
}
.calendar-margin {
  padding-top: 25px;
}
</style>
