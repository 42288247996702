import { render, staticRenderFns } from "./BenifitsPremiumWidget.vue?vue&type=template&id=6aa1d32e&scoped=true"
import script from "./BenifitsPremiumWidget.vue?vue&type=script&lang=js"
export * from "./BenifitsPremiumWidget.vue?vue&type=script&lang=js"
import style0 from "./BenifitsPremiumWidget.vue?vue&type=style&index=0&id=6aa1d32e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aa1d32e",
  null
  
)

/* custom blocks */
import block0 from "./BenifitsPremiumWidget.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports