  <template>
  <div class="row-top-list2 table-row-top">
    <div class="card">
      <div class="card-header">
        <font-awesome-icon icon="align-left" />&nbsp;
        <span>{{ $t("list_quotaion") }}</span>
      </div>
      <div class="card-body">
        <div class="row tbl-margin-top">
          <div class="col table-responsive">
            <vuetable
              class="table table-striped table-bordered nowrap vue-tbl"
              ref="quotesTable"
              :api-url="getUrl"
              :fields="fields"
              pagination-path
              @vuetable:pagination-data="onPaginationData"
              :per-page="3"
              :http-options="httpOptions"
              :append-params="moreParams"
            >
              <template slot="actions" scope="props">
                <!-- Hospital Selection Model Box - START -->
                <div>
                  <b-modal
                    v-model="show"
                    centered
                    :title="$t('quoteListLblModelHospitalTitle')"
                    header-bg-variant="primary"
                    header-text-variant="light"
                    body-bg-variant="light"
                    body-text-variant="dark"
                    footer-bg-variant=""
                    footer-text-variant="dark"
                    :id="'modal-center-medical' + props.rowData.id"
                    @hidden="resetMedicalLetterModal"
                    @ok="handleMedicalLetterOk"
                    @show="resetMedicalLetterModal"
                    ref="modal"
                  >
                    <div class="row">
                      <!-- Hospital list -->
                      <div class="col-sm-12">
                        <form @submit.stop.prevent="handleSubmit" ref="form">
                          <div class="form-group">
                            <label for="lbl_hospital_list">{{
                              $t("quoteListLblHospitals")
                            }}</label>
                            <v-select
                              class="select2"
                              style="width: 100%"
                              v-validate="'required'"
                              name="Hospital"
                              :options="hospitalList"
                              v-model="drpQuoteListHospital"
                            ></v-select>
                          </div>
                        </form>
                      </div>
                      <!-- Hospital list -->
                    </div>
                    <div class="row">
                      <!-- Selection Spouse, Main checkboxes -->
                      <div class="col-sm-12">
                        <div class="form-group">
                          <b-form-group label="Medical Letter for:">
                            <b-form-checkbox-group
                              id="med-letter-group"
                              v-model="selected"
                              :options="activeProduct == 'MRTT' ? optionsMrtt : options"
                              name="medletter"
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                  </b-modal>
                </div>
                <!-- Hospital Selection Model Box - END -->

                <div>
                  <b-modal
                    :id="'modal-center' + props.rowData.id"
                    centered
                    scrollable
                    title="Occupation Loading"
                  >
                    <div class="row">
                      <!-- Hospital list -->
                      <div class="col-sm-12">
                        <b-table :items="occupationLoadingList" responsive>
                          <!-- We are using utility class `text-nowrap` to help illustrate horizontal scrolling -->
                          <div
                            class="text-nowrap"
                            slot="HEAD[]"
                            slot-scope="scope"
                          >
                            {{ scope.label }}
                          </div>
                          <div class="text-nowrap" slot="[]" slot-scope="scope">
                            {{ scope.value }}
                          </div>
                        </b-table>
                      </div>
                    </div>
                  </b-modal>
                </div>

                <div
                  class="custom-actions"
                  style="white-space: nowrap; text-align: center"
                >
                  <b-button
                    style="margin:5px padding: .25rem .65rem;;"
                    variant="info"
                    class="ui btn-sm basic button"
                    title="Edit"
                    v-b-tooltip.hover.bottom="'Edit'"
                    @click="
                      onActionClicked(
                        'view-item',
                        props.rowData,
                        props.rowIndex
                      )
                    "
                    v-if="editPermissionCheck() == true"
                    v-show="props.rowData.prop_final != 'Y'"
                  >
                    <font-awesome-icon icon="pen" />
                  </b-button>
                  <b-button
                    style="margin: 5px; padding: 0.25rem 0.65rem"
                    variant="success"
                    class="ui btn-sm basic button"
                    title="PDF"
                    v-b-tooltip.hover.bottom="'PDF'"
                    @click="
                      onActionClicked(
                        'download-item',
                        props.rowData,
                        props.rowIndex,
                        'Q'
                      )
                    "
                    v-if="viewPermissionCheck() == true"
                  >
                    <font-awesome-icon icon="file-pdf" />
                  </b-button>
                  <b-button
                    style="margin: 5px"
                    variant="warning"
                    class="ui btn-sm basic button"
                    title="Letter"
                    v-b-tooltip.hover.bottom="'Letter'"
                    @click="onMedialLetterClick(props.rowData.id , props.rowData.product)"
                  >
                    <font-awesome-icon icon="stethoscope" />
                  </b-button>
                  <b-button
                    @click="onExLoadingClick(props.rowData.id)"
                    class="ui btn-sm basic button"
                    style="margin: 5px"
                    title="Ex Loading"
                    v-b-tooltip.hover.bottom="'Ex Loading'"
                    variant="danger"
                    v-if="exLoadingPermissionCheck() == true"
                  >
                    <font-awesome-icon icon="user-tie" />
                  </b-button>
                  <b-button
                    style="margin: 5px; padding: 0.25rem 0.65rem"
                    variant="warning"
                    class="ui btn-sm basic button"
                    title="Letter PDF"
                    v-b-tooltip.hover.bottom="'Letter PDF'"
                    @click="
                      onActionClicked(
                        'download-item',
                        props.rowData,
                        props.rowIndex,
                        'L'
                      )
                    "
                    v-if="viewPermissionCheck() == true"
                  >
                    <font-awesome-icon icon="file-pdf" />
                  </b-button>
                  <b-button
                    style="margin:5px padding: .25rem .65rem;;"
                    variant="danger"
                    class="ui btn-sm basic button"
                    title="Proposal"
                    v-b-tooltip.hover.bottom="'Proposal'"
                    @click="
                      onActionClicked(
                        'view-item',
                        props.rowData,
                        props.rowIndex,
                        'P'
                      )
                    "
                    v-if="editPermissionCheck() == true"
                    v-show="
                      props.rowData.prop_final != 'Y' &&
                      props.rowData.prop_conv != 'Y' &&
                      urole == 'ADV'
                    "
                  >
                    <font-awesome-icon icon="file-invoice" />
                  </b-button>
                </div>
              </template>
            </vuetable>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="pagination">
              <vuetable-pagination
                ref="Pagination"
                @vuetable-pagination:change-page="onChangePage"
              ></vuetable-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//console.log(process.env.VUE_APP_API_ENDPOINT);
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
//import { ValidationProvider } from "vee-validate";
//import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

import VuetablePagination from "@/components/vuetable/VuetablePaginationBootstrap4";
import commonMixin from "@/mixins/common";
import { mapState, mapActions, mapGetters } from "vuex";

// let urole = localStorage.getItem("user_role") ? localStorage.getItem("user_role") : null;

export default {
  components: {
    Vuetable,
    VuetablePagination,
    //VuetablePaginationInfo
  },
  data() {
    return {
      show: false,
      urole: localStorage.getItem("user_role")
        ? localStorage.getItem("user_role")
        : null,
      fields: [
        {
          name: "id",
          title: '<span class="orange glyphicon glyphicon-user"></span> #',
          sortField: "id",
        },
        {
          name: "quote_no",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Quotation Number',
          sortField: "quote_no",
        },
        {
          name: "version",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Version',
          sortField: "version",
        },
        {
          name: "product",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Product',
          sortField: "product",
        },
        {
          name: "name",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Customer Name',
          sortField: "name",
        },
        {
          name: "telephone",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Mobile Number',
          sortField: "telephone",
        },
        {
          name: "nic",
          title: '<span class="orange glyphicon glyphicon-user"></span> NIC',
          sortField: "nic",
        },
        {
          name: "agent_code",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Agent Code',
          sortField: "agent_code",
        },
        {
          name: "status",
          title: '<span class="orange glyphicon glyphicon-user"></span> Status',
          sortField: false,
        },
        "__slot:actions",
      ],
      httpOptions: {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: {},
      },
      moreParams: {},
      getUrl: process.env.VUE_APP_API_ENDPOINT + "quote/list",
      selected: ["Main"], // Must be an array reference!
      options: [
        { text: "Main", value: "Main" },
        { text: "Spouse", value: "Spouse" },
      ],
      optionsMrtt: [
        { text: "Main", value: "Main" },
        { text: "Joint", value: "Joint" },
      ],
      quote_no: null,
      drpQuoteListHospital: "Please select",
      activeProduct: null
    };
  },
  computed: {
    ...mapState("quote_platinum", ["hospitalList", "occupationLoadingList"]),
    ...mapGetters("login", ["checkUserPermissionNew"]),
    ...mapState("common_actions", ["shakingIconAction"]),
  },
  mixins: [commonMixin],
  mounted() {
    this.$snotify.clear();
    /* Get the hospital list on load  */
    this.getHospitalList();
    //this.getOccupationLoading();
    this.$events.$on("filter-set", (eventData) => this.onFilterSet(eventData));
    this.$events.$on("filter-reset", this.onFilterReset());
    this.shakingIconAction.product = "";
  },
  methods: {
    ...mapActions("quote_platinum", [
      "getHospitalList",
      "getRequestLetter",
      "getOccupationLoading",
    ]),
    ...mapActions("proposal", ["getBenefitData"]),

    onPaginationData(paginationData) {
      this.$refs.Pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.quotesTable.changePage(page);
    },
    onActionClicked(action, data, rowIndex, pdf = "N") {
      let lang = localStorage.getItem("lang");
      if (pdf == "Q") {
        let base = process.env.VUE_APP_BACKEND;
        let url =
          base +
          "/storage/quotation/" +
          data.id.toString().padStart(8, "0") +
          "_" +
          lang +
          ".pdf";
        window.open(url, "_blank");
      } else if (pdf == "L") {
        let base = process.env.VUE_APP_BACKEND;
        let url = base + "/storage/quotation/" + data.id + "_m_letter.pdf";
        window.open(url, "_blank");
      } else if (pdf == "P") {
        //getBenifitData
        this.getBenefitData(data.id).then(() => {
          this.$router.push({
            name: "proposal_list",
          });
        });
      } else {
        let str_edit_route = "";
        if (data.product == "Platinum") {
          str_edit_route = "edit_quote_platinum";
        } else if (data.product == "Surakshitha") {
          str_edit_route = "edit_quote_surakshitha";
        } else if (data.product == "Platinum Plus") {
          str_edit_route = "edit_quote_platinum_plus";
        } else if (data.product == "Adhyaphana Plus") {
          str_edit_route = "edit_quote_adyapana_plus";
        } else if (data.product == "Deergayu") {
          str_edit_route = "edit_quote_deergayu";
        } else if (data.product == "Flexi Life") {
          str_edit_route = "edit_quote_flexi_life";
        }else if (data.product == "MRTT") {
          str_edit_route = "edit_quote_mrtt";
        }else if (data.product == "Health Plus") {
          str_edit_route = "edit_quote_health_plus";
        }else if (data.product == "Term Plus CIC") {
          str_edit_route = "edit_quote_term_plus_cic";
        }
        

        this.$router.push({
          name: str_edit_route,
          params: {
            ref_no: data.id,
          },
        });
      }
    },
    onFilterSet(filters) {
      this.moreParams = {
        quote_no: filters.quote_no,
        name: filters.name,
        phone_no: filters.phone_no,
        nic: filters.nic,
        date_range :filters.date_range
      };
      Vue.nextTick(() => this.$refs.quotesTable.refresh());
    },
    onFilterReset() {
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.quotesTable.refresh());
    },
    viewPermissionCheck() {
      return this.hasPermission("view-quote");
    },
    editPermissionCheck() {
      return this.hasPermission("edit-quote");
    },
    exLoadingPermissionCheck() {
      return this.hasPermission("ex-loading");
    },
    onMedialLetterClick(id , product) {
      this.$bvModal.show("modal-center-medical" + id);
      this.quote_no = id;
      this.activeProduct = product;
    },
    handleMedicalLetterOk(bvModalEvt) {
      // Prevent modal from closing
      //bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleMedicalLetterSubmit(bvModalEvt);
    },
    handleMedicalLetterSubmit() {
      // Trigger submit handler
      if (!this.checkFormValidity()) {
        alert("There are items that require your attention");
      } else {
        let payload = {
          hospital: this.drpQuoteListHospital,
          parties: this.selected,
          quote_no: this.quote_no,
        };
        this.getRequestLetter(payload);
        this.$bvModal.hide("modal-center" + this.quote_no);
      }
    },
    resetMedicalLetterModal() {
      this.drpQuoteListHospital = "Please select";
      this.selected = ["Main"];
    },
    checkFormValidity() {
      if (this.selected.length == 0 || this.drpQuoteListHospital.code == "") {
        return false;
      } else {
        return true;
      }
    },
    async onExLoadingClick(id) {
      //console.log(id);
      this.$bvModal.show("modal-center" + id);
      let payload = {
        quote_no: id,
      };
      await this.getOccupationLoading(payload);
    },
  },
};
</script>

<style>
.modal-backdrop {
  background-color: #0000001a !important;
}

.modal-content {
  border: 0px solid !important;
}

.tbl-margin-top {
  margin-top: 15px;
}

table th,
.table td {
  padding: 0.2rem 0.75rem !important;
  vertical-align: middle !important;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}

.pagination {
  float: right;
}

.page-link {
  font-size: 12px !important;
}
.tooltip {
  top: 0 !important;
}
</style>

<i18n>
{
  "en": {
    "list_quotaion": "Quotation List",
    "quoteListLblHospitals": "Hospitals",
    "quoteListLblModelHospitalTitle": "Medical Letter Hospital"
  },
  "si": {
  "list_quotaion": "මිල කැඳවුම් ලැයිස්තුව",
    "quoteListLblHospitals": "රෝහල්",
    "quoteListLblModelHospitalTitle": "වෛද්‍ය ලිපි රෝහල"
  },
  "ta": {
    "list_quotaion": "விலைக்கோரலின் பட்டியல்",
    "quoteListLblHospitals": "மருத்துவமனை",
    "quoteListLblModelHospitalTitle": "மருத்துவ கடிதம் மருத்துவமனை"
  }
}
</i18n>