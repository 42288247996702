<!-- Health - Good Health Question Widget (03) -->
<template>
  <!-- <ValidationObserver ref="childHealthQ"> -->
  <ValidationObserver ref="health_child_question_row">

    <div>
      <div
        class="row"
        v-for="(question, index) in ChildHealthQuestions"
        :key="index"
      >
        <div
          class="col"
          v-if="
            (question.quest_answer_ch1 != 'X' ||
              question.quest_answer_ch2 != 'X' ||
              question.quest_answer_ch3 != 'X' ||
              question.quest_answer_ch4 != 'X' ||
              question.quest_answer_ch5 != 'X' ||
              question.quest_answer_ch6 != 'X'
              ) &&
            question.quest_order_id == 1
          "
        >
          <div class="card-outline mt-2 mb-2">
            <div class="card-body">
              <!-- Question content print -->
              <div class="row">
                <div class="col quest">{{ $t(question.quest_id) }}</div>
              </div>
              <div>
                <!-- Child 1-->
                <div class="row" v-if="question.quest_answer_ch1 != 'X'">
                  <div class="col-md-12 col-sm-12">
                    <div class="card-style">
                      <div class="media">
                        <div class="media-left">
                          <img
                            class="media-object card-img"
                            src="@/assets/img/spouse-hubby.png"
                          />
                        </div>

                        <div class="media-body">
                          <a href="#">
                            <h5 class="media-heading">&nbsp;</h5>
                          </a>
                          <div class="members">
                            <strong>{{ $t("Child") }} - 1 </strong>
                          </div>
                          <div class="btn-part" style="margin-right: 1px">
                            <div class="btn-part">
                              <div class="toggle-button toggle-button--aava">
                                <input
                                  :id="question.quest_id + 'child1'"
                                  type="checkbox"
                                  true-value="Y"
                                  false-value="N"
                                  :checked="question.quest_answer_ch1"
                                  v-model="question.quest_answer_ch1"
                                  @change="sendChangedData()"
                                />

                                <label
                                  :for="question.quest_id + 'child1'"
                                  data-on-text="YES"
                                  data-off-text="NO"
                                  class="toggle-lable"
                                ></label>
                                <div class="toggle-button__icon"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          class="text-area-pad"
                          v-show="question.quest_answer_ch1 == 'Y'"
                        >
                          <div class="row text-area-padding">
                            <div class="col-sm-12">
                              <b-form>
                                <ValidationProvider
                                  immediate
                                  :name="'Question '"
                                  :vid="
                                    question.quest_id +
                                    'GoodHealthReasonInsured_child1'
                                  "
                                  :rules="`${
                                    question.quest_answer_ch1 == 'Y'
                                      ? 'required'
                                      : ''
                                  }|alpha_spaces|min:3|max:60`"
                                >
                                  <div slot-scope="{ errors }">
                                    <span class="required-mark">*</span>
                                    <b-form-textarea
                                      id="GoodHealthReasonInsured"
                                      placeholder="Enter details here ..."
                                      rows="2"
                                      v-uppercase
                                      v-model="question.quest_yes_reason_ch1"
                                      @change="sendChangedData()"
                                    >
                                    </b-form-textarea>
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                          </div>
                          <!-- Following fields will only appear for 1st question -->
                          <div
                            class="row text-area-pad"
                            v-show="
                              question.quest_id == 'QChildGestationalWeeks'
                            "
                          >
                            <!-- Weeks at birth -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("WeeksAtBirth")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  :name="'Weeks at Birth'"
                                  :vid="question.quest_id + 'weeks_child1'"
                                  :rules="`${
                                    question.quest_answer_ch1 == 'Y'
                                      ? 'required'
                                      : ''
                                  }|max_value:40`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="weeks"
                                      type="text"
                                      class="form-control"
                                      placeholder="Birth Weeks"
                                      v-model="question.quest_birthweeks_ch1"
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                            <!-- Birth weight -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("BirthWeight")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  :name="'Weight at Birth '"
                                  :vid="question.quest_id + 'weight_child1'"
                                  :rules="`${
                                    question.quest_answer_ch1 == 'Y'
                                      ? 'required|decimal:2'
                                      : ''
                                  }|max_value:10`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="weight"
                                      type="text"
                                      class="form-control"
                                      placeholder="Birth Weight(KG)"
                                      v-model="question.quest_birthweight_ch1"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                            <!-- Duration of hospital stay after -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("DurationOfHospitalStay")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  :name="'Duration of Hospital Stay After (Days)'"
                                  :vid="question.quest_id + 'duration_child1'"
                                  :rules="`${
                                    question.quest_answer_ch1 == 'Y'
                                      ? 'required'
                                      : ''
                                  }|integer`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="duration"
                                      type="text"
                                      class="form-control"
                                      placeholder="Duration of Hospital Stay After  (Days)"
                                      v-model="
                                        question.quest_duration_hospital_stay_after_ch1
                                      "
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Child 1 END -->
                <!-- Child 2 -->
                <div class="row" v-if="question.quest_answer_ch2 != 'X'">
                  <div class="col-md-12 col-sm-12">
                    <div class="card-style">
                      <div class="media">
                        <div class="media-left">
                          <img
                            class="media-object card-img"
                            src="@/assets/img/spouse-hubby.png"
                          />
                        </div>

                        <div class="media-body">
                          <a href="#">
                            <h5 class="media-heading">&nbsp;</h5>
                          </a>
                          <div class="members">
                            <strong>{{ $t("Child") }} - 2</strong>
                          </div>
                          <div class="btn-part" style="margin-right: 1px">
                            <div class="btn-part">
                              <div class="toggle-button toggle-button--aava">
                                <input
                                  :id="question.quest_id + 'child2'"
                                  type="checkbox"
                                  true-value="Y"
                                  false-value="N"
                                  :checked="question.quest_answer_ch2"
                                  v-model="question.quest_answer_ch2"
                                  @change="sendChangedData()"
                                />
                                <label
                                  :for="question.quest_id + 'child2'"
                                  data-on-text="YES"
                                  data-off-text="NO"
                                  class="toggle-lable"
                                ></label>
                                <div class="toggle-button__icon"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          class="text-area-pad"
                          v-show="question.quest_answer_ch2 == 'Y'"
                        >
                          <div class="row text-area-padding">
                            <div class="col-sm-12">
                              <b-form>
                                <ValidationProvider
                                  immediate
                                  :name="'Details '"
                                  :vid="
                                    question.quest_id +
                                    'GoodHealthReasonInsured_child2'
                                  "
                                  :rules="`${
                                    question.quest_answer_ch2 == 'Y'
                                      ? 'required'
                                      : ''
                                  }`"
                                >
                                  <div slot-scope="{ errors }">
                                    <span class="required-mark">*</span>
                                    <b-form-textarea
                                      id="GoodHealthReasonInsured"
                                      placeholder="Enter details here ..."
                                      rows="2"
                                      v-uppercase
                                      v-model="question.quest_yes_reason_ch2"
                                      @change="sendChangedData()"
                                    ></b-form-textarea>
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                          </div>
                          <!-- Following fields will only appear for 1st question -->
                          <div
                            class="row text-area-pad"
                            v-show="
                              question.quest_id == 'QChildGestationalWeeks'
                            "
                          >
                            <!-- Weeks at birth -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("WeeksAtBirth")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  name="Weeks at birth"
                                  :vid="question.quest_id + 'weeks_child2'"
                                  :rules="`${
                                    question.quest_answer_ch2 == 'Y'
                                      ? 'required'
                                      : ''
                                  }|max_value:40`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="height"
                                      type="text"
                                      class="form-control"
                                      placeholder="Weeks at Birth"
                                      v-model="question.quest_birthweeks_ch2"
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                            <!-- Birth weight -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("BirthWeight")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  :name="'Birth Weight'"
                                  :vid="question.quest_id + 'weight_child2'"
                                  :rules="`${
                                    question.quest_answer_ch2 == 'Y'
                                      ? 'required|decimal:2'
                                      : ''
                                  }|max_value:10`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="weight"
                                      type="text"
                                      class="form-control"
                                      placeholder="Birth Weight"
                                      v-model="question.quest_birthweight_ch2"
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                            <!-- Duration of hospital stay after -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("DurationOfHospitalStay")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  name="Duration of Hospital Stay After"
                                  :vid="question.quest_id + 'duration_child2'"
                                  :rules="`${
                                    question.quest_answer_ch2 == 'Y'
                                      ? 'required'
                                      : ''
                                  }`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="weight"
                                      type="text"
                                      class="form-control"
                                      placeholder="Duration of Hospital Stay After"
                                      v-model="
                                        question.quest_duration_hospital_stay_after_ch2
                                      "
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Child 2  END -->
                <!-- Child 3-->
                <div class="row" v-if="question.quest_answer_ch3 != 'X'">
                  <div class="col-md-12 col-sm-12">
                    <div class="card-style">
                      <div class="media">
                        <div class="media-left">
                          <img
                            class="media-object card-img"
                            src="@/assets/img/spouse-hubby.png"
                          />
                        </div>

                        <div class="media-body">
                          <a href="#">
                            <h5 class="media-heading">&nbsp;</h5>
                          </a>
                          <div class="members">
                            <strong>{{ $t("Child") }} - 3 </strong>
                          </div>
                          <div class="btn-part" style="margin-right: 1px">
                            <div class="btn-part">
                              <div class="toggle-button toggle-button--aava">
                                <input
                                  :id="question.quest_id + 'child3'"
                                  type="checkbox"
                                  true-value="Y"
                                  false-value="N"
                                  :checked="question.quest_answer_ch3"
                                  v-model="question.quest_answer_ch3"
                                  @change="sendChangedData()"
                                />
                                <label
                                  :for="question.quest_id + 'child3'"
                                  data-on-text="YES"
                                  data-off-text="NO"
                                  class="toggle-lable"
                                ></label>
                                <div class="toggle-button__icon"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          class="text-area-pad"
                          v-show="question.quest_answer_ch3 == 'Y'"
                        >
                          <div class="row text-area-padding">
                            <div class="col-sm-12">
                              <ValidationProvider
                                immediate
                                :name="'Details '"
                                :vid="
                                  question.quest_id +
                                  'GoodHealthReasonInsured_child3'
                                "
                                :rules="`${
                                  question.quest_answer_ch3 == 'Y'
                                    ? 'required'
                                    : ''
                                }`"
                              >
                                <div slot-scope="{ errors }">
                                  <span class="required-mark">*</span>
                                  <b-form-textarea
                                    id="GoodHealthReasonInsured"
                                    placeholder="Enter details here ..."
                                    rows="2"
                                    v-model="question.quest_yes_reason_ch3"
                                    @change="sendChangedData()"
                                  ></b-form-textarea>
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <!-- Following fields will only appear for 1st question -->
                          <div
                            class="row text-area-pad"
                            v-show="
                              question.quest_id == 'QChildGestationalWeeks'
                            "
                          >
                            <!-- Weeks at birth -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("WeeksAtBirth")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  :name="'Weeks at Birth '"
                                  :vid="question.quest_id + 'weeks_child3'"
                                  :rules="`${
                                    question.quest_answer_ch3 == 'Y'
                                      ? 'required'
                                      : ''
                                  }|max_value:40`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="height"
                                      type="text"
                                      class="form-control"
                                      placeholder="Weeks at birth"
                                      v-model="question.quest_birthweeks_ch3"
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                            <!-- Birth weight -->
                            <div class="col-sm-4">
                              <div class="form-row">
                                <label for="date"
                                  >{{ $t("BirthWeight")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  name="Weight at birth"
                                  :vid="question.quest_id + 'weight_child3'"
                                  :rules="`${
                                    question.quest_answer_ch3 == 'Y'
                                      ? 'required|decimal:2'
                                      : ''
                                  }|max_value:10`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="weight"
                                      type="text"
                                      class="form-control"
                                      placeholder="Weight at birth"
                                      v-model="question.quest_birthweight_ch3"
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </div>
                            </div>
                            <!-- Duration of hospital stay after -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("DurationOfHospitalStay")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  name="Duration of Hospital Stay After"
                                  :vid="question.quest_id + 'duration_child3'"
                                  :rules="`${
                                    question.quest_answer_ch3 == 'Y'
                                      ? 'required'
                                      : ''
                                  }`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="weight"
                                      type="text"
                                      class="form-control"
                                      placeholder="uration of Hospital Stay After"
                                      v-model="
                                        question.quest_duration_hospital_stay_after_ch3
                                      "
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Child 3 END -->
                <!-- Child 4-->
                <div class="row" v-if="question.quest_answer_ch4 != 'X'">
                  <div class="col-md-12 col-sm-12">
                    <div class="card-style">
                      <div class="media">
                        <div class="media-left">
                          <img
                            class="media-object card-img"
                            src="@/assets/img/spouse-hubby.png"
                          />
                        </div>

                        <div class="media-body">
                          <a href="#">
                            <h5 class="media-heading">&nbsp;</h5>
                          </a>
                          <div class="members">
                            <strong>{{ $t("Child") }} - 4 </strong>
                          </div>
                          <div class="btn-part" style="margin-right: 1px">
                            <div class="btn-part">
                              <div class="toggle-button toggle-button--aava">
                                <input
                                  :id="question.quest_id + 'child4'"
                                  type="checkbox"
                                  true-value="Y"
                                  false-value="N"
                                  :checked="question.quest_answer_ch4"
                                  v-model="question.quest_answer_ch4"
                                  @change="sendChangedData()"
                                />
                                <label
                                  :for="question.quest_id + 'child4'"
                                  data-on-text="YES"
                                  data-off-text="NO"
                                  class="toggle-lable"
                                ></label>
                                <div class="toggle-button__icon"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          class="text-area-pad"
                          v-show="question.quest_answer_ch4 == 'Y'"
                        >
                          <div class="row text-area-padding">
                            <div class="col-sm-12">
                              <ValidationProvider
                                immediate
                                :name="'Details ' + question.quest_id"
                                :vid="
                                  question.quest_id +
                                  'GoodHealthReasonInsured_child4'
                                "
                                :rules="`${
                                  question.quest_answer_ch4 == 'Y'
                                    ? 'required'
                                    : ''
                                }`"
                              >
                                <div slot-scope="{ errors }">
                                  <span class="required-mark">*</span>
                                  <b-form-textarea
                                    id="GoodHealthReasonInsured"
                                    placeholder="Enter details here ..."
                                    rows="2"
                                    v-model="question.quest_yes_reason_ch4"
                                    @change="sendChangedData()"
                                  ></b-form-textarea>
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <!-- Following fields will only appear for 1st question -->
                          <div
                            class="row text-area-pad"
                            v-show="
                              question.quest_id == 'QChildGestationalWeeks'
                            "
                          >
                            <!-- Weeks at birth -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("WeeksAtBirth")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  name="Weeks at Birth"
                                  :vid="question.quest_id + 'weeks_child4'"
                                  :rules="`${
                                    question.quest_answer_ch4 == 'Y'
                                      ? 'required'
                                      : ''
                                  }|max_value:40`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="height"
                                      type="text"
                                      class="form-control"
                                      placeholder="Weeks at Birth"
                                      v-model="question.quest_birthweeks_ch4"
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                            <!-- Birth weight -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("BirthWeight")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  name="Birth Weight"
                                  :vid="question.quest_id + 'weight_child4'"
                                  :rules="`${
                                    question.quest_answer_ch4 == 'Y'
                                      ? 'required|max:10|decimal:2'
                                      : ''
                                  }|max_value:10`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="weight"
                                      type="text"
                                      class="form-control"
                                      placeholder="Birth Weight"
                                      v-model="question.quest_birthweight_ch4"
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                            <!-- Duration of hospital stay after -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("DurationOfHospitalStay")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  name="Child 4 Duration of Hospital Stay After"
                                  :vid="question.quest_id + 'duration_child4'"
                                  :rules="`${
                                    question.quest_answer_ch4 == 'Y'
                                      ? 'required'
                                      : ''
                                  }`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="weight"
                                      type="text"
                                      class="form-control"
                                      placeholder="Duration of Hospital Stay After"
                                      v-model="
                                        question.quest_duration_hospital_stay_after_ch4
                                      "
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Child 4 END -->

                 <!-- Child 5-->
                 <div class="row" v-if="question.quest_answer_ch5 != 'X'">
                  <div class="col-md-12 col-sm-12">
                    <div class="card-style">
                      <div class="media">
                        <div class="media-left">
                          <img
                            class="media-object card-img"
                            src="@/assets/img/spouse-hubby.png"
                          />
                        </div>

                        <div class="media-body">
                          <a href="#">
                            <h5 class="media-heading">&nbsp;</h5>
                          </a>
                          <div class="members">
                            <strong>{{ $t("Child") }} - 5 </strong>
                          </div>
                          <div class="btn-part" style="margin-right: 1px">
                            <div class="btn-part">
                              <div class="toggle-button toggle-button--aava">
                                <input
                                  :id="question.quest_id + 'child5'"
                                  type="checkbox"
                                  true-value="Y"
                                  false-value="N"
                                  :checked="question.quest_answer_ch5"
                                  v-model="question.quest_answer_ch5"
                                  @change="sendChangedData()"
                                />
                                <label
                                  :for="question.quest_id + 'child5'"
                                  data-on-text="YES"
                                  data-off-text="NO"
                                  class="toggle-lable"
                                ></label>
                                <div class="toggle-button__icon"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          class="text-area-pad"
                          v-show="question.quest_answer_ch5 == 'Y'"
                        >
                          <div class="row text-area-padding">
                            <div class="col-sm-12">
                              <ValidationProvider
                                immediate
                                :name="'Details ' + question.quest_id"
                                :vid="
                                  question.quest_id +
                                  'GoodHealthReasonInsured_child4'
                                "
                                :rules="`${
                                  question.quest_answer_ch5 == 'Y'
                                    ? 'required'
                                    : ''
                                }`"
                              >
                                <div slot-scope="{ errors }">
                                  <span class="required-mark">*</span>
                                  <b-form-textarea
                                    id="GoodHealthReasonInsured"
                                    placeholder="Enter details here ..."
                                    rows="2"
                                    v-model="question.quest_yes_reason_ch5"
                                    @change="sendChangedData()"
                                  ></b-form-textarea>
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <!-- Following fields will only appear for 1st question -->
                          <div
                            class="row text-area-pad"
                            v-show="
                              question.quest_id == 'QChildGestationalWeeks'
                            "
                          >
                            <!-- Weeks at birth -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("WeeksAtBirth")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  name="Weeks at Birth"
                                  :vid="question.quest_id + 'weeks_child5'"
                                  :rules="`${
                                    question.quest_answer_ch5 == 'Y'
                                      ? 'required'
                                      : ''
                                  }|max_value:40`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="height"
                                      type="text"
                                      class="form-control"
                                      placeholder="Weeks at Birth"
                                      v-model="question.quest_birthweeks_ch5"
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                            <!-- Birth weight -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("BirthWeight")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  name="Birth Weight"
                                  :vid="question.quest_id + 'weight_child5'"
                                  :rules="`${
                                    question.quest_answer_ch5 == 'Y'
                                      ? 'required|max:10|decimal:2'
                                      : ''
                                  }|max_value:10`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="weight"
                                      type="text"
                                      class="form-control"
                                      placeholder="Birth Weight"
                                      v-model="question.quest_birthweight_ch5"
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                            <!-- Duration of hospital stay after -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("DurationOfHospitalStay")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  name="Child 5 Duration of Hospital Stay After"
                                  :vid="question.quest_id + 'duration_child5'"
                                  :rules="`${
                                    question.quest_answer_ch5 == 'Y'
                                      ? 'required'
                                      : ''
                                  }`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="weight"
                                      type="text"
                                      class="form-control"
                                      placeholder="Duration of Hospital Stay After"
                                      v-model="
                                        question.quest_duration_hospital_stay_after_ch5
                                      "
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Child 5 END -->
                <!-- Child 6-->
                <div class="row" v-if="question.quest_answer_ch6 != 'X'">
                  <div class="col-md-12 col-sm-12">
                    <div class="card-style">
                      <div class="media">
                        <div class="media-left">
                          <img
                            class="media-object card-img"
                            src="@/assets/img/spouse-hubby.png"
                          />
                        </div>

                        <div class="media-body">
                          <a href="#">
                            <h5 class="media-heading">&nbsp;</h5>
                          </a>
                          <div class="members">
                            <strong>{{ $t("Child") }} - 6 </strong>
                          </div>
                          <div class="btn-part" style="margin-right: 1px">
                            <div class="btn-part">
                              <div class="toggle-button toggle-button--aava">
                                <input
                                  :id="question.quest_id + 'child6'"
                                  type="checkbox"
                                  true-value="Y"
                                  false-value="N"
                                  :checked="question.quest_answer_ch6"
                                  v-model="question.quest_answer_ch6"
                                  @change="sendChangedData()"
                                />
                                <label
                                  :for="question.quest_id + 'child6'"
                                  data-on-text="YES"
                                  data-off-text="NO"
                                  class="toggle-lable"
                                ></label>
                                <div class="toggle-button__icon"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          class="text-area-pad"
                          v-show="question.quest_answer_ch6 == 'Y'"
                        >
                          <div class="row text-area-padding">
                            <div class="col-sm-12">
                              <ValidationProvider
                                immediate
                                :name="'Details ' + question.quest_id"
                                :vid="
                                  question.quest_id +
                                  'GoodHealthReasonInsured_child6'
                                "
                                :rules="`${
                                  question.quest_answer_ch6 == 'Y'
                                    ? 'required'
                                    : ''
                                }`"
                              >
                                <div slot-scope="{ errors }">
                                  <span class="required-mark">*</span>
                                  <b-form-textarea
                                    id="GoodHealthReasonInsured"
                                    placeholder="Enter details here ..."
                                    rows="2"
                                    v-model="question.quest_yes_reason_ch6"
                                    @change="sendChangedData()"
                                  ></b-form-textarea>
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <!-- Following fields will only appear for 1st question -->
                          <div
                            class="row text-area-pad"
                            v-show="
                              question.quest_id == 'QChildGestationalWeeks'
                            "
                          >
                            <!-- Weeks at birth -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("WeeksAtBirth")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  name="Weeks at Birth"
                                  :vid="question.quest_id + 'weeks_child6'"
                                  :rules="`${
                                    question.quest_answer_ch6 == 'Y'
                                      ? 'required'
                                      : ''
                                  }|max_value:40`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="height"
                                      type="text"
                                      class="form-control"
                                      placeholder="Weeks at Birth"
                                      v-model="question.quest_birthweeks_ch6"
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                            <!-- Birth weight -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("BirthWeight")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  name="Birth Weight"
                                  :vid="question.quest_id + 'weight_child6'"
                                  :rules="`${
                                    question.quest_answer_ch5 == 'Y'
                                      ? 'required|max:10|decimal:2'
                                      : ''
                                  }|max_value:10`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="weight"
                                      type="text"
                                      class="form-control"
                                      placeholder="Birth Weight"
                                      v-model="question.quest_birthweight_ch6"
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                            <!-- Duration of hospital stay after -->
                            <div class="col-sm-4">
                              <b-form>
                                <label for="date"
                                  >{{ $t("DurationOfHospitalStay")
                                  }} <span class="required-mark">*</span></label
                                >
                                <ValidationProvider
                                  immediate
                                  name="Child 6 Duration of Hospital Stay After"
                                  :vid="question.quest_id + 'duration_child6'"
                                  :rules="`${
                                    question.quest_answer_ch5 == 'Y'
                                      ? 'required'
                                      : ''
                                  }`"
                                >
                                  <div slot-scope="{ errors }">
                                    <input
                                      id="weight"
                                      type="text"
                                      class="form-control"
                                      placeholder="Duration of Hospital Stay After"
                                      v-model="
                                        question.quest_duration_hospital_stay_after_ch6
                                      "
                                      @change="sendChangedData()"
                                    />
                                    <p class="p-error">{{ errors[0] }}</p>
                                  </div>
                                </ValidationProvider>
                              </b-form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Child 6 END -->

              </div>
            </div>
          </div>
        </div>

        <div
          class="col"
          v-if="
            (question.quest_answer_ch1 != 'X' ||
              question.quest_answer_ch2 != 'X' ||
              question.quest_answer_ch3 != 'X' ||
              question.quest_answer_ch4 != 'X' ||
              question.quest_answer_ch5 != 'X' ||
              question.quest_answer_ch6 != 'X' 
              ) &&
            question.quest_order_id != 1
          "
        >
          <div class="card-outline mt-2 mb-2">
            <div class="card-body">
              <!-- Question content print -->
              <div class="row">
                <div class="col quest">{{ $t(question.quest_id) }}</div>
              </div>

              <!-- Child 01  -->
              <div class="row" v-if="question.quest_answer_ch1 != 'X'">
                <div class="col-md-12 col-sm-12">
                  <div class="card-style">
                    <div class="media">
                      <div class="media-left">
                        <img
                          class="media-object card-img"
                          src="@/assets/img/spouse-hubby.png"
                        />
                      </div>

                      <div class="media-body">
                        <a href="#">
                          <h5 class="media-heading">&nbsp;</h5>
                        </a>
                        <div class="members">
                          <strong>{{ $t("Child") }} - 1 </strong>
                        </div>
                        <div class="btn-part" style="margin-right: 1px">
                          <div class="btn-part">
                            <div class="toggle-button toggle-button--aava">
                              <input
                                :id="question.quest_id + 'child1'"
                                type="checkbox"
                                true-value="Y"
                                false-value="N"
                                :checked="question.quest_answer_ch1"
                                v-model="question.quest_answer_ch1"
                                @change="sendChangedData()"
                              />

                              <label
                                :for="question.quest_id + 'child1'"
                                data-on-text="YES"
                                data-off-text="NO"
                                class="toggle-lable"
                              ></label>
                              <div class="toggle-button__icon"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        class="text-area-pad"
                        v-show="question.quest_answer_ch1 == 'Y'"
                      >
                        <div class="row text-area-padding">
                          <div class="col-sm-12">
                            <b-form>
                              <ValidationProvider
                                immediate
                                :name="'Details '"
                                :vid="question.quest_id"
                                :rules="`${
                                  question.quest_answer_ch1 == 'Y'
                                    ? 'required'
                                    : ''
                                }|max:60`"
                              >
                                <div slot-scope="{ errors }">
                                  <span class="required-mark">*</span>
                                  <b-form-textarea
                                    id="GoodHealthReasonInsured_reason_child1"
                                    placeholder="Enter details here ..."
                                    rows="2"
                                    v-uppercase
                                    v-model="question.quest_yes_reason_ch1"
                                    @change="sendChangedData()"
                                  >
                                  </b-form-textarea>
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </b-form>
                          </div>
                        </div>
                        <!-- Following fields will only appear for 1st question -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Child 02  -->
              <div class="row" v-if="question.quest_answer_ch2 != 'X'">
                <div class="col-md-12 col-sm-12">
                  <div class="card-style">
                    <div class="media">
                      <div class="media-left">
                        <img
                          class="media-object card-img"
                          src="@/assets/img/spouse-hubby.png"
                        />
                      </div>

                      <div class="media-body">
                        <a href="#">
                          <h5 class="media-heading">&nbsp;</h5>
                        </a>
                        <div class="members">
                          <strong>{{ $t("Child") }} - 2 </strong>
                        </div>
                        <div class="btn-part" style="margin-right: 1px">
                          <div class="btn-part">
                            <div class="toggle-button toggle-button--aava">
                              <input
                                :id="question.quest_id + 'child2'"
                                type="checkbox"
                                true-value="Y"
                                false-value="N"
                                :checked="question.quest_answer_ch2"
                                v-model="question.quest_answer_ch2"
                                @change="sendChangedData()"
                              />

                              <label
                                :for="question.quest_id + 'child2'"
                                data-on-text="YES"
                                data-off-text="NO"
                                class="toggle-lable"
                              ></label>
                              <div class="toggle-button__icon"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        class="text-area-pad"
                        v-show="question.quest_answer_ch2 == 'Y'"
                      >
                        <div class="row text-area-padding">
                          <div class="col-sm-12">
                            <b-form>
                              <ValidationProvider
                                immediate
                                :name="'Details '"
                                :vid="question.quest_id"
                                :rules="`${
                                  question.quest_answer_ch2 == 'Y'
                                    ? 'required'
                                    : ''
                                }`"
                              >
                                <div slot-scope="{ errors }">
                                  <span class="required-mark">*</span>
                                  <b-form-textarea
                                    id="GoodHealthReasonInsured_reason_child2"
                                    placeholder="Enter details here ..."
                                    rows="2"
                                    v-uppercase
                                    v-model="question.quest_yes_reason_ch2"
                                    @change="sendChangedData()"
                                  >
                                  </b-form-textarea>
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </b-form>
                          </div>
                        </div>
                        <!-- Following fields will only appear for 1st question -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Child 03  -->
              <div class="row" v-if="question.quest_answer_ch3 != 'X'">
                <div class="col-md-12 col-sm-12">
                  <div class="card-style">
                    <div class="media">
                      <div class="media-left">
                        <img
                          class="media-object card-img"
                          src="@/assets/img/spouse-hubby.png"
                        />
                      </div>

                      <div class="media-body">
                        <a href="#">
                          <h5 class="media-heading">&nbsp;</h5>
                        </a>
                        <div class="members">
                          <strong>{{ $t("Child") }} - 3 </strong>
                        </div>
                        <div class="btn-part" style="margin-right: 1px">
                          <div class="btn-part">
                            <div class="toggle-button toggle-button--aava">
                              <input
                                :id="question.quest_id + 'child3'"
                                type="checkbox"
                                true-value="Y"
                                false-value="N"
                                :checked="question.quest_answer_ch3"
                                v-model="question.quest_answer_ch3"
                                @change="sendChangedData()"
                              />

                              <label
                                :for="question.quest_id + 'child3'"
                                data-on-text="YES"
                                data-off-text="NO"
                                class="toggle-lable"
                              ></label>
                              <div class="toggle-button__icon"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        class="text-area-pad"
                        v-show="question.quest_answer_ch3 == 'Y'"
                      >
                        <div class="row text-area-padding">
                          <div class="col-sm-12">
                            <b-form>
                              <ValidationProvider
                                immediate
                                :name="'Details '"
                                :id="question.quest_id"
                                :rules="`${
                                  question.quest_answer_ch3 == 'Y'
                                    ? 'required'
                                    : ''
                                }`"
                              >
                                <div slot-scope="{ errors }">
                                  <span class="required-mark">*</span>
                                  <b-form-textarea
                                    id="GoodHealthReasonInsured_reason_child3"
                                    placeholder="Enter details here ..."
                                    rows="2"
                                    v-uppercase
                                    v-model="question.quest_yes_reason_ch3"
                                    @change="sendChangedData()"
                                  >
                                  </b-form-textarea>
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </b-form>
                          </div>
                        </div>
                        <!-- Following fields will only appear for 1st question -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Child 04  -->
              <div class="row" v-if="question.quest_answer_ch4 != 'X'">
                <div class="col-md-12 col-sm-12">
                  <div class="card-style">
                    <div class="media">
                      <div class="media-left">
                        <img
                          class="media-object card-img"
                          src="@/assets/img/spouse-hubby.png"
                        />
                      </div>

                      <div class="media-body">
                        <a href="#">
                          <h5 class="media-heading">&nbsp;</h5>
                        </a>
                        <div class="members">
                          <strong>{{ $t("Child") }} - 4 </strong>
                        </div>
                        <div class="btn-part" style="margin-right: 1px">
                          <div class="btn-part">
                            <div class="toggle-button toggle-button--aava">
                              <input
                                :id="question.quest_id + 'child4'"
                                type="checkbox"
                                true-value="Y"
                                false-value="N"
                                :checked="question.quest_answer_ch4"
                                v-model="question.quest_answer_ch4"
                                @change="sendChangedData()"
                              />

                              <label
                                :for="question.quest_id + 'child4'"
                                data-on-text="YES"
                                data-off-text="NO"
                                class="toggle-lable"
                              ></label>
                              <div class="toggle-button__icon"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        class="text-area-pad"
                        v-show="question.quest_answer_ch4 == 'Y'"
                      >
                        <div class="row text-area-padding">
                          <div class="col-sm-12">
                            <b-form>
                              <ValidationProvider
                                :name="'Details '"
                                :vid="
                                  question.quest_id +
                                  'GoodHealthReasonInsured_reason_child4'
                                "
                                :rules="`${
                                  question.quest_answer_ch4 == 'Y'
                                    ? 'required'
                                    : ''
                                }`"
                              >
                                <div slot-scope="{ errors }">
                                  <span class="required-mark">*</span>
                                  <b-form-textarea
                                    id="GoodHealthReasonInsured"
                                    placeholder="Enter details here ..."
                                    rows="2"
                                    v-uppercase
                                    v-model="question.quest_yes_reason_ch4"
                                    @change="sendChangedData()"
                                  >
                                  </b-form-textarea>
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </b-form>
                          </div>
                        </div>
                        <!-- Following fields will only appear for 1st question -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                     <!-- Child 05  -->
                <div class="row" v-if="question.quest_answer_ch5 != 'X'">
                <div class="col-md-12 col-sm-12">
                  <div class="card-style">
                    <div class="media">
                      <div class="media-left">
                        <img
                          class="media-object card-img"
                          src="@/assets/img/spouse-hubby.png"
                        />
                      </div>

                      <div class="media-body">
                        <a href="#">
                          <h5 class="media-heading">&nbsp;</h5>
                        </a>
                        <div class="members">
                          <strong>{{ $t("Child") }} - 5 </strong>
                        </div>
                        <div class="btn-part" style="margin-right: 1px">
                          <div class="btn-part">
                            <div class="toggle-button toggle-button--aava">
                              <input
                                :id="question.quest_id + 'child5'"
                                type="checkbox"
                                true-value="Y"
                                false-value="N"
                                :checked="question.quest_answer_ch5"
                                v-model="question.quest_answer_ch5"
                                @change="sendChangedData()"
                              />

                              <label
                                :for="question.quest_id + 'child5'"
                                data-on-text="YES"
                                data-off-text="NO"
                                class="toggle-lable"
                              ></label>
                              <div class="toggle-button__icon"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        class="text-area-pad"
                        v-show="question.quest_answer_ch5 == 'Y'"
                      >
                        <div class="row text-area-padding">
                          <div class="col-sm-12">
                            <b-form>
                              <ValidationProvider
                                :name="'Details '"
                                :vid="
                                  question.quest_id +
                                  'GoodHealthReasonInsured_reason_child5'
                                "
                                :rules="`${
                                  question.quest_answer_ch5 == 'Y'
                                    ? 'required'
                                    : ''
                                }`"
                              >
                                <div slot-scope="{ errors }">
                                  <span class="required-mark">*</span>
                                  <b-form-textarea
                                    id="GoodHealthReasonInsured"
                                    placeholder="Enter details here ..."
                                    rows="2"
                                    v-uppercase
                                    v-model="question.quest_yes_reason_ch5"
                                    @change="sendChangedData()"
                                  >
                                  </b-form-textarea>
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </b-form>
                          </div>
                        </div>
                        <!-- Following fields will only appear for 1st question -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                        <!-- Child 06  -->
              <div class="row" v-if="question.quest_answer_ch6 != 'X'">
                <div class="col-md-12 col-sm-12">
                  <div class="card-style">
                    <div class="media">
                      <div class="media-left">
                        <img
                          class="media-object card-img"
                          src="@/assets/img/spouse-hubby.png"
                        />
                      </div>

                      <div class="media-body">
                        <a href="#">
                          <h5 class="media-heading">&nbsp;</h5>
                        </a>
                        <div class="members">
                          <strong>{{ $t("Child") }} - 6 </strong>
                        </div>
                        <div class="btn-part" style="margin-right: 1px">
                          <div class="btn-part">
                            <div class="toggle-button toggle-button--aava">
                              <input
                                :id="question.quest_id + 'child6'"
                                type="checkbox"
                                true-value="Y"
                                false-value="N"
                                :checked="question.quest_answer_ch6"
                                v-model="question.quest_answer_ch6"
                                @change="sendChangedData()"
                              />

                              <label
                                :for="question.quest_id + 'child6'"
                                data-on-text="YES"
                                data-off-text="NO"
                                class="toggle-lable"
                              ></label>
                              <div class="toggle-button__icon"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        class="text-area-pad"
                        v-show="question.quest_answer_ch6 == 'Y'"
                      >
                        <div class="row text-area-padding">
                          <div class="col-sm-12">
                            <b-form>
                              <ValidationProvider
                                :name="'Details '"
                                :vid="
                                  question.quest_id +
                                  'GoodHealthReasonInsured_reason_child6'
                                "
                                :rules="`${
                                  question.quest_answer_ch5 == 'Y'
                                    ? 'required'
                                    : ''
                                }`"
                              >
                                <div slot-scope="{ errors }">
                                  <span class="required-mark">*</span>
                                  <b-form-textarea
                                    id="GoodHealthReasonInsured"
                                    placeholder="Enter details here ..."
                                    rows="2"
                                    v-uppercase
                                    v-model="question.quest_yes_reason_ch6"
                                    @change="sendChangedData()"
                                  >
                                  </b-form-textarea>
                                  <p class="p-error">{{ errors[0] }}</p>
                                </div>
                              </ValidationProvider>
                            </b-form>
                          </div>
                        </div>
                        <!-- Following fields will only appear for 1st question -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { throttle } from "lodash";
export default {
  name: "children-health-question-widget",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState("proposal", ["ChildHealthQuestions", "InfoInsured"]),
  },
  methods: {
    ...mapActions("proposal", ["draftProposalData"]),
    sendChangedData: throttle(async function () {
      //  const validationCQ = await this.$refs.childHealthQ.validate();
      // if(validationCQ){
      await this.draftProposalData();
      // }
    }, process.env.VUE_APP_TIMEOUT_SEND_CHANGED_DATA),
  },
};
</script>

<style scoped>
.card-outline {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-clip: border-box !important;
  border: 1px solid #92d127 !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}

.quest {
  font-size: 12px;
  font-weight: 700;
}

.toggle-lable {
  margin-bottom: 0rem !important;
}

.text-area-pad {
  padding: 10px;
}

.card-style {
  display: block;
  height: auto;
  margin-top: 15px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-radius: calc(0.25rem - 1px);
}

.card-img {
  height: 50px;
  width: 50px;
}

.media-heading {
  margin-top: -19px;
  color: #444;
}

.media-heading:hover,
a:link {
  color: #00c853;
  text-decoration: none;
}

.members {
  margin-top: 0px;
  color: #f39721;
  float: left;
  margin-left: 20px;
}

.btn-part {
  display: inline-block;
  margin-right: 8px;
  float: right;
}

@media (max-width: 768px) {
  .weight-top {
    margin-top: 10px;
  }
}

/* --- toggle switch ---- */

.section {
  text-align: center;
  height: 100%;
  margin: 0 auto;
  padding: 6em 0;
}

.section__title {
  font-size: 1.1em;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #fff;
  margin-bottom: 3em;
}

.section--aava {
  background: #2994b2;
}

.section--tuuli {
  background: #474744;
}

.section--vesi {
  background: #54567a;
}

.section--sade {
  background: #2d4659;
}

.section--ilma {
  background: #095062;
}

.section--tuli {
  background: #068b78;
}

.toggle-button {
  margin: 5px 0px;
}

/*
 * Toggle button styles
 */
.toggle-button {
  position: relative;
  display: inline-block;
  color: #fff;
}

.toggle-button label {
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  text-align: left;
}

.toggle-button input {
  display: none;
}

.toggle-button__icon {
  cursor: pointer;
  pointer-events: none;
}

.toggle-button__icon:before,
.toggle-button__icon:after {
  content: "";
  position: absolute;
  top: 45%;
  left: 35%;
  transition: 0.2s ease-out;
}

.toggle-button--aava label {
  height: 34px;
  line-height: 36px;
  transition: all 0.2s;
  border-radius: 0.2rem;
}

.toggle-button--aava label:before,
.toggle-button--aava label:after {
  position: absolute;
  right: 1.5rem;
  transition: all 0.2s 0.1s ease-out;
}

.toggle-button--aava label:before {
  content: attr(data-on-text);
}

.toggle-button--aava label:after {
  content: attr(data-off-text);
}

.toggle-button--aava input[type="checkbox"] + label {
  width: 100px;
  background: #ff5335;
}

@media (max-width: 768px) {
  .toggle-button--aava input[type="checkbox"] + label {
    width: 88px;
  }
}

.toggle-button--aava input[type="checkbox"] + label:before {
  opacity: 0;
  transform: translate(0, 20px);
}

.toggle-button--aava input[type="checkbox"] + label:after {
  opacity: 1;
  transform: translate(0, 0);
}

.toggle-button--aava input[type="checkbox"]:checked ~ label {
  width: 100px;
  background: #61b136;
}

@media (max-width: 768px) {
  .toggle-button--aava input[type="checkbox"]:checked ~ label {
    width: 88px;
  }
}

.toggle-button--aava input[type="checkbox"]:checked ~ label:before {
  opacity: 1;
  transform: translate(0, 0);
}

.toggle-button--aava input[type="checkbox"]:checked ~ label:after {
  opacity: 0;
  transform: translate(0, -20px);
}

.toggle-button--aava
  input[type="checkbox"]:checked
  ~ .toggle-button__icon:before {
  transform: translate(-10%, 100%) rotate(45deg);
  width: 13.66667px;
}

.toggle-button--aava
  input[type="checkbox"]:checked
  ~ .toggle-button__icon:after {
  transform: translate(30%) rotate(-45deg);
}

.toggle-button--aava .toggle-button__icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 36px;
  width: 38px;
}

.toggle-button--aava .toggle-button__icon:before,
.toggle-button--aava .toggle-button__icon:after {
  height: 3px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.toggle-button--aava .toggle-button__icon:before {
  width: 20px;
  transform: rotate(45deg);
}

.toggle-button--aava .toggle-button__icon:after {
  width: 20px;
  transform: rotate(-45deg);
}

/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}
</style>

<!-- Language Data for Health Good Health Question Widget (03) -->
<i18n>
{
  "en": {
    "QChildGestationalWeeks": "a. Please specify gestational weeks at birth, birth weight and duration of stay in the hospital after birth ?",
    "QChildSpecialCare": "b. Was there any special care needed after birth ?",
    "QChildDeliveryComplications": "c. During birth, did the child ever suffer from/does the child currently suffer from/or is being followed up or being investigated for any residual birth/ delivery complications, prolonged jaundice, respiratory distress syndrome or any other disorder ?",
    "QChildVaccinations": "d. Please state whether all due immunizations and vaccinations have been provided ? If NO, Please provide details",
    "QChildTakenOffFromSchool": "e. Please state the number of days taken off from school(due to sickness) in the current academic year ?",
    "QChildHospitalized": "f. Has the child been hospitalized in the last 2 years ?",
    "QChildOnTreatment": "g. Is the child currently on any treatment ?",
    "QChildInvestigations": "h. Has the child undergone or had been suggested by the doctor to undergo urine test, X-ray, CT scan or any other investigations ?",
    "QChildOperation": "i. Has the child ever had any accident or operation or has any operation been suggested by the doctor ?",
    "QChildWeightReduced": "j. Has the child's weight reduced in the past two years ?",
    "QChildIllnessDisease": "k. Has anyone in the family had any disease or illness in the past three months ?",
    "QChildDefects": "l. Any congenital abnormalities or birth defects ?",
    "QChildDeformity": "m. Polio or any physical deformity ?",
    "QChildCerebralPalsy": "n. Any learning difficulties, Down's Syndrome, Austism and/or Cerebral Palsy ?",
    "QChildIllnessDiseaseHeart": "o. Diseases or disorders of the heart, arteries or veins ?",
    "QChildRespiratorySystem": "p. Asthma or any other disease or disorders of the respiratory system ?",
    "QChildBladderDisorder": "q. Diseases or disorders of the stomach, intestine, liver and gall bladder ?",
    "QChildKidneyDisorder": "r. Diseases or disorders of the kidneys, bladder or genital organs ?",
    "QChildNervousSystem": "s. Paralysis, coma, fits or any other diseases or disorders of the nervous system ?",
    "QChildEyesEarsThroat": "t. Disease or disorders of the eyes, ears or throat ?",
    "QChildSkinBonesJoints": "u. Disease or disorders of the skin, muscles, bones or joints ?",
    "QChildCancerCyst": "v. Any form or type of tumor or cancer or G6PD deficiency or cyst ?",
    "QChildRegularDoctor": "w. Please provide the name and address of regular doctor, if any ?",
     
    "Child": "Child",
    "height": "Height",
    "weight": "Weight",
    "Insured": "Insured",
    "Spouse": "Spouse",
    
    "WeeksAtBirth": "Weeks at Birth",
    "BirthWeight": "Birth Weight",
    "DurationOfHospitalStay": "Duration of Hospital Stay After"
  },
  "si": {
    "QChildGestationalWeeks": "a. දරුවා යම්කිසි විකලංගතාවයකින් හෝ අබලතවයකින් පෙලේද ? පෙලුනේද ?",
    "QChildSpecialCare": "b. දරුවා යම්කිසි අසනීපයකට, හදිසි අනතුරකට හෝ ශල්‍ය කර්මයකට භාජනය වී ඇත්ද? හෝ භාජනය වීමට උපදෙස් ලැබී ඇත්ද ?",
    "QChildBloodVessels": "c. දරුවා යම්කිසි, හෘදය හෝ රුධිර වාහිණි පද්ධතියේ රෝගයකින් හෝ අපහසුතාවයකින් පෙලේද? පෙලුනේද ?",
    "QChildNorseThroatLung": "d. දරුවා යම්කිසි, නාසය, උගුර සහ පෙනහළු ආශ්‍රිතව රෝගයකින් හෝ අපහසුතාවයකින් පෙලේද? පෙලුනේද ?",
    "QChildStomachGallBladder": "e. දරුවා යම්කිසි, අමාශයේ,බඩවැල් අක්මාව සහ පිත්තාශය ආශිතව රෝගයකින් හෝ අපහසුතාවයකින් පෙලේද? පෙලුනේද ?",
    "QChildKidney": "f. දරුවා යම්කිසි, වකුගඩු ,මුත්‍රාශය සහ ප්‍රජනක ඉන්ද්‍රිය ආශ්‍රිතව රෝගයකින් හෝ අපහසුතාවයකින් පෙලේද? පෙලුනේද ?",
    "QChildCentralNervousSystem": "g. දරුවා යම්කිසි, /(මධ්‍යම) ස්නායු පද්ධතියේ,ඇස් සහ කන් ආශ්‍රිතව රෝගයකින් හෝ අපහසුතාවයකින් පෙලේද? පෙලුනේද ?",
    "QChildEndocrineSystem": "h. දරුවා යම්කිසි, ග්‍රන්ථි පද්ධතිය සහ රුධිර පද්ධතිය ආශ්‍රිතව රෝගයකින් හෝ අපහසුතාවයකින් පෙලේද? පෙලුනේද ?",
    "QChildSkinJoints": "i. දරුවා යම්කිසි, සම, මස්පිඩු, අස්ථි කොඳු ඇට පෙළ ආශ්‍රිතව රෝගයකින් හෝ අපහසුතාවයකින් පෙලේද? පෙලුනේද ?",
    "QChildPassTwoYears": "j. පසුගිය වසර දෙක තුලදී ඔබගේ දරුවා ඉහත සඳහන් නොකළ රෝගයකට, තුවාලයකට, ශල්‍ය කර්මයකට, වෛද්‍ය ප්‍රතිකාර වලට, වෛද්‍ය පරීක්ෂණයකට හෝ රෝහල් ගතවිමකට බඳුන් වුයේද ?",
    "QChildMedTreatment": "k. ඔබගේ දරුවා දැනට ප්‍රතිකාර ලබමින් සිටීද ?",
    "Child": "දරුවා",
    "height": "උස",
    "weight": "බර",
    "Insured": "රක්ෂිතයා",
    "Spouse": "කලත්‍රයා",

    "WeeksAtBirth": "Weeks at Birth",
    "BirthWeight": "Birth Weight",
    "DurationOfHospitalStay": "Duration of Hospital Stay After"
  },
  "ta": {
    "QChildGestationalWeeks": "a. குழந்தைக்கு ஏதாவதொரு குறைபாடு அல்லது அங்கவீனம் இருக்கிறதா ? அப்படியாயின் விபரம் தரவும் ?",
    "QChildSpecialCare": "b. குழந்தைக்கு ஏதேனுமொரு நோய் விபத்து ஏற்பட்டுள்ளதா அல்லது ஏதேனும் சத்திரசிகிச்சை திட்டமிடப்பட்டுள்ளதா அவ்வாறாயின் சிகிச்சை அளித்த/ அளிக்கும் வைத்தியரின் பெயர், முகவரியை இங்கு குறிப்பிடவும் ?",
    "QChildBloodVessels": "c. Has the child been having or ever had any illnesses in the Heart or the Blood Vessels ?",
    "QChildNorseThroatLung": "d. Has the child been having or ever had any illnesses in the Norse, Throat and Lungs ?",
    "QChildStomachGallBladder": "e. Has the child been having or ever had any illnesses in the Stomach, Intestines, Liver and Gall Bladder ?",
    "QChildKidney": "f. Has the child been having or ever had any illnesses in the Kidneys, Bladder and Genital Organs ?",
    "QChildCentralNervousSystem": "g. Has the child been having or ever had any illnesses in the (Central) Nervous system, Eyes and Ears ?",
    "QChildEndocrineSystem": "h. Has the child been having or ever had any illnesses in the Endocrine system and Blood ?",
    "QChildSkinJoints": "i. Has the child been having or ever had any illnesses in the skin, Muscles, Bones and joints ?",
    "QChildPassTwoYears": "j. Within the past 2 years, has the child had any illness, injury, operation, medication, hospital care or medical examination not mentioned above ?",
    "QChildMedTreatment": "k. Is your child currently receiving medical treatment from a doctor ?",
    "Child": "பேபி",
    "height": "உயரம்",
    "weight": "எடை",
    "Insured": "வாழ்க்கை",
    "Spouse": "வாழ்க்கைத்துணை",

    "WeeksAtBirth": "Weeks at Birth",
    "BirthWeight": "Birth Weight",
    "DurationOfHospitalStay": "Duration of Hospital Stay After"
  }
}
</i18n>
