<template>
  <div class="container-fluid">
    <div class="row row-top">
      <div class="card-2">
        <div class="card-header-2">
          <font-awesome-icon icon="search" />&nbsp;
          <span>{{ $t('search_panel')}}</span>
        </div>
        <div class="card-body">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label>{{ $t('quotation_number')}}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Quotation Number"
                    v-model="quoteNo"
                    @keyup.enter="doFilter"
                  />
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label>{{ $t('name')}}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Name"
                    v-model="name"
                    @keyup.enter="doFilter"
                  />
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label>{{ $t('mobile_number')}}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Mobile Number"
                    v-model="phoneNo"
                    @keyup.enter="doFilter"
                  />
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label>{{ $t('nic')}}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="NIC"
                    v-model="nic"
                    @keyup.enter="doFilter"
                  />
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label>Quotation Created Date</label>
                  <date-picker v-model="date_range" range valueType="format"></date-picker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-right">
                <button
                  type="button"
                  id="search"
                  class="btn btn-sm btn-success btn-right"
                  @click="doFilter"
                >
                  <font-awesome-icon icon="search" />
                  {{ $t('search')}}
                </button>
                <button
                  class="btn btn-sm btn-danger"
                  type="reset"
                  id="search_reset"
                  @click="resetFilter"
                >
                  <font-awesome-icon icon="ban" />
                  {{ $t('reset')}}
                </button>

                <button type="button" id="export" class="btn btn-sm btn-success btn-right ml-3" @click="exportData">
                    <font-awesome-icon icon="file" />
                    {{ $t("export") }}
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  data() {
    return {
      quoteNo: "",
      name: "",
      phoneNo: "",
      nic: "",
      date_range:null,
      date_format:'YYYY-MM-DD'
    };
  },
  components: {
    DatePicker 
  },
  methods: {
    ...mapActions("export_report", ["exportQuotations"]),
    doFilter() {
      let filters = {
        quote_no: this.quoteNo,
        name: this.name,
        phone_no: this.phoneNo,
        nic: this.nic,
        date_range :this.date_range
      };
      this.$events.fire("filter-set", filters);
    },
    resetFilter() {
      this.quoteNo = "";
      this.name = "";
      this.phoneNo = "";
      this.nic = "";
      this.$events.fire("filter-reset");
    },
    exportData() {
      let param = {
        date_range :this.date_range
      };
      this.exportQuotations(param).then((response) => {
        if (response.data.meta.status) {
          window.open(response.data.meta.url,'_blank')
        }
      });
    }
  }
};
</script>


<style>
.btn-right {
  margin-right: 5px;
}

@media (max-width: 768px) {
  div.card {
    margin-top: 100px;
  }
}
</style>

<i18n>
{
  "en": {
    "quotation_number": "Quotation Number",
    "name": "Name",
    "mobile_number": "Mobile Number",
    "nic": "NIC",
    "search": "Search",
    "reset" : "Reset",
    "search_panel": "Search Panel"
  },
  "si": {
  "quotation_number": "මිල කැඳවීම් අංකය",
    "name": "නම",
  "mobile_number": "දුරකථන අංකය",
  "nic": "ජා.හැ.අංකය",
    "search": "සොයන්න",
  "reset" : "නැවත සකසන්න",
  "search_panel": "සෙවීමේ පුවරුව"
  },
  "ta": {
    "quotation_number": "விலைக்கோராலின் இலக்கம்",
    "name": "பெயர்",
    "mobile_number": "தொலைபேசி இலக்கம்",
    "nic": "தே. அ. அ. இலக்கம்",
    "search": "தேடல்",
  "reset" : "மீட்டமைத்தல்",
    "search_panel": "தேடல் குழு"
  }
}
</i18n>