import { http } from "@/config/http";

const namespaced = true;

const getDefaultState = () => {
  return {
    required_capital : '',
    required_monthly_contibution : '',
    pansion_build_up : [],
    capital_growth: [],
    pansion_build_up_amount : [],
    pansion_build_up_month : []

  };
};

const state = getDefaultState();

const getters = {};

const mutations = {
  clearObj(state) {
    Object.assign(state, getDefaultState());
  },
  /** Error Message setter */
  setError(state, { error, errorType }) {
    //console.log("called set error " + errorType);
    //console.log("called set error " + error);
    state.flash = {
      type: errorType,
      status: true,
      error: error
    };
    //console.log(state.flash.status);
  },
  /** Error Message destroy */
  clearFlash(state) {
    state.flash = {
      type: "",
      status: false,
      error: ""
    };
  },

  /** Minified Summary Message setter */
  setMinMsg(state, data) {
    state.minflash = {
      status: true,
      error: data
    };
    //console.log(state.flash.status);
  },
  /** Minified Summary Message destroy */
  clearMinMsg(state) {
    state.minflash = {
      status: false,
      error: ""
    };
  },
  setPensionCalcObj(state , data){
    state.required_capital = data.data.required_capital;
    state.required_monthly_contibution = data.data.required_monthly_contibution;
    state.pansion_build_up = data.data.pansion_build_up,
    state.capital_growth = data.data.capital_growth,
    state.pansion_build_up_amount = data.data.pansion_build_up_amount,
    state.pansion_build_up_month = data.data.pansion_build_up_month
  }
};
const actions = {
  async clearStateData({ commit }) {
    commit("clearObj");
  },
  async pensionCalc({ commit }, data ) {
    let errorType = "";
    await http
      .post("pension_calc", data)
      .then(response => {
        if (response.data.meta.status == "true") {
          commit("setPensionCalcObj", response.data);
        } else {
          errorType = "E";
          let error = "Error : Rider Service Response Failed";
          commit("setError", { error, errorType });
          //alert("Error : Rider Service Response Failed");
        }
      })
      .catch(error => {
        //alert(error);
        errorType = "E";
        commit("setError", { error, errorType });
      });
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
