<template>
  <div class="card">
    <div class="card-header header-font">
      <font-awesome-icon icon="calculator" />
      <span>&nbsp;{{ $t("capitalGrowthChart") }}</span>
    </div>
    <div class="card-body">
      <line-chart :chart-data="chartdata1" :options="option1" :height="200" :width="200"/>
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/quote_surakshitha/calculator/TimeSeriesChart";
import {mapState} from "vuex";
export default {
  name: 'DefaultAside',
  components: {
    LineChart,
  },
  data() {
    return {
      test: "",
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  computed: {
    ...mapState("pension_calc", ["pansion_build_up_amount" , "pansion_build_up_month"]),

    chartdata1(){
      return {
        "labels": this.pansion_build_up_month,
        "datasets": [
          {
            label: "Data 1",
            data: this.pansion_build_up_amount,
            backgroundColor: "transparent",
          }
        ]
      };
    },
    option1(){
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "CAPITAL GROWTH"
        },
        height:200
      };
    }

  }
}
</script>


<i18n>
{
"en": {
"capitalGrowthChart": "Pension Capital Growth"
},
"si": {
"capitalGrowthChart": "Pension Capital Growth"
},
"ta": {
"capitalGrowthChart": "Pension Capital Growth"
}
}
</i18n>