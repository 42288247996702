<!-- Spouse Information Widget -->
<template id="tmpSpouseChildInfo">
  <ValidationObserver ref="spouse">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header header-font">
            <font-awesome-icon icon="male" />/
            <font-awesome-icon icon="female" />
            <span>&nbsp;{{ $t("spouseInfoHeader") }}</span>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_spouse_title">{{
                    $t("spouseLblTitle")
                  }}</label>
                  <ValidationProvider
                    name="Title"
                    rules="required|genderValidation:@SpGender"
                  >
                    <div slot-scope="{ errors }">
                      <select
                        class="form-control"
                        v-model="spouseInfoData.drpSpouseTitle"
                        name="STitle"
                        @blur="sendChangedData()"
                      >
                        <option disabled>Please select</option>
                        <option>Mr.</option>
                        <option>Mrs.</option>
                        <option>Ms.</option>
                        <option>Dr.</option>
                        <option>Rev.</option>
                      </select>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_spouse_fname">{{
                    $t("spouseLblFirstName")
                  }}</label>
                  <ValidationProvider
                    name="First Name"
                    rules="required|minmax:3,60|alpha_spaces"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        id="txtSpouseFname"
                        name="SFirstName"
                        placeholder="First Name"
                        v-model="spouseInfoData.txtSpouseFname"
                        @blur="sendChangedData()"
                        v-uppercase
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_spouse_mname">{{
                    $t("spouseLblMidName")
                  }}</label>
                  <ValidationProvider
                    name="Middle Name"
                    rules="minmax:3,60|alpha_spaces"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        id="txtSpouseMname"
                        name="MiddleName"
                        placeholder="Middle Name"
                        v-model="spouseInfoData.txtSpouseMname"
                        v-uppercase
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_spouse_lname">{{
                    $t("spouseLblLastName")
                  }}</label>
                  <ValidationProvider
                    name="Last Name"
                    rules="required|minmax:3,60|alpha_spaces"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        id="txtSpouseLname"
                        placeholder="Last Name"
                        name="SLastName"
                        v-model="spouseInfoData.txtSpouseLname"
                        @blur="sendChangedData()"
                        v-uppercase
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="gender">{{ $t("spouseLblGender") }}</label>
                  <ValidationProvider name="SpGender" rules="required">
                    <div slot-scope="{ errors }">
                      <div class="toggleswitchg">
                        <input
                          id="spouse_male"
                          ref="spouse_male_female"
                          name="spouse_male_female"
                          type="radio"
                          value="Male"
                          v-model="spouseInfoData.rdbSpouseGender"
                          @blur="sendChangedData()"
                        />
                        <label for="spouse_male">
                          <i class="fa fa-mars"></i> Male
                        </label>
                        <input
                          checked="checked"
                          id="spouse_female"
                          ref="spouse_male_female"
                          name="spouse_male_female"
                          type="radio"
                          value="Female"
                          v-model="spouseInfoData.rdbSpouseGender"
                          @blur="sendChangedData()"
                        />
                        <label for="spouse_female">
                          <i class="fa fa-venus"></i> Female
                        </label>
                      </div>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="spouse_dob">{{ $t("spouseLblDOB") }}</label>
                  <ValidationProvider name="Date of Birth" rules="required">
                    <div slot-scope="{ errors }">
                      <input
                        type="Date"
                        class="form-control"
                        id="txtSpouseDOB"
                        name="DateOfBirth"
                        placeholder="Date of Birth"
                        v-model="spouseInfoData.txtSpouseDOB"
                        @blur="
                          ageCalc();
                          sendChangedData();
                        "
                        :max="timestamp"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="spouse_age">{{ $t("spouseLblAge") }}</label>
                  <ValidationProvider name="Age" rules="required|max_value:65">
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        name="txtSpouseAge"
                        readonly
                        v-model="spouseInfoData.txtSpouseAge"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="spouse_sum_at_risk">{{
                    $t("spouseLblSumAtRiskPrevPol")
                  }}</label>
                  <ValidationProvider name="Sum at risk" rules="required|numeric">
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        id="txtSpouseSumAtRisk"
                        name="SumAtRisk"
                        placeholder="Sum At Risk of Previous Policies"
                        v-model="spouseInfoData.txtSpouseSumAtRisk"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_spouse_occupation">{{
                    $t("spouseLblOccupation")
                  }}</label>
                  <ValidationProvider name="Occupation" rules="required">
                    <div slot-scope="{ errors }">
                      <!-- <v-select
                        class="select2"
                        style="width: 100%;"
                        v-model="spouseInfoData.drpSpouseOccupation"
                        name="OccupationSpouse"
                        :options="occupationList"
                      ></v-select> -->
                      <input
                        type="text"
                        class="form-control"
                        name="OccupationSpouse"
                        placeholder="Occupation"
                        v-model="spouseInfoData.drpSpouseOccupation"
                        v-uppercase
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SnotifyPosition } from "vue-snotify";
import { throttle } from "lodash";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("quote_health_plus", [
      "spouseInfoData",
      "occupationList",
      "flash",
      "minflash",
      "timestamp"
    ])
  },
  created() {
    this.ageCalc();
  },
  watch: {
    "spouseInfoData.drpSpouseOccupation": {
      handler: function() {
        this.sendChangedData();
      },
      immediate: false
    }
  },
  methods: {
    ...mapActions("quote_health_plus", ["getAge", "addChangedData"]),
    basicValidation() {
      if (this.spouseInfoData.txtSpouseAge == 0) {
        return false;
      } else {
        return true;
      }
    },
    sendChangedData: throttle(async function() {
      this.$refs.spouse.validate().then(valid => {
        if (valid) {
          // do stuff if valid.
          this.$snotify.clear();
          /** Loading (HTML snotify component) */
          this.$snotify.html(
            `<div class="snotifyToast__title"><b>Calculating...</b></div>
            <div class="snotifyToast__body"> Mode Premium Amount</div>
            <div class="snotify-icon snotify-icon--async"></div> `,
            {
              timeout: stop,
              showProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              position: SnotifyPosition.centerTop,
              type: "async"
            }
          );
          return this.getChangeDataResponse()
            .then(
              function(response) {
                this.$snotify.clear();
                if (response.status && response.type == undefined) {
                  /** Success Message  */
                  this.$snotify.success("Mode Premium Amount", response.error, {
                    timeout: stop,
                    closeOnClick: true,
                    pauseOnHover: false,
                    position: SnotifyPosition.centerTop
                  });
                } else {
                  this.$snotify.clear();
                  /** Error Message  */
                  this.$snotify.error(response.error, "Error", {
                    timeout: stop,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    position: SnotifyPosition.centerTop
                  });
                }
              }.bind(this)
            )
            .catch(function(error) {
              this.$snotify.clear();
              /** Exception Message */
              this.$snotify.error(error, "Error", {
                timeout: stop,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop
              });
            });
        }
      });
    }, process.env.VUE_APP_QUOT_TIMEOUT_SEND_CHANGED_DATA),
    async ageCalc() {
      let payload = {
        dob: this.spouseInfoData.txtSpouseDOB,
        type: "spouse"
      };
      await this.getAge(payload);
    },
    async getChangeDataResponse() {
      await this.addChangedData();
      if (this.minflash.status) {
        return this.minflash;
      } else {
        return this.flash;
      }
    }
  }
};
</script>

<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

.close-btn {
  float: right;
}

.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 70px;
  border-bottom-left-radius: 30px;
  padding: 65px;
}
body {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

/*--- Card css-----*/

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #556065 !important;
  border-bottom: 0px solid #06a990 !important;
  color: #fff !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #06a990 !important;
  border-radius: 0.25rem;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked + label {
  background: #92D127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92D127;
}
</style>

<!-- Language Data for SpouseInfoWidget -->
<i18n>
{
  "en": {
    "spouseInfoHeader": "Spouse Information",
    "spouseLblTitle": "Title",
    "spouseLblFirstName": "First Name",
    "spouseLblMidName": "Middle Name",
    "spouseLblLastName": "Last Name",
    "spouseLblGender": "Gender",
    "spouseLblDOB": "Date of Birth (MM/DD/YYYY)",
    "spouseLblAge": "Age",
    "spouseLblSumAtRiskPrevPol": "Previous Sum at Risk",
    "spouseLblHealthExtra":"Health Extra",
     "spouseLblOccupation": "Occupation"
  },
  "si": {
    "spouseInfoHeader": "කලත්‍රයාගේ තොරතුරු",
    "spouseLblTitle": "ශීර්ෂය",
    "spouseLblFirstName": "මුල් නම",
    "spouseLblMidName": "මැද නම",
    "spouseLblLastName": "අවසන් නම",
    "spouseLblGender": "ස්ත්‍රී පුරුෂ භාවය",
    "spouseLblDOB": "උපන්දිනය (MM/DD/YYYY)",
    "spouseLblAge": "වයස",
    "spouseLblSumAtRiskPrevPol": "පෙර ප්‍රතිපත්තිවල අවදානම",
    "spouseLblHealthExtra":"සෞඛ්‍යය අමතර",
    "spouseLblOccupation": "රැකියාව"
  },
  "ta": {
    "spouseInfoHeader": "மனைவி தகவல்",
    "spouseLblTitle": "படிநிலை",
    "spouseLblFirstName": "முதற் பெயர்",
    "spouseLblMidName": "மத்திய பெயர்",
    "spouseLblLastName": "கடைசி பெயர்",
    "spouseLblGender": "பால்",
    "spouseLblDOB": "பிறந்த திகதி (MM/DD/YYYY)",
    "spouseLblAge": "வயது",
    "spouseLblSumAtRiskPrevPol": "முன்னைய காப்புறுதிகளின் இடர்த்தொகை",
    "spouseLblHealthExtra":"සෞඛ්‍යය අමතර",
    "spouseLblOccupation": "தொழில்"
  }
}
</i18n>
