<!-- Final - Insured Signature Widget -->
<template>
  <div>
    <div class="col-12 alert alert-success mt-3">Spouse Signature</div>
    <div class="row">
      <div class="col-6 mt-2">
        <VueSignaturePad
          id="signature"
          width="350px"
          height="250px"
          ref="signaturePad"
          :options="{
            onBegin: () => {
              $refs.signaturePad.resizeCanvas();
            },
          }"
        />
      </div>
      <div class="col-6 mt-2">
        <img
          :src="InfoInsured.sig_data_spouse"
          width="350px"
          height="250px"
          alt="Spouse Signature"
          v-if="InfoInsured.sig_data_spouse"
        />
      </div>
    </div>
    <div class="row">
      <div
        class="btn-group button-space"
        role="group"
        aria-label="Basic example"
      >
        <button class="btn btn-outline-primary" @click="save">Save</button>
        <button class="btn btn-outline-secondary" @click="undo">Undo</button>
        <button class="btn btn-outline-secondary" @click="clear">Clear</button>
      </div>
    </div>
    <div class="row"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "final-signature-widget",
  data() {
    return {};
  },
  computed: {
    ...mapState("proposal", ["InfoInsured", "ProposalID", "QuoteRefNo"]),
  },
  methods: {
    ...mapActions("proposal", ["updateSpouseSignature"]),
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        let params = {
          signature: data,
          proposalId: this.ProposalID,
          quoteRefNo: this.QuoteRefNo,
        };
        this.updateSpouseSignature(params).then((response) => {
          if (response.data.meta.status) {
            this.$snotify.success(response.data.meta.status_message, "", {
              position: "centerTop",
            });

            this.InfoInsured.sig_data_spouse = response.data.meta.data;
            this.$parent.$parent.$parent.$parent.onFilterReset();
            this.$parent.$parent.$parent.$parent.closeSpecificModal(
              "send-signature-link-modal"
            );
          } else {
            this.$snotify.error(response.data.meta.status_message, "", {
              position: "centerTop",
            });
          }
        });
      }
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
  },
};
</script>

<style scoped>
.card-outline {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-clip: border-box !important;
  border: 1px solid #92d127 !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}
.button-space {
  margin-top: 5px;
  margin-left: 20px;
}
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>

<!-- Language Data for Final - Signature Widget -->
<i18n>
{
  "en": {
    "Insured": "Insured",
    "Spouse": "Spouse"
  },
  "si": {
    "Insured": "ජීවිත රක්ෂිතයා",
    "Spouse": "කලත්‍රයා"
  },
  "ta": {
    "Insured": "பிரதான வாழ்க்கை",
    "Spouse": "வாழ்க்கைத்துணை"
  }
}
</i18n>
