<template>
  <div class="container-fluid">
    <div class="row row-top">
      <div class="card-2">
        <div class="card-header-2">
          <font-awesome-icon icon="search" />&nbsp;
          <span>{{ $t("search_panel") }}</span>
        </div>
        <div class="card-body">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label>{{ $t("quotation_number") }}</label>
                  <input type="text" class="form-control" placeholder="Quotation Number" v-model="quoteNo"
                    @keyup.enter="doFilter" />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>{{ $t("proposal_number") }}</label>
                  <input type="text" class="form-control" placeholder="Proposal Number" v-model="proposalNo"
                    @keyup.enter="doFilter" />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>{{ $t("name") }}</label>
                  <input type="text" class="form-control" placeholder="Name" v-model="name" @keyup.enter="doFilter" />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>{{ $t("mobile_number") }}</label>
                  <input type="text" class="form-control" placeholder="Mobile Number" v-model="phoneNo"
                    @keyup.enter="doFilter" />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>{{ $t("nic") }}</label>
                  <input type="text" class="form-control" placeholder="NIC" v-model="nic" @keyup.enter="doFilter" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t("status") }}</label>

                  <select class="form-control" name="PropStatus" v-model="status">
                    <option value="all">All</option>
                    <option v-if="role == 'HO'" value="assign_only">
                      Assign Only Me
                    </option>
                    <option value="1">Pending Personal Info</option>
                    <option value="2">Pending Health Info</option>
                    <option value="3">Pending Documents</option>
                    <option value="4">Pending Policy Holder Sig</option>
                    <option value="5">Pending Spouse Sig</option>
                    <option value="6">Pending Agent Sig</option>
                    <option value="7">Pending Supervisor</option>
                    <option value="8">Pending Ho</option>
                    <option value="9">Returned to Agent</option>
                    <option value="10">Completed</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Proposal Created Date</label>
                  <date-picker v-model="date_range" range valueType="format"></date-picker>
                </div>
              </div>

              <div class="col-md-3 text-right d-flex align-items-end">
                <div class="form-group">
                  <button type="button" id="search" class="btn btn-sm btn-success btn-right" @click="doFilter">
                    <font-awesome-icon icon="search" />
                    {{ $t("search") }}
                  </button>
                  <button class="btn btn-sm btn-danger" type="reset" id="search_reset" @click="resetFilter">
                    <font-awesome-icon icon="ban" />
                    {{ $t("reset") }}
                  </button>

                  <button type="button" id="export" class="btn btn-sm btn-success btn-right ml-3" @click="exportData">
                    <font-awesome-icon icon="file" />
                    {{ $t("export") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="row"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  data() {
    return {
      quoteNo: "",
      proposalNo: "",
      name: "",
      phoneNo: "",
      nic: "",
      status: "all",
      role: "",
      date_range:null,
      date_format:'YYYY-MM-DD'
    };
  },
  components: {
    DatePicker 
  },
  methods: {
    ...mapActions("export_report", ["exportProposals"]),
    doFilter() {
      let filters = {
        quote_no: this.quoteNo,
        proposal_no: this.proposalNo,
        name: this.name,
        phone_no: this.phoneNo,
        nic: this.nic,
        status: this.status,
        date_range :this.date_range
      };
      this.$events.fire("filter-set", filters);
    },
    resetFilter() {
      this.quoteNo = "";
      this.proposalNo = "";
      this.name = "";
      this.phoneNo = "";
      this.nic = "";
      this.status = "all";
      this.date_range = null;
      this.$parent.$refs.proposalListTableRef.onFilterReset();
    },
    exportData() {
      let param = {
        date_range :this.date_range
      };
      this.exportProposals(param).then((response) => {
        if (response.data.meta.status) {
          window.open(response.data.meta.url,'_blank')
        }
      });
    }
  },
  mounted() {
    this.role = localStorage.getItem("user_role")
      ? localStorage.getItem("user_role")
      : null;

    if (this.role == "HO") {
      this.status = 'assign_only';
    }
  },
};
</script>


<style>
.btn-right {
  margin-right: 5px;
}

@media (max-width: 768px) {
  div.card {
    margin-top: 100px;
  }
}
</style>

<i18n>
{
  "en": {
    "quotation_number": "Quotation Number",
    "proposal_number":"Proposal Number",
    "name": "Name",
    "mobile_number": "Mobile Number",
    "search": "Search",
    "status": "Status",
    "nic": "NIC",
    "reset" : "Reset",
    "search_panel": "Search Panel"
  },
  "si": {
    "quotation_number": "මිල කැඳවීම් අංකය",
    "proposal_number":"Proposal Number",
    "name": "නම",
    "mobile_number": "දුරකථන අංකය",
    "nic": "ජා.හැ.අංකය",
    "status": "Status",
    "search": "සොයන්න",
    "reset" : "නැවත සකසන්න",
    "search_panel": "සෙවීමේ පුවරුව"
  },
  "ta": {
    "quotation_number": "விலைக்கோராலின் இலக்கம்",
    "proposal_number":"Proposal Number",
    "name": "பெயர்",
    "mobile_number": "தொலைபேசி இலக்கம்",
    "nic": "தே. அ. அ. இலக்கம்",
    "status": "Status",
    "search": "தேடல்",
    "reset" : "மீட்டமைத்தல்",
    "search_panel": "தேடல் குழு"
  }
}
</i18n>