<!-- Benifits & Premium Widget -->
<template>
  <ValidationObserver ref="benefitPremium">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-md-4">
            <div class="row card-outline">
              <div class="col card-top">
                <div class="list-group">
                  <a class="list-group-item visitor">
                    <table class="tbl-width">
                      <tbody>
                        <tr>
                          <td>{{ $t("Product") }}</td>
                          <td class="notify-td td-float">
                            <h5
                              class="list-group-item-heading count h6"
                              style="margin-bottom: 0rem"
                            >
                              {{ productNamesDisplay(BenefitData.product) }}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </div>
              <div class="col card-top">
                <div class="list-group">
                  <a class="list-group-item visitor">
                    <table class="tbl-width">
                      <tbody>
                        <tr>
                          <td>{{ $t("Purpose") }}</td>
                          <td class="notify-td td-float">
                            <h5
                              class="list-group-item-heading count h6"
                              style="margin-bottom: 0rem"
                            >
                              {{ BenefitData.requirement }}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </div>
              <div class="col card-top" v-if="BenefitData.product != 'MRTT'">
                <div class="list-group">
                  <a class="list-group-item visitor">
                    <table class="tbl-width">
                      <tbody>
                        <tr>
                          <td>{{ $t("Frequency") }}</td>
                          <td class="notify-td td-float">
                            <h5
                              class="list-group-item-heading count h6"
                              style="margin-bottom: 0rem"
                            >
                              {{
                                premPaymentMethod(BenefitData.payment_method)
                              }}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" v-if="BenefitData.product != 'MRTT'">
            <div class="row card-outline">
              <div class="col card-top">
                <div class="list-group">
                  <a class="list-group-item visitor">
                    <table class="tbl-width">
                      <tbody>
                        <tr>
                          <td>{{ $t("PolicyTerm") }}</td>
                          <td class="notify-td td-float">
                            <h5
                              class="list-group-item-heading count h6"
                              style="margin-bottom: 0rem"
                            >
                              <ValidationProvider
                                :name="'Policy Term '"
                                rules="required|min_value:1"
                              >
                                <input
                                  type="hidden"
                                  class="form-control"
                                  readonly
                                  v-model="BenefitData.policy_term"
                                />
                              </ValidationProvider>
                              {{ BenefitData.policy_term }}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </div>
              <div class="col card-top"  v-if="BenefitData.product != 'Term Plus CIC'">
                <div class="list-group">
                  <a class="list-group-item visitor">
                    <table class="tbl-width">
                      <tbody>
                        <tr>
                          <td>{{ $t("PremiumTerm") }}</td>
                          <td class="notify-td td-float">
                            <h5
                              class="list-group-item-heading count h6"
                              style="margin-bottom: 0rem"
                            >
                              <ValidationProvider
                                :name="'Premium Term '"
                                rules="required|min_value:1"
                              >
                                <input
                                  type="hidden"
                                  class="form-control"
                                  readonly
                                  v-model="BenefitData.premium_term"
                                />
                              </ValidationProvider>
                              {{ BenefitData.premium_term }}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </div>
              <div class="col card-top"  v-if="BenefitData.product == 'Term Plus CIC'">
                <div class="list-group">
                  <a class="list-group-item visitor">
                    <table class="tbl-width">
                      <tbody>
                        <tr>
                          <td>{{ $t("ContributionTerm") }}</td>
                          <td class="notify-td td-float">
                            <h5
                              class="list-group-item-heading count h6"
                              style="margin-bottom: 0rem"
                            >
                              <ValidationProvider
                                :name="'Premium Term '"
                                rules="required|min_value:1"
                              >
                                <input
                                  type="hidden"
                                  class="form-control"
                                  readonly
                                  v-model="BenefitData.contribution_term"
                                />
                              </ValidationProvider>
                              {{ BenefitData.contribution_term }}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </div>
              <div class="col card-top" v-if="BenefitData.product != 'Term Plus CIC'">
                <div class="list-group">
                  <a class="list-group-item visitor">
                    <table class="tbl-width">
                      <tbody>
                        <tr>
                          <td>{{ $t("CoverMultiple") }}</td>
                          <td class="notify-td td-float">
                            <h5
                              class="list-group-item-heading count h6"
                              style="margin-bottom: 0rem"
                            >
                              {{ BenefitData.cover_multiple }}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" v-if="BenefitData.product != 'MRTT'">
            <div class="row card-outline">
              <div class="col card-top" v-if="BenefitData.product != 'Term Plus CIC'">
                <div class="list-group">
                  <a class="list-group-item visitor">
                    <table class="tbl-width">
                      <tbody>
                        <tr>
                          <td>{{ $t("SumAssured") }}</td>
                          <td class="notify-td td-float">
                            <h5
                              class="list-group-item-heading count h6"
                              style="margin-bottom: 0rem"
                            >
                              <ValidationProvider
                                :name="'Sum Assured'"
                                rules="required|min_value:1"
                              >
                                <input
                                  type="hidden"
                                  class="form-control"
                                  readonly
                                  v-model="BenefitData.sum_assured"
                                />
                              </ValidationProvider>
                              {{ thousandSeparator(BenefitData.sum_assured) }}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </div>
              <div class="col card-top">
                <div class="list-group">
                  <a class="list-group-item visitor">
                    <table class="tbl-width">
                      <tbody>
                        <tr>
                          <td>{{ $t("AnnualPremium") }}</td>
                          <td class="notify-td td-float">
                            <h5
                              class="list-group-item-heading count h6"
                              style="margin-bottom: 0rem"
                            >
                              <ValidationProvider
                                :name="'Annual Premium'"
                                rules="required|min_value:1"
                              >
                                <input
                                  type="hidden"
                                  class="form-control"
                                  readonly
                                  v-model="BenefitData.annual_premium"
                                />
                              </ValidationProvider>
                              {{
                                thousandSeparator(BenefitData.annual_premium)
                              }}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </div>
              <div class="col card-top">
                <div class="list-group">
                  <a class="list-group-item visitor">
                    <table class="tbl-width">
                      <tbody>
                        <tr>
                          <td>{{ $t("TotalPremium") }}</td>
                          <td class="notify-td td-float">
                            <h5
                              class="list-group-item-heading count h6"
                              style="margin-bottom: 0rem"
                            >
                              <ValidationProvider
                                :name="'Total Premium'"
                                rules="required|min_value:1"
                              >
                                <input
                                  type="hidden"
                                  class="form-control"
                                  readonly
                                  v-model="BenefitData.total_premium"
                                />
                              </ValidationProvider>
                              {{ thousandSeparator(BenefitData.total_premium) }}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4" v-if="BenefitData.product == 'MRTT'">
            <div class="row card-outline">
              <div class="col card-top">
                <div class="list-group">
                  <a class="list-group-item visitor">
                    <table class="tbl-width">
                      <tbody>
                        <tr>
                          <td>{{ $t("LoanAmount") }}</td>
                          <td class="notify-td td-float">
                            <h5
                              class="list-group-item-heading count h6"
                              style="margin-bottom: 0rem"
                            >
                              <ValidationProvider
                                :name="'Cover Amount'"
                                rules="required|min_value:1"
                              >
                                <input
                                  type="hidden"
                                  class="form-control"
                                  readonly
                                  v-model="BenefitData.mrtt_cover_amount"
                                />
                              </ValidationProvider>
                              {{ thousandSeparator(BenefitData.mrtt_cover_amount) }}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </div>
              <div class="col card-top">
                <div class="list-group">
                  <a class="list-group-item visitor">
                    <table class="tbl-width">
                      <tbody>
                        <tr>
                          <td>{{ $t("PeriodOfRepayment") }}</td>
                          <td class="notify-td td-float">
                            <h5
                              class="list-group-item-heading count h6"
                              style="margin-bottom: 0rem"
                            >
                              <ValidationProvider
                                :name="'PeriodOfRepayment'"
                                rules="required|min_value:1"
                              >
                                <input
                                  type="hidden"
                                  class="form-control"
                                  readonly
                                  v-model="BenefitData.mrtt_period_of_repayment"
                                />
                              </ValidationProvider>
                              {{
                                BenefitData.mrtt_period_of_repayment
                              }} Years
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" v-if="BenefitData.product == 'MRTT'">
            <div class="row card-outline">
              <div class="col card-top">
                <div class="list-group">
                  <a class="list-group-item visitor">
                    <table class="tbl-width">
                      <tbody>
                        <tr>
                          <td>{{ $t("GracePeriod") }}</td>
                          <td class="notify-td td-float">
                            <h5
                              class="list-group-item-heading count h6"
                              style="margin-bottom: 0rem"
                            >
                              <ValidationProvider
                                :name="'Grace Period'"
                                rules="required|min_value:1"
                              >
                                <input
                                  type="hidden"
                                  class="form-control"
                                  readonly
                                  v-model="BenefitData.mrtt_grace_period"
                                />
                              </ValidationProvider>
                              {{ BenefitData.mrtt_grace_period }}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </div>
              <div class="col card-top">
                <div class="list-group">
                  <a class="list-group-item visitor">
                    <table class="tbl-width">
                      <tbody>
                        <tr>
                          <td>{{ $t("RepaymentRate") }}</td>
                          <td class="notify-td td-float">
                            <h5
                              class="list-group-item-heading count h6"
                              style="margin-bottom: 0rem"
                            >
                              <ValidationProvider
                                :name="'Repayment Rate'"
                                rules="required|min_value:1"
                              >
                                <input
                                  type="hidden"
                                  class="form-control"
                                  readonly
                                  v-model="BenefitData.mrtt_repayment_rate"
                                />
                              </ValidationProvider>
                              {{ BenefitData.mrtt_repayment_rate }}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapState } from "vuex";
import helpMixin from "@/mixins/filters";

export default {
  name: "benifits-premium-widget",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState("proposal", ["BenefitData"]),
  },
  mixins: [helpMixin],
  methods:{
    productNamesDisplay(current_name){
      if( current_name == 'Surakshitha'){
        return 'FAMILY PROTECTION';
      }else if( current_name == 'MRTT'){
        return 'LOAN PROTECTION';
      }else if( current_name == 'Term Plus CIC'){
        return 'Pure Life Plan';
      }else{
        return current_name;
      }
    }
  }
};
</script>

<style scoped>
.card-outline {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-clip: border-box !important;
  border: 0px solid #ffffff !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}

.td-float {
  float: right !important;
}

.card-pad {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

@media (max-width: 768px) {
  .card-pad {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

td {
  color: #ffffff !important;
}

.card-top {
  margin: 2px 0px 2px 0px !important;
}

.list-group {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.bsa-box {
  background-color: #fdc209 !important;
  color: #fff !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.paying-box {
  background-color: #556065 !important;
  color: #fff !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.list-group {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

a {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #556065;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.p-color {
  color: #fff !important;
}

p {
  font-size: 14px;
  margin: 0 0 10px !important;
  font-weight: 300;
  color: #424242 !important;
}

.policy-box {
  background-color: #1cadb6 !important;
  color: #fff !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
</style>

<!-- Language Data for Benifits & Premium Widget -->
<i18n>
{
  "en": {
    "Product": "Product",
    "PriviledgeLife": "Priviledge Life",
    "Purpose":"Requirement",
    "Frequency":"Premium Payment Method",
    "PolicyTerm":"Policy Term (Years)",
    "PremiumTerm":"Premium Term (Years)",
    "CoverMultiple":"Cover Multiple",
    "SumAssured":"Sum Assured",
    "AnnualPremium":"Annual Premium",
    "TotalPremium":"Total Premium",
    "LoanAmount":"Loan Amount",
    "PeriodOfRepayment":"Period Of Repayment",
    "GracePeriod":"Grace Period",
    "RepaymentRate":"Re-Payment Rate",
    "ContributionTerm":"Contribution Payment Period"
  },
  "si": {
    "Product": "රක්ෂණ සැලසුම",
    "PriviledgeLife": "Priviledge Life",
    "Purpose":"ජීවිත රක්ෂණ අවශ්‍යතාවය",
    "Frequency":"වාරික ගෙවන ක්‍රමය",
    "PolicyTerm":"ප්‍රතිපත්ති කාලය (අවුරුදු)",
    "PremiumTerm":"වාරික වාරය (අවුරුදු)",
    "CoverMultiple":"ප්‍රතිලාභ ගණන",
    "SumAssured":"සහතික කළ මුදල",
    "AnnualPremium":"වාර්ෂික වාරිකය",
    "TotalPremium":"මුළු වාරිකය",
    "LoanAmount":"Loan Amount",
    "PeriodOfRepayment":"Period Of Repayment",
    "GracePeriod":"Grace Period",
    "RepaymentRate":"Re-Payment Rate",
    "ContributionTerm":"Contribution Payment Period"
  },
  "ta": {
    "Product": "காப்பீட்டு திட்டம்",
    "PriviledgeLife": "Priviledge Life",
    "Purpose":"ஆயுள் காப்பீட்டு தேவை",
    "Frequency":"கட்டுப்பணம் செலுத்தும் முறை",
    "PolicyTerm":"காப்புறுதி காலம் (ஆண்டுகள்) ",
    "PremiumTerm":"கட்டுப்பண காலம் (ஆண்டுகள்)",
    "CoverMultiple":"நன்மையின் எண்ணிக்கைகள்",
    "SumAssured":"அடிப்படைக் காப்புறுதித்தொகை",
    "AnnualPremium":"வருடாந்தக் கட்டுப்பணம்",
    "TotalPremium":"மொத்த கட்டுப்பணம்",
    "LoanAmount":"Loan Amount",
    "PeriodOfRepayment":"Period Of Repayment",
    "GracePeriod":"Grace Period",
    "RepaymentRate":"Re-Payment Rate",
    "ContributionTerm":"Contribution Payment Period"
  }
}
</i18n>
