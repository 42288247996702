<!-- Quotation Life Detail Cover  -->
<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header header-font">
            <font-awesome-icon icon="users" />
            <span>&nbsp;{{ $t("widgetHeader") }}</span>
          </div>
          <div class="card-body box-body">
            <div class="row">
              <div class="col-sm-2">
                <div class="form-group">
                  <label for="lbl_main_child_health_cover">
                    {{ $t("rate") }}
                  </label>
                  <ValidationProvider name="Rate" rules="required">
                    <div slot-scope="{ errors }">
                      <select
                        class="form-control"
                        v-model="additonalInfoData.rate"
                        name="Rate"
                        @change="sendChangedData()"
                      >
                        <option v-for="index in rateList" :key="index">
                          {{ index }}
                        </option>
                      </select>

                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label for="lbl_main_child_health_cover">
                    {{ $t("term") }}
                  </label>
                  <ValidationProvider
                    name="Term"
                    rules="required|mrttTermValidation:@GracePeriod"
                  >
                    <div slot-scope="{ errors }">
                      <select
                        class="form-control"
                        v-model="additonalInfoData.term"
                        name="Term"
                        @change="sendChangedData()"
                      >
                        <option v-for="index in termList" :key="index">
                          {{ index }}
                        </option>
                      </select>

                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label for="lbl_main_child_health_cover">
                    {{ $t("grace") }}
                  </label>
                  <ValidationProvider name="Grace" rules="required">
                    <div slot-scope="{ errors }">
                      <select
                        class="form-control"
                        v-model="additonalInfoData.grace"
                        name="Grace"
                        @change="sendChangedData()"
                      >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-2" v-if="additonalInfoData.grace == 1">
                <div class="form-group">
                  <label for="lbl_main_child_health_cover">
                    {{ $t("grace_period") }}
                  </label>
                  <ValidationProvider
                    name="GracePeriod"
                    rules="required|mrttGracePeriodValidation:@Term"
                  >
                    <div slot-scope="{ errors }">
                      <select
                        class="form-control"
                        v-model="additonalInfoData.gracePeriod"
                        name="Grace Period"
                        @change="sendChangedData()"
                      >
                        <!-- <option value="1">6 Month</option> -->
                        <option value="1">12 Month</option>
                        <option value="2">24 Month</option>
                        <option value="3">36 Month</option>
                        <option value="4">48 Month</option>
                        <option value="5">60 Month</option>
                      </select>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label for="lbl_main_sum_cover">
                    {{ $t("loan_amount") }}
                  </label>
                  <ValidationProvider
                    name="Sum Cover"
                    rules="required|numeric|min_value:0"
                  >
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        name="Sum Cover"
                        ref="SumCover"
                        v-model="additonalInfoData.loanAmount"
                        @change="sendChangedData()"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-2" v-show="false">
                <div class="form-group">
                  <label for="lbl_main_sum_cover">
                    {{ $t("discount_wf") }}
                  </label>
                  <ValidationProvider
                    name="Discount on WF"
                    rules="required|numeric|min_value:0|max_value:10"
                  >
                    <div slot-scope="{ errors }">
                      <select
                        class="form-control"
                        v-model="additonalInfoData.discountOnWf"
                        name="Discount on WF"
                        @change="sendChangedData()"
                      >
                        <option v-for="index in discountList" :key="index">
                          {{ index }}
                        </option>
                      </select>

                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div> 
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_monthly_basic_prem">
                    {{ $t("mainLifeLblMonthBasicPrem") }}
                  </label>
                  <ValidationProvider
                    name="Monthly Mode Basic Premium"
                    rules="required"
                  >
                    <div slot-scope="{ errors }">
                      <vue-numeric
                        type="text"
                        class="form-control"
                        id="txtMainLifeMonthlyBasicPrem"
                        name="MonthlyBasicPremium"
                        v-model="additonalInfoData.basicPremium"
                        @change.native="sendChangedData()"
                        separator=","
                        currency="Rs. "
                        disabled
                        v-bind:precision="2"
                      ></vue-numeric>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { SnotifyPosition } from "vue-snotify";
import { throttle } from "lodash";

export default {
  data() {
    return {
      termList: [],
      rateList: [],
      discountList: [],
      form: {
        sumCover: 0,
        rateList: [],
      },
    };
  },
  mounted() {
    this.getRateList();
    this.getTermList();
    this.getDiscount();
  },

  computed: {
    ...mapState("quote_mrtt", [
      "additonalInfoData",
      "flash",
      "minflash",
      "quotationNo",
      "version",
    ]),
  },
  methods: {
    ...mapActions("quote_mrtt", [
      "addChangedData",
      "getOneQuotation",
      "clearStateData",
    ]),
    getDiscount() {
      for (let i = 0; i <= 10; i++) {
        this.discountList.push(i);
      }
    },
    getTermList() {
      for (let i = 1; i <= 25; i++) {
        this.termList.push(i);
      }
    },
    getRateList() {
      for (let i = 5; i <= 24; i++) {
        this.rateList.push(i);
        this.rateList.push(i + 0.5);
      }
      for (let i = 25; i <= 40; i = i + 5) {
        this.rateList.push(i);
      }
    },
    /* calcSumCoverUpdate() {
      this.additonalInfoData.txtMainLifeMonthlyBasicPrem =
        this.additonalInfoData.txtMainHospCover;
    },*/
    sendChangedData: throttle(async function () {
      this.$snotify.clear();
      /** Loading (HTML snotify component) */
      this.$snotify.html(
        `<div class="snotifyToast__title"><b>Calculating...</b></div>
            <div class="snotifyToast__body"> Mode Premium Amount</div>
            <div class="snotify-icon snotify-icon--async"></div> `,
        {
          timeout: stop,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          position: SnotifyPosition.centerTop,
          type: "async",
        }
      );
      return this.getChangeDataResponse()
        .then(
          function (response) {
            this.$snotify.clear();
            if (response.status && response.type == undefined) {
              /** Success Message  */
              this.$snotify.success("Mode Premium Amount", response.error, {
                timeout: stop,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop,
              });
            } else {
              this.$snotify.clear();
              /** Error Message  */
              this.$snotify.error(response.error, "Error", {
                timeout: stop,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop,
              });
            }
          }.bind(this)
        )
        .catch(function (error) {
          this.$snotify.clear();
          /** Exception Message */
          this.$snotify.error(error, "Error", {
            timeout: stop,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            position: SnotifyPosition.centerTop,
          });
        });
    }, process.env.VUE_APP_QUOT_TIMEOUT_SEND_CHANGED_DATA),

    async getChangeDataResponse() {
      await this.addChangedData();
      if (this.minflash.status) {
        return this.minflash;
      } else {
        return this.flash;
      }
    },
  },
};
</script>
<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #556065 !important;
  border-bottom: 0px solid #06a990 !important;
  color: #fff !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #06a990 !important;
  border-radius: 0.25rem;
}

.snotify {
  display: block;
  position: fixed;
  width: 99% !important;
  z-index: 9999;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: none;
}
/*-- toggle switch for Main Life Frequency ----*/

.togglefreq label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.togglefreq input:checked,
.togglefreq input:not(:checked) {
  display: none;
}

.togglefreq input:checked + label {
  background: #92d127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92d127;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked + label {
  background: #92d127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92d127;
}

@media (max-width: 768px) {
  .top-margin {
    margin-top: 60px;
  }
}
</style>
<!-- Language Data for LifeCoveredWidget -->
<i18n>
{
  "en": {
    "widgetHeader": "Life Covered",
    "rate":"Rate",
    "term":"Term",
    "grace":"Grace",
    "grace_period":"Grace Period",
    "loan_amount":"Loan Amount",
    "discount_wf":"Discount on WF",
    "mainLifeLblMonthBasicPrem": "Basic Premium" 
  },
  "si": {
    "widgetHeader": "රක්ෂිතයාගේ පවුලේ තොරතුරු විවාහක අවිවාහක බව",
    "rate":"Rate",    
    "term":"Term",
    "grace":"Grace",
    "grace_period":"Grace Period",
    "loan_amount":"Loan Amount",
    "discount_wf":"Discount on WF",
    "mainLifeLblMonthBasicPrem": "Basic Premium" 
  },
  "ta": {
    "widgetHeader": "குடும்ப விபரங்கள்",
    "rate":"Rate",
    "term":"Term",
    "grace":"Grace",
    "grace_period":"Grace Period",
    "loan_amount":"Loan Amount",
    "discount_wf":"Discount on WF",
    "mainLifeLblMonthBasicPrem": "Basic Premium" 
  }
}
</i18n>
