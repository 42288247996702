<!-- Final - Insured Signature Widget -->
<template>
  <ValidatoinOberserver ref="spouseSignValidate">
    <div>
      <div class="row">
        <div class="col-12 mt-2">
          <ValidationProvider
            immediate           
            name="Spouse Sign"
            :rules="`${InfoInsured.sig_data_spouse == null ? 'required' : ''}`"
          >
            <div slot-scope="{ errors }">
              <VueSignaturePad
                id="signature"
                width="350px"
                height="250px"
                ref="signaturePad"
                :options="{
                  onBegin: () => {
                    $refs.signaturePad.resizeCanvas();
                  },
                }"
              />
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div
          class="btn-group button-space"
          role="group"
          aria-label="Basic example"
        >
          <button class="btn btn-outline-primary" @click="save">Save</button>
          <button class="btn btn-outline-secondary" @click="undo">Undo</button>
          <button class="btn btn-outline-secondary" @click="clear">
            Clear
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-2">
          <img
            :src="InfoInsured.sig_data_spouse"
            width="350px"
            height="250px"
            alt="Spouse Signature"
          />
        </div>
      </div>
    </div>
  </ValidatoinOberserver>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { throttle } from "lodash";
export default {
  name: "final-signature-widget",
  data() {
    return {};
  },
  computed: {
    ...mapState("proposal", ["InfoInsured"]),
  },
  methods: {
    ...mapActions("proposal", ["draftProposalData", "setProposalStatus"]),
    sendChangedData: throttle(async function () {
      await this.draftProposalData();
    }, process.env.VUE_APP_TIMEOUT_SEND_CHANGED_DATA),
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        this.InfoInsured.sig_data_spouse = data;
        this.setPropStatus("sig_customer", "Y");
        this.sendChangedData();
      }
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
      this.setPropStatus("sig_customer", "N");
      this.sendChangedData();
    },
    /** Set Proposal Status */
    async setPropStatus(prop_state, prop_state_value) {
      let payload = {
        prop_status_data: prop_state_value,
        prop_status: prop_state,
      };
      await this.setProposalStatus(payload);
    },
  },
};
</script>

<style scoped>
.card-outline {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-clip: border-box !important;
  border: 1px solid #92d127 !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}
.button-space {
  margin-top: 5px;
  margin-left: 20px;
}
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>

<!-- Language Data for Final - Signature Widget -->
<i18n>
{
  "en": {
    "Insured": "Insured",
    "Spouse": "Spouse"
  },
  "si": {
    "Insured": "ජීවිත රක්ෂිතයා",
    "Spouse": "කලත්‍රයා"
  },
  "ta": {
    "Insured": "பிரதான வாழ்க்கை",
    "Spouse": "வாழ்க்கைத்துணை"
  }
}
</i18n>
