<template>
  <div>
  <Header />
    <LeftNav />
    <main id="page-wrap">
      <!-- <vue-snotify></vue-snotify> -->
      <b-container fluid>
      <UserList/>
      </b-container>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/layout/AppHeader.vue";
import LeftNav from "@/components/common/layout/AppLeftNav";
import Footer from "@/components/common/layout/AppFooter.vue";
import UserList from "@/components/queue_user_management/user_list.vue";

export default {
 name: "UserManagement",
  components: {
    Header,
    LeftNav,
    Footer,
    UserList
  }
}
</script>

<style>

</style>