// import router from "@/router";
import { http } from "@/config/http";
// import _ from "lodash";

const namespaced = true;

let data = {};

const getDefaultState = () => {
  return {
    roleData: {
      role_id: '',
      role_name: '',
      role_permissions: [],
      permissions: [],

    },
    sysRoles: {}
  };
};

const state = getDefaultState();

const getters = {
};

const mutations = {

  clearObj(state) {
    Object.assign(state, getDefaultState());
  },

  /** Error Message setter */
  setError(state, { error, errorType }) {
    //console.log("called set error " + errorType);
    //console.log("called set error " + error);
    state.flash = {
      type: errorType,
      status: true,
      error: error
    };
    //console.log(state.flash.status);
  },

  setRoleData(state, data) {
    state.roleData.role_permissions = [];
    state.roleData.permissions = [];
    state.roleData.role_id = data.role.id;
    state.roleData.role_name = data.role.name;
    state.roleData.permissions = data.permissions;

    data.permissions.forEach(function (permission) {
      if (permission.enabled == true) {
        state.roleData.role_permissions.push(permission.name);
      }
    });
  },

  resetRoleData(state) {
    state.roleData.role_id = '';
    state.roleData.role_name = '';
    state.roleData.permissions = '';
    state.roleData.role_permissions = '';
  },

  SetSystemRoles(state, data) {
    state.roleData.permissions = data.permissions;
  }
};

const actions = {
  async getRoleData({ commit }, data) {
    let role_id = data;

    await http
      .get("roles/" + role_id, { data }, {})
      .then(response => {
        commit('setRoleData', response.data);
      })
      .catch(error => {
        // console.log(error);
        commit('showAlert', {
          title: "Error!",
          msg: "Error occured while retrieving role data.",
          type: "error",
          position: "rightTop"
        }, { root: true });

        let errorType = "E";
        commit("setError", { error, errorType });
      });
  },


  async createRole({ commit }, data) {
    await http
      .post("role/create", {
        name: data.role_name,
        permissions: data.permissions
      })
      .then(response => {
        //console.log(response.data);
        if (response.data.success) {
          commit('clearObj');
          commit(
            "showNotification",
            {
              title: "Role Create",
              msg: response.data.success.message,
              type: "success"
            },
            { root: true }
          );
          commit('resetRoleData');
        } else if (response.data.error) {
          //console.log(response.data.error.message);
          commit(
            "showNotification",
            {
              title: "Error",
              msg: response.data.error.message,
              type: "error"
            },
            { root: true }
          );

        }
      })
      .catch(error => {
        //console.log(error);
        commit(
          "showNotification",
          {
            title: "Error",
            msg: "Error occured while creating role .",
            type: "error"
          },
          { root: true }
        );

        let errorType = "E";
        commit("setError", { error, errorType });
      });
  },


  async updateRoleData({ commit }, data) {
    await http
      .post("roles", {
        role_id: data.role_id,
        name: data.role_name,
        permissions: data.permissions
      })
      .then(response => {
        //console.log(response.data);
        if (response.data.success) {
          commit('clearObj');
          commit(
            "showNotification",
            {
              title: "Role Create",
              msg: response.data.success.message,
              type: "success"
            },
            { root: true }
          );
          commit('resetRoleData');
        } else if (response.data.error) {
          //console.log(response.data.error.message);
          commit('showAlert', {
            title: "Error!",
            msg: response.data.error.message,
            type: "error",
            position: "rightTop"
          }, { root: true });
        }
      })
      .catch(error => {
        // msg: "Error occured while updating role data.",
        commit(
          "showNotification",
          {
            title: "Error",
            msg: error,
            type: "error"
          },
          { root: true }
        );
      });
  },
  async getSystemRoles({ commit }) {
    await http
      .get("roles", null)
      .then(response => {
        commit('SetSystemRoles', response.data.data);
      })
      .catch(error => {
        // msg: "Error occured while retrieving role data.",
        commit(
          "showNotification",
          {
            title: "Error",
            msg: "Error occured while retrieving role data.",
            type: "error"
          },
          { root: true }
        );

        let errorType = "E";
        commit("setError", { error, errorType });
      });
  },
  async getAllPermissions({ commit }) {
    await http
      .get("permissions/all", { data }, {})
      .then(response => {
        // console.log(response.data);
        commit('SetSystemRoles', response.data);
      })
      .catch(error => {
        //console.log(error);

        commit(
          "showNotification",
          {
            title: "Error",
            msg: "Error occured while retrieving role permission.",
            type: "error"
          },
          { root: true }
        );

        let errorType = "E";
        commit("setError", { error, errorType });
      });
  },

};



export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
