import { http } from "@/config/http";

const namespaced = true;

const getDefaultState = () => {
  return {
  };
};

const state = getDefaultState();

const getters = {};

const mutations = {
};
const actions = {
  async exportProposals({ commit }, payload ) {
    return new Promise((resolve, reject) => {
      http
            .post("export/proposal", payload, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
                commit(
                    "showNotification", {
                    title: "Draft",
                    msg: "Error Occured",
                    type: "error",
                }, { root: true }
                );
            });
    });
  },
  async exportQuotations({ commit }, payload ) {
    return new Promise((resolve, reject) => {
      http
            .post("export/quotation", payload, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
                commit(
                    "showNotification", {
                    title: "Draft",
                    msg: "Error Occured",
                    type: "error",
                }, { root: true }
                );
            });
    });
  },
  async exportUsers({ commit }, payload ) {
    return new Promise((resolve, reject) => {
      http
            .post("export/user", payload, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
                commit(
                    "showNotification", {
                    title: "Draft",
                    msg: "Error Occured",
                    type: "error",
                }, { root: true }
                );
            });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
