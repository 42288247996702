import Vue from "vue";
import Router from "vue-router";
import Login from "@/views/login";
import Admin from "@/views/admin";
import Logout from "@/components/auth/logout";
import ListQuote from "@/views/list_quotes";
import ListLead from "@/views/list_leads";
import ListProposal from "@/views/list_proposal";

/** Main Welcome Page */
import Welcome from "@/views/welcome";
/** Package Welcome Pages */
import WelcomePlatinum from "@/views/quote_platinum/welcome_platinum";
import WelcomeSurakshitha from "@/views/quote_surakshitha/welcome_surakshitha";
import WelcomePlatinumPlus from "@/views/quote_platinum_plus/welcome_platinum_plus";
import WelcomeAdyapanaPlus from "@/views/quote_adyapana_plus/welcome_adyapana_plus";
import WelcomeDeergayu from "@/views/quote_deergayu/welcome_deergayu";
import WelcomeFlexiLife from "@/views/quote_flexi_life/welcome_flexi_life";
import WelcomeTermPlusCic from "@/views/quote_term_plus_cic/welcome_term_plus_cic";
import WelcomeHealthPlus from "@/views/quote_health_plus/welcome_health_plus";
import WelcomeMrtt from "@/views/quote_mrtt/welcome_mrtt";
/** Platinum Quotation Package */
import NewQuotePlatinum from "@/views/quote_platinum/new_quote";
import EditQuotePlatinum from "@/views/quote_platinum/edit_quote";
/** Surakshitha Quotation Package */
import NewQuoteSurakshitha from "@/views/quote_surakshitha/new_quote";
import EditQuoteSurakshitha from "@/views/quote_surakshitha/edit_quote";
/** Platinum Plus Quotation Package */
import NewQuotePlatinumPlus from "@/views/quote_platinum_plus/new_quote";
import EditQuotePlatinumPlus from "@/views/quote_platinum_plus/edit_quote";
/** Adyapana Plus Quotation Package */
import NewQuoteAdyapanaPlus from "@/views/quote_adyapana_plus/new_quote";
import EditQuoteAdyapanaPlus from "@/views/quote_adyapana_plus/edit_quote";
/** Deergayu Quotation Package */
import NewQuoteDeergayu from "@/views/quote_deergayu/new_quote";
import EditQuoteDeergayu from "@/views/quote_deergayu/edit_quote";
/** Flexi Life Quotation Package */
import NewQuoteFlexiLife from "@/views/quote_flexi_life/new_quote";
import EditQuoteFlexiLife from "@/views/quote_flexi_life/edit_quote";
/** Term Plus CIC Quotation Package */
import NewQuoteTermPlusCic from "@/views/quote_term_plus_cic/new_quote";
import EditQuoteTermPlusCic from "@/views/quote_term_plus_cic/edit_quote";
/** Health Plus Quotation Package */
import NewQuoteHealthPlus from "@/views/quote_health_plus/new_quote";
import EditQuoteHealthPlus from "@/views/quote_health_plus/edit_quote";
/** MRTT Quotation Package */
import NewQuoteMrtt from "@/views/quote_mrtt/new_quote";
import EditQuoteMrtt from "@/views/quote_mrtt/edit_quote";
/** Proposal */
//import PropWelcome from "@/views/proposal/prop_welcome";
import NewProposal from "@/views/proposal/new_proposal";
import VerifyDocument from "@/views/proposal/verify_document";
import ViewDocument from "@/views/proposal/view_document";
import AuditTrail from "@/views/audit_trail";

/** Leads Management */
import NewLead from "@/views/leads/new_leads";
import EditLead from "@/views/leads/edit_leads";
/** Appointments */
import NewAppointment from "@/views/list_appointments";
import appointmentCalendarView from "@/views/appointment_calendar";
/** Pension Calculator */
import PensionCalculator from "@/views/quote_surakshitha/pension_calculator";

import Listbranches from "@/views/list_branches";
import NewBranch from "@/views/branches/new_branch";
import EditBranch from "@/views/branches/edit_branch";
/** User Management */
import UserManagement from "@/views/user_management/user_management";
import UserForm from "@/views/user_management/user_form";
import PasswordForm from "@/views/user_management/change_password";

/** Queue User Management */
import QueueUserManagement from "@/views/queue_user_management/queue_user_management";
import HoUserAllocationManagement from "@/views/queue_user_management/ho_user_allocation";

/** Role Management */
import RoleManagement from "@/views/role_management/role_management";
import CreateRole from "@/views/role_management/create_role";
import EditRole from "@/views/role_management/edit_role";

/** TEMP ebuddy login simulate */
//import Ebuddy from "@/views/ebuddy";

/** Role Management */
import ViewProposalPdf from "@/views/proposal/view_proposal_pdf";

Vue.use(Router);

export default new Router({
    routes: [

        {
            path: '/'+process.env.VUE_APP_VERSION+'',
            component: {
                render(c) { return c('router-view') }
            },
            children: [
                {
                    path: "admin",
                    name: "admin",
                    component: Admin,
                    meta: {
                        requiresAdmin: true
                    }
                },
                /** New Quotation - Platinum */
                {
                    path: "new_quote_platinum",
                    name: "new_quote_platinum",
                    component: NewQuotePlatinum,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** Edit Quotation - Platinum */
                {
                    path: "edit_quote_platinum/:ref_no/:",
                    name: "edit_quote_platinum",
                    component: EditQuotePlatinum,
                    props: true,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** New Quotation - Surakshitha */
                {
                    path: "new_quote_surakshitha",
                    name: "new_quote_surakshitha",
                    component: NewQuoteSurakshitha,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** Edit Quotation - Surakshitha */
                {
                    path: "edit_quote_surakshitha/:ref_no/:",
                    name: "edit_quote_surakshitha",
                    component: EditQuoteSurakshitha,
                    props: true,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** New Quotation - Platinum Plus */
                {
                    path: "new_quote_platinum_plus",
                    name: "new_quote_platinum_plus",
                    component: NewQuotePlatinumPlus,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** Edit Quotation - Platinum Plus */
                {
                    path: "edit_quote_platinum_plus/:ref_no/:",
                    name: "edit_quote_platinum_plus",
                    component: EditQuotePlatinumPlus,
                    props: true,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** New Quotation - Adyapana Plus */
                {
                    path: "new_quote_adyapana_plus",
                    name: "new_quote_adyapana_plus",
                    component: NewQuoteAdyapanaPlus,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** Edit Quotation - Adyapana Plus */
                {
                    path: "edit_quote_adyapana_plus/:ref_no/:",
                    name: "edit_quote_adyapana_plus",
                    component: EditQuoteAdyapanaPlus,
                    props: true,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** New Quotation - Deergayu */
                {
                    path: "new_quote_deergayu",
                    name: "new_quote_deergayu",
                    component: NewQuoteDeergayu,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** Edit Quotation - Deergayu */
                {
                    path: "edit_quote_deergayu/:ref_no/:",
                    name: "edit_quote_deergayu",
                    component: EditQuoteDeergayu,
                    props: true,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** New Quotation - FlexiLife */
                {
                    path: "new_quote_flexi_life",
                    name: "new_quote_flexi_life",
                    component: NewQuoteFlexiLife,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** Edit Quotation - FlexiLife */
                {
                    path: "edit_quote_flexi_life/:ref_no/:",
                    name: "edit_quote_flexi_life",
                    component: EditQuoteFlexiLife,
                    props: true,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** New Quotation - TermPlusCic */
                {
                    path: "new_quote_term_plus_cic",
                    name: "new_quote_term_plus_cic",
                    component: NewQuoteTermPlusCic,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** Edit Quotation - TermPlusCic */
                {
                    path: "edit_quote_term_plus_cic/:ref_no/:",
                    name: "edit_quote_term_plus_cic",
                    component: EditQuoteTermPlusCic,
                    props: true,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** New Quotation - HealthPlus */
                {
                    path: "new_quote_health_plus",
                    name: "new_quote_health_plus",
                    component: NewQuoteHealthPlus,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** Edit Quotation - HealthPlus */
                {
                    path: "edit_quote_health_plus/:ref_no/:",
                    name: "edit_quote_health_plus",
                    component: EditQuoteHealthPlus,
                    props: true,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** New Quotation - MRTT */
                {
                    path: "new_quote_mrtt",
                    name: "new_quote_mrtt",
                    component: NewQuoteMrtt,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                
                 /** Edit Quotation - HealthPlus */
                 {
                    path: "edit_quote_mrtt/:ref_no",
                    name: "edit_quote_mrtt",
                    component: EditQuoteMrtt,
                    props: true,
                    meta: {
                        requiresAuth: true,
                        permission: ['search-quote']
                    }
                },
                /** Quotation Listing/Filtering - Common */
                {
                    path: "quote_list",
                    name: "quote_list",
                    component: ListQuote,
                    meta: {
                        permission: ['search-quote'],
                        requiresAuth: true
                    }
                },
                /** Lead Listing/Filtering - Common */
                {
                    path: "lead_list",
                    name: "lead_list",
                    component: ListLead,
                    meta: {
                        permission: ['my-leads'],
                        requiresAuth: true
                    }
                },
                /** Proposal list  */
                {
                    path: "proposal_list",
                    name: "proposal_list",
                    component: ListProposal,
                    meta: {
                        permission: ['view-proposal'],
                        requiresAuth: true
                    }
                },

                /** New Lead - Leads */
                {
                    path: "new_lead",
                    name: "new_lead",
                    component: NewLead,
                    meta: {
                        permission: ['my-leads'],
                        requiresAuth: true
                    }
                },

                /** Edit Leads - Leads*/
                {
                    path: "edit_lead/:ref_no/:",
                    name: "edit_lead",
                    component: EditLead,
                    props: true,
                    meta: {
                        permission: ['my-leads'],
                        requiresAuth: true
                    }
                },

                /** View Lead - Leads*/
                {
                    path: "view_lead/:ref_no/:",
                    name: "view_lead",
                    component: EditLead,
                    props: true,
                    meta: {
                        permission: ['my-leads'],
                        requiresAuth: true
                    }
                },

                /** Appointment - Appointments*/
                {
                    path: "appointment_list/:ref_no/:",
                    name: "appointment_list",
                    component: NewAppointment,
                    props: true,
                    meta: {
                        permission: ['search-quote'],
                        requiresAuth: true
                    }
                },

                /** Main Welcome Page */
                {
                    path: "welcome",
                    name: "welcome",
                    component: Welcome,
                    meta: {
                        requiresAuth: true
                    }
                },
                /** Platinum Quotation Welcome Page */
                {
                    path: "welcome_platinum",
                    name: "welcome_platinum",
                    component: WelcomePlatinum,
                    meta: {
                        permission: ['search-quote'],
                        requiresAuth: true
                    }
                },
                /** Surakshitha Quotation Welcome Page */
                {
                    path: "welcome_surakshitha",
                    name: "welcome_surakshitha",
                    component: WelcomeSurakshitha,
                    meta: {
                        permission: ['search-quote'],
                        requiresAuth: true
                    }
                },
                /** Platinum Plus Quotation Welcome Page */
                {
                    path: "welcome_platinum_plus",
                    name: "welcome_platinum_plus",
                    component: WelcomePlatinumPlus,
                    meta: {
                        permission: ['search-quote'],
                        requiresAuth: true
                    }
                },
                /** Adyapana Plus Quotation Welcome Page */
                {
                    path: "welcome_adyapana_plus",
                    name: "welcome_adyapana_plus",
                    component: WelcomeAdyapanaPlus,
                    meta: {
                        permission: ['search-quote'],
                        requiresAuth: true
                    }
                },
                /** Deergayu Quotation Welcome Page */
                {
                    path: "welcome_deergayu",
                    name: "welcome_deergayu",
                    component: WelcomeDeergayu,
                    meta: {
                        permission: ['search-quote'],
                        requiresAuth: true
                    }
                },
                /** Flexi Life Quotation Welcome Page */
                {
                    path: "welcome_flexi_life",
                    name: "welcome_flexi_life",
                    component: WelcomeFlexiLife,
                    meta: {
                        permission: ['search-quote'],
                        requiresAuth: true
                    }
                },

                /** Term Plus CIC Quotation Welcome Page */
                {
                    path: "welcome_term_plus_cic",
                    name: "welcome_term_plus_cic",
                    component: WelcomeTermPlusCic,
                    meta: {
                        permission: ['search-quote'],
                        requiresAuth: true
                    }
                },

                /** Health Plus Quotation Welcome Page */
                {
                    path: "welcome_health_plus",
                    name: "welcome_health_plus",
                    component: WelcomeHealthPlus,
                    meta: {
                        permission: ['search-quote'],
                        requiresAuth: true
                    }
                },
                /** MRTT Quotation Welcome Page */
                {
                    path: "welcome_mrtt",
                    name: "welcome_mrtt",
                    component: WelcomeMrtt,
                    meta: {
                        permission: ['search-quote'],
                        requiresAuth: true
                    }
                },

                /** New/Draft Proposal */
                {
                    path: "new_proposal/:ref_no/:",
                    name: "new_proposal",
                    component: NewProposal,
                    meta: {
                        permission: ['create-proposal'],
                        requiresAuth: true
                    }
                },

                /** Upload Document */
                {
                    path: "verify_document/:ref_no/:",
                    name: "verify_document",
                    component: VerifyDocument,
                    meta: {
                        permission: ['edit-proposal'],
                        requiresAuth: true
                    }
                },

                {
                    path: "view_document/:ref_no/:",
                    name: "view_document",
                    component: ViewDocument,
                    meta: {
                        requiresAuth: true
                    }
                },

                /** New/Draft Proposal */
                {
                    path: "pension_calc",
                    name: "pension_calculator",
                    component: PensionCalculator,
                    meta: {
                        permission: ['pension-calculator'],
                        requiresAuth: true
                    }
                },
                /** User Management */
                {
                    path: "user_management",
                    name: "user_management",
                    component: UserManagement,
                    meta: {
                        permission: ['user-mgt'],
                        requiresAuth: true
                    }
                },
                /** Queue User Management */
                {
                    path: "queue_user_management",
                    name: "queue_user_management",
                    component: QueueUserManagement,
                    meta: {
                        permission: ['queue-user-mgt'],
                        requiresAuth: true
                    }
                },

                /** Ho User Allocation */
                {
                    path: "ho_user_allocation",
                    name: "ho_user_allocation",
                    component: HoUserAllocationManagement,
                    meta: {
                        permission: ['ho-user-allocation-mgt'],
                        requiresAuth: true
                    }
                },

                {
                    path: "user_form",
                    name: "user_form",
                    component: UserForm,
                    meta: {
                        permission: ['user-mgt'],
                        requiresAuth: true
                    }
                },
                {
                    path: "change_password",
                    name: "change_password",
                    component: PasswordForm,
                    meta: {
                        permission: ['user-mgt'],
                        requiresAuth: true
                    }
                },
                /** Role Management */
                {
                    path: "role_management",
                    name: "role_management",
                    component: RoleManagement,
                    meta: {
                        permission: ['role-mgt'],
                        requiresAuth: true
                    }
                },
                {
                    path: "edit_role",
                    name: "edit_role",
                    component: EditRole,
                    props: true,
                    meta: {
                        permission: ['role-mgt'],
                        requiresAuth: true
                    }
                },
                {
                    path: "create_role",
                    name: "create_role",
                    component: CreateRole,
                    props: true,
                    meta: {
                        permission: ['role-mgt'],
                        requiresAuth: true
                    }
                },
                /** Appointment Calendar */
                {
                    path: "appointment_calendar",
                    name: "appointment_calendar",
                    component: appointmentCalendarView,
                    props: true,
                    meta: {
                        requiresAdmin: true,
                        permission: {
                            permission: ['appointment-calendar'],
                            requiresAuth: true
                        }
                    }
                },


                // Branch management
                {
                    path: "branches_list",
                    name: "branches_list",
                    component: Listbranches,
                    meta: {
                        permission: ['branch-mgt'],
                        requiresAuth: true
                    }
                },

                {
                    path: "branches_add",
                    name: "branches_add",
                    component: NewBranch,
                    meta: {
                        permission: ['branch-mgt'],
                        requiresAuth: true
                    }
                },
                {
                    path: "branches_edit/:ref_no/:",
                    name: "branches_edit",
                    component: EditBranch,
                    props: true,
                    meta: {
                        permission: ['branch-mgt'],
                        requiresAuth: true
                    }
                },
                {
                    path: "audit_trail",
                    name: "audit_trail",
                    component: AuditTrail,
                    meta: {
                        permission: ['audit-trail'],
                        requiresAuth: true
                    }
                },
                /** View proposal pdf - ALL */
                {
                    path: "view_proposal/:ref_no",
                    name: "view_proposal",
                    component: ViewProposalPdf,
                    props: true,
                    meta: {
                        permission: ['view-proposal'],
                        requiresAuth: true
                    }
                },
            ]
        },



        {
            path: "/",
            name: "login",
            props: false,
            component: Login,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/logout",
            name: "logout",
            component: Logout
        },
    ]
});
