<!-- Health - Habits Question List Widget (09) -->
<template>
  <div>
    <div
      class="row quest-row"
      v-for="(question, index) in HabitsQuestions"
      :key="index"
    >

      <div class="col">
        <div class="card-outline">
          <div class="card-body">
            <div class="row">
              <!-- Question -->
              <div class="col quest">{{ $t(question.quest_id) }}</div>
            </div>
            <div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="card-style">
                    <div class="media">
                      <div class="media-left">
                        <img
                          v-if="InfoInsured.gender == 'Male'"
                          class="media-object card-img"
                          src="@/assets/img/spouse-hubby.png"
                        />
                        <img
                          v-else
                          class="media-object card-img"
                          src="@/assets/img/spouse-wife.png"
                        />
                      </div>

                      <div class="media-body">
                        <a href="#">
                          <h5 class="media-heading">&nbsp;</h5>
                        </a>
                        <div class="members">
                          <strong>{{ $t("Insured") }}</strong>
                        </div>
                        <div class="btn-part" style="margin-right: 1px">
                          <div class="btn-part">
                            <div class="toggle-button toggle-button--aava">
                              <input
                                :id="question.quest_id + 'Insured'"
                                type="checkbox"
                                true-value="Y"
                                false-value="N"
                                :checked="question.quest_answer_in"
                                v-model="question.quest_answer_in"
                                @change="sendChangedData()"
                              />
                              <label
                                :for="question.quest_id + 'Insured'"
                                data-on-text="YES"
                                data-off-text="NO"
                                class="toggle-lable"
                              ></label>
                              <div class="toggle-button__icon"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row form-area-pad"
                      v-show="question.quest_answer_in == 'Y'"
                    >
                      <div class="col-sm-6 reason-top">
                        <div class="form-group">
                          <label for="since">{{ $t("Diagnosis") }} <span class="required-mark">*</span></label>
                          <ValidationProvider
                            immediate
                            name="Diagnosis"
                            :vid="question.quest_id + 'diagnosis_insured'"
                            :rules="`${
                              question.quest_answer_in == 'Y'
                                ? 'required|min:3|max:20'
                                : ''
                            }`"
                          >
                            <div slot-scope="{ errors }">
                              <input
                                id="diagnosis"
                                type="text"
                                class="form-control"
                                placeholder="Diagnosis"
                                v-model="question.quest_diagnosis_in"
                                @change="sendChangedData()"
                                v-uppercase
                              />
                              <p class="p-error">{{ errors[0] }}</p>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="col-sm-6 reason-top">
                        <div class="form-group">
                          <label for="date">{{ $t("Treatment") }} <span class="required-mark">*</span></label>
                          <ValidationProvider
                            immediate
                            name="Treatment"
                            :vid="question.quest_id + 'treatment_insured'"
                            :rules="`${
                              question.quest_answer_in == 'Y' ? 'required|min:3|max:20' : ''
                            }`"
                          >
                            <div slot-scope="{ errors }">
                              <input
                                id="treatment"
                                type="text"
                                class="form-control"
                                placeholder="Treatment"
                                v-model="question.quest_treatment_in"
                                @change="sendChangedData()"
                                v-uppercase
                              />
                              <p class="p-error">{{ errors[0] }}</p>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="col-sm-12 reason-top text-input-top">
                        <div class="form-group">
                          <label for="date">{{ $t("NameAddDoctor") }} <span class="required-mark">*</span></label>
                          <ValidationProvider
                            immediate
                            name="Name And Add of Doctor"
                            :vid="question.quest_id + 'nameAddDoctor_insured'"
                            :rules="`${
                              question.quest_answer_in == 'Y'
                                ? 'required|min:3|max:20'
                                : ''
                            }`"
                          >
                            <div slot-scope="{ errors }">
                              <input
                                id="nameAddDoctor"
                                type="text"
                                class="form-control"
                                placeholder="Name And Add of Doctor"
                                v-model="question.quest_name_add_doctor_in"
                                @change="sendChangedData()"
                                v-uppercase
                              />
                              <p class="p-error">{{ errors[0] }}</p>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-6 col-sm-6"
                  v-if="InfoInsured.has_spouse == 'Yes'"
                >
                  <div class="card-style">
                    <div class="media">
                      <div class="media-left">
                        <img
                          v-if="InfoInsured.sp_gender == 'Male'"
                          class="media-object card-img"
                          src="@/assets/img/spouse-hubby.png"
                        />
                        <img
                          v-else
                          class="media-object card-img"
                          src="@/assets/img/spouse-wife.png"
                        />
                      </div>

                      <div class="media-body">
                        <a href="#">
                          <h5 class="media-heading">&nbsp;</h5>
                        </a>
                        <div class="members">
                          <strong>{{ $t("Spouse") }}</strong>
                        </div>
                        <div class="btn-part">
                          <div class="toggle-button toggle-button--aava">
                            <input
                              :id="question.quest_id + 'Spouse'"
                              type="checkbox"
                              true-value="Y"
                              false-value="N"
                              :checked="question.quest_answer_sp"
                              v-model="question.quest_answer_sp"
                              @change="sendChangedData()"
                            />
                            <label
                              :for="question.quest_id + 'Spouse'"
                              data-on-text="YES"
                              data-off-text="NO"
                              class="toggle-lable"
                            ></label>
                            <div class="toggle-button__icon"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row form-area-pad"
                      v-show="question.quest_answer_sp == 'Y'"
                    >
                      <div class="col-sm-6 reason-top">
                        <div class="form-group">
                          <label for="date">{{ $t("Diagnosis") }} <span class="required-mark">*</span></label>
                          <ValidationProvider
                            immediate
                            name="Diagnosis"
                            :vid="question.quest_id + 'diagnosis_spouse'"
                            :rules="`${
                              question.quest_answer_sp == 'Y'
                                ? 'required|min:3|max:20'
                                : ''
                            }`"
                          >
                            <div slot-scope="{ errors }">
                              <input
                                id="diagnosis"
                                type="text"
                                class="form-control"
                                placeholder="Diagnosis"
                                v-model="question.quest_diagnosis_sp"
                                @change="sendChangedData()"
                                v-uppercase
                              />
                              <p class="p-error">{{ errors[0] }}</p>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="col-sm-6 reason-top text-input-top">
                        <div class="form-group">
                          <label for="date">{{ $t("Treatment") }} <span class="required-mark">*</span></label>
                          <ValidationProvider
                            immediate
                            name="Treatment"
                            :vid="question.quest_id + 'treatment_spouse'"
                            :rules="`${
                              question.quest_answer_sp == 'Y'
                                ? 'required|min:3|max:20'
                                : ''
                            }`"
                          >
                            <div slot-scope="{ errors }">
                              <input
                                id="treatment"
                                type="text"
                                class="form-control"
                                placeholder="Treatment"
                                v-model="question.quest_treatment_sp"
                                @change="sendChangedData()"
                                v-uppercase
                              />
                              <p class="p-error">{{ errors[0] }}</p>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="col-sm-12 reason-top text-input-top">
                        <div class="form-group">
                          <label for="date">{{ $t("NameAddDoctor") }} <span class="required-mark">*</span></label>
                          <ValidationProvider
                            immediate
                            name="Name And Add of Doctor"
                            :vid="question.quest_id + 'nameAddOfDoctor_spouse'"
                            :rules="`${
                              question.quest_answer_sp == 'Y'
                                ? 'required|min:3|max:20'
                                : ''
                            }`"
                          >
                            <div slot-scope="{ errors }">
                              <input
                                id="nameAddOfDoctor"
                                type="text"
                                class="form-control"
                                placeholder="Name And Add of Doctor"
                                v-model="question.quest_name_add_doctor_sp"
                                @change="sendChangedData()"
                                v-uppercase
                              />
                              <p class="p-error">{{ errors[0] }}</p>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { throttle } from "lodash";
export default {
  name: "health-habits-question-widget",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState("proposal", ["HabitsQuestions", "InfoInsured"]),
  },
  methods: {
    ...mapActions("proposal", ["draftProposalData"]),
    sendChangedData: throttle(async function () {
      await this.draftProposalData();
    }, process.env.VUE_APP_TIMEOUT_SEND_CHANGED_DATA),
  },
};
</script>

<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}
.card-outline {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-clip: border-box !important;
  border: 1px solid #92d127 !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}

.reason-top {
  margin-top: 10px;
}

.form-area-pad {
  padding: 15px;
}

.quest-row {
  margin-bottom: 15px;
}

.quest {
  font-size: 12px;
  font-weight: 700;
}

.toggle-lable {
  margin-bottom: 0rem !important;
}

@media (max-width: 767px) {
  .text-input-top {
    margin-top: 10px;
  }
}

.card-style {
  display: block;
  height: auto;
  margin-top: 15px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-radius: calc(0.25rem - 1px);
}
.card-img {
  height: 50px;
  width: 50px;
}
.media-heading {
  margin-top: -19px;
  color: #444;
}
.media-heading:hover,
a:link {
  color: #00c853;
  text-decoration: none;
}
.members {
  margin-top: 0px;
  color: #f39721;
  float: left;
  margin-left: 20px;
}
.btn-part {
  display: inline-block;
  margin-right: 8px;
  float: right;
}
.badge {
  display: inline-block;
  background: #00c853;
  float: right;
  padding: 7px;
  border-radius: 50px;
  margin: 10px;
}

/* --- toggle switch ---- */

.section {
  text-align: center;
  height: 100%;
  margin: 0 auto;
  padding: 6em 0;
}
.section__title {
  font-size: 1.1em;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #fff;
  margin-bottom: 3em;
}
.section--aava {
  background: #2994b2;
}
.section--tuuli {
  background: #474744;
}
.section--vesi {
  background: #54567a;
}
.section--sade {
  background: #2d4659;
}
.section--ilma {
  background: #095062;
}
.section--tuli {
  background: #068b78;
}

.toggle-button {
  margin: 5px 0px;
}

/*
 * Toggle button styles
 */
.toggle-button {
  position: relative;
  display: inline-block;
  color: #fff;
}
.toggle-button label {
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  text-align: left;
}

.toggle-button input {
  display: none;
}
.toggle-button__icon {
  cursor: pointer;
  pointer-events: none;
}
.toggle-button__icon:before,
.toggle-button__icon:after {
  content: "";
  position: absolute;
  top: 45%;
  left: 35%;
  transition: 0.2s ease-out;
}

.toggle-button--aava label {
  height: 34px;
  line-height: 36px;
  transition: all 0.2s;
  border-radius: 0.2rem;
}
.toggle-button--aava label:before,
.toggle-button--aava label:after {
  position: absolute;
  right: 1.5rem;
  transition: all 0.2s 0.1s ease-out;
}
.toggle-button--aava label:before {
  content: attr(data-on-text);
}
.toggle-button--aava label:after {
  content: attr(data-off-text);
}

.toggle-button--aava input[type="checkbox"] + label {
  width: 100px;
  background: #ff5335;
}

@media (max-width: 768px) {
  .toggle-button--aava input[type="checkbox"] + label {
    width: 88px;
  }
}

.toggle-button--aava input[type="checkbox"] + label:before {
  opacity: 0;
  transform: translate(0, 20px);
}
.toggle-button--aava input[type="checkbox"] + label:after {
  opacity: 1;
  transform: translate(0, 0);
}

.toggle-button--aava input[type="checkbox"]:checked ~ label {
  width: 100px;
  background: #61b136;
}

@media (max-width: 768px) {
  .toggle-button--aava input[type="checkbox"]:checked ~ label {
    width: 88px;
  }
}

.toggle-button--aava input[type="checkbox"]:checked ~ label:before {
  opacity: 1;
  transform: translate(0, 0);
}
.toggle-button--aava input[type="checkbox"]:checked ~ label:after {
  opacity: 0;
  transform: translate(0, -20px);
}

.toggle-button--aava
  input[type="checkbox"]:checked
  ~ .toggle-button__icon:before {
  transform: translate(-10%, 100%) rotate(45deg);
  width: 13.66667px;
}

.toggle-button--aava
  input[type="checkbox"]:checked
  ~ .toggle-button__icon:after {
  transform: translate(30%) rotate(-45deg);
}

.toggle-button--aava .toggle-button__icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 36px;
  width: 38px;
}
.toggle-button--aava .toggle-button__icon:before,
.toggle-button--aava .toggle-button__icon:after {
  height: 3px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.toggle-button--aava .toggle-button__icon:before {
  width: 20px;
  transform: rotate(45deg);
}
.toggle-button--aava .toggle-button__icon:after {
  width: 20px;
  transform: rotate(-45deg);
}
</style>

<!-- Language Data for Health - Habits Question List Widget (09) -->
<i18n>
{
  "en": {
    "BodilyDeformities": "a. Have you had any bodily deformities ?",
    "MedicalTreatmentOperation": "b. Have you currently or at any time suffered from any illnesses or had any medical treatment or operation ?",
    "Hospitalized": "c. Have you ever undergone surgery or are you awaiting surgery or been hospitalized in the last 2 years ?",
    "HeartAttack": "d. Have you ever had a heart attack, chest pain, a stroke, hypertension, hyperlipideamia, diabates or any disorder of the heart blood vessels ?",
    "Diabetes": "e. Are you on treatment for Diabates ?",
    "TumorCancer": "f. Have you had any form or type of tumor or cancer ?",
    "Arthritis": "g. Have you ever had any disorder or disease of the muscles, bones, joints, limbs or spine(including arthritis, rheumatism etc) ?",
    "NeurologicalDisorder": "h. Have you had any anxiety state, depression or any mental, nervous or neurological disorder ?",
    "Tuberculosis": "i. Have you ever suffered from respiratory or lung trouble(Eg. asthma, bronchitis, persitent cough, tuberculosis) ?",
    "StomachDisorder": "j. Have you ever suffered from any disorder of the stomach, intenstine, digestive system, gallbladder or liver (Eg. Actual or suspected gastritis or duodenal ulcer, bleeding from the bowel, recurrent indigestion, hapatitis, gallstones, hiatus hernia) ?",
    "KidneyDisorder": "k. Have you ever suffered from any disease, disorder or infection of the kidneys, bladder or reproductive organs (Eg. Albumin, in urine, stones, prostatitis, venereal disease) ?",
    "DiseaseThroat": "l. Have you ever suffered from diseases of the eyes, ears, nose, mouth and throat ?",
    "AIDS": "m. Have you ever recieved (or expected to recieve) any medical advice, blood test or other tests in connection with AIDS, AIDS related condition ?",
    "BloodDisorder": "n. Have you ever suffered from any disorders of the blood (Eg. hemophilia, anemia) ?",
    "SexualDisease": "o. Have you ever suffered from hepatitis or any sexually transmitted diseases ?",
    "AnyOtherIllness": "p. Have you ever suffered from any illness or disorder which is not mentioned above ?",
    "OperatedHospital": "q. Have you ever been examined, treated, or operated in a hospital, nursing home, clinic, Ayurvedic centre or sanatorium ?",
    "NervousSystem": "r. Have you ever suffered from paralysis, coma, fits, or any other diseases or disorders of the nervous system ?",
    "SkinJointsDisorder": "s. Have you ever suffered from disease or disorders of the skin, muscles, bones or joints ?",
    "FamilyIllness": "t. Has anyone in the family had any disease or illness in the past three months ? If so, Please give details",

    "Diagnosis": "Diagnosis?",
    "Treatment": "Treatment?",
    "NameAddDoctor": "Name And Add of Doctor?",
  
    "Insured": "Insured",
    "Spouse": "Spouse",

    "MrttHyperlipidemia": "a. Hyperlipidemia, diabetes or sugar in urine",
    "MrttNervousDisorders": "b. Nervous system or neurological disorders – e.g. stroke, epileptic fits, paralysis, depression",
    "MrttBloodSystem": "c. Diseases of the blood and blood circulatory system – e.g. Heart disease, blood pressure, chest pain, rheumatic fever, stroke, anaemia, haemophilia",
    "MrttLungConditions": "d. Lung or other respiratory conditions – e.g. asthma, bronchitis, pneumonia, tuberculosis",
    "MrttKidneyDisorders": "e.Kidney, bladder or reproductive organs disorders – e.g. renal stones, venereal disease, prostatitis",
    "MrttDigestiveDisorder": "f. Disorder of the digestive system, gall bladder or liver – e.g. gastric or duodenal ulcer, jaundice, hiatus hernia, gallstones",
    "MrttCancer": "g. Any form of type of cancer, tumors or cyst",
    "MrttDiseasesOfEye": "h.Diseases of eye, ear, nose and throat",
    "MrttHepatitis": "i.Hepatitis, AIDS or any sexually transmitted disease",
    "MrttMusculoskeletalDisorders": "j. Musculoskeletal system disorders – e.g. arthritis, connective tissue disorders, spinal disorders, motor neurone diseases, muscular dystrophy",
    "MrttHospitalClinic": "2) Have you been referred to a specialist at any hospital or clinic within the last 5 years?",
    "MrttPhysicalDefect": "3) Have you ever suffered from any physical defect, illness or disorder not mentioned above?"
  
  },
  "si": {
    "BodilyDeformities": "a. Have you had any bodily deformities ?",
    "MedicalTreatmentOperation": "b. Have you currently or at any time suffered from any illnesses or had any medical treatment or operation ?",
    "Hospitalized": "c. Have you ever undergone surgery or are you awaiting surgery or been hospitalized in the last 2 years ?",
    "HeartAttack": "d. Have you ever had a heart attack, chest pain, a stroke, hypertension, hyperlipideamia, diabates or any disorder of the heart blood vessels ?",
    "Diabetes": "e. Are you on treatment for Diabates ?",
    "TumorCancer": "f. Have you had any form or type of tumor or cancer ?",
    "Arthritis": "g. Have you ever had any disorder or disease of the muscles, bones, joints, limbs or spine(including arthritis, rheumatism etc) ?",
    "NeurologicalDisorder": "h. Have you had any anxiety state, depression or any mental, nervous or neurological disorder ?",
    "Tuberculosis": "i. Have you ever suffered from respiratory or lung trouble(Eg. asthma, bronchitis, persitent cough, tuberculosis) ?",
    "StomachDisorder": "j. Have you ever suffered from any disorder of the stomach, intenstine, digestive system, gallbladder or liver (Eg. Actual or suspected gastritis or duodenal ulcer, bleeding from the bowel, recurrent indigestion, hapatitis, gallstones, hiatus hernia) ?",
    "KidneyDisorder": "k. Have you ever suffered from any disease, disorder or infection of the kidneys, bladder or reproductive organs (Eg. Albumin, in urine, stones, prostatitis, venereal disease) ?",
    "DiseaseThroat": "l. Have you ever suffered from diseases of the eyes, ears, nose, mouth and throat ?",
    "AIDS": "m. Have you ever recieved (or expected to recieve) any medical advice, blood test or other tests in connection with AIDS, AIDS related condition ?",
    "BloodDisorder": "n. Have you ever suffered from any disorders of the blood (Eg. hemophilia, anemia) ?",
    "SexualDisease": "o. Have you ever suffered from hepatitis or any sexually transmitted diseases ?",
    "AnyOtherIllness": "p. Have you ever suffered from any illness or disorder which is not mentioned above ?",
    "OperatedHospital": "q. Have you ever been examined, treated, or operated in a hospital, nursing home, clinic, Ayurvedic centre or sanatorium ?",
    "NervousSystem": "r. Have you ever suffered from paralysis, coma, fits, or any other diseases or disorders of the nervous system ?",
    "SkinJointsDisorder": "s. Have you ever suffered from disease or disorders of the skin, muscles, bones or joints ?",
    "FamilyIllness": "t. Has anyone in the family had any disease or illness in the past three months ? If so, Please give details",
    
    "Diagnosis": "Diagnosis?",
    "Treatment": "Treatment?",
    "NameAddDoctor": "Name And Add of Doctor?",

    "Insured": "රක්ෂිතයා",
    "Spouse": "කලත්‍රයා"
  },
  "ta": {
    "BodilyDeformities": "a. Have you had any bodily deformities ?",
    "MedicalTreatmentOperation": "b. Have you currently or at any time suffered from any illnesses or had any medical treatment or operation ?",
    "Hospitalized": "c. Have you ever undergone surgery or are you awaiting surgery or been hospitalized in the last 2 years ?",
    "HeartAttack": "d. Have you ever had a heart attack, chest pain, a stroke, hypertension, hyperlipideamia, diabates or any disorder of the heart blood vessels ?",
    "Diabetes": "e. Are you on treatment for Diabates ?",
    "TumorCancer": "f. Have you had any form or type of tumor or cancer ?",
    "Arthritis": "g. Have you ever had any disorder or disease of the muscles, bones, joints, limbs or spine(including arthritis, rheumatism etc) ?",
    "NeurologicalDisorder": "h. Have you had any anxiety state, depression or any mental, nervous or neurological disorder ?",
    "Tuberculosis": "i. Have you ever suffered from respiratory or lung trouble(Eg. asthma, bronchitis, persitent cough, tuberculosis) ?",
    "StomachDisorder": "j. Have you ever suffered from any disorder of the stomach, intenstine, digestive system, gallbladder or liver (Eg. Actual or suspected gastritis or duodenal ulcer, bleeding from the bowel, recurrent indigestion, hapatitis, gallstones, hiatus hernia) ?",
    "KidneyDisorder": "k. Have you ever suffered from any disease, disorder or infection of the kidneys, bladder or reproductive organs (Eg. Albumin, in urine, stones, prostatitis, venereal disease) ?",
    "DiseaseThroat": "l. Have you ever suffered from diseases of the eyes, ears, nose, mouth and throat ?",
    "AIDS": "m. Have you ever recieved (or expected to recieve) any medical advice, blood test or other tests in connection with AIDS, AIDS related condition ?",
    "BloodDisorder": "n. Have you ever suffered from any disorders of the blood (Eg. hemophilia, anemia) ?",
    "SexualDisease": "o. Have you ever suffered from hepatitis or any sexually transmitted diseases ?",
    "AnyOtherIllness": "p. Have you ever suffered from any illness or disorder which is not mentioned above ?",
    "OperatedHospital": "q. Have you ever been examined, treated, or operated in a hospital, nursing home, clinic, Ayurvedic centre or sanatorium ?",
    "NervousSystem": "r. Have you ever suffered from paralysis, coma, fits, or any other diseases or disorders of the nervous system ?",
    "SkinJointsDisorder": "s. Have you ever suffered from disease or disorders of the skin, muscles, bones or joints ?",
    "FamilyIllness": "t. Has anyone in the family had any disease or illness in the past three months ? If so, Please give details",

    "Diagnosis": "Diagnosis?",
    "Treatment": "Treatment?",
    "NameAddDoctor": "Name And Add of Doctor?",

    "Insured": "வாழ்க்கை",
    "Spouse": "வாழ்க்கைத்துணை"
  }
}
</i18n>
