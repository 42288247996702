<!-- create/modify customer quotations -->
<template>
  <div>
    <Header />
    <LeftNav />
    <main id="page-wrap">
      <vue-snotify></vue-snotify>
      <b-container fluid>
        <CreateRoleComponent />
      </b-container>
    </main>
    <Footer />
  </div>
</template>

<script>
import CreateRoleComponent from "@/components/role_management/role_form.vue";
import Header from "@/components/common/layout/AppHeader.vue";
import Footer from "@/components/common/layout/AppFooter.vue";
import LeftNav from "@/components/common/layout/AppLeftNav";
export default {
  name: "CreateRoleView",
  components: {
    CreateRoleComponent,
    Header,
    Footer,
    LeftNav
  },
};
</script>

<style scoped>
div.card .card-header {
  background: #ff660d !important; /* fallback for old browsers */
  box-shadow: 0 10px 10px -12px rgba(0, 0, 0, 0.42),
    0 4px 10px 0px rgba(0, 0, 0, 0.12), 0 5px 7px -5px rgba(0, 0, 0, 0.2);
}
</style>
