<template>
  <div class="row-top-list2 table-row-top">
    <vue-snotify></vue-snotify>
    <div class="card">
      <div class="card-header">
        <font-awesome-icon icon="align-left" />&nbsp;
        <span>{{ $t("allocation_list") }} </span>
      </div>
      <div class="card-body">
        <div class="row tbl-margin-top">
          <div class="col table-responsive">
            <vuetable
              class="table table-striped table-bordered nowrap vue-tbl"
              ref="usertable"
              :api-url="getUrl"
              :fields="fields"
              pagination-path
              @vuetable:pagination-data="onPaginationData"
              :per-page="10"
              :row-class="onRowClass"
              :http-options="httpOptions"
              :append-params="moreParams"
            >
              <template slot="actions" slot-scope="props">
                <!-- ACTION SLOT -->
                <div
                  class="custom-actions"
                  style="white-space: nowrap; text-align: center"
                >
                  <b-dropdown
                    class="ui btn-sm basic button"
                    variant="info"
                    right
                    text="Actions"
                  >
                    <b-dropdown-item
                      @click="
                        onActionClicked('revert', props.rowData, props.rowIndex)
                      "
                      :disabled="(props.rowData.proposal && props.rowData.proposal.proposal_status != 8) || props.rowData.status != 1"
                    >
                      Revert
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <!-- ACTION SLOT -- END  -->
              </template>
            </vuetable>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="pagination">
              <vuetable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              ></vuetable-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//console.log(process.env.VUE_APP_API_ENDPOINT);
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
//import { ValidationProvider } from "vee-validate";
//import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

import VuetablePagination from "@/components/vuetable/VuetablePaginationBootstrap4";
// import EditUser from "@/components/user_management/edit_user.vue"

import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    Vuetable,
    VuetablePagination,
  },
  data() {
    return {
      rejectConfirmModelShow: false,
      closeConfirmModelShow: false,
      cretateQuotationModelShow: false,
      quote_type: 1,
      user_id: "",
      fields: [
        {
          name: "id",
          title: '<span class="orange glyphicon glyphicon-user"></span> #',
          sortField: "id",
        },
        {
          name: "proposal_id",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Proposal Id',
          sortField: "proposal_id",
        },
        {
          name: "proposal.prop_no",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Proposal Number'
        },
        {
          name: "user.full_name",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Ho User ',
          sortField: "user_id",
        },
        {
          name: "user.agent_code",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> User Code '
        },
        {
          name: "processed_at",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Processed Date ',
          sortField: "processed_at",
          width: "20%",
        },

        {
          name: "status",
          sortField: "status",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Allocation Status',
          width: "5%",
          callback: "getStatus",
          html: true,
        },
        {
          name: "proposal.proposal_status",
          sortField: "proposal.porposal_status",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Proposal Status',
          width: "5%",
          callback: "getProposalStatus",
          html: true,
        },
        {
          name: "user.branch.branch_name",
          title: '<span class="orange glyphicon glyphicon-user"></span> Branch',
        },
        "__slot:actions",
      ],
      httpOptions: {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: {},
      },
      moreParams: {},
      getUrl:
        process.env.VUE_APP_API_ENDPOINT +
        "queue_users/ho_user_allocation_list",
      activeHoUsers: [],
    };
  },
  computed: {
    ...mapState("user", [""]),
    ...mapGetters("login", ["checkUserPermissionNew"]),
  },
  mounted() {
    this.$events.$on("filter-set", (eventData) => this.onFilterSet(eventData));
    this.$events.$on("filter-reset", this.onFilterReset());
  },
  methods: {
    ...mapActions("user", [
      "getUserBranchList",
      "getUserRoleList",
      "clearUserSession",
      "revertUserAllocation",
    ]),

    ...mapActions("login", ["loginAsUser"]),
    getRoles(roles) {
      let str = "";
      roles.forEach(function (role, index) {
        str += "<span class='badge badge-dark'>" + role.name + "</span>";
        if (index != roles.length - 1) {
          str += ",";
        }
      });
      return str;
    },
    getStatus(status) {
      if (status == 1) {
        return "<span class='badge badge-success'> Allocated </span>";
      } else if (status == 2) {
        return "<span class='badge badge-danger'> Reverted </span>";
      } else {
        ("<span class='badge badge-warning'> N/A </span>");
      }
    },
    getProposalStatus(status) {
      if (status == 1) {
        return "<span class='badge badge-info'> Pending Personal Info </span>";
      } else if (status == 2) {
        return "<span class='badge badge-info'> Pending Health Info </span>";
      } else if (status == 3) {
        return "<span class='badge badge-info'> Pending Documents </span>";
      } else if (status == 4) {
        return "<span class='badge badge-info'> Pending Policy Holder Sig </span>";
      } else if (status == 5) {
        return "<span class='badge badge-info'> Pending Spouse Sig </span>";
      } else if (status == 6) {
        return "<span class='badge badge-info'> Pending Agent Sig </span>";
      } else if (status == 7) {
        return "<span class='badge badge-info'> Pending Supervisor </span>";
      } else if (status == 8) {
        return "<span class='badge badge-info'> Pending Ho </span>";
      } else if (status == 9) {
        return "<span class='badge badge-info'> Returned to Agent </span>";
      } else if (status == 10) {
        return "<span class='badge badge-info'> Complete </span>";
      } else {
        ("<span class='badge badge-info'> N/A </span>");
      }
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.usertable.changePage(page);
    },
    onRowClass(dataItem) {
      if (dataItem.status == "Expired") {
        return dataItem.status
          ? "expired-field-background"
          : "background-field-white";
      }
    },
    onActionClicked(action, data) {
      if (action == "revert") {
        this.revertAllocation(data.id);
      }
    },

    revertAllocation(id) {
      let data = {
        id: id,
      };

      this.revertUserAllocation(data).then((response) => {
        if (response.data.meta.status) {
          this.$snotify.success(response.data.meta.status_message, "", {
            position: "centerTop",
          });
          Vue.nextTick(() => this.$refs.usertable.refresh());
        }
      });

      
    },

    onFilterSet(filters) {
      this.moreParams = {
        active_user: filters.active_user,
        status: filters.status,
        proposal_status: filters.proposal_status,
      };
      Vue.nextTick(() => this.$refs.usertable.refresh());
    },
    onFilterReset() {
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.usertable.refresh());
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  background-color: #0000001a !important;
}

.modal-content {
  border: 0px solid !important;
}

.tbl-margin-top {
  margin-top: 15px;
}

table th,
.table td {
  padding: 0.2rem 0.75rem !important;
  vertical-align: middle !important;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}

.pagination {
  float: right;
}

.btn {
  margin: 5px;
}

.page-link {
  font-size: 12px !important;
}

.tooltip {
  top: 0 !important;
}

.background-field-white {
  background-color: white;
}

.expired-field-background {
  background-color: #fc8d89 !important;
}
</style>

<i18n>
{
  "en": {
    "allocation_list": "Allocation List"
  },
  "si": {
  "allocation_list": "Allocation List"
  },
  "ta": {
     "allocation_list": "Allocation List"
  }
}
</i18n>
