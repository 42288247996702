<!-- create/modify customer quotations -->
<template>
  <div>
    <Header />
    <SumView v-if="calcSummary.summary_formatted != undefined" />
    <LeftNav />
    <main id="page-wrap">
      <vue-snotify></vue-snotify>
      <ValidationObserver ref="form">
        <form v-on:submit.prevent="onSubmit">
          <b-container fluid>
            <div class="row row-top">
              <div class="col-sm-3 top-margin">
                <ValidationObserver ref="main">
                  <div class="form-group">
                    <label for="lbl_main_title">Type</label>
                    <ValidationProvider name="Type" rules="required">
                      <div slot-scope="{ errors }">
                        <select
                          class="form-control"
                          v-model="additonalInfoData.mainType"
                          name="Title"
                          @change="sendChangedData()"
                        >
                          <option value="">Please select</option>
                          <option value="1">INDIVIDUAL</option>
                          <option value="2">JOINT</option>
                        </select>
                        <p class="p-error">{{ errors[0] }}</p>
                      </div>
                    </ValidationProvider>
                  </div>
                </ValidationObserver>
              </div>
            </div>
            <MainLifeInfoWidget />
            <SpouseLifeInfoWidget v-if="additonalInfoData.mainType == 2" />
            <LifeCoveredWidget />
            <CoverDetailsWidget />
            <QuoteGenButton ref="GenerateButton" />
          </b-container>
        </form>
      </ValidationObserver>
    </main>
    <Footer />
  </div>
</template>

<script>
import MainLifeInfoWidget from "@/components/quote_mrtt/MainLifeInfoWidget.vue";
import SpouseLifeInfoWidget from "@/components/quote_mrtt/SpouseLifeInfoWidget.vue";
import LifeCoveredWidget from "@/components/quote_mrtt/LifeCoveredWidget.vue";
import CoverDetailsWidget from "@/components/quote_mrtt/CoverDetailsWidget.vue";
import Header from "@/components/common/layout/AppHeader.vue";
import Footer from "@/components/common/layout/AppFooter.vue";
import SumView from "@/components/common/layout/AppSummaryViewMrtt.vue";
import LeftNav from "@/components/common/layout/AppLeftNav";
import QuoteGenButton from "@/components/quote_mrtt/QuoteGenButton.vue";
import { mapActions, mapState } from "vuex";
import { SnotifyPosition } from "vue-snotify";
import { throttle } from "lodash";

export default {
  name: "NewQuote",
  data() {
    return {
      mainType: 1,
    };
  },
  components: {
    MainLifeInfoWidget,
    SpouseLifeInfoWidget,
    LifeCoveredWidget,
    Header,
    Footer,
    SumView,
    LeftNav,
    QuoteGenButton,
    CoverDetailsWidget
  },
  computed: {
    ...mapState("quote_mrtt", [
      "mainInfoData",
      "additonalInfoData",
      "calcSummary",
      "flash",
      "minflash",
    ]),
    ...mapState("common_actions", ["shakingIconAction"])
  },
  methods: {
    ...mapActions("quote_mrtt", ["addChangedData"]),
    sendChangedData: throttle(async function () { 
      this.$refs.main.validate().then((valid) => {
        if (valid) {
          // do stuff if valid.
          this.$snotify.clear();
          /** Loading (HTML snotify component) */
          this.$snotify.html(
            `<div class="snotifyToast__title"><b>Calculating...</b></div>
            <div class="snotifyToast__body"> Mode Premium Amount</div>
            <div class="snotify-icon snotify-icon--async"></div> `,
            {
              timeout: stop,
              showProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              position: SnotifyPosition.centerTop,
              type: "async",
            }
          );
          return this.getChangeDataResponse()
            .then(
              function (response) {
                this.$snotify.clear();
                if (response.status && response.type == undefined) {
                  /** Success Message  */
                  this.$snotify.success("Mode Premium Amount", response.error, {
                    timeout: stop,
                    closeOnClick: true,
                    pauseOnHover: false,
                    position: SnotifyPosition.centerTop,
                  });
                } else {
                  this.$snotify.clear();
                  /** Error Message  */
                  this.$snotify.error(response.error, "Error", {
                    timeout: stop,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    position: SnotifyPosition.centerTop,
                  });
                }
              }.bind(this)
            )
            .catch(function (error) {
              this.$snotify.clear();
              /** Exception Message */
              this.$snotify.error(error, "Error", {
                timeout: stop,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop,
              });
            });
        }
      });
    }, process.env.VUE_APP_QUOT_TIMEOUT_SEND_CHANGED_DATA),
    async getChangeDataResponse() {
      await this.addChangedData();
      if (this.minflash.status) {
        return this.minflash;
      } else {
        return this.flash;
      }
    },
    async onSubmit() {
      this.$refs.GenerateButton.disableButton();
      this.$refs.form
        .validate()
        .then((result) => {
          if (!result) {
            this.$refs.GenerateButton.enableButton();
            this.$snotify.error("There are items that require your attention", {
              timeout: 3000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              position: SnotifyPosition.centerTop,
            });

            return;
          } else {
            if (this.$route.params.ref_no) {
              this.addChangedData("update", this.$route.params.ref_no);
              if (this.flash.status && this.flash.type == "S") {
                this.$snotify
                  .success("Quotation Successfully Updated", {
                    timeout: 3000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    position: SnotifyPosition.centerTop,
                  })
                  .on("destroyed", () =>
                    this.$router.push({
                      name: "quote_list",
                    })
                  );
                //this.$router.push("/quote_list");
              }
            } else {
              (async () => {
                await this.addChangedData("create");
                if (this.flash.status && this.flash.type == "S") {
                  this.$snotify
                    .success("Quotation Successfully Created", {
                      timeout: 3000,
                      showProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: false,
                      position: SnotifyPosition.centerTop,
                    })
                    .on("destroyed", () =>
                      this.$router.push({
                        name: "quote_list",
                      })
                    );
                  //this.$router.push("/quote_list");
                } else {
                  this.$snotify.error(this.flash.error, {
                    timeout: 3000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    position: SnotifyPosition.centerTop,
                  });
                  this.$refs.GenerateButton.enableButton();
                }
              })();
            }
          }
        })
        .catch(() => {});
    },
  },
  mounted(){
    this.shakingIconAction.product = 'mrtt';
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .top-margin {
    margin-top: 60px;
  }
}
div.card .card-header {
  background: #ff660d !important; /* fallback for old browsers */
  box-shadow: 0 10px 10px -12px rgba(0, 0, 0, 0.42),
    0 4px 10px 0px rgba(0, 0, 0, 0.12), 0 5px 7px -5px rgba(0, 0, 0, 0.2);
}
</style>
