  <template>
  <div class="row-top-list2 table-row-top">
    <div class="card">
      <div class="card-header">
        <font-awesome-icon icon="align-left" />&nbsp;
        <span>{{ $t("list_branch") }}</span>

        <b-button
          style="margin:5px padding: .25rem .65rem;float:right;"
          variant="success"
          class="ui btn-sm basic button"
          title="Add New Branch"
          v-b-tooltip.hover.bottom="'Add New Branch'"
          @click="onActionClicked('add-item')"
        >
          <font-awesome-icon icon="plus" />&nbsp;Add New Branch
        </b-button>
      </div>
      <div class="card-body">
        <div class="row tbl-margin-top">
          <div class="col table-responsive">
            <vuetable
              class="table table-striped table-bordered nowrap vue-tbl"
              ref="branchTable"
              :api-url="getUrl"
              :fields="fields"
              pagination-path
              @vuetable:pagination-data="onPaginationData"
              :per-page="3"
              :row-class="onRowClass"
              :http-options="httpOptions"
              :append-params="moreParams"
            >
              <template slot="actions" slot-scope="props">
                <!-- ACTION SLOT -->
                <div
                  class="custom-actions"
                  style="white-space: nowrap; text-align: center"
                >
                  <b-button
                    style="margin:5px padding: .25rem .65rem;;"
                    variant="info"
                    class="ui btn-sm basic button"
                    title="Edit"
                    v-b-tooltip.hover.bottom="'Edit'"
                    @click="
                      onActionClicked(
                        'edit-item',
                        props.rowData,
                        props.rowIndex
                      )
                    "
                    v-if="
                      hasPermission('branch-mgt') &&
                      checkEditable(props.rowData['leadstatus']) == true
                    "
                  >
                    <font-awesome-icon icon="pen" />
                  </b-button>
                </div>
                <!-- ACTION SLOT -- END  -->
              </template>
            </vuetable>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="pagination">
              <vuetable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              ></vuetable-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
import commonMixin from "@/mixins/common";
import VuetablePagination from "@/components/vuetable/VuetablePaginationBootstrap4";

import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    Vuetable,
    VuetablePagination,
  
  },
  mixins:[commonMixin],
  data() {
    return {
      rejectConfirmModelShow: false,
      closeConfirmModelShow: false,
      cretateQuotationModelShow: false,
      quote_type: 1,
      fields: [
        {
          name: "id",
          title: '<span class="orange glyphicon glyphicon-user"></span> #',
          sortField: "id",
        },
        {
          name: "branch_name",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Branch Name',
          sortField: "branch_name",
        },

        {
          name: "branch_code",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Branch Code',
          sortField: "branch_code",
        },

        {
          name: "active",
          title: '<span class="orange glyphicon glyphicon-user"></span> Status',
          sortField: "active",
        },

        "__slot:actions",
      ],
      httpOptions: {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: {},
      },
      moreParams: {},
      getUrl: process.env.VUE_APP_API_ENDPOINT + "branch/list",
    };
  },
  computed: {
    ...mapState("branch", [""]),
    ...mapGetters("login", ["checkUserPermissionNew"]),
  },
  mounted() {
    this.$events.$on("filter-set", (eventData) => this.onFilterSet(eventData));
    this.$events.$on("filter-reset", this.onFilterReset());
  },
 
  methods: {
    ...mapActions("branch", ["getCSVExport"]),

    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.branchTable.changePage(page);
    },
    onRowClass(dataItem) {
    

      if (dataItem.status == "Expired") {
        return dataItem.status
          ? "expired-field-background"
          : "background-field-white";
      }
    },
    onActionClicked(action, data) {
      console.log(action);

      if (action == "edit-item") {
        let str_edit_route = "branches_edit";

        this.$router.push({
          name: str_edit_route,
          params: {
            ref_no: data.id,
          },
        });
      } else if (action == "view-item") {
        let str_edit_route = "appointment_list";

        this.$router.push({
          name: str_edit_route,
          params: {
            ref_no: data.id,
          },
        });
      } else if (action == "add-item") {
        let str_edit_route = "branches_add";

        this.$router.push({
          name: str_edit_route,
          params: {},
        });
      } else if (action == "export-csv") {
        this.csvExport();
      }
    },
    onFilterSet(filters) {

      this.moreParams = {
        branch_name: filters.branch_name,
        branch_code: filters.branch_code,
        status: filters.status
      };
      Vue.nextTick(() => this.$refs.branchTable.refresh());
    
    },
    onFilterReset() {
   
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.branchTable.refresh());
     
    },
    viewPermissionCheck() {
      return this.checkUserPermissionNew("view-quote");
    },
    editPermissionCheck() {
      return this.checkUserPermissionNew("edit-quote");
    },
    exLoadingPermissionCheck() {
      return this.checkUserPermissionNew("ex-loading");
    },

    checkEditable(status) {
      if (status == "CLOSED" && status == "REJECTED") {
        return false;
      } else {
        return true;
      }
    },

    remarksFormatter(value) {
      if (value) {
        return value.length >= 90 ? value.substring(0, 90) + "...." : value;
      } else {
        return " ";
      }
    },
  },
};
</script>

<style scoped>

.tbl-margin-top {
  margin-top: 15px;
}

table th,
.table td {
  padding: 0.2rem 0.75rem !important;
  vertical-align: middle !important;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}

.pagination {
  float: right;
}

.btn {
  margin: 5px;
}
.page-link {
  font-size: 12px !important;
}
.tooltip {
  top: 0 !important;
}

.background-field-white {
  background-color: white;
}
.expired-field-background {
  background-color: #fc8d89 !important;
}
</style>

<i18n>
{
  "en": {
    "list_branch": "Branches List"

  },
  "si": {
  "list_branch": "කැඳවුම් ලැයිස්තුව"
  
  },
  "ta": {
     "list_branch": "Branches List"
   
  }
}
</i18n>