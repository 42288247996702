<template>
  <div id="list_quote">
    <Header />
    <LeftNav />
    <main id="page-wrap">
      <b-container fluid>
        <div class="calulator_margin">
        <div class="row row-top">
          <div class="col-6 col-md-6 col-sm-12 col-xs-12">
            <PensionCalcForm />
          </div>
          <div class="col-6 top-margin col-md-6 col-sm-12 col-xs-12">
            <PensionCapitalGrowthChart/>
            <b-tabs content-class="mt-12" class="tab_size" align="right">
              <b-tab title="MONTHLY PENSION"><p>
                <PensionInfoTable />
              </p></b-tab>
              <b-tab title="CAPITAL GROWTH">
                <PensionCapitalGrowthTable />
              </b-tab>
            </b-tabs>
          </div>
        </div>
        </div>
      </b-container>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/layout/AppHeader.vue";
import Footer from "@/components/common/layout/AppFooter.vue";
import LeftNav from "@/components/common/layout/AppLeftNav";
import PensionCalcForm from "@/components/quote_surakshitha/calculator/PensionCalcFormWidget.vue";
import PensionInfoTable from "@/components/quote_surakshitha/calculator/PensionInfoTable.vue";
import PensionCapitalGrowthTable from "@/components/quote_surakshitha/calculator/PensionCapitalGrowthTable.vue";
import PensionCapitalGrowthChart from "@/components/quote_surakshitha/calculator/PensionCapitalGrowthChart.vue";

export default {
  name: "pension_calculator",
  components: {
    Header,
    Footer,
    LeftNav,
    PensionCalcForm,
    PensionInfoTable,
    PensionCapitalGrowthTable,
    PensionCapitalGrowthChart

  }
};
</script>

<style scoped>
.row-top-list {
  margin-top: 40px;
}

@media (max-width: 768px) {
  
.col-6{
  display:contents;
}

.calulator_margin{
  padding-top: 25px;
}

.tab_size{
  width: 100%;
}

.row-top{
  padding-left: 20px;
  padding-right: 20px;
}

}

</style>

