import { http } from "@/config/http";
import { throttle } from "lodash";

const namespaced = true;

const getDefaultState = () => {
    return {
        QuoteRefNo: "",
        ProposalID: "",
        ProductType:"",
        /** Information and Benefit data */
        ChildInfoData: [],
        BenefitData: [],
        InfoInsured: [
            {
                fund_type : ""
            }
        ],
        InfoSpouse: [
            
        ],
        BenefitsInsured: [],
        BenefitsSpouse: [],
        BenefitsChild: [],

        /** Question data */
        DynamicQuestions: [],
        HabitsQuestions: [],
        PresentStateHealthQuestions: [],
        ChildHealthQuestions: [],
        DoctorConsultationInfo: [],
        NomineeInfo: [],
        FemaleOnlyQuestion: [],
        FamilyInfoInsSp: [],
        PrevPolicyInfo: [],
        PrevPolicyInfoSp: [],

        FundTypeInfo: [],

       /*  Mrtt Cover CoverDetails */
        MrttCoverData: [],

        /** Proposal status start */
        ProposalStatus: {},
        isBenefitComplete: "N",
        isInfoComplete: "N",
        isHealthComplete: "N",
        isLinkSentToCustomer: "N",
        isCustomerCompleted: "N",
        isAgentSigned: "N",
        isFullCompleted: "N",

        /* Upload documents */
        UploadTypes: [],
        UploadedDocs: [],
        isLoading: false,
        isValidationError: false,
        activeStep: 0,
        transferStatus: null,
        pStatus: null,
        religionsDrpOpt: [
            {
                code: null,
                label: "Please select",
            }, {
                code: "MUSLIM",
                label: "Muslim",
            },
            {
                code: "BUDDHIST",
                label: "Buddhist",
            },
            {
                code: "CHRISTIAN",
                label: "Christian",
            },
            {
                code: "HINDU",
                label: "Hindu",
            },
            {
                code: "OTHER",
                label: "Other",
            }
        ],
        nationalityDrpOpt: [
            {
                code: null,
                label: "Please select",
            }, {
                code: "SRILANKAN",
                label: "Srilankan",
            },
            {
                code: "NON SRILANKAN",
                label: "Non Srilankan",
            }
        ],
        contributionPaidByDrpOpt: [
            {
                code: null,
                label: "Please select",
            }, {
                code: "SELF",
                label: "Self",
            },
            {
                code: "SPOUSE",
                label: "Spouse",
            },
            {
                code: "EMPLOYER",
                label: "Employer",
            },
            {
                code: "PARENT",
                label: "PARENT",
            },
            {
                code: "OTHER",
                label: "Other",
            }
        ],
        contributionPaidByDrpOptWithoutSpouse:[
            {
                code: null,
                label: "Please select",
            }, {
                code: "SELF",
                label: "Self",
            },
            {
                code: "SPOUSE",
                label: "Spouse",
            },
            {
                code: "EMPLOYER",
                label: "Employer",
            },
            {
                code: "PARENT",
                label: "PARENT",
            },
            {
                code: "OTHER",
                label: "Other",
            }
        ],
        nomineeDrpOpt: [
            {
                code: null,
                label: "Please select",
            }, {
                code: "SPOUSE",
                label: "Spouse",
            },
            {
                code: "DAUGHTER",
                label: "Daughter",
            },
            {
                code: "SON",
                label: "Son",
            },
            {
                code: "BROTHER",
                label: "Brother",
            },
            {
                code: "SISTER",
                label: "Sister",
            },
            {
                code: "FATHER",
                label: "Father",
            },
            {
                code: "MOTHER",
                label: "Mother",
            },
            {
                code: "OTHER",
                label: "Other",
            }
        ],
        FamilyMemberInfo: [],
        FamilyMemberInfoSp: [],
        defaultRelationship: [
            {
                relationship:'FATHER'
            },
            {
                relationship:'MOTHER'
            }
        ]
    };
};

const state = getDefaultState();

const getters = {};

const mutations = {
    /** clear state to initial state */
    clearObj(state) {
        Object.assign(state, getDefaultState());
    },
    /** Info - Add Nominee */
    addNomineeObj(state) {
        state.NomineeInfo.push({ nominee_name: "", guardian_name: "" });
    },
    /** Info - Remove Nominee */
    removeNomineeObj(state, index) {
        state.NomineeInfo.splice(index, 1);
    },
    /** Info - Add FamilyMemberInfo */
    addFamilyMemberInfoObj(state , obj) {
        if(obj.type == 'main'){
            state.FamilyMemberInfo.push({});
        }else if(obj.type == 'spouse'){
            state.FamilyMemberInfoSp.push({});
        }else{
            //
        }
    },
    /** Info - Remove FamilyMemberInfo */
    removeFamilyMemberInfoObj(state, obj) {
        if(obj.type == 'main'){
            state.FamilyMemberInfo.splice(obj.index, 1);
        }else if(obj.type == 'spouse'){
            state.FamilyMemberInfoSp.splice(obj.index, 1);
        }else{
            //
        }
    },
    /** Info - Remove Nominee */
    removeInsPrevPolicyObj(state, index) {
        state.PrevPolicyInfo.splice(index, 1);
    },
    /** Info - Remove Nominee */
    removeInsPrevPolicyObjSp(state, index) {
        state.PrevPolicyInfoSp.splice(index, 1);
    },
    setAgeObj(state, { data, type, index }) {
        if (type == "nominee") {
            // Set nominee age
            state.NomineeInfo[index].nominee_age = data.age;
        }
    },
    setBenefitData(state, data) {
        /** Quotation Reference Number */
        state.QuoteRefNo = data.prop_insured_spouse_info.quote_ref;
        
        /** Product type */
        state.ProductType = data.prop_insured_spouse_info.product;

        /** Proposal Unique ID */
        state.ProposalID = data.prop_insured_spouse_info.proposal_id;

        /* Active step */
        state.activeStep = data.prop_insured_spouse_info.active_step;

        /* Transfer status */
        state.transferStatus = data.prop_insured_spouse_info.transfer_status;

        /* Proposal status */
        state.pStatus = data.prop_insured_spouse_info.proposal_status;

        /** Insured Data */
        state.InfoInsured = data.prop_insured_spouse_info;

        /** Spouse Data */
        state.InfoSpouse = data.prop_insured_spouse_info;

        /** Children Data */
        state.ChildInfoData = data.prop_child_info;

        /** Common Benefit Data */
        state.BenefitData = data.prop_insured_spouse_info;

        /** Insured Benefit Data */
        state.BenefitsInsured = data.prop_insured_benefit_info;

        /** Spouse Benefit Data */
        state.BenefitsSpouse = data.prop_spouse_benefit_info;

        /** Child Benefit Data */
        state.BenefitsChild = data.prop_child_benefit_info;

        /** Dynamic Question Data */
        state.DynamicQuestions = data.prop_dynamic_question_info;

        /** Habits Question Data */
        state.HabitsQuestions = data.prop_habits_quest_info;

        /** Present State of Health (Good Health) Question Data */
        state.PresentStateHealthQuestions = data.prop_good_health_quest_info;

        /** Child Health Question Data */
        state.ChildHealthQuestions = data.prop_child_health_quest_info;

        /** Info - Nominee Question Data */
        state.NomineeInfo = data.nominee_info;

        /** Health - Female Only Question Data */
        state.FemaleOnlyQuestion = data.prop_female_only_quest_info;

        /** Health - Family Health Information Question Data */
        state.FamilyInfoInsSp = data.prop_family_info_ins_sp_data;

        /* New Family Dev */
        /** Family Info Data */
        state.FamilyMemberInfo = data.prop_family_info_data.length ? data.prop_family_info_data : [
            {
                relationship:'FATHER'
            },
            {
                relationship:'MOTHER'
            }
        ];

        /** Family Info Data Sp */
        state.FamilyMemberInfoSp = data.prop_family_info_data_sp.length ? data.prop_family_info_data_sp : [
            {
                relationship:'FATHER'
            },
            {
                relationship:'MOTHER'
            }
        ];

        /** Final - Previous Policy Information Question Data */
        state.PrevPolicyInfo = data.prop_prev_life_policy_data;

        /** Final - Previous Policy Information Question Data Sp */
        state.PrevPolicyInfoSp = data.prop_prev_life_policy_data_sp;

        /** Proposal Status Data */
        state.ProposalStatus = data.prop_status_data;
        state.isBenefitComplete = state.ProposalStatus.prop_master.benefit;
        state.isInfoComplete = state.ProposalStatus.prop_master.info;
        state.isHealthComplete = state.ProposalStatus.prop_master.health;

        state.isLinkSentToCustomer = state.ProposalStatus.prop_master.agree;
        state.isCustomerCompleted = state.ProposalStatus.prop_master.sig_customer;
        state.isAgentSigned = state.ProposalStatus.prop_master.sig_agent;
        state.isFullCompleted = state.ProposalStatus.prop_master.final;
    },
    setUploadTypesObj(state, data) {
        state.UploadTypes = data;
    },
    /** Set Info Wizard Status */
    setProposalStatusObj(state, prop_data) {
        switch (prop_data.prop_status) {
            case "info":
                state.ProposalStatus.prop_master.info = prop_data.proposal_info_status;
                state.isInfoComplete = prop_data.proposal_info_status;
                break;
            case "health":
                state.ProposalStatus.prop_master.health =
                    prop_data.proposal_info_status;
                state.isHealthComplete = prop_data.proposal_info_status;
                break;
            case "agree":
                state.ProposalStatus.prop_master.agree = prop_data.proposal_info_status;
                state.isLinkSentToCustomer = prop_data.proposal_info_status;
                break;
            case "sig_customer":
                state.ProposalStatus.prop_master.sig_customer =
                    prop_data.proposal_info_status;
                state.isCustomerCompleted = prop_data.proposal_info_status;
                break;
            case "sig_agent":
                state.ProposalStatus.prop_master.sig_agent =
                    prop_data.proposal_info_status;
                state.isAgentSigned = prop_data.proposal_info_status;
                break;
            case "final":
                state.ProposalStatus.prop_master.final = prop_data.proposal_info_status;
                state.isFullCompleted = prop_data.proposal_info_status;
                break;
        }
    },
    setUploadedDocs(state, data) {
        state.UploadedDocs = data;
    },

    /** Set visibility of preloader */
    setIsLoading(state, data) {
        state.isLoading = data;
    },
    setIsValidationError(state, data) {
        state.isValidationError = data;
    },
};

const actions = {
    /** Clear state data to initial state */
    async clearStateData({ commit }) {
        commit("clearObj");
    },
    /** Info - Add Nominee */
    async addNominee({ commit }, index) {
        commit("addNomineeObj", index);
    },
    /** Info - Remove Nominee */
    async removeNominee({ commit }, index) {
        commit("removeNomineeObj", index);
    },
    /** Info - Add FamilyMemberInfo */
    async addFamilyMemberInfo({ commit }, obj) {
        commit("addFamilyMemberInfoObj", obj);
    },
    /** Info - Remove FamilyMemberInfo */
    async removeFamilyMemberInfo({ commit }, obj) {
        commit("removeFamilyMemberInfoObj", obj);
    },
    /** Info - Remove Prev Policy */
    async removeInsPrevPolicy({ commit }, index) {
        commit("removeInsPrevPolicyObj", index);
    },
    /** Info - Remove Prev Policy */
    async removeInsPrevPolicySp({ commit }, index) {
        commit("removeInsPrevPolicyObjSp", index);
    },
    /** Retrieve benefit information by quotation reference number */
    async getBenefitData({ commit }, refNo) {
        await
            new Promise((resolve, reject) => {
                http
                    .post("proposal/get_benefits", { ref_no: refNo }, {})
                    .then((response) => {
                        if (response.data.meta.status == "true") {
                            commit("setBenefitData", response.data.data);
                            resolve(response.data.meta.status);
                        } else {
                            commit(
                                "showNotification", {
                                title: "Error",
                                msg: response.data.meta.status_message,
                                type: "error",
                            }, { root: true }
                            );
                        }
                        resolve(response.data.meta.status);
                    })
                    .catch((error) => {
                        commit(
                            "showNotification", {
                            title: "Error",
                            msg: error,
                            type: "error",
                        }, { root: true }
                        );
                        reject(error);
                    });
            });
    },


    //Draft Proposal Data
    draftProposalData: throttle(async function ({ commit, state }) {
        return await new Promise((resolve, reject) => {
            setTimeout(() => {
                // async draftProposalData({ commit, state }) {
                /** personal data */
                let insured_info = {};
                let spouse_info = {};
                let child_info = {};
                let nominee_info = {};
                /** question data */
                let dynamic_quest_data = {};
                let habit_quest_data = {};
                let state_health_quest_data = {};
                let child_health_quest_data = {};
                let doctor_consult_quest_data = {};
                let female_only_quest_data = {};
                let family_info_ins_sp_data = {};
                let family_info_data = [];
                let family_info_sp_data = [];
                let prev_policy_info_data = {};
                let prev_policy_info_data_sp = {};
                /** proposal status data */
                let proposal_status_data = {};

                let data = {
                    quote_ref_no: state.QuoteRefNo,
                    proposal_id: state.ProposalID,
                };
                /** personal data */
                insured_info = state.InfoInsured;
                spouse_info = state.InfoSpouse;
                child_info = state.ChildInfoData;
                nominee_info = state.NomineeInfo;
                /** question data */
                dynamic_quest_data = state.DynamicQuestions;
                habit_quest_data = state.HabitsQuestions;
                state_health_quest_data = state.PresentStateHealthQuestions;
                child_health_quest_data = state.ChildHealthQuestions;
                doctor_consult_quest_data = state.DoctorConsultationInfo;
                female_only_quest_data = state.FemaleOnlyQuestion;
                family_info_ins_sp_data = state.FamilyInfoInsSp;
                family_info_data = state.FamilyMemberInfo;
                family_info_sp_data = state.FamilyMemberInfoSp;
                prev_policy_info_data = state.PrevPolicyInfo;
                prev_policy_info_data_sp = state.PrevPolicyInfoSp;
                /** proposal status data */
                proposal_status_data = state.ProposalStatus;

                data = {
                    ...data,
                    /** personal data */
                    insured_info,
                    spouse_info,
                    child_info,
                    nominee_info,
                    /** question data */
                    dynamic_quest_data,
                    habit_quest_data,
                    state_health_quest_data,
                    child_health_quest_data,
                    doctor_consult_quest_data,
                    female_only_quest_data,
                    family_info_ins_sp_data,
                    family_info_data,
                    family_info_sp_data,
                    prev_policy_info_data,
                    prev_policy_info_data_sp,
                    /** proposal status data */
                    proposal_status_data,
                };

                http
                    .post("proposal/draft", { data }, {})
                    .then((response) => {
                        if (response.data.meta.status == "true") {
                            commit(
                                "showNotification", {
                                title: "Draft",
                                msg: response.data.meta.status_message,
                                type: "success",
                            }, { root: true }
                            );
                            resolve(response.data.meta.status);
                        } else {
                            commit("setIsLoading", false);
                            commit(
                                "showNotification", {
                                title: "Draft",
                                msg: response.data.meta.status_message,
                                type: "error",
                            }, { root: true }
                            );
                            reject(response.data.meta.status);
                        }
                    })
                    .catch((error) => {
                        commit("setIsLoading", false);
                        commit('setIsValidationError', true);
                        commit(
                            "showNotification", {
                            title: "Error",
                            msg: error,
                            type: "error",
                        }, { root: true }
                        );
                        reject(error);
                    });

            }, 1000);
        });
    }, process.env.VUE_APP_TIMEOUT_SEND_CHANGED_DATA),

    /** Proposal Status update */
    async setProposalStatus({ commit }, payload) {
        let proposal_info_status = payload.prop_status_data;
        let prop_status = payload.prop_status;

        commit("setProposalStatusObj", { prop_status, proposal_info_status });
    },

    async saveProposalPDF({ commit }, payload) {
        let ref_no = payload.q_ref_no;
        await http
            .post("proposal/save", { ref_no: ref_no }, {})
            .then((response) => {
                if (response.data.meta.status == "true") {
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: response.data.meta.status_message,
                        type: "success",
                    }, { root: true }
                    );
                } else {
                    let error = "Error : Proposal PDF Save Service Response Failed";
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: error,
                        type: "error",
                    }, { root: true }
                    );
                }
            })
            .catch((error) => {
                commit(
                    "showNotification", {
                    title: "Error",
                    msg: error,
                    type: "error",
                }, { root: true }
                );
            });
    },
    /** Calculate age by dob */
    async getAge({ commit }, payload) {
        let dob = payload.dob;
        //** Age Calculation axios call  */
        await http
            .post("quote/get_age", { dob: dob }, {})
            .then((response) => {
                if (response.data.meta.status == "true") {
                    let data = response.data.data;
                    let type = payload.type;

                    //this is to be used to identify which nominee
                    let index = 0;
                    if (payload.hasOwnProperty("index")) {
                        index = payload.index;
                    }
                    commit("setAgeObj", { data, type, index });
                } else {
                    let error = "Error : Age Calculator Service Response Failed";
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: error,
                        type: "error",
                    }, { root: true }
                    );
                }
            })
            .catch((error) => {
                commit(
                    "showNotification", {
                    title: "Error",
                    msg: error,
                    type: "error",
                }, { root: true }
                );
            });
    },
    /** Get document upload types */
    async getUploadTypes({ commit } , payload) {
        await http
            .post("conf/upload_types", payload)
            .then((response) => {
                if (response.data.meta.status == "true") {
                    commit("setUploadTypesObj", response.data.data);
                } else {
                    let error = "Error : Get document types Failed";
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: error,
                        type: "error",
                    }, { root: true }
                    );
                }
            })
            .catch((error) => {
                commit(
                    "showNotification", {
                    title: "Error",
                    msg: error,
                    type: "error",
                }, { root: true }
                );
            });
    },
    /** Upload documents to the server */
    async UploadFile({ commit }, payload) {

        return new Promise((resolve, reject) => {
            http
            .post("doc/upload_doc", payload)
            .then((response) => {
                if (response.data.meta.status == "true") {
                    commit(
                        "showNotification", {
                        title: "Success",
                        msg: response.data.meta.status_message,
                        type: "success",
                    }, { root: true }
                    );
                    commit("setUploadedDocs", response.data.data.files);
                } else {
                    let error = "Error : File Upload Failed";
                    commit(
                        "showNotification", {
                        title: "Error",
                        msg: error,
                        type: "error",
                    }, { root: true }
                    );
                }
                resolve(response);
            })
            .catch((error) => {
                commit(
                    "showNotification", {
                    title: "Error",
                    msg: error,
                    type: "error",
                }, { root: true }
                );
                reject(error);
            });
        });


        
    },
    /** retrieve uploaded documents */
    async loadUploadedFiles({ commit }, payload) {
        await http
            .post("doc/get_upload_doc", payload)
            .then((response) => {
                if (response.data.meta.status == "true") {
                    console.log(response.data.data.files);
                    commit("setUploadedDocs", response.data.data.files);
                } else {
                    let error = "Error : Load uploaded files failed";
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: error,
                        type: "error",
                    }, { root: true }
                    );
                }
            })
            .catch((error) => {
                commit(
                    "showNotification", {
                    title: "Error",
                    msg: error,
                    type: "error",
                }, { root: true }
                );
            });
    },
    async setProposalNumber({ commit }, propo) {
        let data = {
            proposal_id: propo.proposal_id,
            proposal_no: propo.proposal_no,
        };

        return new Promise((resolve, reject) => {
            http.post("proposal/add_number", data, {}).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
                commit(
                    "showNotification", {
                    title: "Draft",
                    msg: "Propsal Transfer Error",
                    type: "error",
                }, { root: true }
                );
            });
        });
    },

    /** Transfer Proposal To Customer */
    async transferProposal({ commit }, payload) {
        return new Promise((resolve, reject) => {
            http
                .post("proposal/transfer_proposal_to_customer", payload, {})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Propsal Transfer Error",
                        type: "error",
                    }, { root: true }
                    );
                });
        });
    },
    /** Send Customer Signature Request Lnk */
    async sendCustomerSignatureRequestLink({ commit }, payload) {
        return new Promise((resolve, reject) => {
            http
                .post("proposal/send_customer_signature_request_link", payload, {})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Propsal Transfer Error",
                        type: "error",
                    }, { root: true }
                    );
                });
        });
    },
    /** update proposal status new */
    async updateProposalStatus({ commit }, payload) {
        return new Promise((resolve, reject) => {
            http
                .post("proposal/update_proposal_status", payload, {})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Propsal Transfer Error",
                        type: "error",
                    }, { root: true }
                    );
                });
        });
    },
    async updateCustomerSignature({ commit }, payload) {
        return new Promise((resolve, reject) => {
            http
                .post("proposal/update_customer_signature", payload, {})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Error Occured",
                        type: "error",
                    }, { root: true }
                    );
                });
        });
    },
    async updateSpouseSignature({ commit }, payload) {
        return new Promise((resolve, reject) => {
            http
                .post("proposal/update_spouse_signature", payload, {})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Error Occured",
                        type: "error",
                    }, { root: true }
                    );
                });
        });
    },
    async updateAgentSignature({ commit }, payload) {
        return new Promise((resolve, reject) => {
            http
                .post("proposal/update_agent_signature", payload, {})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Error Occured",
                        type: "error",
                    }, { root: true }
                    );
                });
        });
    },
    async forceProposalUpdate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            http
                .post("proposal/force_proposal_update", payload, {})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Error Occured",
                        type: "error",
                    }, { root: true }
                    );
                });
        });
    },
    async submitDocumentComments({ commit }, payload) {
        return new Promise((resolve, reject) => {
            http
                .post("proposal/submit_document_comments", payload, {})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Error Occured",
                        type: "error",
                    }, { root: true }
                    );
                });
        });
    },
    async getDocumentCommentHistory({ commit }, payload) {
        return new Promise((resolve, reject) => {
            http
                .post("proposal/get_document_comment_history", payload, {})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Error Occured",
                        type: "error",
                    }, { root: true }
                    );
                });
        });
    },
    /** Load Proposal Data View */
    async loadProposalDataView({ commit }, payload) {
        return new Promise((resolve, reject) => {
            http
                .post("proposal/load_proposal_data_view", payload, {})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Error Occured",
                        type: "error",
                    }, { root: true }
                    );
                });
        });
    },
    /** Load Proposal Data View */
    async loadProposalDataViewByHashId({ commit }, payload) {
        return new Promise((resolve, reject) => {
            http
                .post("proposal/load_proposal_data_view_by_hash_id", payload, {})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Error Occured",
                        type: "error",
                    }, { root: true }
                    );
                });
        });
    },
    /** Delete Agent Document */
    async deleteAgentDocument({ commit }, payload) {
        return new Promise((resolve, reject) => {
            http
                .post("doc/delete_agent_document", payload, {})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    commit(
                        "showNotification", {
                        title: "Draft",
                        msg: "Error Occured",
                        type: "error",
                    }, { root: true }
                    );
                });
        });
    },
    
    /** set preloader status */
    preLoader({ commit }, flag) {
        commit("setIsLoading", flag);
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};