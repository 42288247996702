<!-- Application Header Component -->
<template>
  <div>
    <div>
      <b-navbar
        class="nav-bar-color nav-new-2"
        toggleable="lg"
        type="dark"
        variant="info"
      >
        <b-navbar-brand href="#">
          <img src="@/assets/img/logo.png" class="header-img" alt="Logo" />
        </b-navbar-brand>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav></b-navbar-nav>
        </b-collapse>
        <div>
          <div>
            <b-button-group class="btn-group-right lang-2-nav" size="sm">
              <b-button
                id="engButton"
                :class="{ active: 'en' == activeStatus }"
                variant="outline-info"
                @click="setLocale('en')"
                >English</b-button
              >
              <b-button
                id="sinButton"
                :class="{ active: 'si' == activeStatus }"
                variant="outline-info"
                @click="setLocale('si')"
                >සිංහල</b-button
              >
              <b-button
                id="tamilButton"
                :class="{ active: 'ta' == activeStatus }"
                variant="outline-info"
                @click="setLocale('ta')"
                >தமிழ்</b-button
              >
            </b-button-group>
          </div>
        </div>

        <div>
          <b-dropdown
            :text="userName"
            class="btn user"
            size="sm"
            variant="outline-light"
          >
            <!-- Using 'button-content' slot -->
            <!-- <b-dropdown-item>{{userName }}</b-dropdown-item> -->
            <!-- <QuotationRightBar v-if="calcSummary.summary != undefined"/> -->
            <b-dropdown-item class="lang-1-nav">
              <div>
                <b-button-group class="btn-group-right" size="sm">
                  <b-button
                    :class="{ active: 'en' == activeStatus }"
                    variant="outline-primary"
                    @click="setLocale('en')"
                    >English</b-button
                  >
                  <b-button
                    :class="{ active: 'si' == activeStatus }"
                    variant="outline-primary"
                    @click="setLocale('si')"
                    >සිංහල</b-button
                  >
                  <b-button
                    :class="{ active: 'ta' == activeStatus }"
                    variant="outline-primary"
                    @click="setLocale('ta')"
                    >தமிழ்</b-button
                  >
                </b-button-group>
              </div>
            </b-dropdown-item>
            <b-dropdown-item>
              <b-button class="btn btn-sm btn-block" variant="danger">
                <router-link to="/logout" style="color: #fff !important">
                  <font-awesome-icon icon="power-off" />
                  &nbsp;{{ $t("signout") }}
                </router-link>
              </b-button>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <!-- <b-button id="kkk" @click="shakeIcon">Spin Icon</b-button> -->
        <span @click="openModalCalc" v-if="showCalculator() && shakingIconAction.shake" :class="shakingIconAction.shake ? 'shake calIcon-section' : 'calIcon-section'"  >
          <b-icon-calculator
            class="h2 mt-2 cal-button"
            @animationend="stopShake"
           
          >
          </b-icon-calculator>
        </span>
        <span @click="openModalCalc" v-if="showCalculator() && !shakingIconAction.shake" class="calIcon-section"  >
          <b-icon-calculator
            class="h2 mt-2 cal-button"
            @click="openModalCalc"
            @animationend="stopShake"
          ></b-icon-calculator>
        </span>
      </b-navbar>
    </div>
    <div>
      <b-navbar
        class="nav-new nav-bar-color2 navbar-2-height navbar2"
        type="dark"
      ></b-navbar>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Header",
  components: {},
  data() {
    return {
      userName: "",
      langs: ["en", "si", "ta"],
      activeStatus: "",
      shaking: false,
    };
  },
  computed: {
    // ...mapState("quote_platinum", ["calcSummary"]),
    // ...mapState("quote_adyapana_plus", ["calcSummary"]),
    // ...mapState("quote_deergayu", ["calcSummary"]),
    // ...mapState("quote_flexi_life", ["calcSummary"]),
    // ...mapState("quote_health_plus", ["calcSummary"]),
    // ...mapState("mrtt", ["calcSummary"]),
    // ...mapState("quote_platinum_plus", ["calcSummary"]),
    // ...mapState("quote_surakshitha", ["calcSummary"]),
    // ...mapState("quote_flexi_life", ["calcSummary"]),
    ...mapState("common_actions", ["shakingIconAction"]),

    ...mapState("quote_platinum", {
      calcSummaryPlatinum: (state) => state.calcSummary,
    }),
    ...mapState("quote_adyapana_plus", {
      calcSummaryAdyapanaPlus: (state) => state.calcSummary,
    }),
    ...mapState("quote_deergayu", {
      calcSummaryDeergayu: (state) => state.calcSummary,
    }),
    ...mapState("quote_flexi_life", {
      calcSummaryFlexiLife: (state) => state.calcSummary,
    }),
    ...mapState("quote_health_plus", {
      calcSummaryHealthPlus: (state) => state.calcSummary,
    }),
    ...mapState("quote_mrtt", {
      calcSummaryMrtt: (state) => state.calcSummary,
    }),
    ...mapState("quote_platinum_plus", {
      calcSummaryPlatinumPlus: (state) => state.calcSummary,
    }),
    ...mapState("quote_surakshitha", {
      calcSummarySurakshitha: (state) => state.calcSummary,
    }),
    ...mapState("quote_term_plus_cic", {
      calcSummaryTermPlus: (state) => state.calcSummary,
    }),
  },
  methods: {
    showCalculator() {
      let vShow = false;
      if (
        this.shakingIconAction.product == "adyapana_plus" &&
        this.calcSummaryAdyapanaPlus.summary_formatted != undefined
      ) {
        vShow = true;
      } else if (
        this.shakingIconAction.product == "deergayu" &&
        this.calcSummaryDeergayu.summary_formatted != undefined
      ) {
        vShow = true;
      } else if (
        this.shakingIconAction.product == "flexi_life" &&
        this.calcSummaryFlexiLife.summary_formatted != undefined
      ) {
        vShow = true;
      } else if (
        this.shakingIconAction.product == "health_plus" &&
        this.calcSummaryHealthPlus.summary_formatted != undefined
      ) {
        vShow = true;
      } else if (
        this.shakingIconAction.product == "mrtt" &&
        this.calcSummaryMrtt.summary_formatted != undefined
      ) {
        vShow = true;
      } else if (
        this.shakingIconAction.product == "platinum" &&
        this.calcSummaryPlatinum.summary_formatted != undefined
      ) {
        vShow = true;
      } else if (
        this.shakingIconAction.product == "platinum_plus" &&
        this.calcSummaryPlatinumPlus.summary_formatted != undefined
      ) {
        vShow = true;
      } else if (
        this.shakingIconAction.product == "surakshitha" &&
        this.calcSummarySurakshitha.summary_formatted != undefined
      ) {
        vShow = true;
      } else if (
        this.shakingIconAction.product == "term_plus" &&
        this.calcSummaryTermPlus.summary_formatted != undefined
      ) {
        vShow = true;
      }
      return vShow;
    },
    openModalCalc(e) {
      
      e.preventDefault();
      e.stopPropagation();
      if (this.shakingIconAction.product == "adyapana_plus") {
        this.shakingIconAction.adyapanaPlusOpen =
          !this.shakingIconAction.adyapanaPlusOpen;
      } else if (this.shakingIconAction.product == "deergayu") {
        this.shakingIconAction.deergayuOpen =
          !this.shakingIconAction.deergayuOpen;
      } else if (this.shakingIconAction.product == "flexi_life") {
        this.shakingIconAction.flexiLifeOpen =
          !this.shakingIconAction.flexiLifeOpen;
      } else if (this.shakingIconAction.product == "health_plus") {
        this.shakingIconAction.healthPlusOpen =
          !this.shakingIconAction.healthPlusOpen;
      } else if (this.shakingIconAction.product == "mrtt") {
        this.shakingIconAction.mrttOpen = !this.shakingIconAction.mrttOpen;
      } else if (this.shakingIconAction.product == "platinum") {
        this.shakingIconAction.platinumOpen =
          !this.shakingIconAction.platinumOpen;
      } else if (this.shakingIconAction.product == "platinum_plus") {
        this.shakingIconAction.platinumPlusOpen =
          !this.shakingIconAction.platinumPlusOpen;
      } else if (this.shakingIconAction.product == "surakshitha") {
        this.shakingIconAction.surakshithaOpen =
          !this.shakingIconAction.surakshithaOpen;
      } else if (this.shakingIconAction.product == "term_plus") {
        this.shakingIconAction.termPlusOpen =
          !this.shakingIconAction.termPlusOpen;
      }
    },
    setLocale(lng) {
      this.$root.$i18n.locale = lng;
      this.activeStatus = lng;
      localStorage.setItem("lang", lng);
    },
    shakeIcon() {
      this.shakingIconAction.shake = true;
      // Start the shaking animation for 2 seconds
      setTimeout(this.stopShake, 1000);
    },
    stopShake() {
      this.shakingIconAction.shake = false;
    },
  },
  mounted() {
    if (localStorage.username) {
      this.userName = localStorage.getItem("username");
    }
    this.activeStatus = localStorage.getItem("lang");
  },
  watch: {
    "shakingIconAction.shake": {
      handler: function () {
        if (this.shakingIconAction.shake) {
          this.shakeIcon();
        }
      },
      immediate: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  height: 50px !important;
}

.header-img {
  width: 230px;
  margin: -39px -11px -32px 39px;
  height: 64px;
}

.custom-select[data-v-db41c070] {
  border: 0px solid !important;
}

.nav-bar-color {
  background: linear-gradient(to right, #556065, #646b6f, #909193);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.nav-bar-color2 {
  background: #92d127;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

// .btn-outline-info {
//     color: #92D127;
//     border-color: #92D127;
// }
.btn-outline-info {
  color: #fff;
  border-color: #fff;
}

.btn-outline-info:hover {
  background-color: #92d127;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #92d127;
  border-color: #fff !important;
  box-shadow: none !important;
}

.language-color {
  background-color: #92d127 !important;
}

.h-font {
  font-size: 14px !important;
  color: rgba(255, 255, 255, 1) !important;
}

.h-font-size {
  font-size: 14px !important;
}

.custom-select {
  background-color: #fff0 !important;
  border: 0px solid !important;
  color: #ffffff !important;
}

.img-w {
  width: 248px;
}

.custom-select[data-v-5e0b32e6] {
  border: 0px solid !important;
}

.navbar-2-height {
  height: 55px;
}

.nav-new {
  z-index: 10000;
  position: fixed;
  width: 100%;
  top: 60px;
}

.nav-new-2 {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

div.sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
}

.sign-out {
  margin-left: 48px;
}

.user-name {
  margin-right: 35px;
}

.navbar-brand {
  margin-left: -55px !important;
}

.btn-simple {
  border-radius: 10rem !important;
}

.btn-group-right {
  margin-right: 5px;
}

.user {
  margin-right: 28px;
}

@media (max-width: 768px) {
  .navbar-2-height {
    margin-top: 0px;
  }

  .lang-2-nav {
    visibility: hidden;
    display: none;
  }

  .user {
    margin-right: 0px;
    padding-top: 20px;
    padding-left: 35px;
  }

  .header-img {
    width: 215px;
    margin: -35px -11px -28px 32px;
    height: 64px;
  }
}

@media (min-width: 1024px) {
  .lang-1-nav {
    visibility: hidden;
    display: none;
  }
}
.shake {
  animation: shake 0.5s ease infinite;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-5px);
  }
  40% {
    transform: translateX(5px);
  }
  60% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
#engButton, #sinButton, #tamilButton:hover {
    border-color: #fff !important;
}
</style>

<i18n>
{
  "en": {
    "admin": "Admin",
    "quotation" : "Create Quotation",
    "quotation_list" : "List Quotation",
    "signout" : "Sign Out"
  },
  "si": {
    "admin": "පරිපාලක",
    "quotation" : "මිල කියවුම්",
    "quotation_list" : "මිල කැඳවුම් ලැයිස්තුව",
    "signout" : "ඉවත් වන්න"
    },
    "ta": {
    "admin": "පරිපාලක",
    "quotation" : "விலைக்கோரலின்",
    "quotation_list" : "விலைக்கோரலின் பட்டியல்",
    "signout" : "வெளியேறு"
  }
}
</i18n>
