<!-- Quotation Main Life Information Capture Widget  -->
<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header header-font">
          <font-awesome-icon icon="heartbeat" />
          <span>&nbsp;{{ $t("mainLifeHeaderMsg") }}</span>
          <span class="fa-pull-right" v-if="quotationNo != ''">
            {{ "Quote No: " + quotationNo + " V-" + version }}
          </span>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_title">{{ $t("main_title") }}</label>
                <ValidationProvider
                  name="Title"
                  rules="required|genderValidation:@InGender"
                >
                  <div slot-scope="{ errors }">
                    <select
                      class="form-control"
                      v-model="mainInfoData.title"
                      name="Title"
                    >
                      <option value="">Please select</option>
                      <option value="Mr.">Mr.</option>
                      <option value="Mrs.">Mrs.</option>
                      <option value="Miss.">Miss.</option>
                      <option value="Ms.">Ms.</option>
                      <option value="Ven.">Ven.</option>
                      <option value="Dr.">Dr.</option>
                      <option value="Rev.">Rev.</option>
                    </select>
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_first_name">
                  {{ $t("main_fName") }}
                </label>
                <ValidationProvider
                  name="First Name"
                  rules="required|minmax:3,60|alpha_spaces"
                >
                  <div slot-scope="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="txtMainLifeFname"
                      name="FirstName"
                      placeholder="First Name"
                      v-model="mainInfoData.fName"
                      v-uppercase
                    />
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_mid_name">
                  {{ $t("main_mName") }}
                </label>
                <ValidationProvider
                  name="Middle Name"
                  rules="minmax:3,60|alpha_spaces"
                >
                  <div slot-scope="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="txtMainLifeMname"
                      name="MiddleName"
                      placeholder="Middle Name"
                      v-model="mainInfoData.mName"
                      v-uppercase
                    />
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_last_name">
                  {{ $t("main_lName") }}
                </label>
                <ValidationProvider
                  name="Last Name"
                  rules="required|minmax:3,60|alpha_spaces"
                >
                  <div slot-scope="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="txtMainLifeLname"
                      name="LastName"
                      placeholder="Last Name"
                      v-model="mainInfoData.lName"
                      v-uppercase
                    />
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_nic">{{ $t("main_nic") }}</label>
                <ValidationProvider
                  name="NIC Number"
                  :rules="{
                    required: true,
                    regex: /^(\d{9}?[V|X]{1}|\d{12})$/i,
                  }"
                >
                  <div slot-scope="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="txtMainLifeNIC"
                      name="NIC"
                      placeholder="NIC Number"
                      v-model.trim="mainInfoData.nic"
                      v-uppercase
                    />
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_mobile">
                  {{ $t("main_mobile") }}
                </label>
                <ValidationProvider
                  name="Mobile No"
                  :rules="{ required: true, regex: /^(\+[1-9]{1}[0-9]{3,14})?([0-9]{7,14})$/ }"
                >
                  <div slot-scope="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="txtMainLifeMob"
                      name="MobileNumber"
                      placeholder="Mobile Number"
                      v-model="mainInfoData.mob"
                    />
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_email">{{ $t("main_email") }}</label>
                <ValidationProvider name="E-mail" rules="email">
                  <div slot-scope="{ errors }">
                    <input
                      type="Email"
                      class="form-control"
                      id="txtMainLifeEmail"
                      placeholder="Email"
                      name="Email"
                      v-model.trim="mainInfoData.email"
                      v-lowercase
                    />
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_gender">
                  {{ $t("main_gender") }}
                </label>
                <ValidationProvider name="InGender" rules="required">
                  <div slot-scope="{ errors }">
                    <div class="toggleswitchg">
                      <input
                        id="INSMale"
                        checked="checked"
                        name="INSGender"
                        ref="rdbMainGender"
                        type="radio"
                        value="M"
                        v-model="mainInfoData.gender"
                        @change="sendChangedData()"
                      />
                      <label for="INSMale">
                        <i class="fa fa-mars"></i> Male
                      </label>
                      <input
                        id="INSFemale"
                        name="INSGender"
                        ref="rdbMainGender"
                        type="radio"
                        value="F"
                        v-model="mainInfoData.gender"
                        @change="sendChangedData()"
                      />
                      <label for="INSFemale">
                        <i class="fa fa-venus"></i> Female
                      </label>
                    </div>
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <ValidationObserver ref="insured">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_dob">{{ $t("main_dob") }}</label>
                  <ValidationProvider name="Date of Birth" rules="required">
                    <div slot-scope="{ errors }">
                      <input
                        @blur="
                          ageCalc();
                          sendChangedData();
                        "
                        @change="
                          ageCalc();
                          sendChangedData();
                        "
                        class="form-control"
                        id="txtMainLifeDOB"
                        name="DateOfBirth"
                        placeholder="Date of Birth"
                        type="Date"
                        v-model="mainInfoData.dob"
                        :max="timestamp"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_age">{{ $t("main_age") }}</label>
                  <ValidationProvider name="Age" rules="required">
                    <div slot-scope="{ errors }">
                      <input
                        @change="sendChangedData()"
                        type="text"
                        class="form-control"
                        id="txtMainLifeAge"
                        name="txtMainLifeAge"
                        readonly
                        v-model="mainInfoData.age"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lblMaritalStatus">{{ $t('familyInfoLblMaritalStatus') }}</label>
                  <select
                    class="form-control"
                    v-model="mainInfoData.maritalStatus"
                    @change="sendChangedData()"
                    data-vv-as="Marital Status"
                  >
                    <option disabled value>Please select</option>
                    <option>Married</option>
                    <option>Unmarried</option>
                    <option>Divorced</option>
                  </select>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SnotifyPosition } from "vue-snotify";
import { throttle } from "lodash";

export default {
  data() {
    return {
      form: {
        sumCover: 0,
      },
    };
  },
  async mounted() {
    if (this.$route.params.ref_no) {
      //await this.getOccupationList();
      //await this.loadQuotation();
      await this.ageCalc();
    } else {
      await this.clearData();
      this.ageCalc();
    }
  },

  computed: {
    ...mapState("quote_mrtt", [
      "mainInfoData",
      "additonalInfoData",
      "flash",
      "minflash",
      "quotationNo",
      "version",
      "timestamp",
    ]),
  },
  methods: {
    ...mapActions("quote_mrtt", [
      "addChangedData",
      "getOneQuotation",
      "clearStateData",
      "getAge",
    ]),

    sendChangedData: throttle(async function () {
      // alert(this.mainInfoData.txtMainLifeDOB);
      this.$refs.insured.validate().then((valid) => {
        if (valid) {
          this.$snotify.clear();

          /** Loading (HTML snotify component) */
          this.$snotify.html(
            `<div class="snotifyToast__title"><b>Calculating...</b></div>
            <div class="snotifyToast__body"> Mode Premium Amount</div>
            <div class="snotify-icon snotify-icon--async"></div> `,
            {
              timeout: stop,
              showProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              position: SnotifyPosition.centerTop,
              type: "async",
            }
          );
          return this.getChangeDataResponse()
            .then(
              function (response) {
                this.$snotify.clear();
                if (response.status && response.type == undefined) {
                  /** Success Message  */
                  this.$snotify.success("Mode Premium Amount", response.error, {
                    timeout: stop,
                    closeOnClick: true,
                    pauseOnHover: false,
                    position: SnotifyPosition.centerTop,
                  });
                } else {
                  this.$snotify.clear();
                  /** Error Message  */
                  this.$snotify.error(response.error, "Error", {
                    timeout: stop,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    position: SnotifyPosition.centerTop,
                  });
                }
              }.bind(this)
            )
            .catch(function (error) {
              this.$snotify.clear();
              /** Exception Message */
              this.$snotify.error(error, "Error", {
                timeout: stop,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop,
              });
            });
        }
      });
    }, process.env.VUE_APP_QUOT_TIMEOUT_SEND_CHANGED_DATA),
    async ageCalc() {
      let payload = {
        dob: this.mainInfoData.dob,
        type: "main",
      };
      await this.getAge(payload);
    },
    async loadQuotation() {
      await this.getOneQuotation(this.$route.params.ref_no);
    },
    async clearData() {
      await this.clearStateData();
    },
    async getChangeDataResponse() {
      await this.addChangedData();
      if (this.minflash.status) {
        return this.minflash;
      } else {
        return this.flash;
      }
    },
  },
};
</script>
<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #556065 !important;
  border-bottom: 0px solid #06a990 !important;
  color: #fff !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #06a990 !important;
  border-radius: 0.25rem;
}

.snotify {
  display: block;
  position: fixed;
  width: 99% !important;
  z-index: 9999;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: none;
}
/*-- toggle switch for Main Life Frequency ----*/

.togglefreq label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.togglefreq input:checked,
.togglefreq input:not(:checked) {
  display: none;
}

.togglefreq input:checked + label {
  background: #92d127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92d127;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked + label {
  background: #92d127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92d127;
}

@media (max-width: 768px) {
  .top-margin {
    margin-top: 60px;
  }
}
</style>

<!-- Language Data for MainLifeInfoWidget-->
<i18n>
{
  "en": {
    "mainLifeHeaderMsg": "Main Life Personal Information",
    "main_title": "Title",
    "main_fName": "First Name",
    "main_mName": "Middle Name",
    "main_lName": "Last Name",
    "main_nic": "NIC No",
    "main_mobile": "Mobile No", 
    "main_email": "Email", 
    "main_gender": "Gender",
    "main_dob": "Date of Birth (MM/DD/YYYY)",
    "main_age": "Age",
    "familyInfoLblMaritalStatus": "Marital Status" 
  },
  "si": {
    "mainLifeHeaderMsg": "ජීවිත රක්ෂිතයාගේ පෞද්ගලික තොරතුරු",
    "main_title": "ශීර්ෂය",
    "main_fName": "මුල් නම",
    "main_mName": "මැද නම",
    "main_lName": "අවසන් නම",
    "main_nic": "ජා.හැ.අංකය",
    "main_mobile": "ජංගම දුරකථන අංකය", 
    "main_email": "විද්‍යුත් තැපෑල", 
    "main_gender": "ස්ත්‍රී පුරුෂ භාවය",
    "main_dob": "උපන් දිනය (MM/DD/YYYY)",
    "main_age": "වයස",
    "familyInfoLblMaritalStatus": "විවාහක අවිවාහක බව"
  },
  "ta": {
    "mainLifeHeaderMsg": "பிரதான காப்புறுதிதாரரின் தனிப்பட்ட விபரங்கள்",
    "main_title": "படிநிலை",
    "main_fName": "முதற் பெயர்",
    "main_mName": "நடுத்தர பெயர்",
    "main_lName": "கடைசி பெயர்",
    "main_nic": "தே. அ. அ. இலக்கம்",
    "main_mobile": "தொலைபேசி இலக்கம்", 
    "main_email": "மின்னஞ்சல்", 
    "main_gender": "பால்",
    "main_dob": "பிறந்த திகதி (MM/DD/YYYY)",
    "main_age": "வயது",
    "familyInfoLblMaritalStatus": "விவாக நிலை"
  }
}
</i18n>
