<!-- Insured Child Details Widget-->
<template>
  <div class="card">
    <div class="card-header">
      <i class="ti ti-user"></i>
      <span
        >&nbsp;&nbsp;&nbsp;{{ $t("PersonalDetailsWidgetTitle") }} -
        {{ $t("tabChild") }} ({{ index + 1 }})</span
      >
    </div>
    <div class="card-body box-body">
      <div class="col-sm-12 top-margin">
        <!-- Child details row 1 Title, First Name, Mid Name, Last Name, DOB, Age -->
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_child_title">{{ $t("langChildTitle") }} <span class="required-mark">*</span></label>
              <select
                class="form-control"
                :name="'ChildTitle' + index"
                v-model="ChildInfoData[index].ch_title"
                disabled
              >
                <option value="">Select</option>
                <option value="Master">Master</option>
                <option value="Miss.">Miss.</option>
              </select>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_child_fname">{{ $t("langChildFName") }} <span class="required-mark">*</span></label>
              <ValidationProvider
                :name="'Child '+ (index+1) + ' First Name'"
                rules="required|minmax:3,60|alpha_spaces"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    :id="'ChildFirstName' + index"
                    :name="'ChildFirstName' + index"
                    v-model.trim="ChildInfoData[index].ch_first_name"
                    placeholder="First Name"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_child_mname">{{ $t("langChildMName") }}</label>
              <ValidationProvider
                :name="'Child ' + (index+1) + ' Middle Name'"
                rules="minmax:3,60|alpha_spaces"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    :id="'ChildMiddleName' + index"
                    :name="'ChildMiddleName' + index"
                    v-model.trim="ChildInfoData[index].ch_middle_name"
                    placeholder="Middle Name"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_child_lname">{{ $t("langChildLName") }} <span class="required-mark">*</span></label>
              <ValidationProvider
                :name="'Child ' + (index+1) + ' Last Name'"
                rules="required|minmax:3,60|alpha_spaces"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    :id="'ChildLastName' + index"
                    :name="'ChildLastName' + index"
                    v-model.trim="ChildInfoData[index].ch_last_name"
                    placeholder="Last Name"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <!-- Child details row 1 End -->

        <div class="row">
          <div class="col-sm-2">
            <div class="form-group">
              <label for="lbl_child_dob">{{ $t("langChildDOB") }} <span class="required-mark">*</span></label>
              <input
                class="form-control"
                data-vv-as="Date of birth"
                :id="'ChildDateOfBirth' + index"
                :name="'ChildDateOfBirth' + index"
                v-model="ChildInfoData[index].ch_dob"
                placeholder="Date of Birth"
                type="Date"
                readonly
              />
            </div>
          </div>
          <div class="col-sm-1">
            <div class="form-group">
              <label for="lbl_child_age">{{ $t("langChildAge") }} <span class="required-mark">*</span></label>
              <input
                type="text"
                class="form-control"
                :id="'ChildAge' + index"
                :name="'ChildAge' + index"
                v-model="ChildInfoData[index].ch_age"
                readonly
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_child_gender">{{ $t("langChildGender") }} <span class="required-mark">*</span></label>
              <div class="toggleswitchg">
                <input
                  :id="'child_male' + index"
                  checked="checked"
                  :name="'chkChildGender' + index"
                  v-model="ChildInfoData[index].ch_gender"
                  type="radio"
                  value="Male"
                  disabled
                />
                <label :for="'child_male' + index">
                  <i class="fa fa-mars"></i> Male
                </label>
                <input
                  :id="'child_female' + index"
                  :name="'chkChildGender' + index"
                  v-model="ChildInfoData[index].ch_gender"
                  type="radio"
                  value="Female"
                  disabled
                />
                <label :for="'child_female' + index">
                  <i class="fa fa-venus"></i> Female
                </label>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_child_birth_place">{{
                $t("langChildPlaceBirth")
              }}</label>
              <ValidationProvider
                :name="'Child ' + (index+1) + ' Place of Birth'"
                rules="minmax:3,20|alpha_spaces"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    :id="'ChildBirthplace' + index"
                    :name="'ChildBirthplace' + index"
                    v-model.trim="ChildInfoData[index].ch_birth_place"
                    placeholder="Birth Place"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_child_relationship">{{
                $t("langChildRelationship")
              }} </label>
              <ValidationProvider
                :name="'Child ' + (index+1) + ' Relationship'"
                rules="minmax:3,20|alpha_spaces"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    :id="'ChildRelationship' + index"
                    :name="'ChildRelationship' + index"
                    v-model.trim="ChildInfoData[index].ch_relationship"
                    placeholder="Relationship"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <!-- Child details row 2 End -->

        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_child_height">{{ $t("langChildHeight") }} <span v-if="ProductType == 'Health Plus' || ProductType == 'Platinum Plus'" class="required-mark">*</span></label>
              <ValidationProvider
                :name="'Child ' + (index+1) + ' Height'"
                :rules="`${(ProductType == 'Health Plus' || ProductType == 'Platinum Plus') ? 'required|numeric|minmax_value:20,180' : 'numeric|minmax_value:0,180'}`"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    :id="'ChildHeight' + index"
                    :name="'ChildHeight' + index"
                    v-model.trim="ChildInfoData[index].ch_height"
                    placeholder="Exact Height"
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <!-- rules="required|numeric|minmax_value:0,400" -->
            <div class="form-group">
              <label for="lbl_child_weight">{{ $t("langChildWeight") }} <span v-if="ProductType == 'Health Plus' || ProductType == 'Platinum Plus'" class="required-mark">*</span></label>
              <ValidationProvider
                :name="'Child ' + (index+1) + ' Weight'"
                :rules="`${(ProductType == 'Health Plus' || ProductType == 'Platinum Plus') ? 'required|numeric|minmax_value:1,400' : 'numeric|minmax_value:0,400'}`"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    :id="'ChildWeight' + index"
                    :name="'ChildWeight' + index"
                    v-model.trim="ChildInfoData[index].ch_weight"
                    placeholder="Exact Weight"
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <!-- Child details row 3 End -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { throttle } from "lodash";
export default {
  name: "personal-details-child-widget",
  data() {
    return {};
  },
  props: ["index"],
  computed: {
    ...mapState("proposal", ["ChildInfoData" , "ProductType"]),
  },
  methods: {
    ...mapActions("proposal", ["draftProposalData"]),
    sendChangedData: throttle(async function() {
      await this.draftProposalData();
    }, process.env.VUE_APP_TIMEOUT_SEND_CHANGED_DATA),
  },
  mounted(){
    console.log(this.ProductType);
  }
};
</script>

<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

/* -- toggle switch ---*/

.toggleswitchg input:checked + label {
  background: #e9ecef !important;
  color: #575e65 !important;
  font-weight: 400 !important;
  border: 1px solid #ced4da !important;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked + label {
  background: #039fec;
  color: #fff;
  font-weight: 600;
  border: 1px solid #039fec;
}
</style>

<!-- Language Data for Personal Details Child Widget -->
<i18n>
{
  "en": {    
    "PersonalDetailsWidgetTitle": "Personal Details",
    "tabChild": "Child",
    "langChildTitle": "Title",
    "langChildFName": "First Name",
    "langChildMName": "Middle Name",
    "langChildLName": "Last Name",

    "langChildDOB": "Date of Birth ( MM/DD/YYYY)",
    "langChildAge": "Age",
    "langChildGender": "Gender",
    "langChildPlaceBirth": "Place of Birth",
    
    "langChildRelationship": "Relationship to the Proposer/Insured",
    "langChildHeight": "Child's exact Height(cm)",
    "langChildWeight": "Child's exact Weight(Kg)"
  },
  "si": {
    "PersonalDetailsWidgetTitle": "පෞද්ගලික තොරතුරු",
    "tabChild": "දරුවා",
    "langChildTitle": "ශීර්ෂය",
    "langChildFName": "මුල් නම",
    "langChildMName": "මැද නම",
    "langChildLName": "අවසන් නම",

    "langChildDOB": "උපන් දිනය ( MM/DD/YYYY)",
    "langChildAge": "වයස",
    "langChildGender": "ස්ත්‍රී/පුරුෂ භාවය",
    "langChildPlaceBirth": "උපන් ස්ථානය",
    
    "langChildRelationship": "යෝජකයා/රක්ෂිතයා සමඟ සම්බන්ධතාවය",
    "langChildHeight": "දරුවාගේ නිශ්චිත උස (සෙ.මී.)",
    "langChildWeight": "දරුවාගේ නියම බර (කි.ග්‍රෑ)"
  },
  "ta": {
    "PersonalDetailsWidgetTitle": "தனிப்பட்ட விவரங்கள்",
    "tabChild": "பேபி",
    "langChildTitle": "படிநிலை",
    "langChildFName": "முதற் பெயர்",
    "langChildMName": "நடுத்தர பெயர்",
    "langChildLName": "கடைசி பெயர்",

    "langChildDOB": "பிறந்த திகதி ( MM/DD/YYYY)",
    "langChildAge": "வயது",
    "langChildGender": "பால்",
    "langChildPlaceBirth": "பிறந்த இடம்",
    
    "langChildRelationship": "முன்மொழிபவர்/காப்பீட்டாளருடனான உறவு",
    "langChildHeight": "குழந்தையின் சரியான உயரம் (செ.மீ)",
    "langChildWeight": "குழந்தையின் சரியான எடை (கிலோ)"
  }
}
</i18n>
