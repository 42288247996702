<!-- Final - Document Upload Widget -->
<template>
  <div class>
    <vue-snotify></vue-snotify>
    <div class="card">
      <div class="card-header">
        <i class="ti ti-user"></i>
        <span>&nbsp;&nbsp;&nbsp;{{ $t("FinalDocumentUploadTitle") }} - View</span>
      </div>
      <div class="card-body box-body mb-5">
        <!-- Final - Particulars of Previous life Policies/Proposals Question Widget (12) -->
        <div class="col">
          <ValidationObserver ref="documentCommentForm">
            <div class="container-fluid">
              <div class v-for="(upload_type, index) in UploadTypes" :key="index">
                <div class="p-2 mb-1 bg-secondary text-white" :class="getDynamicStatusClass(upload_type)">
                  {{ upload_type.title }}
                  <span class="float-right">{{ approveStatusStr(upload_type) }}
                    <label>
                      <i :class="getDynamicCheckClass(upload_type)"></i>
                    </label>
                  </span>
                </div>
                <div class="border border-primary rounded mb-2 p-3">
                  <div class="upload">
                    <div class="example-btn">
                      <div v-if="user_role == 'ADV'">
                        <input type="file" :data-code="upload_type.id" class="btn btn-primary m-3 upload_img"
                          v-on:change="onFileChange" ref="file" accept="image/png, image/jpeg" multiple="multiple" />
                          <small class="text-warning"> *(only jpeg,jpg,png,PDF)   *(max file size - 2mb)</small>
                        <textarea class="form-control mb-2 col-md-6" placeholder="Enter your remarks" v-model="upload_type.adv_remark">
                        </textarea>
                      </div>

                      <div class="row">
                        <div v-for="item in filterUploadImages(UploadedDocs, upload_type.id)" :key="item.id"
                          class="col-md-3">
                          <div class="padding-without-card">
                            <div class="card-body">
                              <div class="alert alert-primary card-title p-1">Doc ID : {{ item.id }}</div>
                              <a v-bind:href="item.url" target="_blank" v-if="['jpeg','jpg' , 'png' , 'PNG' , 'JPEG' , 'jpg'].includes(item.extension)">
                                <img v-bind:src="item.url" target="_blank" class="rounded mt-1 mb-1 ml-3" alt="No Preview"
                                  width="150" />
                              </a>
                              <a class="doc-preview-link" v-else v-bind:href="item.url" target="_blank">
                              {{ item.name }}
                              </a>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div class="row" v-if="filtercommentHistory(upload_type.id).length != 0">
                        <div class="col-md-6 m-2">
                          <table class="table table-striped table-bordered table-hover">
                            <thead class="thead-dark">
                              <tr>
                                <td>Id</td>
                                <td>Status</td>
                                <td>Remark</td>
                                <td>User</td>
                                <td>Date</td>
                              </tr>
                            </thead>
                            <tr v-for="(history, index) in filtercommentHistory(
                              upload_type.id
                            )" :index="index" :key="index">
                              <td>{{ history.id }}</td>
                              <td>{{ getApprovalPostActionText(history.approval_status) }}</td>
                              <td>{{ history.remark }}</td>
                              <td>{{ history.user.agent_code }}</td>
                              <td>{{ history.created_at }}</td>
                            </tr>
                            <tr v-if="filtercommentHistory(upload_type.id).length == 0
                              ">
                              <td colspan="5">No history</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <ValidationObserver ref="proposalForm">
      <b-modal centered :title="$t('prop_no_add_modal_title')" header-bg-variant="primary" header-text-variant="light"
        body-bg-variant="light" body-text-variant="dark" footer-bg-variant="" footer-text-variant="dark"
        ref="add-proposal-modal" @ok="addPropNo" :id="'add-proposal-modal'">
        <div class="row">
          <div class="col-sm-12">
            <form @submit.stop.prevent="handleSubmit" ref="form">
              <div class="form-group">
                <label for="lbl_hospital_list">Proposal Number</label>
                <ValidationProvider immediate name="Proposal Number" rules="required|min:3|max:20">
                  <div slot-scope="{ errors }">
                    <b-form-input type="text" class="form-control" id="proposalNo" name="proposalNo"
                      v-model.trim="proposal_no" minlength="3" maxlength="20" required>
                    </b-form-input>
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </form>
          </div>
        </div>
      </b-modal>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
//import FileUpload from "vue-upload-component";
export default {
  name: "final-document-upload-widget",
  computed: {
    ...mapState("proposal", [
      "UploadTypes",
      "UploadedDocs",
      "QuoteRefNo",
      "ProposalID",
    ]),
    remarkState() {
      return this.remark.length > 2 ? true : false;
    },
    selectedSearchType: {
      get() {
        //console.log(this.selectedSearchType);
        return this.value;
      },
    },
  },
  components: {
    // FileUpload
  },
  mounted() {
    let payloadRef = {};
    if (this.$route.params.ref_no) {
      payloadRef = {
        q_ref_no: this.$route.params.ref_no,
      };
    }
    this.getUploadTypes(payloadRef);
    this.getCommentHistory();
    if (this.$route.params.ref_no) {
      let payload = {
        q_ref_no: this.$route.params.ref_no,
      };

      this.loadUploadedFiles(payload);

      this.getBenefitData(this.$route.params.ref_no);
    }
    if (this.$route.name == "push_back") {
      this.setBtnApproveStatus(true);
    }
    if (localStorage.user_role) {
      this.user_role = localStorage.getItem("user_role");
    }
  },
  methods: {
    ...mapActions("proposal", [
      "UploadFile",
      "loadUploadedFiles",
      "getUploadTypes",
      "submitDocumentComments",
      "setProposalNumber",
      "getBenefitData",
      "getDocumentCommentHistory",
    ]),
    filterUploadImages(obj, id) {
      if (obj.length) {
        return obj.filter((item) => {
          return item.proposal_upload_type_id == id;
        });
      } else {
        return [];
      }
    },
    filtercommentHistory(doc_id) {
      if (this.commentHistory.length) {
        return this.commentHistory.filter((history) => {
          return history.doc_type_id == doc_id;
        });
      } else {
        return [];
      }
    },
    approveStatusStr(uploadType) {
      let approval_status = "";
      let text = "";
      let res = this.propcommentHistory.filter((historyData) => {
        return historyData.doc_type_id == uploadType.id;
      });
      if (res) {
        approval_status = res[0] ? res[0].approval_status : "";
      }
      if (approval_status == "Approve") {
        text = "Approved";
      } else if (approval_status == "Reject") {
        text = "Rejected";
      } else if (approval_status == "NA") {
        text = "No Action";
      }
      return text;
    },
    getDynamicCheckClass(uploadType) {
      let approval_status = "";
      let dynClass = "";
      let res = this.propcommentHistory.filter((historyData) => {
        return historyData.doc_type_id == uploadType.id;
      });
      if (res) {
        approval_status = res[0] ? res[0].approval_status : "";
      }
      if (approval_status == "Approve") {
        dynClass = "ti ti-check fa-bold";
      } else if (approval_status == "Reject") {
        dynClass = "ti ti-close fa-bold";
      }
      return dynClass;
    },
    getDynamicStatusClass(uploadType) {
      let approval_status = "";
      let dynClass = "bg-secondary";
      let res = this.propcommentHistory.filter((historyData) => {
        return historyData.doc_type_id == uploadType.id;
      });
      if (res) {
        approval_status = res[0] ? res[0].approval_status : "";
      }
      if (approval_status == "Approve") {
        dynClass = "bg-success";
      } else if (approval_status == "Reject") {
        dynClass = "bg-danger";
      }
      return dynClass;
    },
    getApprovalPostActionText(approval_status) {
      let approve_text = "";
      if (approval_status == "Approve") {
        approve_text = "Approved";
      } else if (approval_status == "Reject") {
        approve_text = "Rejected";
      } else if (approval_status == "NA") {
        approve_text = "No Action";
      }
      return approve_text;
    },
    getCommentHistory() {
      this.$snotify.clear();
      let payload = {
        q_ref_no: this.$route.params.ref_no,
      };

      this.getDocumentCommentHistory(payload).then((response) => {
        if (response.data.meta.status) {
          this.commentHistory = response.data.data;
          this.propcommentHistory = response.data.statusData;
        }
      });
    },
    resetModal() {
      this.proposal_no = "";
      this.nameState = null;
    },
    checkFormValidity() {
      const valid = this.$refs.proposalForm.validate();
      this.nameState = valid;
      return valid;
    },
    addPropNo(bvModalEvt) {
      this.$snotify.clear();
      let propo_id = this.ProposalID;
      let propo_no = this.proposal_no;

      let payload = {
        proposal_id: propo_id,
        proposal_no: propo_no,
      };

      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        bvModalEvt.preventDefault();
        return;
      }

      this.setProposalNumber(payload).then((response) => {
        if (response.data.meta.status) {
          this.$snotify
            .success(response.data.meta.status_message, "", {
              position: "centerTop",
            })
            .on("destroyed", () => {
              this.$router.push({
                name: "proposal_list",
              });
            });
        }else{
          this.$snotify
            .error("Something went wrong while adding proposal number.Please try again.", "", {
              position: "centerTop",
            });
        }
      });

      //this.setProposalNumber(payload);
      this.resetModal();
    },
    submitComments() {
      this.$snotify.clear();
      let payload = {
        documents: this.UploadTypes,
        q_ref_no: this.$route.params.ref_no,
        ProposalID: this.ProposalID,
      };

      this.submitDocumentComments(payload).then((response) => {
        if (response.data.meta.status) {
          this.$snotify
            .success(response.data.meta.status_message, "", {
              position: "centerTop",
            })
            .on("destroyed", () => {
              if (response.data.allApprove && response.data.role == "SUP") {
                this.$bvModal.show("add-proposal-modal");
              } else {
                this.$router.push({
                  name: "proposal_list",
                });
              }
            });
        } else {
          this.$refs.documentCommentForm.setErrors(response.data.meta.errors);
        }
      });
    },
    onFileChange(e) {
      let formData = new FormData();
      formData.append("q_ref_no", this.$route.params.ref_no);
      formData.append("p_doc_type", e.currentTarget.dataset.code);

      for (var i = 0; i < e.currentTarget.files.length; i++) {
        let file = e.currentTarget.files[i];
        //console.log(file);
        formData.append("files[" + i + "]", file);
      }
      this.UploadFile(formData);
    },
  },

  data() {
    return {
      index: 0,
      files1: [],
      file: [],
      user_role: "",
      proposal_no: "",
      prop_id: "",
      prop_no: "",
      commentHistory: "",
      propcommentHistory: [],
    };
  },
};
</script>

<style scoped>
.fa-bold {
  font-weight: bold;
}

.card-outline {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-clip: border-box !important;
  border: 1px solid #92d127 !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}

.example-multiple label.btn {
  margin-bottom: 0 !important;
  margin-right: 1rem !important;
}

.example-multiple .upload {
  margin-bottom: 1rem !important;
}
</style>

<!-- Language Data for Final - Document Upload Widget -->
<i18n>
{
  "en": {
    "AreYouInGoodHealthQuestion": "Are you in good health ? If 'No', Give details",
    "Insured": "Insured",
    "Spouse": "Spouse",
    "FinalDocumentUploadTitle": "Document Upload",
    "prop_no_add_modal_title": "Add Proposal Number "
  },
  "si": {
    "AreYouInGoodHealthQuestion": "Are you in good health ?",
    "Insured": "ජීවිත රක්ෂිතයා",
    "Spouse": "කලත්‍රයා",
    "FinalDocumentUploadTitle": "ලේඛන උඩුගත කිරීම",
    "prop_no_add_modal_title": "Add Proposal Number "
  },
  "ta": {
    "AreYouInGoodHealthQuestion": "Are you in good health ?",
    "Insured": "பிரதான வாழ்க்கை",
    "Spouse": "வாழ்க்கைத்துணை",
    "FinalDocumentUploadTitle": "ஆவண பதிவேற்றம்",
    "prop_no_add_modal_title": "Add Proposal Number "
  }
}
</i18n>
