<!--Lead Information Capture Widget  -->
<template>
      <div class="card">
        <loading :active.sync="isLoading"
                 :is-full-page="fullPage"
                 color="#00F">
        </loading>
        <vue-snotify></vue-snotify>
        <div class="card-header header-font">
          <font-awesome-icon icon="calculator" />
          <span>&nbsp;{{ $t("pension_calc_form_header") }}</span>
        </div>

        <div class="card-body">
          <ValidationObserver ref="pensionform">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="CurrentAge">
                    {{
                    $t("current_age")
                    }}
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <ValidationProvider name="Current Age" rules="required|max_value:120|integer">
                    <div slot-scope="{ errors }">
                      <input
                        type="number"
                        ref="current_age"
                        class="form-control"
                        id="txtPensionCurrentAge"
                        name="CurrentAge"
                        placeholder="Current Age"
                        v-model="current_age"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="CurrentAge">
                    {{
                    $t("funeral_expense")
                    }}
                  </label>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <ValidationProvider name="Funerel Expense" rules="required">
                    <div slot-scope="{ errors }">
                      <select class="form-control" name="Title" v-model="funeral_expense_annual_expenses">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="lblExpectedMonthlyIncome">
                    {{
                    $t("monthly_income")
                    }}
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <ValidationProvider name="Expected Monthly Income" rules="required|decimal">
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        ref="current_age"
                        class="form-control"
                        id="txtExpectedIncome"
                        name="ExpectedMonthlyIncome"
                        placeholder="Expected Monthly Income"
                        v-model="expected_monthly_income"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <!-- EXPECTED MONTHLY PENSION -->

            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="CurrentAge">
                    {{
                    $t("retirement_age")
                    }}
                  </label>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <ValidationProvider name="Retirement Age" rules="required">
                    <div slot-scope="{ errors }">
                      <select class="form-control" name="RetirementAge" v-model="retirement_age">
                        <option>55</option>
                        <option>56</option>
                        <option>57</option>
                        <option>58</option>
                        <option>59</option>
                        <option>60</option>
                        <option>61</option>
                        <option>62</option>
                        <option>63</option>
                        <option>64</option>
                        <option>65</option>
                      </select>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <!-- RETIREMENT AGE SELECT -->

            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="lblMonthlyPensionDuration">
                    {{
                    $t("monthly_pension_duration")
                    }}
                  </label>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <ValidationProvider name="Pension Duration" rules="required">
                    <div slot-scope="{ errors }">
                      <select class="form-control" name="PensionDuration" v-model="pension_duration">
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                        <option>13</option>
                        <option>14</option>
                        <option>15</option>
                        <option>16</option>
                        <option>17</option>
                        <option>18</option>
                        <option>19</option>
                        <option>20</option>
                        <option>21</option>
                        <option>22</option>
                        <option>23</option>
                        <option>24</option>
                        <option>25</option>
                        <option>26</option>
                        <option>27</option>
                        <option>28</option>
                        <option>29</option>
                        <option>30</option>
                        <option>31</option>
                        <option>32</option>
                        <option>33</option>
                        <option>34</option>
                        <option>35</option>
                        <option>36</option>
                        <option>37</option>
                        <option>38</option>
                        <option>39</option>
                        <option>40</option>
                        <option>41</option>
                        <option>42</option>
                        <option>43</option>
                        <option>44</option>
                        <option>45</option>
                      </select>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <!-- MONTHLY PENSION DURATION SELECT -->

            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="lblGrowthRate">
                    {{
                    $t("growth_rate")
                    }}
                  </label>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <ValidationProvider name="growth Rate" rules="required">
                    <div slot-scope="{ errors }">
                      <select class="form-control" name="growthRate" v-model="growth_rate">
                        <option>8</option>
                        <option>10</option>
                        <option>12</option>
                      </select>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <!-- GROWTH RATE SELECT -->

            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="lblExpectedMonthlyIncome">
                    {{
                    $t("capital_savings")
                    }}
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <ValidationProvider name="Invest Amount" rules="decimal">
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        ref="current_age"
                        class="form-control"
                        id="txtInvestAmount"
                        name="InvestAmount"
                        placeholder="Topup Amount"
                        v-model="investment_amount"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <!-- TOP UP AMOUNT  -->

            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="lblPaymentMode">
                    {{
                      $t("payment_mode")
                    }}
                  </label>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <ValidationProvider name="Payment Mode" rules="required">
                    <div slot-scope="{ errors }">
                      <select class="form-control" name="PaymentMode" v-model="payment_mode" disabled="">
                        <option value="Y">Y</option>
                        <option value="H">H</option>
                        <option value="M">M</option>
                        <option value="Q">Q</option>
                      </select>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <!-- PAYMENT MODE -->

            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="lblExpectedMonthlyIncome">
                    {{
                    $t("monthly_contribution")
                    }}
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <ValidationProvider name="Monthly Contribution" rules="">
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        ref="monthly_contribution"
                        class="form-control"
                        id="txtMonthlyContribution"
                        name="MonthlyContribution"
                        placeholder="Monthly Contribution"
                        v-model="required_monthly_contibution"
                        disabled
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <!-- MONTHLY CONTRIBUTION  -->

            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="lblcapitalRetirementAge">
                    Required Capital As At Retirement Age
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <ValidationProvider name="Required Capital" rules="">
                    <div slot-scope="{ errors }">
                      <input
                          type="text"
                          ref="monthly_contribution"
                          class="form-control"
                          id="cpRetirementAge"
                          name="CapitalRequired"
                          placeholder="Required Capital As At Retirement Age"
                          v-model="required_capital"
                          disabled
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
              <!-- REQUIRED CAPITAL  -->

            <b-row>
              <div class="col-md-6 col-lg-6">

              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <button
                    type="submit"
                    ref="createBtn"
                    v-on:click="onSubmit"
                    class="btn btn btn-success btn-sm btn-block"
                >
                  <font-awesome-icon icon="calculator" />&nbsp;Calculate
                </button>
                <button
                    type="submit"
                    ref="updateBtn"
                    class="btn btn btn-secondary btn-sm btn-block"
                    v-on:click="resetForm"
                >&nbsp;Reset</button>
              </div>
            </b-row>

          </ValidationObserver>
        </div>
      </div>

</template>

<script>
import {mapActions , mapState } from "vuex";
import { SnotifyPosition } from "vue-snotify";

import { extend } from 'vee-validate';
import { integer } from 'vee-validate/dist/rules';

// No message specified.
extend('integer', integer);


export default {
  name: "appointment-info-widget",

  data: function() {
    return {
      time: new Date("hh-MM-ss"),
      current_age : '',
      funeral_expense_annual_expenses : '',
      expected_monthly_income: '',
      retirement_age: '',
      pension_duration:'',
      growth_rate:'',
      investment_amount:'',
      payment_mode:'M',
      isLoading: false,
      fullPage: true,
    };
  },
  components: {},
  async created() {

  },

  computed: {
    ...mapState("pension_calc", ["required_capital" , "required_monthly_contibution" , "capital_growth" , "pansion_build_up"]) // assuming you are using namespaced modules
  },
  watch: {},

  methods: {
    ...mapActions("pension_calc", ["pensionCalc" , "clearStateData"]),

    async resetForm(){
      this.current_age = '';
      this.funeral_expense_annual_expenses = '';
      this.expected_monthly_income = '';
      this.retirement_age = '';
      this.pension_duration = '';
      this.growth_rate = '';
      this.investment_amount = '';
      this.payment_mode = '';
      this.clearStateData();
    },
    async onSubmit() {
      this.isLoading = true;

      let data = {
        current_age: this.current_age,
        funeral_expense_annual_expenses: this.funeral_expense_annual_expenses,
        expected_monthly_income: this.expected_monthly_income,
        retirement_age: this.retirement_age,
        pension_duration: this.pension_duration,
        growth_rate: this.growth_rate,
        investment_amount: this.investment_amount,
        payment_mode: this.payment_mode
      };

      this.$refs.pensionform
        .validate()
        .then(result => {
          if (!result) {
            this.$snotify.clear();
            this.$snotify.error("There are items that require your attention", {
              timeout: 3000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              position: SnotifyPosition.centerTop
            });
            this.isLoading = false;
          } else {
            this.$snotify.clear();
            if( (parseInt(this.retirement_age) + parseInt(this.pension_duration)  ) > 100){
              this.$snotify.error("Please change pension receiving period or retiring age", {
                timeout: 3000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop
              });
              this.isLoading = false;
            }else if( (parseInt(this.retirement_age) - parseInt(this.current_age)) > 30){
              this.$snotify.error("Contribution term is more than 30,Please change retirement age", {
                timeout: 3000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop
              });
              this.isLoading = false;
            }else{
              this.pensionCalc(data).then(() => {
                this.isLoading = false;
              });
            }

          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #556065 !important;
  border-bottom: 0px solid #06a990 !important;
  color: #fff !important;
}
.card {
  position: relative;
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #06a990 !important;
  border-radius: 0.25rem;
}

.snotify {
  display: block;
  position: fixed;
  width: 99% !important;
  z-index: 9999;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: none;
}
/*-- toggle switch for Main Life Frequency ----*/

.togglefreq label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.togglefreq input:checked,
.togglefreq input:not(:checked) {
  display: none;
}

.togglefreq input:checked + label {
  background: #92D127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92D127;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked + label {
  background: #92D127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92D127;
}

.gen-btn {
  margin-bottom: 100px;
}

.btn_arrangment{
  padding-bottom: 20px;
  }

  .btn_height{
    height: 45px;
    }


@media (max-width: 768px) {
  .btn-sm,
  .btn-group-sm > .btn {
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    line-height: 1.5;
  }
   .top-margin {
    margin-top: 60px;
  }
}

</style>





<!-- Language Data for Pension Calculator -->
<i18n>
{
  "en": {
    "pension_calc_form_header": "Pension Calculator",
    "current_age": "What is your Current Age ?",
    "funeral_expense": "How many times of Annual Contribution is Required as Funeral Expense ?",
    "monthly_income": "How much would you love to receive when you retire ?",
    "retirement_age": "When you would like to retire ?",
    "monthly_pension_duration": "How long would you like to receive monthly pension ?",
    "growth_rate": "Expected Growth Rate (%)",
    "capital_savings": "How much capital/savings can you invest now (Top Up) ?",
    "monthly_contribution": "Required monthly contribution",
    "payment_mode": "Payment Mode"
  },
   "si": {
    "pension_calc_form_header": "Pension Calculator",
    "current_age": "What is your Current Age ?",
    "funeral_expense": "How many times of Annual Contribution is Required as Funeral Expense ?",
    "monthly_income": "How much would you love to receive when you retire ?",
    "retirement_age": "When you would like to retire ?",
    "monthly_pension_duration": "How long would you like to receive monthly pension ?",
    "growth_rate": "Expected Growth Rate (%)",
    "capital_savings": "How much capital/savings can you invest now (Top Up) ?",
    "monthly_contribution": "Required monthly contribution",
    "payment_mode": "Payment Mode"
  }, 
  "ta": {
    "pension_calc_form_header": "Pension Calculator",
    "current_age": "What is your Current Age ?",
    "funeral_expense": "How many times of Annual Contribution is Required as Funeral Expense ?",
    "monthly_income": "How much would you love to receive when you retire ?",
    "retirement_age": "When you would like to retire ?",
    "monthly_pension_duration": "How long would you like to receive monthly pension ?",
    "growth_rate": "Expected Growth Rate (%)",
    "capital_savings": "How much capital/savings can you invest now (Top Up) ?",
    "monthly_contribution": "Required monthly contribution",
    "payment_mode": "Payment Mode"
  }
  
}
</i18n>