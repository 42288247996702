<!-- New Proposal View -->
<template>
  <div>
    <Header />
    <LeftNav />
    <main id="page-wrap">
      <!-- <vue-snotify></vue-snotify> -->
      <b-container fluid>
        <NewProposalWizardHolder />
      </b-container>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/layout/AppHeader.vue";
import LeftNav from "@/components/common/layout/AppLeftNav";
import NewProposalWizardHolder from "@/components/proposal/NewProposalWizardHolder";
import Footer from "@/components/common/layout/AppFooter.vue";

export default {
  name: "NewProposal",
  components: {
    Header,
    LeftNav,
    NewProposalWizardHolder,
    Footer
  }
};
</script>

<style scoped>
</style>