<template>
  <div class="row-top-list2 table-row-top">
    <!-- Appointment-Modal -->
    <div class="card">
      <b-modal
        centered
        size="xl"
        :title="$t('AppointmentCreateModalTitle')"
        header-bg-variant="primary"
        header-text-variant="light"
        body-bg-variant="light"
        body-text-variant="dark"
        footer-bg-variant=""
        footer-text-variant="dark"
        :id="'modal-create-quotation'"
        ref="modal-create-quotation"
        :ok-disabled="true"
      >
        <ValidationObserver ref="appointmentrow">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="lbl_appointment_date">{{
                  $t("LblappointmentDate")
                }}</label>
                <ValidationProvider name="Appointment Date" rules="required">
                  <div slot-scope="{ errors }">
                    <input
                      type="date"
                      class="form-control"
                      id="txtAppointmentDate"
                      name="AppointmentDate"
                      v-model="AppointmentInfo.txtAppointDate"
                    />

                    <!-- 
                                <datepicker
                                class="datep"
                                id="txtAppointmentDate"
                                name="Appointment Date"
                                v-model="AppointmentInfo.txtAppointDate"
                                :format="customFormatter"></datepicker>
                              -->

                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label for="lbl_appointment_time">{{
                  $t("appointmentTime")
                }}</label>
                <ValidationProvider name="Appointment Time" rules="required">
                  <div slot-scope="{ errors }">
                    <input
                      class="form-control"
                      id="txtAppointmentTime"
                      name="AppointmentTime"
                      placeholder="Appointment Time"
                      type="Time"
                      v-model="AppointmentInfo.txtAppointTime"
                    />

                    <!-- <timeselector
                                id="txtAppointmentTime"
                                name="AppointmentTime"
                                :placeholder="'Select a time'"
                                v-model="AppointmentInfo.txtAppointTime"
                                :interval="{h:1, m:5}"
                                :displayFormat= "'HH-mm a'"
                                :returnFormat ="'HH-mm-ss'"
                              ></timeselector>-->

                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label for="lbl_appointment_remarks">{{
                  $t("appointmentRemarks")
                }}</label>
                <ValidationProvider name="Appointment Remarks">
                  <div slot-scope="{ errors }">
                    <textarea
                      class="form-control"
                      id="txtAppointmentRemarks"
                      name="AppointmentRemarks"
                      placeholder="Appointment Remarks"
                      type="text"
                      v-model="AppointmentInfo.txtAppointRemarks"
                    ></textarea>
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label for="lbl_appointment_status">{{
                  $t("appointmentStatus")
                }}</label>
                <ValidationProvider name="Appointment Status">
                  <!-- rules="appointmentStatusValidation:@Appointment Remarks" -->
                  <div slot-scope="{ errors }">
                    <select
                      class="form-control"
                      name="Title"
                      v-model="AppointmentInfo.drpAppointStatus"
                      placeholder="Pending"
                    >
                      <!-- <option
                                  v-for="opt in options"
                                  :key="opt.value"
                                  :disabled="opt.disabled"
                                >{{opt.display}}</option>-->

                      <option>Pending</option>
                      <!-- <option></option> -->
                      <option>Visited</option>
                      <!-- <option>Rescheduled</option> -->
                      <option>Cancelled</option>
                      <!-- <option>Expired</option> -->
                    </select>
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <b-row>
            <div class="col-sm-8"></div>
            <div class="col-sm-4">
              <button
                type="submit"
                ref="createBtn"
                class="btn btn btn-success btn-sm btn-block"
                v-on:click="onSubmit"
                v-show="this.AppointForm.isSave"
              >
                &nbsp;Save
              </button>

              <button
                type="submit"
                ref="updateBtn"
                class="btn btn btn-warning btn-sm btn-block"
                v-on:click="onSubmit"
                v-show="!this.AppointForm.isSave"
              >
                &nbsp;Update Appointment
              </button>

              <button
                type="submit"
                title="resetData"
                ref="resetBtn"
                class="btn btn btn-secondary btn-sm btn-block"
                v-on:click="resetData"
              >
                &nbsp;Reset
              </button>
            </div>
          </b-row>
        </ValidationObserver>
      </b-modal>
    </div>
    <!-- Appointment Modal Box - END -->

    <div class="appoinment_card">
      <b-card no-body class="card-calendar">
        <!-- Card Header -->
        <b-card-header>
          <div class="row">
            <div class="col-sm-6 col-md-8 col-lg-8">
              <!-- Title  -->
              <h5 class="h6 mb-0 title_style">Appointment Calendar</h5>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-4">
              <!-- <b-button
          style="margin:5px padding: .25rem .65rem; "
          variant="success"
          class="ui btn-sm basic button btn_arrange"
          title="Add New Lead"
          v-b-tooltip.hover.bottom="'Add New Appointment'"
          @click="this.createAppointment"
        >
          <font-awesome-icon icon="plus" />&nbsp;Add New Appointment
        </b-button> -->
            </div>
          </div>
        </b-card-header>

        <!-- Card Body  -->
        <div class="card-body">
          <div class="demo-app">
            <div class="demo-app-sidebar"></div>

            <FullCalendar
              class="calendar"
              refs="appointmentCalendar"
              :options="calendarOptions"
            >
              <template v-slot:eventContent="arg">
                <!-- <i>{{ arg.timeText }}</i> &nbsp;
          <i>{{ arg.event.title }}</i> -->
              </template>
            </FullCalendar>
          </div>
        </div>
      </b-card>
    </div>

    <!-- <div class="card">
      <div class="card-header">
        <font-awesome-icon icon="align-left" />&nbsp;
        <span>{{ $t('list_appointment')}}</span>
      </div>
      <div class="card-body">
      
        <div class='demo-app'>
            <div class='demo-app-sidebar'>
              </div>
            <div class='demo-app-main'>
            <FullCalendar
                class='demo-app-calendar'
                refs ='appointmentCalendar'
                :options='calendarOptions'
            >
                <template v-slot:eventContent='arg'>
                 <i>{{ arg.timeText }}</i> &nbsp; 
                <i>{{ arg.event.title }}</i>
                </template>
            </FullCalendar>
            </div>
        </div>
      </div>
    </div>  -->
  </div>
</template>

<script>
import Vue from "vue";

import { mapState, mapActions, mapGetters } from "vuex";

//FullCalender
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

// import { INITIAL_EVENTS, createEventId } from '@/mixins/event-utils';

//moment datepicker
import moment from "moment";
Vue.use(require("vue-moment"));

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
  data() {
    return {
      time: new Date("hh-MM-ss"),
      isSaved: true,
      AppointDate: "",
      txtAppointTime: "",
      txtAppointRemarks: "",
      drpAppointStatus: "",
      txtAppointDate: "",

      calendarOptions: {
        dayMaxEventRows: true,
        timeZone: "local", // the default (unnecessary to specify)
        eventColor: "#FF5722",
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth",
        },
        initialView: "dayGridMonth",
        events: true,
        editable: false,
        selectable: true,
        selectMirror: true,
        weekends: true,
        eventTextColor: "black",
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        // moreLinkClick: this.handleEventClick,
        eventTimeFormat: {
          // like '14:30:00'
          hour: "numeric",
          minute: "2-digit",
          meridiem: "short",
        },
        // events: [
        //             {
        //               id: '1',
        //               title: 'my event',
        //               start: '2020-10-15',
        //               backgroundColor:'#ff0000'
        //             }
        //     ]
        // timeFormat: "H(:mm)", // uppercase H for 24-hour clock
      },

      currentEvents: [],
    };
  },
  computed: {
    ...mapGetters("login", ["checkUserPermissionNew"]),
    ...mapState("lead", [
      "AppointmentInfo",
      "AppointForm",
      "flash",
      "minflash",
      "options",
      "statusOptions",
      "CalendarList",
    ]),
    // reLoadCalendar() {
    //   // return calendarOptions.;
    //   return this.AppointForm.loadCalendar;
    // },
  },

  mounted() {
    this.loadCalendar(); // reload calendar
  },
  watch: {
    // reLoadCalendar() {
    //   this.loadCalendar(); // reload calendar
    // },
  },
  methods: {
    ...mapActions("lead", [
      "addAppointment",
      "updateAppointment",
      "clearStateData",
      "getTimestamp",
      "loadDefAppointmentTime",
      "loadAppoint",
      "getCalendarAllAppointmentList",
      "setCalendarAppointmentData",
      "calendarDateSelect",
    ]),

    customFormatter(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    async loadCalendarAppointmentInfo(data) {
      await this.get(data);
    },

    async setAppointmentInfo(action, data) {
      await this.loadAppoint(data);
    },

    //Appointment
    async onSubmit() {
      this.$refs.appointmentrow
        .validate()
        .then((result) => {
          if (!result) {
            // this.$snotify.error("There are items that require your attention", {
            //   timeout: 3000,
            //   showProgressBar: true,
            //   closeOnClick: true,
            //   pauseOnHover: false,
            //   position: SnotifyPosition.centerTop
            // });
            return;
          } else {
            if (this.AppointForm.isSave == true) {
              //Create New Appointment
              this.addAppointment(this.$route.params.ref_no);
            } else {
              //Update Existing Appointment
              this.updateAppointment(this.AppointmentInfo.txtAppointID);
              this.$bvModal.hide("modal-create-quotation");
            }
          }
        })
        .catch(() => {});
    },
    //APPOINTMENT MODAL FUNCTIONS
    async onCancel() {
      //this.$router.push("/lead_list");
      this.$router.push({
        name: "lead_list",
      });
    },
    async clearData() {
      await this.clearStateData();
    },
    async resetData() {
      await this.clearStateData();
    },

    async loadCalendar() {
      await this.getCalendarAllAppointmentList();
      this.calendarOptions.events = this.CalendarList;
    },
    //CALENDAR FUNCTIONS
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },

    handleDateSelect(selectInfo) {
      this.isSaved = false;
      this.calendarDateSelect(selectInfo.startStr);
      this.$emit("setAppointDate", selectInfo.startStr);
      // this.loadCalendar();
    }, //Calendar Date Select

    createAppointment() {
      this.isSaved = true;
      this.clearData();
      this.$bvModal.show("modal-create-quotation");
      this.loadCalendar();
    },

    handleEventClick(clickinfo) {
      this.setCalendarAppointmentData(clickinfo.event.id);
      this.calendarDateSelect(clickinfo.startStr);
      this.isSaved = false;
      this.$bvModal.show("modal-create-quotation");
    }, //Calendar Appointment Select

    handleEvents(events) {
      this.currentEvents = events;
    },
  },
};
</script>

<style>
.modal-backdrop {
  background-color: #0000001a !important;
}

.modal-content {
  border: 0px solid !important;
}

.tbl-margin-top {
  margin-top: 15px;
}

table th,
.table td {
  padding: 0.2rem 0.75rem !important;
  vertical-align: middle !important;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}

.pagination {
  float: right;
}

.page-link {
  font-size: 12px !important;
}
.tooltip {
  top: 0 !important;
}

.highlight-appointment {
  background-color: darkgoldenrod;
}

div.card {
  margin-top: 50px !important;
}

.btn_arrange {
  float: right;
}

.title_style {
  padding-top: 6px;
}

.fc-daygrid-event {
  background-color: coral;
}
/* APPOINTMENT MODAL STYLES */
@media (min-width: 992px) {
  .modal-lg {
    max-width: auto !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: auto !important;
  }
}

@media (max-width: 768px) {
  .appoinment_card {
    padding-top: 5px;
  }

  .btn_arrange {
    float: none;
    padding-top: 8px;
  }

  .title_style {
    padding-bottom: 6px;
  }

  div.card {
    margin-top: 60px !important;
  }
}
</style>

<i18n>
{
  "en": {
    "list_appointment": "Appointments",
    "AppointmentModalTitle": "Appointments",
    "AppointmentCreateModalTitle": "New Appointment",
    "AppointmentEditModalTitle": "Edit Appointment",
    "LblappointmentDate": "Appointment Date",
    "appointmentTime": "Time",
    "appointmentRemarks": "Remarks",
    "appointmentStatus": "Status"
  },
  "si": {
  "list_appointment": "හමුවීම් ලැයිස්තුව",
    "AppointmentModalTitle": "හමුවීම්",
    "AppointmentCreateModalTitle": "New Appointment",
    "AppointmentEditModalTitle": "Edit Appointment",
    "LblappointmentDate": "Appointment Date",
    "appointmentTime": "Time",
    "appointmentRemarks": "Remarks",
    "appointmentStatus": "Status"
  },
  "ta": {
    "list_appointment": "நியமனங்கள்",
    "AppointmentModalTitle": "sநியமனங்கள்",
    "AppointmentCreateModalTitle": "New Appointment",
    "AppointmentEditModalTitle": "Edit Appointment",
    "LblappointmentDate": "Appointment Date",
    "appointmentTime": "Time",
    "appointmentRemarks": "Remarks",
    "appointmentStatus": "Status"

  }
}
</i18n>
