<script>
import { Line,mixins } from "vue-chartjs";
import {mapState} from "vuex";
const { reactiveProp } = mixins
export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['chartData','options'],
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  updated() {
    this.renderChart(this.chartData, this.options);
  },
  computed: {
    ...mapState("pension_calc", ["pansion_build_up_amount" , "pansion_build_up_month"])
  },
  data(){
    return {

    };
  },
  watch: {
    'pansion_build_up_amount': function () {
      this.renderChart(this.chartData, this.options);
    },
    'pansion_build_up_month': function () {
      this.renderChart(this.chartData, this.options);
    },
  }

};
</script>
<style scoped></style>