  <template>
  <div class="row-top-list2 table-row-top">
    <div class="card">
      <div class="card-header">
        <font-awesome-icon icon="align-left" />&nbsp;
        <span>Audit List</span>
      </div>
      <div class="card-body">
        <div class="row tbl-margin-top">
          <div class="col table-responsive">
            <vuetable
              class="table table-striped table-bordered nowrap vue-tbl"
              ref="auditTable"
              :api-url="getUrl"
              :fields="fields"
              pagination-path
              @vuetable:pagination-data="onPaginationData"
              :per-page="10"
              :http-options="httpOptions"
              :append-params="moreParams"
              :sort-order="sortOrder"
            >
              <template slot="actions" scope="props">
                <div
                  class="custom-actions"
                  style="white-space: nowrap; text-align: center"
                >
                  <b-dropdown
                    class="ui btn-sm basic button"
                    variant="info"
                    right
                    text="Actions"
                  >
                    <b-dropdown-item
                      @click="
                        onActionClicked('view-changed-data', props.rowData)
                      "
                    >
                      View
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </vuetable>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="pagination">
              <vuetable-pagination
                ref="Pagination"
                @vuetable-pagination:change-page="onChangePage"
              ></vuetable-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- View Audit details modal -->
    <b-modal
      centered
      size="lg"
      title="View audit details"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="light"
      body-text-variant="dark"
      footer-bg-variant=""
      footer-text-variant="dark"
      :id="'view-audit-details-modal'"
      ref="view-audit-details-modal"
      :ok-disabled="false"
    >
      <div class="row">
        <div class="col-sm-12">
          <b-tabs content-class="mt-3">
            <b-tab title="Old Values" active
              ><p style="overflow: auto;">{{ allDetails.old_values }}</p></b-tab
            >
            <b-tab title="Updated Values"
              ><p style="overflow: auto;">{{ allDetails.new_values }}</p></b-tab
            >
            <b-tab title="Other">
              <label>IP Address</label>
              <p>{{ allDetails.ip_address }}</p>

              <label>User Agent</label>
              <p>{{ allDetails.user_agent }}</p>

              <label>URL</label>
              <p>{{ allDetails.url }}</p>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
//console.log(process.env.VUE_APP_API_ENDPOINT);
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
//import { ValidationProvider } from "vee-validate";
//import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

import VuetablePagination from "@/components/vuetable/VuetablePaginationBootstrap4";
import commonMixin from "@/mixins/common";
import { mapActions } from "vuex";

// let urole = localStorage.getItem("user_role") ? localStorage.getItem("user_role") : null;

export default {
  components: {
    Vuetable,
    VuetablePagination,
    //VuetablePaginationInfo
  },
  data() {
    return {
      show: false,
      urole: localStorage.getItem("user_role")
        ? localStorage.getItem("user_role")
        : null,
      fields: [
        {
          name: "id",
          title: '<span class="orange glyphicon glyphicon-user"></span> #',
          sortField: "id",
        },
        {
          name: "user.full_name",
          title: '<span class="orange glyphicon glyphicon-user"></span> User',
        },
        {
          name: "event",
          title: '<span class="orange glyphicon glyphicon-user"></span> Event'
        },
        {
          name: "auditable_type",
          title: '<span class="orange glyphicon glyphicon-user"></span> Type',
        },
        {
          name: "auditable_id",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Record Id',
        },

        {
          name: "created_at",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Created At',
        },
        {
          name: "old_values",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> Old Values',
          visible: false,
        },
        {
          name: "new_values",
          title:
            '<span class="orange glyphicon glyphicon-user"></span> New Values',
          visible: false,
        },

        "__slot:actions",
      ],
      httpOptions: {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: {},
      },
      moreParams: {},
      getUrl: process.env.VUE_APP_API_ENDPOINT + "audit/list",
      allDetails: [],
      sortOrder: [
        {
          field: 'id',
          direction: 'desc'
        }
      ]
    };
  },
  computed: {},
  mixins: [commonMixin],
  mounted() {
    this.$snotify.clear();
    this.$events.$on("filter-set", (eventData) => this.onFilterSet(eventData));
    this.$events.$on("filter-reset", this.onFilterReset());
  },
  methods: {
    ...mapActions("proposal", ["getBenefitData"]),

    onPaginationData(paginationData) {
      this.$refs.Pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.auditTable.changePage(page);
    },
    onActionClicked(action, data) {
      this.allDetails = data;
      if (action == "view-changed-data") {
        this.$bvModal.show("view-audit-details-modal");
      }
    },
    onFilterSet(filters) {
      this.moreParams = {
        event_name: filters.event_name,
      };
      Vue.nextTick(() => this.$refs.auditTable.refresh());
    },
    onFilterReset() {
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.auditTable.refresh());
    },
  },
};
</script>

<style>
.modal-backdrop {
  background-color: #0000001a !important;
}

.modal-content {
  border: 0px solid !important;
}

.tbl-margin-top {
  margin-top: 15px;
}

table th,
.table td {
  padding: 0.2rem 0.75rem !important;
  vertical-align: middle !important;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}

.pagination {
  float: right;
}

.page-link {
  font-size: 12px !important;
}
.tooltip {
  top: 0 !important;
}
</style>

<i18n>
{
  "en": {
    "list_quotaion": "Quotation List",
    "quoteListLblHospitals": "Hospitals",
    "quoteListLblModelHospitalTitle": "Medical Letter Hospital"
  },
  "si": {
  "list_quotaion": "මිල කැඳවුම් ලැයිස්තුව",
    "quoteListLblHospitals": "රෝහල්",
    "quoteListLblModelHospitalTitle": "වෛද්‍ය ලිපි රෝහල"
  },
  "ta": {
    "list_quotaion": "விலைக்கோரலின் பட்டியல்",
    "quoteListLblHospitals": "மருத்துவமனை",
    "quoteListLblModelHospitalTitle": "மருத்துவ கடிதம் மருத்துவமனை"
  }
}
</i18n>