<!-- New Proposal View -->
<template>
  <div>
    <Header />
    <LeftNav />
    <main id="page-wrap">
      <!-- <vue-snotify></vue-snotify> -->
     
        <ViewProposalPdf />
   
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/layout/AppHeader.vue";
import LeftNav from "@/components/common/layout/AppLeftNav";
import ViewProposalPdf from "@/components/proposal/ViewProposalPdf";
import Footer from "@/components/common/layout/AppFooter.vue";

export default {
  name: "ViewProposalPdfView",
  components: {
    Header,
    LeftNav,
    ViewProposalPdf,
    Footer
  }
};
</script>

<style scoped>
</style>