<!-- Main Life Riders List Component -->
<template>
  <div>
    <div style="margin-top:50px;">
      <div class="row">
        <div class="col-sm-3" v-for="(rider, index) in riderDataMain" :key="index" style="margin-bottom: 50px;">
          <div class="card-rider" border-variant="primary">
            <div class="card-header-rider-d text-center" v-bind:class="{ 'card-header-rider-a': rider.enabled }" @click="
              checkSelect($event, rider);
            sendChangedData();
            " id="checkboxarea">
              <input class="form-check-input checkboxez" type="checkbox" value :id="rider.code" v-model="rider.enabled"
                @change="sendChangedData()" />
              <!-- Rider Component Title (Cover Code) -->
              <span>
                <div class="lbl-value" :for="rider.code">{{ rider.code }}</div>
              </span>
            </div>
            <div class="card-body">
              <form>
                <!-- Rider Element 1 -->
                <div class="form-group">
                  <vue-numeric :disabled="rider.input.text.status == false || rider.enabled == false
                    " type="text" class="form-control" id="cover" v-model="rider.sum_assured"
                    @change.native="sendChangedData()" data-vv-as="Rider sum assured" separator=","
                    currency="Rs. "></vue-numeric>
                </div>
                <!-- Rider Element 2 -->
                <div class="form-group form-style">
                  <select class="form-control" v-model="rider.drp_val" :disabled="rider.input.dropdown.status == false ||
                    rider.enabled == false
                    " @change="sendChangedData()" data-vv-as="Rider option">
                    <option v-for="(option, index) in rider.input.dropdown.options" :key="index" :value="index"
                      :disabled="checkHbRiderOptionDisability(rider, option)">{{ option }}</option>
                  </select>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SnotifyPosition } from "vue-snotify";
import { throttle } from "lodash";

export default {
  computed: {
    ...mapState("quote_flexi_life", ["riderDataMain", "flash", "minflash", "calcSummary"]),
  },
  methods: {
    ...mapActions("quote_flexi_life", ["addChangedData"]),
    sendChangedData: throttle(async function () {
      this.$snotify.clear();
      /** Loading (HTML snotify component) */
      this.$snotify.html(
        `<div class="snotifyToast__title"><b>Calculating...</b></div>
            <div class="snotifyToast__body"> Mode Premium Amount</div>
            <div class="snotify-icon snotify-icon--async"></div> `,
        {
          timeout: stop,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          position: SnotifyPosition.centerTop,
          type: "async"
        }
      );
      return this.getChangeDataResponse()
        .then(
          function (response) {
            this.$snotify.clear();
            if (response.status && response.type == undefined) {
              /** Success Message  */
              this.$snotify.success("Mode Premium Amount", response.error, {
                timeout: stop,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop
              });
            } else {
              this.$snotify.clear();
              /** Error Message  */
              this.$snotify.error(response.error, "Error", {
                timeout: stop,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop
              });
            }
          }.bind(this)
        )
        .catch(function (error) {
          this.$snotify.clear();
          /** Exception Message */
          this.$snotify.error(error, "Error", {
            timeout: stop,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            position: SnotifyPosition.centerTop
          });
        });
    }, process.env.VUE_APP_QUOT_TIMEOUT_SEND_CHANGED_DATA),
    async getChangeDataResponse() {
      await this.addChangedData();
      if (this.minflash.status) {
        return this.minflash;
      } else {
        return this.flash;
      }
    },
    checkSelect: function (event, rider) {
      rider.enabled = rider.enabled ? false : true;
    },
    checkHbRiderOptionDisability: function (rider, option) {
      if (rider.code == 'HB') {
        let total_rider_prem_for_wi_excl_hb_wi = this.calcSummary ? this.calcSummary.total_rider_prem_for_wi_excl_hb_wi : 0;
        let drp_val = parseFloat(option.replace(/,/g, ''));
        let min_val = 0;

        if (total_rider_prem_for_wi_excl_hb_wi < 150000) {
          min_val = 100001;
        }
        else if (total_rider_prem_for_wi_excl_hb_wi < 200000) {
          min_val = 150001;
        }
        else if (total_rider_prem_for_wi_excl_hb_wi < 250000) {
          min_val = 200001;
        }
        else if (total_rider_prem_for_wi_excl_hb_wi < 300000) {
          min_val = 250001;
        }
        else {
          min_val = 300001;
        }

        if (min_val < drp_val) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }

    }
  }
};
</script>

<style scoped>
.text-center {
  text-align: center;
}

.border-primary {
  border-color: #007bff !important;
}

.card-rider {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  color: #fff;
  background-clip: border-box !important;
  border: 1px solid #92D127 !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0 !important;
}

.text-disabled {
  color: #000 !important;
}

.text-anabled {
  color: #fff !important;
}

.lbl-value {
  font-size: 12px !important;
  font-weight: 600;
}

.card-header-rider-d {
  padding: 0.5rem 1rem !important;
  margin-bottom: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-top: -18px !important;
  background: #c7c7c7;
  border-radius: 5px !important;
  cursor: pointer;
}

.card-header-rider-a {
  padding: 0.5rem 1rem !important;
  margin-bottom: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-top: -18px !important;
  background: #92D127;
  border-radius: 5px !important;
  cursor: pointer;
  color: #000;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem !important;
}

.form-style {
  margin-bottom: 0px !important;
}

.div.card-rider .card-header-rider {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.checkboxez {
  display: none;
}
</style>
