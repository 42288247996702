<!-- Insured Personal Details Widget-->
<template>
  <div class="col-sm-12 top-margin">
    <!-- Personal details row 1 Title, First Name, Mid Name, Last Name, DOB, Age -->
    <ValidationObserver ref="rowonetwo">
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <label for="lbl_insured_title">{{ $t("langInsTitle") }} <span class="required-mark">*</span></label>
            <ValidationProvider
              immediate
              name="Title"
              rules="required|genderValidation:@InGender"
            >
              <div slot-scope="{ errors }">
                <select
                  class="form-control"
                  name="lstInsuredTitle"
                  v-model="InfoInsured.title"
                  disabled
                >
                  <option value="">Select</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Miss.">Miss.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Ven.">Ven.</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Rev.">Rev.</option>
                </select>
                <p class="p-error">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <label for="lbl_insured_fname">{{ $t("langInsFName") }} <span class="required-mark">*</span></label>
            <ValidationProvider
              immediate
              name="First Name"
              rules="required|minmax:3,60|alpha_spaces"
            >
              <div slot-scope="{ errors }">
                <input
                  type="text"
                  class="form-control"
                  id="txtInsuredFName"
                  name="FirstName"
                  placeholder="First Name"
                  v-model.trim="InfoInsured.first_name"
                  v-uppercase
                  @change="sendChangedData()"
                />
                <p class="p-error">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="lbl_insured_mname">{{ $t("langInsMName") }}</label>
            <ValidationProvider
              immediate
              name="Middle Name"
              rules="minmax:3,60|alpha_spaces"
            >
              <div slot-scope="{ errors }">
                <input
                  type="text"
                  class="form-control"
                  id="txtInsuredMName"
                  name="MiddleName"
                  placeholder="Middle Name"
                  v-model.trim="InfoInsured.middle_name"
                  v-uppercase
                  @change="sendChangedData()"
                />
                <p class="p-error">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="lbl_insured_lname">{{ $t("langInsLName") }} <span class="required-mark">*</span></label>
            <ValidationProvider
              immediate
              name="Last Name"
              rules="required|minmax:3,60|alpha_spaces"
            >
              <div slot-scope="{ errors }">
                <input
                  type="text"
                  class="form-control"
                  id="txtInsuredLName"
                  name="LastName"
                  placeholder="Last Name"
                  v-model.trim="InfoInsured.last_name"
                  v-uppercase
                  @change="sendChangedData()"
                />
                <p class="p-error">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <!-- Personal details row 1 End -->

      <div class="row">
        <div class="col-sm-2">
          <div class="form-group">
            <label for="lbl_insured_dob">{{ $t("langInsDOB") }} <span class="required-mark">*</span></label>
            <ValidationProvider immediate name="Date of Birth" rules="required">
              <div slot-scope="{ errors }">
                <input
                  class="form-control"
                  data-vv-as="Date of birth"
                  id="txtInsuredDOB"
                  name="DateOfBirth"
                  placeholder="Date of Birth"
                  v-model="InfoInsured.dob"
                  type="Date"
                  readonly
                />
                <p class="p-error">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-sm-1">
          <div class="form-group">
            <label for="lbl_insured_age">{{ $t("langInsAge") }}</label>
            <input
              type="text"
              class="form-control"
              id="txtInsuredAge"
              name="Age"
              v-model="InfoInsured.age"
              readonly
            />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="lbl_insured_gender">{{ $t("langInsGender") }} <span class="required-mark">*</span></label>
            <ValidationProvider immediate name="InGender" rules="required">
              <div slot-scope="{ errors }">
                <div class="toggleswitchg">
                  <input
                    id="insured_male"
                    checked="checked"
                    name="chkInsGender"
                    v-model="InfoInsured.gender"
                    ref="chkInsGender"
                    type="radio"
                    value="Male"
                    disabled
                  />
                  <label for="insured_male">
                    <i class="fa fa-mars"></i> Male
                  </label>
                  <input
                    id="insured_female"
                    name="chkInsGender"
                    v-model="InfoInsured.gender"
                    ref="chkInsGender"
                    type="radio"
                    value="Female"
                    disabled
                  />
                  <label for="insured_female">
                    <i class="fa fa-venus"></i> Female
                  </label>
                </div>
                <p class="p-error">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="lbl_insured_civil_status">{{
              $t("langInsCivilStatus")
            }} <span class="required-mark">*</span></label>
            <div class="toggleswitchg">
              <input
                id="chk_married"
                checked="checked"
                name="chkInsCivilStatus"
                v-model="InfoInsured.civil_status"
                ref="chkInsCivilStatus"
                type="radio"
                value="Married"
                disabled
              />
              <label for="chk_married">
                <i class="fa fa-mars"></i> Married
              </label>
              <input
                id="chk_single"
                name="chkInsCivilStatus"
                v-model="InfoInsured.civil_status"
                ref="chkInsCivilStatus"
                type="radio"
                value="Unmarried"
                disabled
              />
              <label for="chk_single">
                <i class="fa fa-venus"></i> Unmarried
              </label>

              <input
                id="chk_divorced"
                name="chkInsCivilStatus"
                v-model="InfoInsured.civil_status"
                ref="chkInsCivilStatus"
                type="radio"
                value="Divorced"
                disabled
              />
              <label for="chk_single">
                <i class="fa fa-venus"></i> Divorced
              </label>
            </div>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <label for="lbl_insured_religion">{{
              $t("langInsReligion")
            }} <span class="required-mark">*</span></label>
            <ValidationProvider
              name="Religion"
              rules="required|minmax:3,20"
            >
              <div slot-scope="{ errors }">
                <!-- <input
                  type="text"
                  class="form-control"
                  id="txtInsReligion"
                  name="Religion"
                  placeholder="Religion"
                  v-model.trim="InfoInsured.religion"
                  v-uppercase
                  @change="sendChangedData()"
                /> -->

                <v-select
                  class="select2"
                  style="width: 100%;"
                  v-model="InfoInsured.religion"
                  id="txtInsReligion"
                  name="Religion"
                  :options="religionsDrpOpt"
                  :reduce="(val) => val.code"
                  @change="sendChangedData()"
                ></v-select>

                <p class="p-error">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <!-- Personal details row 2 End -->
    </ValidationObserver>
    <div class="row">
      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_insured_nic">{{ $t("langInsNIC") }} <span class="required-mark">*</span></label>
          <keep-alive>
            <ValidationObserver ref="colnic">
              <ValidationProvider
                immediate
                name="NIC Number"
                :rules="{
                  required: true,
                  regex: /^(\d{9}?[V|X]{1}|\d{12})$/i
                }"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="txtInsNIC"
                    name="NIC"
                    placeholder="NIC Number"
                    v-model.trim="InfoInsured.nic"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </ValidationObserver>
          </keep-alive>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_insured_add_line_one">{{
            $t("langInsAddLineOne")
          }} <span class="required-mark">*</span></label>
          <ValidationProvider
            name="Address Line 1"
            :rules="{ required: true, min:3,max:30, regex: /^[a-z0-9\s,'-/]*$/i }"
          >
            <div slot-scope="{ errors }">
              <input
                type="text"
                class="form-control"
                id="txtInsAddLineOne"
                name="Address Line 1"
                placeholder="Address Line 1"
                v-model.trim="InfoInsured.add_line_1"
                v-uppercase
                @change="sendChangedData()"
              />
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_insured_add_line_two">{{
            $t("langInsAddLineTwo")
          }} <span class="required-mark">*</span></label>
          <ValidationProvider
            name="Address Line 2"
            :rules="{ required: true, min:3,max:60, regex: /^[a-z0-9\s,'-/]*$/i }"
          >
            <div slot-scope="{ errors }">
              <input
                type="text"
                class="form-control"
                id="txtInsAddLineTwo"
                name="Address Line 2"
                placeholder="Address Line 2"
                v-model.trim="InfoInsured.add_line_2"
                v-uppercase
                @change="sendChangedData()"
              />
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_insured_city">{{ $t("langInsCity") }} <span class="required-mark">*</span></label>
          <ValidationProvider
            name="City"
            :rules="{ required: true, min:3,max:60, regex: /^[a-z\d\s]+$/i }"
          >
            <div slot-scope="{ errors }">
              <input
                type="text"
                class="form-control"
                id="txtInsCity"
                placeholder="City"
                name="City"
                v-model.trim="InfoInsured.city"
                v-uppercase
                @change="sendChangedData()"
              />
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <!-- Personal details row 3 End -->

    <div class="row">
      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_insured_mobile">{{ $t("langInsMobile") }}<span class="required-mark">*</span></label>
          <ValidationProvider
            name="Mobile No"
            :rules="{ required: true, regex: /^(\+[1-9]{1}[0-9]{3,14})?([0-9]{7,14})$/ }"
          >
            <div slot-scope="{ errors }">
              <input
                type="text"
                class="form-control"
                id="txtInsMobile"
                name="MobileNo"
                placeholder="Mobile No"
                v-model.trim="InfoInsured.mobile_no"
                @change="sendChangedData()"
              />
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_residence">{{ $t("langInsResidenceNo") }}</label>
          <ValidationProvider
            name="Residence Contact No"
            :rules="{ length: 10, regex: /^[\d]+$/i }"
          >
            <div slot-scope="{ errors }">
              <input
                type="text"
                class="form-control"
                id="txtInsResidence"
                placeholder="Residence Contact No"
                name="Residence"
                v-model.trim="InfoInsured.residence_no"
                @change="sendChangedData()"
              />
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_insured_email">{{ $t("langInsEmail") }}</label>
          <ValidationProvider name="E-mail" rules="email|max:100">
            <div slot-scope="{ errors }">
              <input
                type="Email"
                class="form-control"
                id="txtInsEmail"
                placeholder="Email"
                name="Email"
                v-model.trim="InfoInsured.email"
                v-lowercase
                @change="sendChangedData()"
              />
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_ins_citizenship">{{ $t("langInsCitizenship") }} <span class="required-mark">*</span></label>
          <ValidationProvider name="Citizenship" rules="required|minmax:2,60">
            <div slot-scope="{ errors }">
              <!-- <input
                type="text"
                class="form-control"
                id="txtInsCitizenship"
                placeholder="Citizenship"
                name="Citizenship"
                v-model.trim="InfoInsured.citizenship"
                v-uppercase
                @change="sendChangedData()"
              /> -->
              <v-select
                class="select2"
                style="width: 100%;"
                v-model="InfoInsured.citizenship"
                name="Citizenship"
                :options="nationalityDrpOpt"
                :reduce="(val) => val.code"
                @change="sendChangedData()"
              ></v-select>
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <!-- Personal details row 4 End -->

    <div class="row">
      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_public_position">{{
            $t("langInsPublicPositionHeld")
          }} <span class="required-mark">*</span></label>
          <ValidationProvider
            name="Details of Any Public Positions Held"
            rules="required|minmax:3,100|alpha_spaces"
          >
            <div slot-scope="{ errors }">
              <input
                type="text"
                class="form-control"
                id="txtPublicPosition"
                placeholder="Details of Any Public Positions Held"
                name="PublicPositionHeld"
                v-model.trim="InfoInsured.public_position_held"
                v-uppercase
                @change="sendChangedData()"
              />
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_ins_contribution_paid_by">{{
            $t("langInsContributionPaidBy")
          }} <span class="required-mark">*</span></label>
          <ValidationProvider
            name="Contribution Paid By"
            rules="required|minmax:3,60"
          >
            <div slot-scope="{ errors }">
              <!-- <input
                type="text"
                class="form-control"
                id="txtOtherCountry"
                placeholder="Contribution Paid By"
                name="ContributionPaidBy"
                v-model="InfoInsured.contribution_paid_by"
                v-uppercase
                @change="sendChangedData()"
              /> -->

              <v-select
                class="select2"
                style="width: 100%;"
                v-model="InfoInsured.contribution_paid_by"
                name="ContributionPaidBy"
                :options="InfoInsured.has_spouse == 'Yes' ? contributionPaidByDrpOpt : contributionPaidByDrpOptWithoutSpouse"
                :reduce="(val) => val.code"
                @change="sendChangedData()"
              ></v-select>
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>

          <ValidationProvider
            name="Contribution Paid By Explanation" 
            :rules="InfoInsured.contribution_paid_by == 'OTHER'
                              ? { required : true , min: 5, max: 50}
                              : { }
                          "
            v-if="InfoInsured.contribution_paid_by == 'OTHER'"
          >
            <div slot-scope="{ errors }">
              <textarea class="form-control" placeholder="Please explain more details here.." v-model="InfoInsured.cpb_explanation"></textarea>
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <!-- Personal details row 5 End -->
    <hr />
    <!-- Personal details row 6 Start -->
    <div class="row">
      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_ins_occupation">{{ $t("langInsOccupation") }}</label>
          <input
            type="text"
            class="form-control"
            id="txtInsOccupation"
            placeholder="Occupation"
            name="Occupation"
            v-model.trim="InfoInsured.occupation"
            readonly
          />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_ins_name_employee">{{
            $t("langInsEmployerName")
          }} 
          <!-- <span class="required-mark">*</span> -->
          <span v-if="InfoInsured.contribution_paid_by == 'SELF'" class="required-mark">*</span>
        </label>
          <!-- <ValidationProvider name="Employer Name" rules="required|alpha_spaces|minmax:3,60"> -->
            <ValidationProvider name="Employer Name"
              :rules="
                InfoInsured.contribution_paid_by == 'SELF'
                  ? 'required|alpha_spaces|minmax:3,60'
                  : 'alpha_spaces|minmax:3,60'
              "
            >
            
            <div slot-scope="{ errors }">
              <input
                type="text"
                class="form-control"
                id="txtInsEmployerName"
                placeholder="Employer Name"
                name="txtInsEmployerName"
                v-model.trim="InfoInsured.employer_name"
                v-uppercase
                @change="sendChangedData()"
              />
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_ins_emp_add_line_one">{{
            $t("langInsAddLineOne")
          }} 
          <!-- <span class="required-mark">*</span> -->
          <span v-if="InfoInsured.contribution_paid_by == 'SELF'" class="required-mark">*</span>
        </label>
          <!-- :rules="{ required: true, min:1,max:30, regex: /^[a-z0-9\s,'-/]*$/i }" -->
          <ValidationProvider
            name="Employer Address Line 1"
            :rules="
              InfoInsured.contribution_paid_by == 'SELF'
                ? { required: true, min:3,max:60, regex: /^[a-z0-9\s,'-/]*$/i }
                : { min:3,max:60, regex: /^[a-z0-9\s,'-/]*$/i }
            "
          >
            <div slot-scope="{ errors }">
              <input
                type="text"
                class="form-control"
                id="txtInsEmpAddressLine1"
                placeholder="Address Line 1"
                name="txtInsEmpAddressLine1"
                v-model.trim="InfoInsured.emp_address_1"
                v-uppercase
                @change="sendChangedData()"
              />
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_ins_emp_add_line_two">{{
            $t("langInsAddLineTwo")
          }} 
          <!-- <span class="required-mark">*</span> -->
          <span v-if="InfoInsured.contribution_paid_by == 'SELF'" class="required-mark">*</span>
        </label>
          <!-- :rules="{ required: true, min:3,max:60, regex: /^[a-z0-9\s,'-/]*$/i }" -->
          <ValidationProvider
            name="Employer Address Line 2"
            :rules="
              InfoInsured.contribution_paid_by == 'SELF'
                ? { required: true, min:3,max:60, regex: /^[a-z0-9\s,'-/]*$/i }
                : { min:3,max:60, regex: /^[a-z0-9\s,'-/]*$/i }
            "
          >
            <div slot-scope="{ errors }">
              <input
                type="text"
                class="form-control"
                id="txtInsEmpAddressLine2"
                placeholder="Address Line 2"
                name="txtInsEmpAddressLine2"
                v-model.trim="InfoInsured.emp_address_2"
                v-uppercase
                @change="sendChangedData()"
              />
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <!-- Personal details row 5 End -->

    <div class="row">
      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_emp_city">{{ $t("langInsCity") }} <span class="required-mark" v-if="InfoInsured.contribution_paid_by == 'SELF'">*</span></label>
          
          <!-- :rules="{ required: true, min:3,max:60, regex: /^[a-z\d\s]+$/i }" -->
          <ValidationProvider
            name="Employer City"
            :rules="
              InfoInsured.contribution_paid_by == 'SELF'
                ? { required: true, min:3,max:60, regex: /^[a-z\d\s]+$/i }
                : { min:3,max:60, regex: /^[a-z\d\s]+$/i }
            "
          >
            <div slot-scope="{ errors }">
              <input
                type="text"
                class="form-control"
                id="txtInsEmpCity"
                placeholder="City"
                name="txtInsEmpCity"
                v-model.trim="InfoInsured.emp_city"
                v-uppercase
                @change="sendChangedData()"
              />
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_emp_country">{{ $t("langInsEmpCountry") }} <span v-if="InfoInsured.contribution_paid_by == 'SELF'" class="required-mark">*</span></label>
          <!-- rules="required|alpha_spaces|minmax:3,60" -->
          <ValidationProvider name="Employer Country"
            :rules="
              InfoInsured.contribution_paid_by == 'SELF'
                ? 'required|alpha_spaces|minmax:3,60'
                : 'alpha_spaces|minmax:3,60'
            "
          >
            <div slot-scope="{ errors }">
              <input
                type="text"
                class="form-control"
                id="txtInsEmpCountry"
                placeholder="Country"
                name="txtInsEmpCountry"
                v-model.trim="InfoInsured.emp_country"
                v-uppercase
                @change="sendChangedData()"
              />
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label for="lbl_emp_contact">{{ $t("langInsEmpContactNo") }}</label>
          <ValidationProvider
            name="Employer Contact No"
            :rules="{ length: 10, regex: /^[\d]+$/i }"
          >
            <div slot-scope="{ errors }">
              <input
                type="text"
                class="form-control"
                id="txtInsEmpContact"
                placeholder="Employer Contact Number"
                name="txtInsEmpContact"
                v-model.trim="InfoInsured.emp_contact_no"
                @change="sendChangedData()"
              />
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-sm-3">
        <!-- rules="required|numeric|minmax_value:10000,5000000" -->
        <div class="form-group">
          <label for="lbl_spouse_monthly_income">{{
            $t("langInsMonthlyIncome")
          }} <span class="required-mark" v-if="InfoInsured.contribution_paid_by != 'SPOUSE'">*</span></label>
          <ValidationProvider
            name="Insured Monthly Income"
            :rules="InfoInsured.contribution_paid_by != 'SPOUSE'
                              ? { required : true , min_value: 10000, max_value: 10000000}
                              : { min_value: 0, max_value: 10000000 }
                          "
          >
            <div slot-scope="{ errors }">
              <input
                type="text"
                class="form-control"
                id="txtInsMonthlyIncome"
                placeholder="Monthly Income"
                name="txtInsMonthlyIncome"
                v-model.trim="InfoInsured.monthly_income"
                @change="sendChangedData()"
              />
              <p class="p-error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <!-- Personal details row 6 End -->
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label for="lbl_ins_nature_of_duties">{{
            $t("langInsNatOfDuties")
          }}</label> <span class="required-mark">*</span>
         <ValidationProvider name="Nature of Duties" rules="required|alpha_spaces|minmax:3,60">
            <div slot-scope="{ errors }">
              <b-form-textarea
                id="txtInsNatOfDuties"
                class="text-uppercase"
                rows="3"
                placeholder="Nature of Duties"
                name="txtInsNatOfDuties"
                v-model.trim="InfoInsured.nature_of_duties"
                @change="sendChangedData()"
              ></b-form-textarea>
               <p class="p-error">{{ errors[0] }}</p>
            </div>
           </ValidationProvider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { throttle } from "lodash";
import helpMixin from "@/mixins/filters";

export default {
  name: "personal-details-insured-widget",
  data() {
    return {};
  },
  async created() {
    await this.clearData();
  },
  computed: {
    ...mapState("proposal", ["InfoInsured" , "religionsDrpOpt" , "nationalityDrpOpt" , "contributionPaidByDrpOpt" , "contributionPaidByDrpOptWithoutSpouse"])
  },
  mixins: [helpMixin],
  methods: {
    ...mapActions("proposal", ["clearStateData", "draftProposalData"]),
    sendChangedData: throttle(async function() {
      const valid12 = await this.$refs.rowonetwo.validate();
      const validNIC = await this.$refs.colnic.validate();

      //this.$refs.rowonetwo.validate().then(valid => {
      if (valid12 && validNIC) {
        this.callDraftProposalData();
      }
      // });
    }, process.env.VUE_APP_TIMEOUT_SEND_CHANGED_DATA),
    async clearData() {
      await this.clearStateData();
    },
    async callDraftProposalData() {
      await this.draftProposalData();
    }
  },
  mounted() {
    this.sendChangedData();
  }
};
</script>

<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

/* -- togle switch ---*/

.toggleswitchg input:checked + label {
  background: #e9ecef !important;
  color: #575e65 !important;
  font-weight: 400 !important;
  border: 1px solid #ced4da !important;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked + label {
  background: #039fec;
  color: #fff;
  font-weight: 600;
  border: 1px solid #039fec;
}
</style>

<!-- Language Data for Personal Details Insured Widget -->
<i18n>
{
  "en": {    
    "langInsTitle": "Title",
    "langInsFName": "First Name",
    "langInsMName": "Middle Name",
    "langInsLName": "Last Name",
    "langInsDOB": "Date of Birth (MM/DD/YYYY)",
    "langInsAge": "Age",
    
    "langInsGender": "Gender",
    "langInsCivilStatus": "Civil Status",
    "langInsReligion": "Religion",
    "langInsNIC": "NIC No",
    "langInsMobile": "Mobile No",
    "langInsResidenceNo": "Residence Phone No",
    "langInsEmail": "Email",
    "langInsAddLineOne": "Address Line 1",
    "langInsAddLineTwo": "Address Line 2",

    "langInsCity": "City",
    "langInsCitizenship": "Nationality/Citizenship",
    "langInsPublicPositionHeld": "Details of Any Public Positions Held",
    "langInsContributionPaidBy": "Contribution Paid By",
    
    "langInsMonthlyIncome": "Monthly Income",
    "langInsOccupation": "Occupation",
    "langInsNatOfDuties": "Nature of Duties",
    "langInsEmployerName": "Employer Name",
    "langInsEmpCountry": "Employer Country",
    "langInsEmpContactNo": "Employer Contact No"

  },
  "si": {
    "langInsTitle": "ශීර්ෂය",
    "langInsFName": "මුල් නම",
    "langInsMName": "මැද නම",
    "langInsLName": "අවසන් නම",
    "langInsDOB": "උපන් දිනය (MM/DD/YYYY)",
    "langInsAge": "වයස",

    "langInsGender": "ස්ත්‍රී පුරුෂ භාවය",
    "langInsCivilStatus": "විවාහක/අවිවාහක තත්වය",
    "langInsReligion": "ආගම",
    "langInsNIC": "ජා.හැ.අංකය",
    "langInsMobile": "ජංගම දූරකථන අංකය",
    "langInsResidenceNo": "පදිංචි දුරකථන අංකය",
    "langInsEmail": "විද්‍යුත් තැපෑල",
    "langInsAddLineOne": "ලිපිනය තීරුව 1",
    "langInsAddLineTwo": "ලිපිනය තීරුව 2",

    "langInsCity": "නගරය",
    "langInsCitizenship": "පුරවැසිභාවය",
    "langInsPublicPositionHeld": "පවත්වනු ලබන ඕනෑම රාජ්‍ය තනතුරක විස්තර",
    "langInsContributionPaidBy": "දායකයා",

    "langInsMonthlyIncome": "මාසික ආදායම",
    "langInsOccupation": "රැකියාව",
    "langInsNatOfDuties": "රාජකාරි වල ස්වභාවය",
    "langInsEmployerName": "සේවා යෝජකයාගේ නම",
    "langInsEmpCountry": "සේවා යෝජකයාගේ රට",
    "langInsEmpContactNo": "සේවා යෝජකයාගේ දුරකථන අංකය"
  },
  "ta": {
    "langInsTitle": "படிநிலை",
    "langInsFName": "முதற் பெயர்",
    "langInsMName": "நடுத்தர பெயர்",
    "langInsLName": "கடைசி பெயர்",
    "langInsDOB": "பிறந்த திகதி (MM/DD/YYYY)",
    "langInsAge": "வயது",

    "langInsGender": "பால்",
    "langInsCivilStatus": "திருமணம்/திருமணமாகாத",
    "langInsReligion": "மதம்",
    "langInsNIC": "தே. அ. அ. இலக்கம்",
    "langInsMobile": "மொபைல் எண்",
    "langInsResidenceNo": "குடியிருப்பு தொலைபேசி எண்",
    "langInsEmail": "மின்னஞ்சல்",
    "langInsAddLineOne": "முகவரி வரி 1",
    "langInsAddLineTwo": "முகவரி வரி 2",

    "langInsCity": "நகரம்",
    "langInsCitizenship": "குடியுரிமை",
    "langInsPublicPositionHeld": "நடைபெற்ற எந்த பொது நிலையின் விவரங்களும்",
    "langInsContributionPaidBy": "பங்களிப்பாளர்",

    "langInsMonthlyIncome": "மாத வருமானம்",
    "langInsOccupation": "தொழில்",
    "langInsNatOfDuties": "கடமைகளின் தன்மை",
    "langInsEmployerName": "முதலாளியின் பெயர்",
    "langInsEmpCountry": "முதலாளி நாடு",
    "langInsEmpContactNo": "முதலாளி தொலைபேசி எண்"
  }
}
</i18n>
