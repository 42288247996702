<!-- Quotation Main Life Information Capture Widget  -->
<template>
  <div class="row row-top">
    <div class="col-sm-12 top-margin">
      <div class="card">
        <div class="card-header header-font">
          <font-awesome-icon icon="heartbeat" />
          <span>&nbsp;{{ $t("mainLifeHeaderMsg") }}</span>
          <span class="fa-pull-right" v-if="quotationNo != ''">
            {{
            "Quote No: " + quotationNo + " V-" + version
            }}
          </span>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_title">{{ $t("mainLifeLblTitle") }}</label>
                <ValidationProvider name="Title" rules="required|genderValidation:@InGender">
                  <div slot-scope="{ errors }">
                    <select
                      class="form-control"
                      v-model="mainInfoData.drpMainLifetitle"
                      name="Title"
                    >
                      <option>Please select</option>
                      <option>Mr.</option>
                      <option>Mrs.</option>
                      <option>Miss.</option>
                      <option>Ms.</option>
                      <option>Ven.</option>
                      <option>Dr.</option>
                      <option>Rev.</option>
                    </select>
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_first_name">
                  {{
                  $t("mainLifeLblFirstName")
                  }}
                </label>
                <ValidationProvider name="First Name" rules="required|minmax:3,60|alpha_spaces">
                  <div slot-scope="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="txtMainLifeFname"
                      name="FirstName"
                      placeholder="First Name"
                      v-model="mainInfoData.txtMainLifeFname"
                      v-uppercase
                    />
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_mid_name">
                  {{
                  $t("mainLifeLblMidName")
                  }}
                </label>
                <ValidationProvider name="Middle Name" rules="minmax:3,60|alpha_spaces">
                  <div slot-scope="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="txtMainLifeMname"
                      name="MiddleName"
                      placeholder="Middle Name"
                      v-model="mainInfoData.txtMainLifeMname"
                      v-uppercase
                    />
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_last_name">
                  {{
                  $t("mainLifeLblLastName")
                  }}
                </label>
                <ValidationProvider name="Last Name" rules="required|minmax:3,60|alpha_spaces">
                  <div slot-scope="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="txtMainLifeLname"
                      name="LastName"
                      placeholder="Last Name"
                      v-model="mainInfoData.txtMainLifeLname"
                      v-uppercase
                    />
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_nic">{{ $t("mainLifeLblNICNo") }}</label>
                <ValidationProvider
                  name="NIC Number"
                  :rules="{
                    required: true,
                    regex: /^(\d{9}?[V|X]{1}|\d{12})$/i,
                  }"
                >
                  <div slot-scope="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="txtMainLifeNIC"
                      name="NIC"
                      placeholder="NIC Number"
                      v-model.trim="mainInfoData.txtMainLifeNIC"
                      v-uppercase
                    />
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_mobile">
                  {{
                  $t("mainLifeLblMobNo")
                  }}
                </label>
                <ValidationProvider
                  name="Mobile No"
                  :rules="{ required: true, regex: /^(\+[1-9]{1}[0-9]{3,14})?([0-9]{7,14})$/ }"
                >
                  <div slot-scope="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="txtMainLifeMob"
                      name="MobileNumber"
                      placeholder="Mobile Number"
                      v-model="mainInfoData.txtMainLifeMob"
                    />
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_email">{{ $t("mainLifeLblEmail") }}</label>
                <ValidationProvider name="E-mail" rules="email">
                  <div slot-scope="{ errors }">
                    <input
                      type="Email"
                      class="form-control"
                      id="txtMainLifeEmail"
                      placeholder="Email"
                      name="Email"
                      v-model.trim="mainInfoData.txtMainLifeEmail"
                      v-lowercase
                    />
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="lbl_main_gender">
                  {{
                  $t("mainLifeLblGender")
                  }}
                </label>
                <ValidationProvider name="InGender" rules="required">
                  <div slot-scope="{ errors }">
                    <div class="toggleswitchg">
                      <input
                        id="INSMale"
                        checked="checked"
                        name="INSGender"
                        ref="rdbMainGender"
                        type="radio"
                        value="Male"
                        v-model="mainInfoData.rdbMainGender"
                        @change="sendChangedData()"
                      />
                      <label for="INSMale">
                        <i class="fa fa-mars"></i> Male
                      </label>
                      <input
                        id="INSFemale"
                        name="INSGender"
                        ref="rdbMainGender"
                        type="radio"
                        value="Female"
                        v-model="mainInfoData.rdbMainGender"
                        @change="sendChangedData()"
                      />
                      <label for="INSFemale">
                        <i class="fa fa-venus"></i> Female
                      </label>
                    </div>
                    <p class="p-error">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <ValidationObserver ref="insured">
            <div class="row">
              <div class="col-sm-2">
                <div class="form-group">
                  <label for="lbl_main_dob">{{ $t("mainLifeLblDOB") }}</label>
                  <ValidationProvider name="Date of Birth" rules="required">
                    <div slot-scope="{ errors }">
                      <input
                        @blur="
                          ageCalc();
                          sendChangedData();
                        "
                        class="form-control"
                        id="txtMainLifeDOB"
                        name="DateOfBirth"
                        placeholder="Date of Birth"
                        type="Date"
                        v-model="mainInfoData.txtMainLifeDOB"
                        :max="timestamp"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-1">
                <div class="form-group">
                  <label for="lbl_main_age">{{ $t("mainLifeLblAge") }}</label>
                  <ValidationProvider name="Age" rules="required|max_value:65">
                    <div slot-scope="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        id="txtMainLifeAge"
                        name="txtMainLifeAge"
                        readonly
                        v-model="mainInfoData.txtMainLifeAge"
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_occupation">
                    {{
                    $t("mainLifeLblOccupation")
                    }}
                  </label>
                  <ValidationProvider name="Occupation" rules="required">
                    <div slot-scope="{ errors }">
                      <!-- <v-select
                        class="select2"
                        style="width: 100%;"
                        v-model="mainInfoData.drpMainLifeOccupation"
                        name="Occupation"
                        :options="occupationList"
                      ></v-select> -->
                      <input
                        type="text"
                        class="form-control"
                        name="Occupation"
                        placeholder="Occupation"
                        v-model="mainInfoData.drpMainLifeOccupation"
                        v-uppercase
                      />
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="lbl_main_prim_need">
                    {{
                    $t("mainLifeLblPrimNeed")
                    }}
                  </label>
                  <ValidationProvider name="Primary Need" rules="required">
                    <div slot-scope="{ errors }">
                      <select
                        class="form-control"
                        v-model="mainInfoData.drpMainLifePrimaryNeed"
                        @change="sendChangedData()"
                        name="PrimaryNeed"
                      >
                        <option disabled>Please select</option>
                        <option>Protection</option>
                        <option>Savings</option>
                        <option
                          :disabled="
                            mainInfoData.txtMainLifeAge +
                              mainInfoData.txtMainLifePolicyTerm <
                              55
                          "
                        >Retirement</option>
                        <option>Health</option>
                      </select>
                      <p class="p-error">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SnotifyPosition } from "vue-snotify";
import { throttle } from "lodash";

export default {
  data() {
    return {
      
      form: {
        sumCover: 0
      }
    };
  },
  async created() {

  
    if (this.$route.params.ref_no) {
      await this.getOccupationList();
      await this.loadQuotation();
      await this.ageCalc();
    } else {
      await this.clearData();
      this.ageCalc();
      this.getOccupationList();
      this.getRidersListView();
    }
  },
  watch: {
    "mainInfoData.drpMainLifeOccupation": {
      handler: function() {
        this.sendChangedData();
      },
      immediate: false
    }
  },
  mounted() {
          if(this.$route.params.gender != undefined){
          this.mainInfoData.rdbMainGender = this.$route.params.gender ;      
      }      
      if(this.$route.params.fname != undefined){
         this.mainInfoData.txtMainLifeFname = this.$route.params.fname ;
      }
      if(this.$route.params.mname != undefined){
        this.mainInfoData.txtMainLifeMname = this.$route.params.mname ;
      }
      if(this.$route.params.lname != undefined){
         this.mainInfoData.txtMainLifeLname = this.$route.params.lname ;
      }
      if(this.$route.params.nic != undefined){
            this.mainInfoData.txtMainLifeNIC = this.$route.params.nic ;    
      }
      if(this.$route.params.contact != undefined){
         this.mainInfoData.txtMainLifeMob = this.$route.params.contact ;
      }
      if(this.$route.params.email != undefined){
          this.mainInfoData.txtMainLifeEmail = this.$route.params.email ;
      }
      if(this.$route.params.title != undefined){
           this.mainInfoData.drpMainLifetitle = this.$route.params.title ;    
      }
      if(this.$route.params.dob != undefined){
          this.mainInfoData.txtMainLifeDOB = this.$route.params.dob ;      
      }
  },
  computed: {
    ...mapState("quote_flexi_life", [
      "mainInfoData",
      "occupationList",
      "flash",
      "minflash",
      "quotationNo",
      "version",
      "timestamp"
    ]),

      
  },
  methods: {
    ...mapActions("quote_flexi_life", [
      "addChangedData",
      "getOneQuotation",
      "getRidersList",
      "clearStateData",
      "getOccupationList",
      "getAge"
    ]),

  
    sendChangedData: throttle(async function() {      

  // alert(this.mainInfoData.txtMainLifeDOB);
      this.$refs.insured.validate().then(valid => {
        if (valid) {
          this.$snotify.clear();
         
          /** Loading (HTML snotify component) */
          this.$snotify.html(
            `<div class="snotifyToast__title"><b>Calculating...</b></div>
            <div class="snotifyToast__body"> Mode Premium Amount</div>
            <div class="snotify-icon snotify-icon--async"></div> `,
            {
              timeout: stop,
              showProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              position: SnotifyPosition.centerTop,
              type: "async"
            }
          );
          return this.getChangeDataResponse()
            .then(
              function(response) {
                this.$snotify.clear();
                if (response.status && response.type == undefined) {
                  /** Success Message  */
                  this.$snotify.success("Mode Premium Amount", response.error, {
                    timeout: stop,
                    closeOnClick: true,
                    pauseOnHover: false,
                    position: SnotifyPosition.centerTop
                  });
                } else {
                  this.$snotify.clear();
                  /** Error Message  */
                  this.$snotify.error(response.error, "Error", {
                    timeout: stop,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    position: SnotifyPosition.centerTop
                  });
                }
              }.bind(this)
            )
            .catch(function(error) {
              this.$snotify.clear();
              /** Exception Message */
              this.$snotify.error(error, "Error", {
                timeout: stop,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                position: SnotifyPosition.centerTop
              });
            });
        }
      });
    }, process.env.VUE_APP_QUOT_TIMEOUT_SEND_CHANGED_DATA),
    async ageCalc() {
      let payload = {
        dob: this.mainInfoData.txtMainLifeDOB,
        type: "main"
      };
      await this.getAge(payload);
    },
    async loadQuotation() {
      await this.getOneQuotation(this.$route.params.ref_no);
    },
    async getRidersListView() {
      await this.getRidersList();
    },
    async clearData() {
      await this.clearStateData();
    },
    async getChangeDataResponse() {
      await this.addChangedData();
      if (this.minflash.status) {
        return this.minflash;
      } else {
        return this.flash;
      }
    }
  }
};
</script>
<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #556065 !important;
  border-bottom: 0px solid #06a990 !important;
  color: #fff !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #06a990 !important;
  border-radius: 0.25rem;
}

.snotify {
  display: block;
  position: fixed;
  width: 99% !important;
  z-index: 9999;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: none;
}
/*-- toggle switch for Main Life Frequency ----*/

.togglefreq label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.togglefreq input:checked,
.togglefreq input:not(:checked) {
  display: none;
}

.togglefreq input:checked + label {
  background: #92D127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92D127;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked + label {
  background: #92D127;
  color: #fff;
  font-weight: 600;
  border: 1px solid #92D127;
}

@media (max-width: 768px) {
  .top-margin {
    margin-top: 60px;
  }
}
</style>

<!-- Language Data for MainLifeInfoWidget-->
<i18n>
{
  "en": {
    "mainLifeHeaderMsg": "Main Life Personal Information",
    "mainLifeLblTitle": "Title",
    "mainLifeLblFirstName": "First Name",
    "mainLifeLblMidName": "Middle Name",
    "mainLifeLblLastName": "Last Name",
    "mainLifeLblNICNo": "NIC No",
    "mainLifeLblMobNo": "Mobile No",
    "mainLifeLblTelNo": "Telephone No",
    "mainLifeLblEmail": "Email",
    "mainLifeLblOccupation": "Occupation",
    "mainLifeLblGender": "Gender",
    "mainLifeLblDOB": "Date of Birth (MM/DD/YYYY)",
    "mainLifeLblAge": "Age",
    "mainLifeLblSmoking": "Smoking",
    "mainLifeLblPrimNeed": "Primary Need",
    "mainLifeLblMonthBasicPrem": "Mode Basic Premium",
    "mainLifeLblSingleBasicPrem": "Single Premium",
    "mainLifeLblPolicyTerm": "Policy Term",
    "mainLifeLblPremTerm": "Premium Paying Term",
    "mainLifeLblPolicyType": "Policy Paying Type",
    "mainLifeLblFrequency": "Frequency",
    "mainLblSumAtRiskPrevPol": "Previous Sum at Risk",
  "mainLblNumOfSumAsTimes": "No of Sum Assured Times",
  "mainLblMED-REM3Floater": "Family Floater"
  },
  "si": {
    "mainLifeHeaderMsg": "ජීවිත රක්ෂිතයාගේ පෞද්ගලික තොරතුරු",
    "mainLifeLblTitle": "ශීර්ෂය",
    "mainLifeLblFirstName": "මුල් නම",
    "mainLifeLblMidName": "මැද නම",
    "mainLifeLblLastName": "අවසන් නම",
    "mainLifeLblNICNo": "ජා.හැ.අංකය",
    "mainLifeLblMobNo": "ජංගම දුරකථන අංකය",
    "mainLifeLblTelNo": "දුරකථන අංකය",
    "mainLifeLblEmail": "විද්‍යුත් තැපෑල",
    "mainLifeLblOccupation": "රැකියාව",
    "mainLifeLblGender": "ස්ත්‍රී පුරුෂ භාවය",
    "mainLifeLblDOB": "උපන් දිනය (MM/DD/YYYY)",
    "mainLifeLblAge": "වයස",
    "mainLifeLblSmoking": "දුම්පානය කරනව",
    "mainLifeLblPrimNeed": "මුලික අවශ්‍යයතාවය",
    "mainLifeLblMonthBasicPrem": "මුලික රක්ෂිත වාර මුදල",
  "mainLifeLblSingleBasicPrem": "තනි වාරික",
  "mainLifeLblPolicyTerm": "රක්ෂණ ඔප්පු කාල සීමාව",
    "mainLifeLblPremTerm": "වාරික මුදල් ගෙවන කාල සීමාව",
    "mainLifeLblPolicyType": "ප්‍රතිපත්ති වර්ගය",
    "mainLifeLblFrequency": "වාර ගනන",
    "mainLblSumAtRiskPrevPol": "දැනට පවතින රක්ෂණ ඔප්පු වල සම්පූර්ණ අවධානම",
    "mainLblNumOfSumAsTimes": "මුලික රක්ෂිත මුදල මෙන්",
  "validation.MobileNumber.digits": "ඇෂුවරන්ඩ් ටයිම්ස් ගණන",
  "mainLblMED-REM3Floater": "Family Floater",
  "mainLifeLblSumCover" : "Sum Cover"
  },
  "ta": {
    "mainLifeHeaderMsg": "பிரதான காப்புறுதிதாரரின் தனிப்பட்ட விபரங்கள்",
    "mainLifeLblTitle": "படிநிலை",
    "mainLifeLblFirstName": "முதற் பெயர்",
    "mainLifeLblMidName": "நடுத்தர பெயர்",
    "mainLifeLblLastName": "கடைசி பெயர்",
    "mainLifeLblNICNo": "தே. அ. அ. இலக்கம்",
    "mainLifeLblMobNo": "தொலைபேசி இலக்கம்",
    "mainLifeLblTelNo": "தொலைபேசி இலக்கம்",
    "mainLifeLblEmail": "மின்னஞ்சல்",
    "mainLifeLblOccupation": "தொழில்",
    "mainLifeLblGender": "பால்",
    "mainLifeLblDOB": "பிறந்த திகதி (MM/DD/YYYY)",
    "mainLifeLblAge": "வயது",
    "mainLifeLblSmoking": "Smoking",
    "mainLifeLblPrimNeed": "முதன்மை தேவை",
    "mainLifeLblMonthBasicPrem": "மாதாந்த அடிப்படை கட்டுப்பணம்",
  "mainLifeLblSingleBasicPrem": "ஒற்றை கட்டுப்பணம்",
  "mainLifeLblPolicyTerm": "கொள்கை காலம்",
    "mainLifeLblPremTerm": "கட்டுபண காலம்",
    "mainLifeLblPolicyType": "Policy Paying Type",
    "mainLifeLblFrequency": "அடுக்கு நிகழ்வு",
    "mainLblSumAtRiskPrevPol": "முன்னைய காப்புறுதிகளின் இடர்த்தொகை",
  "mainLblNumOfSumAsTimes": "காப்புறுதித் தொகையின் தடவைகளின் எண்ணிக்கை",
  "mainLblMED-REM3Floater": "Family Floater",
  "mainLifeLblSumCover" : "Sum Cover"
  }
}
</i18n>
