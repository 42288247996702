<!-- Spouse Personal Details Widget-->
<template>
  <div class="col-sm-12 top-margin">
    <ValidationObserver ref="info_sp_form">
      <form @submit.prevent="onSubmit">
        <!-- Personal details row 1 Title, First Name, Mid Name, Last Name -->
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_spouse_title">{{ $t("langSpouseTitle") }}</label>
              <ValidationProvider name="Title">
                <div slot-scope="{ errors }">
                  <select
                    class="form-control"
                    name="lstSpouseTitle"
                    v-model="InfoSpouse.sp_title"
                    disabled
                  >
                    <option value="">Select</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Miss.">Miss.</option>
                    <option value="Ms.">Ms.</option>
                    <option value="Ven.">Ven.</option>
                    <option value="Dr.">Dr.</option>
                    <option value="Rev.">Rev.</option>
                  </select>
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_spouse_fname">{{ $t("langSpouseFName") }} <span class="required-mark">*</span></label>
              <ValidationProvider
                name="Spouse First Name"
                rules="required|minmax:3,60|alpha_spaces"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="txtSpouseFName"
                    name="SpFirstName"
                    placeholder="First Name"
                    v-model.trim="InfoSpouse.sp_first_name"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_spouse_mname">{{ $t("langSpouseMName") }}</label>
              <ValidationProvider
                name="Spouse Middle Name"
                rules="minmax:3,60|alpha_spaces"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="txtSpouseMName"
                    name="MiddleName"
                    placeholder="Middle Name"
                    v-model.trim="InfoSpouse.sp_middle_name"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_spouse_lname">{{ $t("langSpouseLName") }} <span class="required-mark">*</span></label>
              <ValidationProvider
                name="Spouse Last Name"
                rules="required|minmax:3,60|alpha_spaces"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="txtSpouseLName"
                    name="LastName"
                    placeholder="Last Name"
                    v-model.trim="InfoSpouse.sp_last_name"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <!-- Personal details row 1 End -->
        <!-- Personal details row 2  DOB, Age, Gender, Civil Status, Nationality, NIC, Mobile, Email, Add 1, Add 2 -->
        <div class="row">
          <div class="col-sm-2">
            <div class="form-group">
              <label for="lbl_spouse_dob">{{ $t("langSpouseDOB") }} <span class="required-mark">*</span></label>
              <ValidationProvider name="Spouse Date of Birth" rules="required">
                <div slot-scope="{ errors }">
                  <input
                    class="form-control"
                    id="txtSpouseDOB"
                    name="DateOfBirth"
                    v-model="InfoSpouse.sp_dob"
                    placeholder="Date of Birth"
                    type="Date"
                    readonly
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-1">
            <div class="form-group">
              <label for="lbl_spouse_age">{{ $t("langSpouseAge") }}</label>
              <input
                type="text"
                class="form-control"
                id="txtSpouseAge"
                name="Age"
                v-model="InfoSpouse.sp_age"
                readonly
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_spouse_gender">{{
                $t("langSpouseGender")
              }}</label>
              <div class="toggleswitchg">
                <input
                  id="spouse_male"
                  checked="checked"
                  name="chkSpouseGender"
                  v-model="InfoSpouse.sp_gender"
                  ref="chkSpouseGender"
                  type="radio"
                  value="Male"
                  disabled
                />
                <label for="spouse_male">
                  <i class="fa fa-mars"></i> Male
                </label>
                <input
                  id="spouse_female"
                  name="chkSpouseGender"
                  v-model="InfoSpouse.sp_gender"
                  ref="chkSpouseGender"
                  type="radio"
                  value="Female"
                  disabled
                />
                <label for="spouse_female">
                  <i class="fa fa-venus"></i> Female
                </label>
              </div>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_spouse_religion">{{
                $t("langSpouseReligion")
              }} <span class="required-mark">*</span></label>
              <ValidationProvider
                name="Spouse Religion"
                rules="required|alpha_spaces|minmax:3,20"
              >
                <div slot-scope="{ errors }">
                  <!-- <input
                    type="text"
                    class="form-control"
                    id="txtSpouseReligion"
                    name="Religion"
                    placeholder="Religion"
                    v-model.trim="InfoSpouse.sp_religion"
                    v-uppercase
                    @change="sendChangedData()"
                  /> -->
                  <v-select
                    class="select2"
                    style="width: 100%;"
                    v-model="InfoSpouse.sp_religion"
                    id="txtSpouseReligion"
                    name="Religion"
                    :options="religionsDrpOpt"
                    :reduce="(val) => val.code"
                    @change="sendChangedData()"
                  ></v-select>
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_spouse_nic">{{ $t("langSpouseNIC") }} <span class="required-mark">*</span></label>
              <ValidationProvider
                name="Spouse NIC Number"
                :rules="{ required: true, regex: /^(\d{9}?[V|X]{1}|\d{12})$/i }"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="txtSpouseNIC"
                    name="NIC"
                    placeholder="NIC Number"
                    v-model.trim="InfoSpouse.sp_nic"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <!-- Personal details row 3 City, Country Res, Country Perm Res, Other Citizenship -->
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_spouse_mobile">{{
                $t("langSpouseMobile")
              }}</label>
              <ValidationProvider
                name="Spouse Mobile No"
                :rules="{ regex: /^(\+[1-9]{1}[0-9]{3,14})?([0-9]{7,14})$/ }"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="txtSpouseMobile"
                    name="MobileNumber"
                    placeholder="Mobile Number"
                    v-model.trim="InfoSpouse.sp_mobile_no"
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_residence">Residence No</label>
              <ValidationProvider
                name="Residence Contact No"
                :rules="{ length: 10, regex: /^[\d]+$/i }"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="txtSpouseResidence"
                    placeholder="Residence Contact No"
                    name="Residence"
                    v-model.trim="InfoSpouse.sp_residence_no"
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_spouse_email">{{ $t("langSpouseEmail") }}</label>
              <ValidationProvider name="Spouse E-mail" rules="email|max:100">
                <div slot-scope="{ errors }">
                  <input
                    type="Email"
                    class="form-control"
                    id="txtSpouseEmail"
                    placeholder="Email"
                    name="Email"
                    v-model.trim="InfoSpouse.sp_email"
                    v-lowercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_spouse_citizenship">{{
                $t("langSpouseCitizenship")
              }} <span class="required-mark">*</span></label>
              <ValidationProvider
                name="Spouse Citizenship"
                rules="required|minmax:3,60"
              >
                <div slot-scope="{ errors }">
                  <!-- <input
                    type="text"
                    class="form-control"
                    id="txtSpouseCitizenship"
                    placeholder="Citizenship"
                    name="Spouse Citizenship"
                    v-model.trim="InfoSpouse.sp_citizenship"
                    v-uppercase
                    @change="sendChangedData()"
                  /> -->
                  <v-select
                    class="select2"
                    style="width: 100%;"
                    v-model="InfoSpouse.sp_citizenship"
                    name="Citizenship"
                    :options="nationalityDrpOpt"
                    :reduce="(val) => val.code"
                    @change="sendChangedData()"
                  ></v-select>
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <!-- Personal details row 3 End -->
        <!-- Personal details row 4 Monthly Income, Occupation, Nature of Duties, Name and address of employee -->

        <hr />
        <!-- Personal details row 4 End -->
        <!-- Personal details row 5 Start -->
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_spouse_occupation">{{
                $t("langSpouseOccupation")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="txtSpouseOccupation"
                placeholder="Occupation"
                name="Occupation"
                v-model.trim="InfoSpouse.sp_occupation"
                readonly
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_spouse_name_employee">{{
                $t("langSpouseEmployerName")
              }}</label> <span v-if="InfoInsured.contribution_paid_by == 'SPOUSE'" class="required-mark">*</span>
              <ValidationProvider
                name="Spouse Employer Name"
                :rules="`${
                            InfoInsured.contribution_paid_by == 'SPOUSE'
                              ? 'required|alpha_spaces|minmax:3,60'
                              : 'alpha_spaces|minmax:3,60'
                          }`"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="txtSpouseEmployerName"
                    placeholder="Employer Name"
                    name="Employer Name"
                    v-model.trim="InfoSpouse.sp_employer_name"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_emp_contact">{{
                $t("langSpouseAddLineOne")
              }}</label> <span v-if="InfoInsured.contribution_paid_by == 'SPOUSE'" class="required-mark">*</span>
              <ValidationProvider
                name="Spouse Employer Add Line 1"
                :rules="InfoInsured.contribution_paid_by == 'SPOUSE'
                              ? { required : true , min: 1, max: 30, regex: /^[a-z0-9\s,'-/]*$/i }
                              : { min: 1, max: 30, regex: /^[a-z0-9\s,'-/]*$/i }
                          "
              >
              
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="txtSpouseEmpAddLine1"
                    placeholder="Address Line 1"
                    name="txtSpouseEmpAddLine1"
                    v-model.trim="InfoSpouse.sp_emp_address_1"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <!-- { required : true , min: 3, max: 60, regex: /^[a-z0-9\s,'-/]*$/i } -->
              <label for="lbl_emp_contact">{{
                $t("langSpouseAddLineTwo")
              }}</label> <span v-if="InfoInsured.contribution_paid_by == 'SPOUSE'" class="required-mark">*</span>
              <ValidationProvider
                name="Spouse Employer Add Line 2"
                :rules="InfoInsured.contribution_paid_by == 'SPOUSE'
                              ? { required : true , min: 3, max: 60, regex: /^[a-z0-9\s,'-/]*$/i }
                              : { min: 3, max: 60, regex: /^[a-z0-9\s,'-/]*$/i }
                          "
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="txtSpouseEmpAddLine2"
                    placeholder="Address Line 2"
                    name="txtSpouseEmpAddLine2"
                    v-model.trim="InfoSpouse.sp_emp_address_2"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <!-- Personal details row 5 End -->

        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_emp_contact">{{ $t("langSpouseCity") }}</label> <span v-if="InfoInsured.contribution_paid_by == 'SPOUSE'" class="required-mark">*</span>
              <ValidationProvider
                name="Spouse Employer City"
                :rules="
                            InfoInsured.contribution_paid_by == 'SPOUSE'
                              ? { required : true , min: 3, max: 60, regex: /^[a-z\d\s]+$/i }
                              : { min: 3, max: 60, regex: /^[a-z\d\s]+$/i }
                          "
              >

              
              
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="txtSpouseEmpCity"
                    placeholder="City"
                    name="txtSpouseEmpCity"
                    v-model.trim="InfoSpouse.sp_emp_city"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_emp_contact">{{
                $t("langSpouseEmpCountry")
              }}</label> <span v-if="InfoInsured.contribution_paid_by == 'SPOUSE'" class="required-mark">*</span>
              <ValidationProvider
                name="Spouse Employer Country"
                :rules="`${
                            InfoInsured.contribution_paid_by == 'SPOUSE'
                              ? 'required|alpha_spaces|minmax:3,60'
                              : 'alpha_spaces|minmax:3,60'
                          }`"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="txtSpouseEmpCountry"
                    placeholder="Country"
                    name="txtSpouseEmpCountry"
                    v-model.trim="InfoSpouse.sp_emp_country"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_emp_contact">{{
                $t("langSpouseEmpContactNo")
              }}</label> <span v-if="InfoInsured.contribution_paid_by == 'SPOUSE'" class="required-mark">*</span>
              <ValidationProvider
                name="Employer Contact No"
                :rules="
                            InfoInsured.contribution_paid_by == 'SPOUSE'
                              ? { required : true , length: 10, regex: /^[\d]+$/i }
                              : { length: 10, regex: /^[\d]+$/i }
                          "
              >

              
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="txtSpouseEmpContact"
                    placeholder="Contact Number"
                    name="txtSpouseEmpContact"
                    v-model.trim="InfoSpouse.sp_emp_contact_no"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="lbl_spouse_monthly_income">{{
                $t("langSpouseMonthlyIncome")
              }}</label> <span v-if="InfoInsured.contribution_paid_by == 'SPOUSE'" class="required-mark">*</span>
              <ValidationProvider
                name="Monthly Income"
                :rules="`${
                            InfoInsured.contribution_paid_by == 'SPOUSE'
                              ? 'required|numeric|minmax_value:0,10000000'
                              : 'numeric|minmax_value:0,10000000'
                          }`"
              >
                <div slot-scope="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="txtSpouseMonthlyIncome"
                    placeholder="Monthly Income"
                    name="txtSpouseMonthlyIncome"
                    v-model.trim="InfoSpouse.sp_monthly_income"
                    v-uppercase
                    @change="sendChangedData()"
                  />
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <!-- Personal details row 6 End -->

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="lbl_spouse_nature_of_duties">{{
                $t("langSpouseNatOfDuties")
              }}</label> <span v-if="InfoInsured.contribution_paid_by == 'SPOUSE'" class="required-mark">*</span>
              <ValidationProvider
                name="Nature of Duties"
                :rules="`${
                            InfoInsured.contribution_paid_by == 'SPOUSE'
                              ? 'required|alpha_spaces|minmax:3,60'
                              : 'alpha_spaces|minmax:3,60'
                          }`"
              >
                <div slot-scope="{ errors }">
                  <b-form-textarea
                    id="txtSpouseNatOfDuties"
                    class="text-uppercase"
                    rows="3"
                    placeholder="Nature of Duties"
                    name="Nature of Duties"
                    v-model.trim="InfoSpouse.sp_nature_of_duties"
                    @change="sendChangedData()"
                  ></b-form-textarea>
                  <p class="p-error">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { throttle } from "lodash";

export default {
  name: "personal-details-Spouse-widget",
  data() {
    return {};
  },
  computed: {
    ...mapState("proposal", ["InfoInsured" , "InfoSpouse" , "religionsDrpOpt" , "nationalityDrpOpt"]),
  },
  methods: {
    ...mapActions("proposal", ["draftProposalData"]),
    sendChangedData: throttle(async function () {
      await this.draftProposalData();
    }, process.env.VUE_APP_TIMEOUT_SEND_CHANGED_DATA),
  },
};
</script>

<style scoped>
/* -- inline error alert ---*/
.p-error {
  margin: 8px 0 10px !important;
  color: #e60d0d !important;
  /*background-color:#fff3f3;
  padding: 10px;*/
  border-radius: 3px;
  font-size: small;
}

/* --- toggle switch -----*/
.toggleswitchg input:checked + label {
  background: #e9ecef !important;
  color: #727b83 !important;
  font-weight: 600 !important;
  border: 1px solid #ced4da !important;
}

/*-- toggle switch for Gender of main life ----*/

.toggleswitchg label {
  display: inline-block;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  padding: 4px 10px;
  margin: 2px;
  font-weight: normal;
  color: #888a8c;
}

/* Don't hide the radio button in older browsers */
.toggleswitchg input:checked,
.toggleswitchg input:not(:checked) {
  display: none;
}

.toggleswitchg input:checked + label {
  background: #039fec;
  color: #fff;
  font-weight: 600;
  border: 1px solid #039fec;
}
</style>

<!-- Language Data for Personal Details Spouse Widget -->
<i18n>
{
  "en": {    
    "langSpouseTitle": "Title",
    "langSpouseFName": "First Name",
    "langSpouseMName": "Middle Name",
    "langSpouseLName": "Last Name",
    "langSpouseDOB": "Date of Birth (MM/DD/YYYY)",
    "langSpouseAge": "Age",
    
    "langSpouseGender": "Gender",
    "langSpouseCivilStatus": "Civil Status",
    "langSpouseReligion": "Religion",
    "langSpouseNIC": "NIC No",
    "langSpouseMobile": "Mobile No",
    "langSpouseEmail": "Email",
    "langSpouseAddLineOne": "Address Line 1",
    "langSpouseAddLineTwo": "Address Line 2",

    "langSpouseCity": "City",
    "langSpouseCitizenship": "Nationality/Citizenship",
    
    "langSpouseMonthlyIncome": "Monthly Income",
    "langSpouseOccupation": "Occupation",
    "langSpouseNatOfDuties": "Nature of Duties",
    "langSpouseEmployerName": "Employer Name",
    "langSpouseEmpCountry": "Employer Country",
    "langSpouseEmpContactNo": "Employer Contact No"
  },
  "si": {
    "langSpouseTitle": "ශීර්ෂය",
    "langSpouseFName": "මුල් නම",
    "langSpouseMName": "මැද නම",
    "langSpouseLName": "අවසන් නම",
    "langSpouseDOB": "උපන් දිනය (MM/DD/YYYY)",
    "langSpouseAge": "වයස",

    "langSpouseGender": "ස්ත්‍රී පුරුෂ භාවය",
    "langSpouseCivilStatus": "විවාහක/අවිවාහක තත්වය",
    "langSpouseReligion": "ආගම",
    "langSpouseNIC": "ජා.හැ.අංකය",
    "langSpouseMobile": "දුරකථන අංකය",
    "langSpouseEmail": "විද්‍යුත් තැපෑල",
    "langSpouseAddLineOne": "ලිපිනය තීරුව 1",
    "langSpouseAddLineTwo": "ලිපිනය තීරුව 2",

    "langSpouseCity": "නගරය",
    "langSpouseCitizenship": "පදිංචි රට",

    "langSpouseMonthlyIncome": "මාසික ආදායම",
    "langSpouseOccupation": "රැකියාව",
    "langSpouseNatOfDuties": "රාජකාරි වල ස්වභාවය",
    "langSpouseEmployerName": "සේවා යෝජකයාගේ නම",
    "langSpouseEmpCountry": "සේවා යෝජකයාගේ රට",
    "langSpouseEmpContactNo": "සේවා යෝජකයාගේ දුරකථන අංකය"
  },
  "ta": {
    "langSpouseTitle": "படிநிலை",
    "langSpouseFName": "முதற் பெயர்",
    "langSpouseMName": "நடுத்தர பெயர்",
    "langSpouseLName": "கடைசி பெயர்",
    "langSpouseDOB": "பிறந்த திகதி (MM/DD/YYYY)",
    "langSpouseAge": "வயது",

    "langSpouseGender": "பால்",
    "langSpouseCivilStatus": "திருமணம்/திருமணமாகாத",
    "langSpouseReligion": "மதம்",
    "langSpouseNIC": "தே. அ. அ. இலக்கம்",
    "langSpouseMobile": "தொலைபேசி இலக்கம்",
    "langSpouseEmail": "மின்னஞ்சல்",
    "langSpouseAddLineOne": "முகவரி வரி 1",
    "langSpouseAddLineTwo": "முகவரி வரி 2",

    "langSpouseCity": "நகரம்",
    "langSpouseCitizenship": "வசிக்கும் நாடு",

    "langSpouseMonthlyIncome": "மாத வருமானம்",
    "langSpouseOccupation": "தொழில்",
    "langSpouseNatOfDuties": "கடமைகளின் தன்மை",
    "langSpouseEmployerName": "முதலாளியின் பெயர்",
    "langSpouseEmpCountry": "முதலாளி நாடு",
    "langSpouseEmpContactNo": "முதலாளி தொலைபேசி எண்"
  }
}
</i18n>
