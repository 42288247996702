<template>
  <div class="container-fluid">
    <div class="row row-top">
      <div class="card-2">
        <div class="card-header-2">
          <font-awesome-icon icon="search" />&nbsp;
          <span>{{ $t("search_panel") }}</span>
        </div>
        <div class="card-body">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label>{{ $t("branch_name") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Branch Name"
                    v-model="branch_name"
                    @keyup.enter="doFilter"
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>{{ $t("branch_code") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Branch Code"
                    v-model="branch_code"
                    @keyup.enter="doFilter"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t("status") }}</label>
                  <select class="form-control" name="status" v-model="status" aria-placeholder="Status">
                    <option value="">--Select Status--</option>
                    <option value="1">Active</option>
                    <option value="0">Deactivated</option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group text-right">
                  <div class="row">
                    <div class="col-md-8 filter-btn">
                      <button
                        type="button"
                        id="search"
                        class="btn btn-sm btn-success btn-right"
                        @click="doFilter"
                      >
                        <font-awesome-icon icon="search" />
                        {{ $t("search") }}
                      </button>
                      <button
                        class="btn btn-sm btn-danger"
                        type="reset"
                        id="search_reset"
                        @click="resetFilter"
                      >
                        <font-awesome-icon icon="ban" />
                        {{ $t("reset") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";

Vue.use(require("vue-moment"));
import JsonCSV from "vue-json-csv";
Vue.component("downloadCsv", JsonCSV);

export default {
  data() {
    return {
      branch_name: "",
      branch_code: "",
      status: "",
    };
  },
  components: {},
  async created() {
    this.exportBranchCSV();
  },
  computed: {
    ...mapState("branch", ["highlighted", "BranchTable", "exportCSV"]),
  },
  methods: {
    ...mapActions("branch", ["refreshBranchTable", "getCSVExport"]),
    doFilter() {
      let filters = {
        branch_name: this.branch_name,
        branch_code: this.branch_code,
        status: this.status,
      };

      this.$events.fire("filter-set", filters);
    },
    resetFilter() {
      this.branch_name = "";
      this.branch_code = "";
      this.status = "";
      this.$parent.$refs.brListTableRef.onFilterReset();
    },

    exportBranchCSV() {
      let filters = {
        branch_name: this.branch_name,
        branch_code: this.branch_code,
        status: this.status,
      };

      this.getCSVExport(filters);
    },
  },
};
</script>


<style>
.btn-right {
  margin-right: 5px;
}
.datep > div > input {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 12px;
  color: #495057;
}

.filter-btn {
  padding-top: 20px;
}

@media (max-width: 768px) {
  div.card {
    margin-top: 100px;
  }
}
</style>

<i18n>
{
  "en": {
    "search_panel":"Search Panel",
    "branch_name": "Branch Name",
    "branch_code": "Branch Code",
    "status": "Status",
    "search": "Search",
    "reset" : "Reset"
  },
  "si": {
    "search_panel":"Search Panel",
    "branch_name": "Branch Name",
    "branch_code": "Branch Code",
    "status": "Status",
    "search": "Search",
    "reset" : "නැවත සකසන්න"
  },
  "ta": {
    "search_panel":"Search Panel",
    "branch_name": "Branch Name",
    "branch_code": "Branch Code",
    "status": "Status",
    "search": "Search",
    "reset" : "மீட்டமைத்தல்"
  }
}

</i18n>