import Vue from "vue";
import Vuex from "vuex";
import login from "@/store/modules/login";
import quote_platinum from "@/store/modules/quote_platinum";
import quote_platinum_plus from "@/store/modules/quote_platinum_plus";
import quote_surakshitha from "@/store/modules/quote_surakshitha";
import quote_adyapana_plus from "@/store/modules/quote_adyapana_plus";
import quote_deergayu from "@/store/modules/quote_deergayu";
import quote_flexi_life from "@/store/modules/quote_flexi_life";
import quote_term_plus_cic from "@/store/modules/quote_term_plus_cic";
import quote_health_plus from "@/store/modules/quote_health_plus";
import quote_mrtt from "@/store/modules/quote_mrtt";
import proposal from "@/store/modules/proposal";
import lead from "@/store/modules/lead";
import pension_calc from "@/store/modules/pension_calc";
import branch from "@/store/modules/branch";
import role from "@/store/modules/role";
import user from "@/store/modules/user";
import common_actions from "@/store/modules/common_actions";
import export_report from "@/store/modules/export";

/** Snotify notifications */
import { SnotifyPosition, SnotifyStyle } from "vue-snotify";

Vue.use(Vuex);

const namespaced = true;

export default new Vuex.Store({
  namespaced,
  modules: {
    login,
    quote_platinum,
    quote_surakshitha,
    quote_platinum_plus,
    quote_adyapana_plus,
    quote_deergayu,
    quote_flexi_life,
    quote_term_plus_cic,
    quote_health_plus,
    quote_mrtt,
    proposal,
    lead,
    pension_calc,
    branch,
    role,
    user,
    common_actions,
    export_report
  },
  state: {},
  mutations: {
    showNotification: (state, data) => {
      let type = "";

      switch (data.type) {
        case "error": {
          type = SnotifyStyle.error;
          break;
        }
        case "success": {
          type = SnotifyStyle.success;
          break;
        }
        case "info": {
          type = SnotifyStyle.info;
          break;
        }
        case "warning": {
          type = SnotifyStyle.warning;
          break;
        }
        case "async": {
          type = SnotifyStyle.async;
          break;
        }
        case "confirm": {
          type = SnotifyStyle.confirm;
          break;
        }
        case "prompt": {
          type = SnotifyStyle.prompt;
          break;
        }
        default: {
          type = SnotifyStyle.simple;
          break;
        }
      }
      Vue.$snotify.clear();

      Vue.$snotify.create({
        title: data.title,
        body: data.msg,
        config: {
          position: SnotifyPosition.centerTop,
          type: type,
          timeout: 5000,
          closeOnClick: true,
          pauseOnHover: true
        }
      })
    }
  },
  actions: {}
});
